/* eslint-disable */
import React, { useState } from 'react';
import { Paper, TextField, Button } from '@material-ui/core';
import styled from 'styled-components/macro';
import API from '../../../../axios/instances/API';

const ModalWrapper = styled(Paper)`
  padding: 12px;
  width: 460px;
  max-height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const EditDescriptionModal = ({ details, setSnackbarText, handleClose, mutate }) => {
  const [text, setText] = useState(details?.description);

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      const sendObj = {
        id: details?.id,
        name: details?.name,
        description: text,
        estimated_time: details?.estimatedTime,
        language: details?.language,
        due_date: details?.dueDate,
        start_date: details?.startDate,
        display_answers: details?.displayAnswers,
        disclaimer: details?.disclaimer,
        disclaimer_text: details?.disclaimerText,
      };
      await API.put('dashboard/quiz/details/', sendObj);
      await mutate();
      setIsUpdating(false);

      setSnackbarText('Module description updated success');
      handleClose();
    } catch (error) {
      setIsUpdating(false);
      setSnackbarText('Failed to update module name error');
    }
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 15, fontWeight: '600' }}>Edit Module Description</span>
      <div style={{ height: 8 }} />
      <TextField
        multiline
        minRows={3}
        fullWidth
        variant="outlined"
        size="small"
        color="secondary"
        helperText={text.length < 3 ? '*3 characters minimum' : false}
        onChange={e => setText(e.target.value)}
        value={text}
        inputProps={{
          maxLength: 300,
        }}
      />
      <div style={{ height: 8 }} />
      <Button
        color="secondary"
        variant="contained"
        size="small"
        style={{ alignSelf: 'flex-end' }}
        onClick={handleSave}
        disabled={text.length < 3 || text === details?.description || isUpdating}
      >
        {isUpdating ? '...' : 'Save'}
      </Button>
    </ModalWrapper>
  );
};

export default EditDescriptionModal;
