/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from '../../../styles';
import LaunchModal from './LaunchModal';
import RetailChecklist from './RetailChecklist';

const MatchWrapper = styled.div`
  min-height: 480px;
  width: 100%;
  flex: 1;
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;

const LaunchButton = styled(Button)`
  background: #337ab7;
  color: white;

  &:disabled {
    background: lightgray;
  }

  &:hover {
    background: #1f74be;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const RetailLaunch = ({
  id,
  steps,
  setSnackbarText,
  details,
  isModuleLive,
  isModulePending,
  isModuleConvertingNextStateDev,
  isModuleConvertingNextStateLive,
}) => {
  const [launchModalOpen, setLaunchModalOpen] = useState(false);

  const launchDisabled =
    !steps[0].complete ||
    !steps[1].complete ||
    !steps[2].complete ||
    !steps[3].complete ||
    isModuleLive ||
    isModulePending;

  return (
    <>
      <MatchWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 6,

            width: '100%',
          }}
        >
          <span style={{ fontSize: 16, fontWeight: '600', color: 'black' }}>Launch Module</span>
        </div>
        <RetailChecklist
          details={details}
          steps={steps}
        />
        <ContentWrapper>
          {isModuleConvertingNextStateDev && !launchDisabled && (
            <Alert
              severity="info"
              style={{ width: '100%' }}
            >
              <p style={{ fontSize: 13, fontWeight: '600' }}>Video Transcoding</p>
              <p>You can launch now, the module will go live when video finishes transcoding</p>
            </Alert>
          )}
        </ContentWrapper>
        <div style={{ width: '100%', marginTop: 'auto' }} />

        <div
          style={{
            width: '100%',

            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          <LaunchButton
            disabled={launchDisabled || isModuleConvertingNextStateLive}
            onClick={() => setLaunchModalOpen(true)}
          >
            Launch Module 🚀
          </LaunchButton>
        </div>
      </MatchWrapper>
      <Modal
        open={launchModalOpen}
        onClose={() => setLaunchModalOpen(false)}
      >
        <LaunchModal
          data={details}
          setSnackbarText={setSnackbarText}
          handleClose={() => setLaunchModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default RetailLaunch;

RetailLaunch.propTypes = {
  id: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  hasVideoConverting: PropTypes.bool.isRequired,
  setPassScoreModalOpen: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
  setIncompleteError: PropTypes.func.isRequired,
};
