/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import useVendorUsersBreakdown from '../../../../../swr/hooks/VendorReports/useVendorUsersBreakdown';
import { ENGAGEMENT_TYPES } from '../../../../../storage/constants';
import EngagementTypeSelect from '../../reuseableComponents/EngagementTypeSelect';
import UsersTable from '../../../engagedUsers/UsersTable';
import { Alert } from '../../../styles';
import Loader from '../Loader';
const TableWrapper = styled.div`
  min-width: 420px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const UsersBreakdown = () => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  const { isLoading, data, isError } = useVendorUsersBreakdown();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) return <Alert severity="error">Unable to load module report, please refresh</Alert>;

  return (
    <TableWrapper>
      <EngagementTypeSelect
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        isVendor
      />
      <div style={{ height: 12 }} />
      <UsersTable
        data={data.users_breakdown}
        engagementType={engagementType}
      />
    </TableWrapper>
  );
};

export default UsersBreakdown;
