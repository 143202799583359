/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import { CustomDatatableV2 } from "../styles";
import { RefreshCw, Triangle } from "react-feather";
import API from "../../../axios/instances/API";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import TableLoader from "../../../components/Loaders/TableLoader";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";
import { addDays } from "date-fns";
import useTeamInvitations from "../../../swr/hooks/Team/useTeamInvitations";

const options = makeDefaultTableOptions("team_member_outstanding_invitiations");
options.sortOrder = {
  name: "created",
  direction: "desc",
};
options.textLabels = {
  body: {
    noMatch:
      'No outstanding invitations pending. Click the "Invite Team Member" button above to send invitations to new team members!',
  },
};
options.rowsPerPage = 100;

const OutstandingInvitationsTable = ({ readOnly }) => {
  const { isLoading, data, mutate, isError } = useTeamInvitations();

  const [snackbarText, setSnackbarText] = useState("");
  const [confirmInfo, setConfirmInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (isLoading) return <TableLoader />;

  const { invitations } = data;

  const options = makeDefaultTableOptions(
    "team_member_outstanding_invitiations"
  );
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.textLabels = {
    body: {
      noMatch:
        'No outstanding invitations pending. Click the "Invite Team Member" button above to send invitations to new team members!',
    },
  };
  options.rowsPerPage = 100;
  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const resendInvitation = async () => {
    try {
      await API.put("dashboard/teams/invitations/details/", {
        access_token: confirmInfo?.token,
      });
      setSnackbarText(`Resent invitation to ${confirmInfo?.name} success`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to resend invitation to ${confirmInfo?.name}, please try again. error`
      );
      setConfirmInfo(null);
    }
  };

  const revokeInvitation = async () => {
    try {
      await API.delete("dashboard/teams/invitations/details/", {
        data: {
          access_token: confirmInfo?.token,
        },
      });
      await mutate();
      setSnackbarText(`Deleted invitation to ${confirmInfo?.name} warning`);
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to delete invitation to ${confirmInfo?.name}, please try again. error`
      );
      setConfirmInfo(null);
    }
  };

  const tableData = invitations.map((invite) => ({
    id: invite.access_token,
    name: `${invite.first_name} ${invite.last_name}`,
    email: invite.email ? invite.email : "N/A",
    sentBy:
      Boolean(invite.sent_by_first_name) && Boolean(invite.sent_by_last_name)
        ? `${invite.sent_by_first_name} ${invite.sent_by_last_name}`
        : "---/---",
    created: new Date(invite.created).toISOString(),
    expires: new Date(invite.expiration_date).toISOString().split("T")[0],
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sentBy",
      label: "Sent By",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "created",
      label: "Sent",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} />
          // <span>{fixIncomingDate(value.split("T")[0]).toDateString()}</span>
        ),
      },
    },
    {
      name: "expires",
      label: "Expires",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={addDays(new Date(value), 1)} />
          // <span>{fixIncomingDate(value).toDateString()}</span>
        ),
      },
    },
    {
      name: "resend",
      label: "Resend",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="resend inviation"
            Icon={RefreshCw}
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setConfirmInfo({
                token: rowData[0],
                name: rowData[1],
                type: "resend",
              });
            }}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={() => {
              const { rowData } = tableMeta;
              setConfirmInfo({
                token: rowData[0],
                name: rowData[1],
                type: "delete",
              });
            }}
          />
        ),
      },
    },
  ];
  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            title="Team member outstanding invitations"
            Icon={Triangle}
          />
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(confirmInfo)}
        onClose={() => setConfirmInfo(null)}
        aria-labelledby="Delete a Team Member Invite"
        aria-describedby="Delete a Team Member Invite"
      >
        <div>
          <ConfirmModalSmall
            title={
              confirmInfo?.type === "delete"
                ? "Delete invite to"
                : "Resend invite to"
            }
            name={confirmInfo?.name}
            handleConfirm={
              confirmInfo?.type === "delete"
                ? revokeInvitation
                : resendInvitation
            }
            confirmAccept={confirmInfo?.type !== "delete"}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default OutstandingInvitationsTable;

OutstandingInvitationsTable.propTypes = {
  invites: PropTypes.array,
  mutate: PropTypes.func,
  invitesLoading: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
};

OutstandingInvitationsTable.defaultProps = {
  invites: null,
  mutate: null,
  invitesLoading: null,
};
