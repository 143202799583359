/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Bar, Line } from "react-chartjs-2";
import { getCorrectLabels } from "./utils";
import { MenuItem, Select, FormControl } from "@material-ui/core";

const ChartWrapper = styled.div`
  width: 100%;
  height: 390px;
`;

const ExtraToggleHolder = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  min-height: 30px;
  width: 160px;
`;

const UNIQUE_CHART_OPTIONS = {
  CUMULATIVE: "Cumulative",
  TIME_PERIOD: "Time Period",
};

const FollowersChart = ({ data, frequency }) => {
  const [selectedMetric, setSelectedMetric] = useState(
    UNIQUE_CHART_OPTIONS.TIME_PERIOD
  );

  const labels = getCorrectLabels(data, frequency);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // label: (ctx) => `New Followers: ${ctx.raw}`,
          label: () => "",
          beforeBody: (context) => `New Followers: ${context[0].raw}`,
          //   beforeTitle: (context) =>
          //   `Submissions: ${chartCount[context[0].dataIndex]}`,
          // // afterTitle: context => `Completions: ${passedCount[context[0].dataIndex]}`,
          // title: () => "",
          // label: () => "",
          // beforeBody: (context) => `Week start: ${context[0].label}`,
          // afterBody: (context) => getWeekEnd(context[0].label),
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0,0,0,0.03)",
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: {
          color: "rgba(0,0,0,0.03)",
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        data: data.map((v) => v.followers_added_per_period),
        borderWidth: 1,
        borderColor: "black",
        borderRadius: 4,
        backgroundColor: "rgba(100, 181, 246, 0.4)",
      },
    ],
  };

  const chartDataLine = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 160);
    gradient.addColorStop(0, "rgba(100, 181, 246, 0.6");
    gradient.addColorStop(1, "rgba(100, 181, 246, 0.1");

    const datasets = [
      {
        data: data.map(
          (v) => v.followers_added_per_period + v.followers_before
        ),
        fill: {
          target: "origin",
          above: gradient,
        },
        borderColor: "slategrey",
        pointBorderColor: "black",
        tension: 0.4,
        pointBackgroundColor: "black",
      },
    ];

    return {
      labels,
      type: "line",
      datasets,
    };
  };

  const chartOptionsLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => "",
          title: (context) => {
            const numUsers = data[context[0].dataIndex]?.followers_after;
            return `Followers: ${numUsers}`;
          },
          afterTitle: (context) => {
            const numAdded =
              data[context[0].dataIndex]?.followers_added_per_period;

            return numAdded ? `(+${numAdded} new)` : "";
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <ChartWrapper>
      {selectedMetric === UNIQUE_CHART_OPTIONS.TIME_PERIOD ? (
        <Bar options={options} data={chartData} />
      ) : (
        <Line data={chartDataLine} options={chartOptionsLine} />
      )}
      <ExtraToggleHolder>
        <FormControl size="small" fullWidth>
          <Select
            variant="outlined"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            size="small"
          >
            <MenuItem value={UNIQUE_CHART_OPTIONS.TIME_PERIOD}>
              By Week/Month
            </MenuItem>
            <MenuItem value={UNIQUE_CHART_OPTIONS.CUMULATIVE}>
              Over Time
            </MenuItem>
          </Select>
        </FormControl>
      </ExtraToggleHolder>
    </ChartWrapper>
  );
};

export default FollowersChart;

FollowersChart.propTypes = {
  data: PropTypes.object.isRequired,
  frequency: PropTypes.string.isRequired,
};
