import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search, X, Download } from 'react-feather';
import styled from 'styled-components/macro';
import { Tooltip, Menu, MenuItem } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { TextField, Button } from '../../styles';

const StyledButton = styled(Button)`
  height: 34px;
  width: 34px;
  min-width: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 40px; 
  display: flex; 
  align-items: center;
  justify-content: flex-end;
`;

const CustomToolBar = ({
  setSearchText,
  isLoading,
  setPageNumber,
  currentSubmissions,
  retDataUnpaginated,
}) => {
  const [text, setText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadingData, setDownloadingData] = useState(false);

  const open = Boolean(anchorEl);

  // eslint-disable-next-line arrow-body-style
  const currentSubmissionsWithTeamClean = currentSubmissions.map(row => {
    return ({
      ...row,
      team: !row.team ? 'No Team' : row.team.map(t => `${t.company} | ${t.location}`).join(', '),
    });
  });

  const getUnpaginatedData = async () => {
    setDownloadingData(true);
    const data = await retDataUnpaginated();
    setDownloadData(data);
    setDownloadingData(false);
  };

  useEffect(() => {
    if (!open) return null;

    return getUnpaginatedData();
  }, [open]);

  return (
    <Wrapper>
      <TextField
        variant="outlined"
        placeholder="Search Submissions..."
        style={{ width: 220, marginRight: 6 }}
        size="small"
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <StyledButton
        disabled={text.length < 3 || isLoading}
        onClick={() => {
          setPageNumber(1);
          setSearchText(text);
        }}
        variant="contained"
        color="primary"
      >
        <Search height={16} width={16} />
      </StyledButton>
      {
        text.length >= 1 && (
          <StyledButton
            disabled={text.length < 3 || isLoading}
            ml={1}
            onClick={() => {
              setSearchText('');
              setText('');
            }}
            variant="standard"
            color="inherit"
          >
            <X height={16} width={16} />
          </StyledButton>
        )
      }
      <Tooltip title="Download">
        <StyledButton
          variant="standard"
          color="inherit"
          ml={1}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <Download height={16} width={16} />
        </StyledButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          style={{ fontSize: 10 }}
        >
          <CSVLink
            data={currentSubmissionsWithTeamClean || []}
            filename="page_submissions.csv"
            style={{ textDecoration: 'none', fontSize: 11, color: 'black' }}
          >
            Download Current Page
          </CSVLink>
        </MenuItem>
        <MenuItem
          style={{ fontSize: 10 }}
          disabled={downloadingData}
        >
          <CSVLink
            data={downloadingData ? [] : downloadData}
            filename="all_submissions.csv"
            disabled={downloadingData}
            style={{ textDecoration: 'none', fontSize: 11, color: 'black' }}
          >
            Download All
          </CSVLink>
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default CustomToolBar;

CustomToolBar.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  currentSubmissions: PropTypes.array.isRequired,
  retDataUnpaginated: PropTypes.func.isRequired,
};
