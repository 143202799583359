/* eslint-disable */
import React, { useReducer } from "react";
import RequiredChip from "../UI/RequiredChip";
import PropTypes from "prop-types";
import HalfPanel from "../UI/HalfPanel";
import Quizbuilder from "./Quizbuilder";
import builderReducer from "./state/builderReducer";
import { QUESTION_TYPES } from "../../../../storage/constants";
import ShowQuiz from "./ShowQuiz";
import ScoreToPass from "./ScoreToPass";

const initalState = {
  editInfo: { isEditing: false, editId: null, order: null },
  passScore: 0,
  quizType: QUESTION_TYPES.TRUE_FALSE,
};

const Builder = ({
  id,
  details,
  isModuleLive,
  setSnackbarText,
  mutate,
  isModulePending,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  const [state, dispatch] = useReducer(builderReducer, initalState);
  const builderContext = { state, dispatch };

  return (
    <>
      <HalfPanel
        title="Add Questions"
        RightComponent={() => (
          <RequiredChip
            completed={details?.questions?.questions?.length > 0}
            title="Required"
            small
            requirementText="Every module must have at least 1 question"
          />
        )}
      >
        <Quizbuilder
          id={id}
          builderContext={builderContext}
          isModuleLive={isModuleLive}
          details={details}
          setSnackbarText={setSnackbarText}
          mutate={mutate}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        />
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />
      <HalfPanel title="Review Questions">
        <ShowQuiz
          id={id}
          details={details}
          builderContext={builderContext}
          questions={details?.questions?.questions}
          setSnackbarText={setSnackbarText}
          isModuleLive={isModuleLive}
          isModulePending={isModulePending}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isModuleConvertingNextStatePending={
            isModuleConvertingNextStatePending
          }
        />
        {details?.questions?.questions?.length > 0 && (
          <ScoreToPass
            details={details}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
          />
        )}
      </HalfPanel>
    </>
  );
};

export default Builder;

Builder.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
};
