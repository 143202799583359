/* eslint-disable */
import React from "react";
import { Alert } from "@material-ui/lab";
import styled from "styled-components/macro";

const HighlightTag = styled.a`
  font-size: 12px;
  color: rgb(51, 122, 183);
  font-weight: 700;

  text-decoration: underline;
`;

const NotApprovedAlert = () => {
  return (
    <Alert severity="warning" mb={5} mt={-4}>
      <p style={{ fontWeight: "700", fontSize: 13 }}>
        Company awaiting approval
      </p>
      <ul>
        <li>
          Some functionality will be limited until the company is approved.
        </li>
        <li>
          You can check out our{" "}
          <HighlightTag
            href="https://knowledge.withspiffy.com/knowledge/introducing-the-spiffy-dashboard"
            target="_blank"
          >
            Understanding the Dashboard
          </HighlightTag>{" "}
          guide to get an overview of the menu and the functionality within each
          tab in the meantime.
        </li>
      </ul>
    </Alert>
  );
};

export default NotApprovedAlert;
