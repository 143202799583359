/* eslint-disable */

import { Alert } from "@material-ui/lab";

import { isWithinInterval } from "date-fns";
import React, { useState } from "react";
import styled from "styled-components/macro";

import useContentRequests from "../../../../../swr/hooks/VendorReports/useContentRequests";
import CompanyTable from "./CompanyTable";
import UserTable from "./UserTable";

import Loader from "../Loader";
import DateRangeSelector from "../../../../../components/DateRangeSelector";

const TablesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 12px;
  justify-content: stretch;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const getCompanyCounts = (data) => {
  try {
    const retObj = {};

    data.forEach((request) => {
      request.user.companies.forEach((company) => {
        if (retObj.hasOwnProperty(company.id)) {
          retObj[company.id].count += 1;
        } else {
          retObj[company.id] = {
            count: 1,
            name: company.name,
            logo: company.logo,
            id: company.id,
          };
        }
      });
    });
    return retObj;
  } catch (error) {
    return {};
  }
};

const getFilteredContentRequestsV2 = (
  data,
  startDate,
  endDate,
  filterActive
) => {
  if (!filterActive || !endDate || !startDate) return data;

  const filtered = data.filter((req) =>
    isWithinInterval(new Date(req.created), {
      start: startDate,
      end: endDate,
    })
  );
  return filtered;
};

const ContentRequests = () => {
  const { isLoading, isError, data } = useContentRequests();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [filterActive, setFilterActive] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Alert severity="error" style={{ width: "100%" }}>
        Unable to load content requests report, please refresh
      </Alert>
    );
  }

  const filteredContentRequestsV2 = getFilteredContentRequestsV2(
    data,
    startDate,
    endDate,
    filterActive
  );

  const requestsByCompany = getCompanyCounts(filteredContentRequestsV2);

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <DateRangeSelector
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setFilterActive={setFilterActive}
        filterActive={filterActive}
      />
      <div style={{ height: 16 }} />
      <TablesContainer>
        <CompanyTable data={requestsByCompany} />
        <UserTable data={filteredContentRequestsV2} />
      </TablesContainer>
    </div>
  );
};

export default ContentRequests;
