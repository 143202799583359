/* eslint-disable */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import { AlertTriangle, Check } from 'react-feather';
import styled from 'styled-components/macro';
import { Typography } from '../../../styles';

const Wrapper = styled.div`
  padding: 0px;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

const HoldList = styled.div`
  flex: 0.25;
  min-width: 200px;
`;

const FlexDiv = styled.div`
  padding: 2px 6px;
  background-color: #eeeeee10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  border: 1px solid #eeeeee;
  border-radius: 4px;

  p {
    font-size: 11px;
    font-weight: 400;
  }
`;

const ChecklistContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
`;

const FlexDivHighlight = styled(FlexDiv)`
  padding: 2px 8px;
  background-color: #eeeeee;
  flex: 0.25;
  margin-top: 8px;
  border-radius: 4px;
`;

const GreenCheck = styled(Check)`
  height: 12px;
  width: 12px;
  color: mediumseagreen;
  padding: 2px;
  border-radius: 2px;

  background-color: #eeeeee;
  margin-right: 3px;
`;

const RedX = styled(AlertTriangle)`
  height: 12px;
  width: 12px;
  color: darkorange;
  border: 1px solid lightgray;
  padding: 2px;
  border-radius: 2px;
  background: white;
  margin-right: 3px;
`;

const CheckDiv = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 2px;
  background-color: seagreen;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    height: 12px;
    width: 12px;
  }
`;

const WarningDiv = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid lightgray;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: darkorange;
  }
`;

const RetailChecklist = ({ details, steps }) => (
  <>
    <Wrapper>
      <ChecklistContainer>
        <HoldList>
          <FlexDivHighlight isComplete={steps[0].complete ? 'yes' : 'no'}>
            <Typography style={{ fontWeight: '600', fontSize: 14 }}>Basics</Typography>
            <>
              {steps[0].complete ? (
                <CheckDiv>
                  <Check />
                </CheckDiv>
              ) : (
                <WarningDiv>
                  <AlertTriangle />
                </WarningDiv>
              )}
            </>
          </FlexDivHighlight>
          <FlexDiv>
            <p>Name Set</p>
            {details.name ? <GreenCheck /> : <RedX />}
          </FlexDiv>
          <FlexDiv>
            <p>Start Date Set</p>
            {details.startDate ? <GreenCheck /> : <RedX />}
          </FlexDiv>
          <FlexDiv>
            <p>Description Set</p>
            {details.description ? <GreenCheck /> : <RedX />}
          </FlexDiv>

          <FlexDiv>
            <p>Estimated Time Set</p>
            {details.estimatedTime ? <GreenCheck /> : <RedX />}
          </FlexDiv>
        </HoldList>
        <HoldList>
          <FlexDivHighlight isComplete={steps[1].complete ? 'yes' : 'no'}>
            <Typography style={{ fontWeight: '600', fontSize: 14 }}>Content</Typography>
            <>
              {steps[1].complete ? (
                <CheckDiv>
                  <Check />
                </CheckDiv>
              ) : (
                <WarningDiv>
                  <AlertTriangle />
                </WarningDiv>
              )}
            </>
          </FlexDivHighlight>

          <FlexDiv>
            <p>Content Piece Set</p>
            {steps[1].complete ? <GreenCheck /> : <RedX />}
          </FlexDiv>
          <FlexDiv>
            <p>Content Type Selected</p>
            {!steps[1].complete ? (
              <span style={{ fontSize: 11, fontWeight: '600' }}>none</span>
            ) : details.media && details.video ? (
              <span style={{ fontSize: 11, fontWeight: '600' }}>Video & PDF</span>
            ) : details.media ? (
              <span style={{ fontSize: 11, fontWeight: '600' }}>PDF</span>
            ) : (
              <span style={{ fontSize: 11, fontWeight: '600' }}>Video</span>
            )}
          </FlexDiv>
        </HoldList>
        <HoldList>
          <FlexDivHighlight isComplete={steps[2].complete ? 'yes' : 'no'}>
            <Typography style={{ fontWeight: '600', fontSize: 14 }}>Questions</Typography>
            <>
              {steps[2].complete ? (
                <CheckDiv>
                  <Check />
                </CheckDiv>
              ) : (
                <WarningDiv>
                  <AlertTriangle />
                </WarningDiv>
              )}
            </>
          </FlexDivHighlight>

          <FlexDiv>
            <p>Minimum 1 question</p>
            {details.questions.questions.length ? <GreenCheck /> : <RedX />}
          </FlexDiv>
          <FlexDiv>
            <p>Score To Pass</p>
            <span style={{ fontSize: 11, fontWeight: '600' }}>
              {details.passScore}/{details.questions.questions.length}
            </span>
          </FlexDiv>
        </HoldList>
        <HoldList>
          <FlexDivHighlight isComplete={steps[3].complete ? 'yes' : 'no'}>
            <Typography style={{ fontWeight: '600', fontSize: 14 }}>Audience</Typography>
            <>
              {steps[3].complete ? (
                <CheckDiv>
                  <Check />
                </CheckDiv>
              ) : (
                <WarningDiv>
                  <AlertTriangle />
                </WarningDiv>
              )}
            </>
          </FlexDivHighlight>
          <FlexDiv>
            <p>Audience Saved</p>
            {steps[3].complete ? <GreenCheck /> : <RedX />}
          </FlexDiv>
        </HoldList>
      </ChecklistContainer>
    </Wrapper>
  </>
);

export default RetailChecklist;

RetailChecklist.propTypes = {
  details: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
};
