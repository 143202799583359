import styled from 'styled-components/macro';
import { Chip, FormControl, FormGroup } from '@material-ui/core';
import { lighten } from 'polished';

export const TransformWrapper = styled.div`
  transform: translate(40px, 0px);
  width: calc(100% - 40px);
`;

export const CustomFormGroup = styled(FormGroup)`
  width: 100%;
  flex-wrap: nowrap;
`;

export const CustomFormControl = styled(FormControl)`
  width: 100%;
  height: 100%;
`;

export const AEWrapper = styled.div`
  min-height: 100px;
  width: 100%;
`;

export const LocationHolder = styled.div`
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const LocationChip = styled(Chip)`
  background-color: ${props => props.theme.palette.grey[200]};
  padding: 0px; 
  font-weight: 400;
  font-size: 12px;
  color: black;
  margin-bottom: 2px;
`;

export const StateChip = styled(Chip)`
  background-color: white;
  color: black;
  font-weight: 500;
  padding: 0 10px;
  font-size: 12px;
  border: 2px solid rgba(0, 0, 0, .05);
  margin-bottom: 4px;

  svg{
    color: black;
    height: 18px;
    width:18px
  }
`;

export const RoleChipVendor = styled(Chip)`
  background-color: ${props => lighten(0.1, props.theme.palette.info.light)};
  padding: 0px; 
  font-weight: 400;
  font-size: 14px;
  color: black;
  margin-bottom: 4px;
  margin-right: 4px;


  svg{
    color: black;
  }
`;

export const CompanyChipVendor = styled(Chip)`
  background-color: ${props => lighten(0.1, props.theme.palette.success.light)};
  padding: 0px; 
  font-weight: 400;
  font-size: 14px;
  color: black;
  margin-bottom: 4px;
  margin-right: 4px;


  svg{
    color: black;
  }
`;

export const RoleChip = styled(Chip)`
  background-color: ${props => lighten(0.1, props.theme.palette.info.light)};
  padding: 0px; 
  font-weight: 500;
  font-size: 10px;
  color: black;
  margin-bottom: 2px;
  height: 20px;

  svg{
    height: 14px;
    width: 14px;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  min-height: 120px;
  padding: 20px;
`;
