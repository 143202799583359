/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { List, MapPin } from "react-feather";
import PropTypes from "prop-types";
import useLocations from "../../../swr/hooks/Locations/useLocations";
import { StyledLink, CustomDatatableV2 } from "../styles";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import useLocationProgress from "../../../swr/hooks/Locations/useLocationProgress";
import { retProgress } from "../reports/utils/utils";
import ProgressBar from "../reports/reuseableComponents/ProgressBar";
import TableLoader from "../../../components/Loaders/TableLoader";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import MembersInLocationTooltip from "./MembersInLocationTooltip";
import SuperDeployTooltip from "./SuperDeployTooltip";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const AlignLeftDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LocationsDataTable = ({ setDeleteInfo, readOnly, isManager }) => {
  const { locations, isLoading } = useLocations();
  const { progress, isError } = useLocationProgress();
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (isLoading) {
    return <TableLoader text="loading locations..." />;
  }
  const options = makeDefaultTableOptions("all-locations");
  options.rowsPerPage = 50;
  options.textLabels = {
    body: {
      noMatch:
        'No locations found for your company. Click on "Create New location" to get started',
    },
  };
  options.sortOrder = {
    name: "progress",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = locations.locations.map((location) => ({
    id: location.id,
    name: location.name,
    teamMembers: location.teamMembers,
    managers: location.managers,
    supervisorDeploy: location.managers_can_deploy_content ? "Yes" : "No",
    progress: retProgress(location.id, progress),
    address: `${location.city.name}, ${
      location.state.name ? `${location.state.name},` : ""
    } ${location.country.name}`,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        download: true,
        print: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/locations/detail/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={MapPin}
          />
        ),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
        download: true,
        print: true,
        display: true,
      },
    },
    {
      name: "teamMembers",
      label: "Team Members",
      options: {
        filter: true,
        sort: true,
        download: true,
        print: true,
        customBodyRender: (value, tableMeta) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <span>{value}</span>
            {value > 0 && (
              <MembersInLocationTooltip
                id={tableMeta?.rowData[0]}
                name={tableMeta.rowData[1]}
              />
            )}
          </div>
        ),
      },
    },
    {
      name: "managers",
      label: "Supervisors",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <span style={{ marginRight: 4 }}>{value}</span>
            <SuperDeployTooltip value={tableMeta.rowData[5]} />
          </div>
        ),
      },
    },
    {
      name: "supervisorDeploy",
      label: " ",
      options: {
        filter: false,
        sort: false,
        download: true,
        print: false,
        display: true,
        customBodyRenderLite: () => <div />,
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_, tableMeta) => (
          <AlignLeftDiv>
            <ProgressBar
              progress={progress}
              id={tableMeta.rowData[0]}
              isLoading={isLoading}
              isError={isError}
            />
          </AlignLeftDiv>
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        display: !isManager,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly || isManager}
            handleClick={() => {
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
                numTeamMembers: rowData[3],
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <CustomDatatableV2
      title={<TableTitle Icon={List} title="Location List" />}
      data={tableData}
      columns={columns}
      options={options}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default LocationsDataTable;

LocationsDataTable.propTypes = {
  setDeleteInfo: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
};
