/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

// const URL = '/dashboard/analytics/report/?report=company_team_member_completions' /// previous endpoint
const URL = "/dashboard/analytics/retail-reports/progress-by-user/";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.progress;
};

const useTeamMemberProgress = () => {
  const { data, error, mutate } = useSWR(URL, fetcher, {
    // dedupingInterval: 90000, // Minute and a half should be fine //
    revalidateOnFocus: false,
  });

  return {
    progress: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMemberProgress;
