/* eslint-disable */
/* eslint-disable max-len */
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import PrivateHeader from "../../../components/PrivateHeader";
import { GlobalState } from "../../../store/GlobalState";
import ManagerHome from "./manager";
import VendorHome from "./vendor";
import RetailHome from "./retail";
import Guides from "../../../components/Guides";
import NotApprovedAlert from "./reuseableComponents/NotApprovedAlert";

const Home = () => {
  const { state } = useContext(GlobalState);
  const { config } = state;
  const { isRetail, job } = config;
  const { is_approved: isApproved } = config.user.company;

  // ------ 3 versions of home page ------- //

  // Retail Manager //
  if (job === "M" && isRetail) {
    return (
      <>
        <Helmet title="Spiffy | Home" />
        <ManagerHome />
      </>
    );
  }

  // Retail Admin //
  if (isRetail) {
    return (
      <>
        <Helmet title="Spiffy | Home" />
        {!isApproved && <NotApprovedAlert />}
        <RetailHome config={config} isApproved={isApproved} />
        <Guides />
      </>
    );
  }

  // Vendor //
  return (
    <>
      <Helmet title="Spiffy | Home" />
      <VendorHome isApproved={isApproved} />
      <Guides />
    </>
  );
};

export default Home;
