/* eslint-disable */
import React, { useState } from "react";
import {
  LIBRARY_MODAL_KEYS,
  QUIZSTATES,
  USER_TYPES,
} from "../../../../../storage/constants";
import { StyledItem } from "../styles";
import API from "../../../../../axios/instances/API";
import { Send } from "react-feather";

const OrganizationCopy = ({
  id,
  mutate,
  setSnackbarText,
  userType,
  moduleState,
  setModalState,
  handleClose,
  isRetailerModuleFromMarketplace,
  userHasOrgAccess,
}) => {
  if (isRetailerModuleFromMarketplace) {
    return null;
  }

  if (![USER_TYPES.RETAIL_ADMIN].includes(userType)) return null;

  if (moduleState === QUIZSTATES.CONVERSION) return null;

  if (!userHasOrgAccess) return null;

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.ORG_SHARE, id });
        handleClose();
      }}
    >
      <Send />
      <span>Share within Organization</span>
      {/* <div
        style={{
          minHeight: 16,
          minWidth: 24,
          borderRadius: 3,
          background: "lightgray",
          padding: "0px 3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: 12,
        }}
      >
        <span style={{ fontSize: 10, fontWeight: "600", color: "black" }}>
          NEW
        </span>
      </div> */}
    </StyledItem>
  );
};

export default OrganizationCopy;
