/* eslint-disable */
import { useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Briefcase } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import NoImage from "../../../../../assets/images/empty-small.jpg";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import {
  DEFINITIONS,
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import {
  getSrcTwo,
  makeDefaultTableOptions,
} from "../../../../../storage/helpers";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import {
  CenterWithColor,
  CustomDatatableV2,
  StyledLink,
} from "../../../styles";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import MembersInMultipleCompaniesWarning from "../../reuseableComponents/MembersInMultipleCompaniesWarning";
import {
  retCompsByEngagementType,
  retPassRateByEngagementType,
  retSubsByEngagementType,
} from "../../utils/utils";

const TableWrapper = styled.div`
  min-width: 420px;
  flex-grow: 1;
`;

const getTableData = (companies, engagementType) => {
  return companies.map((company) => ({
    ...company,
    submissions: retSubsByEngagementType(company, engagementType),
    completions: retCompsByEngagementType(company, engagementType),
    passRate: retPassRateByEngagementType(company, engagementType),
  }));
};

const CompaniesTable = ({ companies, usersInMultiple }) => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("companies");
  options.rowsPerPage = 50;
  options.sortOrder = {
    name: "completions",
    direction: "desc",
  };

  options.textLabels = {
    body: {
      noMatch:
        "No users from any company(ies) have engaged with your brand yet! Create new modules and deploy it to the marketplace to engage new users!",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <LazyLoadImage
                src={getSrcTwo(tableMeta.rowData[6])}
                effect="opacity"
                style={{
                  height: 36,
                  width: 36,
                  objectFit: "contain",
                  marginRight: 4,
                  borderRadius: 3,
                  border: "1px solid #eeeeee",
                }}
                onError={(event) => (event.target.src = NoImage)}
              />
              <StyledLink
                to={`/vendor-reports/company/${tableMeta.rowData[0]}`}
                value={value}
                CustomIcon={Briefcase}
              />
            </div>
          );
        },
      },
    },
    {
      name: "active_employees",
      label: "Team Members",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        hint: DEFINITIONS.passRate,
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}%</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "logo",
      label: " ",
      options: {
        display: false,
        sort: false,
        print: false,
        download: false,
      },
    },
  ];

  const tableData = getTableData(companies, engagementType);

  return (
    <TableWrapper>
      {usersInMultiple > 0 && (
        <>
          <MembersInMultipleCompaniesWarning num={usersInMultiple} />
          <div style={{ height: 18 }} />
        </>
      )}
      <EngagementTypeSelect
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        isVendor
      />
      <div style={{ height: 12 }} />

      <CustomDatatableV2
        title={
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TableTitle
              title="Companies"
              Icon={Briefcase}
              submissionType={engagementType}
              isVendor
            />
          </div>
        }
        columns={columns}
        data={tableData}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </TableWrapper>
  );
};

export default CompaniesTable;

CompaniesTable.propTypes = {
  companies: PropTypes.array.isRequired,
};
