/* eslint-disable */
import React from 'react';
import { Alert } from '../../../styles';
import QuizzesTable from './QuizzesTable';
import { LoadMoreWrapper, LoadingMore } from '../../../marketplace/marketplaceStyles';
import useModuleReport from '../../../../../swr/hooks/VendorReports/useModuleReport';
import Loader from '../Loader';

const QuizBreakdown = () => {
  const { isLoading, isError, data } = useModuleReport();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) return <Alert severity="error">Unable to load module report, please refresh</Alert>;

  return <QuizzesTable quizzes={data.quizzes_breakdown} />;
};

export default QuizBreakdown;
