/* eslint-disable */
import React, { useState, useContext } from "react";
import { Button, Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import { GlobalState } from "../../../../store/GlobalState";
import { getSrcCompanyPhoto } from "../../../../storage/helpers";
import CheckboxBrandBasic from "../../../../components/CheckboxBrandBasic";
import API from "../../../../axios/instances/API";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(540px, 100vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ListContainer = styled.div`
  width: 100%;
  min-height: 200px;

  max-height: 340px;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  flex-direction: column;
  border: 1px solid #eeeeee;
  padding: 4px;
  border-radius: 4px;
`;

const ListItem = styled.div`
  width: 100%;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  gap: 6px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: #eeeeee85;
  }
`;

const OrganizationCopyModal = ({ id, setSnackbarText, handleClose }) => {
  const [isSharing, setIsSharing] = useState(false);
  const [selected, setSelected] = useState([]);
  const { state } = useContext(GlobalState);
  const { config } = state;

  const currentCompany = config?.user?.company?.id;

  const availableCompanies = config.companies.filter(
    (comp) => comp.id !== currentCompany
  );

  const handleShare = async () => {
    try {
      setIsSharing(true);

      const sendObj = {
        quiz_id: id,
        target_company_ids: selected,
      };

      await API.post("/dashboard/quiz/copy/", sendObj);
      setIsSharing(false);
      setSnackbarText(
        `Module has been shared with ${selected.length} ${
          selected.length > 1 ? "companies" : "company"
        } success`
      );
      handleClose();
    } catch (error) {
      setSnackbarText("Failed to share module error");
      setIsSharing(false);
    }
  };

  return (
    <ModalWrapper>
      <p style={{ fontSize: 16, fontWeight: "600" }}>
        Share within organization
      </p>
      <span style={{ fontSize: 13, color: "darkgrey" }}>
        Select companies in your organization to share this module.
      </span>
      <div style={{ height: 16 }} />
      <ListContainer>
        {availableCompanies.map((company) => (
          <ListItem
            key={company?.id}
            onClick={() =>
              setSelected((p) => {
                if (selected.includes(company.id)) {
                  const filtered = p.filter((id) => id !== company.id);

                  return filtered;
                }

                return [...p, company.id];
              })
            }
          >
            <img
              style={{
                height: 36,
                minHeight: 36,
                minWidth: 36,
                width: 36,
                borderRadius: 4,
                objectFit: "contain",
                border: "1px solid #eeeeee",
              }}
              src={getSrcCompanyPhoto(company.logo)}
            />
            <span style={{ fontWeight: "500" }}>{company.name}</span>
            <div style={{ marginLeft: "auto" }}>
              <CheckboxBrandBasic
                checked={selected.includes(company.id)}
                handleChange={() => {}}
              />
            </div>
          </ListItem>
        ))}
      </ListContainer>
      <div style={{ height: 12 }} />
      <Button
        variant="contained"
        color="secondary"
        disabled={selected.length < 1 || isSharing}
        style={{ marginLeft: "auto" }}
        onClick={handleShare}
      >
        {isSharing ? "Sending..." : "Share"}
      </Button>
    </ModalWrapper>
  );
};

export default OrganizationCopyModal;
