/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Bar } from "react-chartjs-2";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import { getCorrectLabels } from "./utils";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import EngagementTypeSelect from "../../../reports/reuseableComponents/EngagementTypeSelect";

const ChartWrapper = styled.div`
  width: 100%;
  height: 390px;
  position: relative;
`;

const ExtraToggleHolder = styled.div`
  position: absolute;
  top: -46px;
  right: 90px;
  min-height: 30px;
  width: 120px;
`;

// const ENGAGEMENT_TYPES = {
//   ALL: "all",
//   FOLLOWER: "follower",
//   ASSIGNED: "assigned",
// };

const getCorrectChartData = (data, engagmentType) => {
  const dataValues = Object.values(data);

  if (engagmentType === ENGAGEMENT_TYPES.ALL) {
    return {
      submissions: dataValues.map(
        (val) => val.follower_submissions + val.assigned_submissions
      ),
      completions: dataValues.map(
        (val) => val.follower_completions + val.assigned_completions
      ),
    };
  }
  if (engagmentType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return {
      submissions: dataValues.map((val) => val.follower_submissions),
      completions: dataValues.map((val) => val.follower_completions),
    };
  }

  return {
    submissions: dataValues.map((val) => val.assigned_submissions),
    completions: dataValues.map((val) => val.assigned_completions),
  };
};

const getTooltipText = (engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ALL) return "";
  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT)
    return "Follower Engagement";
  return "Assigned Engagement";
};

const EngagementChart = ({ data, frequency }) => {
  const [option, setOption] = useState(ENGAGEMENT_TYPES.ALL);
  const labels = getCorrectLabels(data, frequency);
  const { submissions, completions } = getCorrectChartData(data, option);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          beforeBody: () => getTooltipText(option),
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: "Submissions",
        data: submissions,
        backgroundColor: "rgba(100, 181, 246, 0.4)",
        borderColor: "black",
        borderWidth: 1,
        borderRadius: 2,
      },
      {
        label: "Completions",
        data: completions,
        backgroundColor: "rgba(23, 62, 88, .76)",
        borderColor: "black",
        borderWidth: 1,
        borderRadius: 2,
      },
    ],
  };

  return (
    <ChartWrapper>
      <Bar options={options} data={chartData} />
      <ExtraToggleHolder>
        <EngagementTypeSelect
          setEngagementType={setOption}
          engagementType={option}
          isVendor
        />
      </ExtraToggleHolder>
    </ChartWrapper>
  );
};

export default EngagementChart;

EngagementChart.propTypes = {
  data: PropTypes.object.isRequired,
  frequency: PropTypes.string.isRequired,
};
