/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  previousMonday,
  format,
  subWeeks,
  subMonths,
  subDays,
  addMonths,
  nextMonday,
  startOfMonth,
  addDays,
} from "date-fns";
import ShowReport from "./ShowReport";
import API from "../../../../../axios/instances/API";
import GenerateReportFilters from "./GenerateReportFilters";
import {
  CHART_TYPES,
  FREQUENCIES,
  TIME_PERIODS,
  REPORT_STATUS,
  BASE_ENDPOINT,
} from "./utils";

const Wrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  border-radius: 5px;
  background: #eeeeee;
  padding: 12px;
`;

const DATE_FORMAT = "yyyy-MM-dd";

const formatDateForApi = (date) => format(date, DATE_FORMAT);

const getEndDate = (frequency) => {
  const TODAY = new Date();

  if (frequency === FREQUENCIES.DAILY) {
    /// End date should be current day
    return addDays(TODAY, 1);
  }

  if (frequency === FREQUENCIES.WEEKLY) {
    // We want the upcoming monday
    return nextMonday(TODAY);
  }

  if (frequency === FREQUENCIES.MONTHLY) {
    // if monthly... must be first day of the next month
    return startOfMonth(addMonths(TODAY, 1));
  }
};

const getStartDate = (endDate, timePeriod) => {
  //// Monthly Timeperiods .. must return first day of the month
  if (timePeriod === TIME_PERIODS.THREE_MONTHS) {
    return subMonths(endDate, 3);
  }

  if (timePeriod === TIME_PERIODS.SIX_MONTHS) {
    const newStartDate = subMonths(endDate, 6);
    return newStartDate;
  }

  if (timePeriod === TIME_PERIODS.NINE_MONTHS) {
    const newStartDate = subMonths(endDate, 9);
    return newStartDate;
  }

  if (timePeriod === TIME_PERIODS.TWELVE_MONTHS) {
    const newStartDate = subMonths(endDate, 12);
    return newStartDate;
  }

  // Weekly timeperiods -> must be previous monday //
  if (timePeriod === TIME_PERIODS.FOUR_WEEKS) {
    const firstMonday = previousMonday(subWeeks(endDate, 3));
    return firstMonday;
  }

  if (timePeriod === TIME_PERIODS.EIGHT_WEEKS) {
    const firstMonday = previousMonday(subWeeks(endDate, 7));
    return firstMonday;
  }
  if (timePeriod === TIME_PERIODS.TWELVE_WEEKS) {
    const firstMonday = previousMonday(subWeeks(endDate, 11));
    return firstMonday;
  }
  if (timePeriod === TIME_PERIODS.SIXTEEN_WEEKS) {
    const firstMonday = previousMonday(subWeeks(endDate, 15));
    return firstMonday;
  }

  // Daily timeperiods => just # of days from end date
  if (timePeriod === TIME_PERIODS.SEVEN_DAYS) {
    const daysAgo = subDays(endDate, 7);
    return daysAgo;
  }

  if (timePeriod === TIME_PERIODS.FOURTEEN_DAYS) {
    const daysAgo = subDays(endDate, 14);
    return daysAgo;
  }

  if (timePeriod === TIME_PERIODS.TWENTY_ONE_DAYS) {
    const daysAgo = subDays(endDate, 21);
    return daysAgo;
  }
  if (timePeriod === TIME_PERIODS.TWENTY_EIGHT_DAYS) {
    const daysAgo = subDays(endDate, 28);
    return daysAgo;
  }

  return new Date();
};

const CustomReport = () => {
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [frequency, setFrequency] = useState(FREQUENCIES.MONTHLY);
  const [chartType, setChartType] = useState(CHART_TYPES.SUBMISSIONS);
  const [timePeriod, setTimePeriod] = useState(TIME_PERIODS.SIX_MONTHS);
  const [reportStatus, setReportStatus] = useState(REPORT_STATUS.LOADING);
  const [chartData, setChartData] = useState(null);

  const [endDate, setEndDate] = useState(getEndDate(frequency));

  const [startDate, setStartDate] = useState(getStartDate(endDate, timePeriod));

  const handleGenerateReport = (
    chartTypeString,
    frequencyString,
    newTimePeriod
  ) => {
    setReportStatus(REPORT_STATUS.LOADING);
    setChartType(chartTypeString);
    setFrequency(frequencyString);
    setTimePeriod(newTimePeriod);
    const newEndDate = getEndDate(frequencyString);
    const newStartDate = getStartDate(newEndDate, newTimePeriod);
    setEndDate(newEndDate);
    setStartDate(newStartDate);
    setTriggerUpdate((p) => !p);
  };

  useEffect(() => {
    const getChartData = async () => {
      try {
        const apiStartDate = formatDateForApi(startDate);
        const apiEndDate = formatDateForApi(endDate);

        const URL = `${BASE_ENDPOINT}/?start_date=${apiStartDate}&end_date=${apiEndDate}&frequency=${frequency}&report_name=${chartType}`;
        const { data } = await API.get(URL);
        setChartData(data.data);
        setReportStatus(REPORT_STATUS.VIEW);
      } catch (error) {
        setReportStatus(REPORT_STATUS.ERROR);
      }
    };
    getChartData();
  }, [triggerUpdate]);

  return (
    <Wrapper>
      <GenerateReportFilters
        globalReportType={chartType}
        globalInterval={frequency}
        globalTimePeriod={timePeriod}
        handleGenerateReport={handleGenerateReport}
        reportStatus={reportStatus}
      />
      <ShowReport
        chartType={chartType}
        chartData={chartData}
        reportStatus={reportStatus}
        frequency={frequency}
      />
    </Wrapper>
  );
};

export default CustomReport;
