/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import { CircularProgress, MenuItem, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { Alert, Button, Typography, TextField } from "../../styles";
import { JOB_LEVELS, FEEDBACK_MESSAGES } from "../../../../storage/constants";
import API from "../../../../axios/instances/API";
import useLocations from "../../../../swr/hooks/Locations/useLocations";
import { GlobalState } from "../../../../store/GlobalState";
import styled from "styled-components/macro";

const ADMIN_LEVELS = {
  SUPERVISOR: "supervisor",
  READ_ONLY: "readOnly",
  ORGANIZATION: "orgAccess",
  ADMIN: "adminAccess",
};

const Wrapper = styled(Paper)`
  padding: 20px;
  width: min(520px, 98vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
  border: 2px solid black;
`;

const Promote = ({ promoteInfo, setPromoteModalOpen, setSnackbarText }) => {
  const [isPromoting, setIsPromoting] = useState(false);
  const [errorAlert, setErrorAlert] = useState();
  const [adminLevel, setAdminLevel] = useState(ADMIN_LEVELS.ADMIN);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { state } = useContext(GlobalState);
  const { organizationAccess } = state.config;
  const { locations, isLoading } = useLocations();

  const autocompleteOptions = isLoading
    ? null
    : locations.locations.map((location) => ({
        name: location.name,
        id: location.id,
      }));

  const handleSelectChange = (e) => {
    setAdminLevel(e.target.value);
  };

  const handlePromote = async () => {
    setErrorAlert(false);
    // eslint-disable-next-line no-unused-expressions
    setIsPromoting(true);
    try {
      let sendObj;
      if (adminLevel === ADMIN_LEVELS.SUPERVISOR) {
        sendObj = {
          id: promoteInfo.id,
          job: JOB_LEVELS.SUPERVISOR,
          location_id: selectedLocation.id,
          read_only: false,
          org_access: false,
        };
      } else {
        sendObj = {
          id: promoteInfo.id,
          job: JOB_LEVELS.ADMIN,
          read_only: adminLevel === ADMIN_LEVELS.READ_ONLY,
          org_access: adminLevel === ADMIN_LEVELS.ORGANIZATION,
        };
      }
      await API.put("dashboard/teams/promote/", sendObj);
      // eslint-disable-next-line no-unused-expressions
      setSnackbarText(
        `Successfully promoted ${promoteInfo.fullName} to ${
          adminLevel === ADMIN_LEVELS.SUPERVISOR ? "Supervisor" : "Admin"
        } success`
      );
      setIsPromoting(false);
      setPromoteModalOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-unused-expressions
      setIsPromoting(false);
      setErrorAlert(FEEDBACK_MESSAGES.GENERIC_ERROR);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h5">
        {`Promote ${promoteInfo.fullName}`} ?
      </Typography>
      <Typography mb={6} variant="body1">
        You can give <strong>{promoteInfo.fullName}</strong> Admin or Supervisor
        privileges
      </Typography>
      <TextField
        select
        fullWidth
        size="small"
        variant="outlined"
        value={adminLevel}
        onChange={handleSelectChange}
        name="level"
        mb={2}
        label="Select Promotion Level"
      >
        {promoteInfo.currentLevel !== "M" && (
          <MenuItem value={ADMIN_LEVELS.SUPERVISOR}>
            Promote To Supervisor
          </MenuItem>
        )}
        <MenuItem value={ADMIN_LEVELS.ADMIN}>Promote To Admin</MenuItem>
        <MenuItem value={ADMIN_LEVELS.READ_ONLY}>
          Promote To Read-Only Admin
        </MenuItem>
        {organizationAccess && (
          <MenuItem value={ADMIN_LEVELS.ORGANIZATION}>
            Promote To Admin With Organization Access
          </MenuItem>
        )}
      </TextField>
      {adminLevel === ADMIN_LEVELS.SUPERVISOR && (
        <Autocomplete
          size="small"
          options={autocompleteOptions}
          getOptionLabel={(option) => option.name}
          onChange={(_, newValue) => setSelectedLocation(newValue)}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              mb={3}
              mt={1}
              {...params}
              size="small"
              variant="outlined"
              label="Location To Supervise"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
      <Button
        mb={0}
        fullWidth
        variant="contained"
        color="secondary"
        disabled={adminLevel === ADMIN_LEVELS.SUPERVISOR && !selectedLocation}
        startIcon={isPromoting ? <CircularProgress size={20} /> : null}
        onClick={handlePromote}
      >
        {isPromoting ? "Promoting" : "Promote"}
      </Button>
      {errorAlert && (
        <Alert mt={2} severity="error" onClose={() => setErrorAlert(false)}>
          {errorAlert}
        </Alert>
      )}
    </Wrapper>
  );
};

export default Promote;

Promote.propTypes = {
  promoteInfo: PropTypes.object.isRequired,
  setPromoteModalOpen: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
