/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Frown } from "react-feather";
import { Typography } from "../../../styles";
import { HighlightOrange, CurrentAudienceWrapper } from "../../styles";
import ShowVendorAudienceChip from "../../../../../components/ShowVendorAudienceChip";

const BFrown = styled(Frown)`
  color: ${(props) => props.theme.palette.info.light};
`;

const VendorAudienceCurrent = ({ vendorAudience }) => {
  // const history = useHistory();
  const [chosenAudience] =
    vendorAudience.savedID !== null
      ? vendorAudience.savedAudiences.filter(
          (saved) => saved.id === vendorAudience.savedID
        )
      : [null];
  return (
    <CurrentAudienceWrapper>
      {vendorAudience.savedID ? (
        <>
          <Typography variant="h3">Your Current Audience</Typography>
          <Typography variant="subtitle1" mb={2}>
            Module Will Be Pushed To Locations in
            <HighlightOrange>
              <em>
                <strong> Custom Audience</strong>
              </em>
            </HighlightOrange>
          </Typography>
          <ShowVendorAudienceChip
            name={chosenAudience.name}
            id={chosenAudience.id}
            shouldLinkToAudience={false}
          />
        </>
      ) : (
        <>
          <Typography variant="h3">You Currently Have No Audience</Typography>
          <Typography variant="subtitle1">
            Choose an Audience and it will show up here
          </Typography>
          <BFrown />
        </>
      )}
    </CurrentAudienceWrapper>
  );
};

export default VendorAudienceCurrent;

VendorAudienceCurrent.propTypes = {
  vendorAudience: PropTypes.object.isRequired,
};
