/* eslint-disable */
import React, { useState } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { lighten } from "polished";
import PropTypes from "prop-types";
import { Plus } from "react-feather";
import styled from "styled-components/macro";
import { mutate as swrMutate } from "swr";
import API from "../../../../../axios/instances/API";
import { QUESTION_TYPES } from "../../../../../storage/constants";
import { Button, TextField, Typography } from "../../../styles";

const AnswerButton = styled(Button)`
  min-width: 100px;
  background-color: ${(props) =>
    props.isactive === "Y"
      ? props.theme.palette.info.light
      : lighten(0.28, props.theme.palette.info.light)};
  &:hover {
    background-color: ${(props) => props.theme.palette.info.light};
  }3
`;

const SaveButton = styled(Button)`
  margin-top: auto;
`;

const QHolder = styled.div`
  min-height: 420px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const QuestionTF = ({
  id,
  details,
  setSnackbarText,
  questions,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSaveQuestion = async () => {
    let sendObj;
    setIsSubmitting(true);

    sendObj = {
      id,
      pass_score: details.passScore,
      questions: [
        ...questions,
        {
          id: null,
          title: "none",
          question,
          type: QUESTION_TYPES.TRUE_FALSE,
          correctAnswer: answer === "T",
          options: null,
        },
      ],
    };

    try {
      await API.put("dashboard/quiz/questions/", sendObj);
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      setIsSubmitting(false);
      setQuestion("");
      setAnswer("");
      return setSnackbarText("Question successfully added success");
    } catch (error) {
      setIsSubmitting(false);
      return setSnackbarText("Failed to add question. Please try again. error");
    }
  };

  return (
    <QHolder>
      <Typography variant="h4" mt={4} mb={4}>
        True/False
      </Typography>
      <Typography variant="subtitle2" mb={1} mt={2}>
        Your Question
      </Typography>
      <TextField
        type="text"
        size="small"
        variant="outlined"
        multiline
        value={question}
        onChange={(e) => setQuestion(e.currentTarget.value)}
        fullWidth
      />
      <Typography variant="subtitle2" mt={10}>
        Answer Key{" "}
        <small>
          <em>(choose one)</em>
        </small>
      </Typography>
      <Grid container spacing={4}>
        <Grid item>
          <AnswerButton
            isactive={answer === "T" ? "Y" : "N"}
            variant={answer === "T" ? "contained" : "outlined"}
            onClick={() => setAnswer("T")}
            mt={2}
            color="inherit"
            disabled={isModuleLive}
          >
            True
          </AnswerButton>
        </Grid>
        <Grid item xs={4}>
          <AnswerButton
            isactive={answer === "F" ? "Y" : "N"}
            variant={answer === "F" ? "contained" : "outlined"}
            onClick={() => setAnswer("F")}
            mt={2}
            color="inherit"
            disabled={isModuleLive}
          >
            False
          </AnswerButton>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          alignItems: "center",

          justifyContent: " flex-end",
          gap: 12,
          marginTop: "auto",
        }}
      >
        <SaveButton
          variant="contained"
          color="secondary"
          disabled={
            question.length <= 2 ||
            !answer ||
            isSubmitting ||
            isModuleConvertingNextStateLive
          }
          onClick={handleSaveQuestion}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <Plus height={14} width={14} color="white" />
            )
          }
        >
          {isSubmitting ? "Saving Question" : "Add Question"}
        </SaveButton>
      </div>
    </QHolder>
  );
};

export default QuestionTF;

QuestionTF.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  builderContext: PropTypes.object.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
};
