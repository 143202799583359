/* eslint-disable */
import { Collapse, Paper } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import styled from 'styled-components/macro';
import { GlobalState } from '../../../../../store/GlobalState';
import Lower from './Lower';

const Wrapper = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PaddingWrap = styled.div`
  padding: 16px;
`;

const UpperWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eeeeee;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 260ms ease-in;

  &:hover {
    cursor: pointer;
    background: #eeeeee99;
  }
`;

const AlertAccordion = ({ snackbarText, setSnackbarText }) => {
  const { state } = useContext(GlobalState);

  const { config } = state;

  const [isOpen, setIsOpen] = useState(false);

  const pendingRequests = config.user.company.pending_access_requests;

  const numPending =
    pendingRequests?.team_members + pendingRequests?.admins + pendingRequests?.managers;

  if (!numPending) return <span />;

  return (
    <PaddingWrap>
      <Wrapper>
        <UpperWrapper onClick={() => setIsOpen(p => !p)}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}
          >
            <div
              style={{
                height: 22,
                minWidth: 22,
                borderRadius: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#337ab7',
              }}
            >
              <span style={{ color: '#eeeeee', fontWeight: '600', fontSize: 14 }}>
                {numPending}
              </span>
            </div>
            <span
              style={{
                fontWeight: '600',
                color: 'black',
                fontSize: 14,
              }}
            >
              {numPending > 1 ? 'Alerts' : 'Alert'} requiring attention
            </span>
          </div>
          <div
            style={{
              borderRadius: 8,
              height: 34,
              width: 34,
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isOpen ? <ChevronUp color="black" /> : <ChevronDown color="black" />}
          </div>
        </UpperWrapper>
        <Collapse
          in={isOpen}
          unmountOnExit
        >
          <Lower
            setSnackbarText={setSnackbarText}
            snackbarText={snackbarText}
          />
        </Collapse>
      </Wrapper>
    </PaddingWrap>
  );
};

export default AlertAccordion;
