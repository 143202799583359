/* eslint-disable */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable arrow-body-style */
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";
import { BarChart2 } from "react-feather";
import styled from "styled-components/macro";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { Divider, Typography } from "../../styles";
import SubmissionTypeChip from "../retail/SubmissionTypeChip";
import { getSubBreakdown } from "./utils";
import Last14Chip from "../../../../components/Table/Last14Chip";

const Wrapper = styled.div`
  max-width: 50%;
  border-radius: 4px;
  flex-grow: 1;

  @media (max-width: 1000px) {
    max-width: none;
    width: 100%;
    flex-grow: 1;
  }
`;

const InnerWrapper = styled(Paper)`
  padding: 20px;
`;

const TIMEPERIODS = {
  TODAY: "Today",
  WEEK: "Last Week",
  TWOWEEK: "Last 14 Days",
};

const BREAKDOWNS = {
  USER: "User",
  MODULE: "Module",
  COMPANY: "Company",
};

const getTrimmed = (metricArray) => {
  if (metricArray.length < 11) {
    return metricArray.sort((first, second) => {
      return second.subs - first.subs;
    });
  }
  const sorted = metricArray.sort((first, second) => {
    return second.subs - first.subs;
  });
  return sorted.slice(0, 10);
};

// const getBgColorByEngagementType = (num, engagementType) => {
//   const COLOR_MAP = {
//     [ENGAGEMENT_TYPES.ALL]: '#eeeeee',
//     [ENGAGEMENT_TYPES.ASSIGNED]: '#0000fa1a',
//     [ENGAGEMENT_TYPES.EXTRACREDIT]: '#ffeeb091',
//   };

//   const colorsArray = new Array(num).fill(COLOR_MAP[engagementType]);
//   if (engagementType === ENGAGEMENT_TYPES.ALL) {
//     colorsArray[0] = 'darkgrey';
//   }

//   return colorsArray;
// };

const Top10Chart = ({
  title,
  Icon,
  allSubmissions,
  metric,
  users,
  engagementType,
}) => {
  if (allSubmissions?.length < 10) {
    return (
      <Wrapper>
        <InnerWrapper>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 6,
            }}
          >
            <SubmissionTypeChip
              isVendor
              small
              submissionType={engagementType}
            />
            <Typography
              style={{ fontSize: 16, fontWeight: "600" }}
              align="left"
            >
              {title}
            </Typography>
          </div>
          <Divider mt={1} mb={4} />
          <div
            style={{
              height: 400,
              background: "#eeeeee",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <BarChart2 height={14} width={14} color="darkgrey" />
            <span style={{ fontWeight: "500" }}>
              Not enough data to create chart
            </span>
            <span style={{ color: "darkgrey" }}>
              Must be 10 recent submissions to create charts.
            </span>
          </div>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const metricArray = Object.values(
    getSubBreakdown(
      allSubmissions,
      TIMEPERIODS.TWOWEEK,
      BREAKDOWNS[metric],
      "All",
      users
    )
  );

  const trimmed = getTrimmed(metricArray);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cornerRadius: 4,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: () => "",
          beforeBody: (context) =>
            `${context[0]?.dataset?.label}: ${context[0].raw}`,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          callback: (_, index) => {
            const fullLabel = trimmed[index].name;
            return fullLabel.length > 14
              ? fullLabel?.substring(0, 14)?.concat("...")
              : fullLabel;
          },
        },
      },
      x: {
        beginAtZero: true,
        suggestexMax: 10,
        stacked: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const data = {
    labels: trimmed.map((l) => l.name),
    datasets: [
      {
        label: "Completions",
        data: trimmed.map((l) => l.pass),
        borderRadius: 4,
        maxBarThickness: 40,
        minBarThickness: 40,
        backgroundColor: "lightgray",
        borderColor: "darkgrey",
        borderWidth: 1,

        // borderWidth: 1,

        // backgroundColor: [...getBgColorByEngagementType(trimmed.length, engagementType)],
        // borderColor:
        //   engagementType === ENGAGEMENT_TYPES.ALL
        //     ? 'black'
        //     : engagementType === ENGAGEMENT_TYPES.ASSIGNED
        //     ? '#587dea'
        //     : '#aa9700',
      },
      {
        label: "Failed Submissions",
        data: trimmed.map((l) => l.subs - l.pass),
        borderRadius: 4,
        maxBarThickness: 40,
        minBarThickness: 40,
        borderWidth: 1,
        backgroundColor: "#eeeeee",
        borderColor: "lightgray",
        // borderWidth: 1,

        // backgroundColor: [...getBgColorByEngagementType(trimmed.length, engagementType)],
        // borderColor:
        //   engagementType === ENGAGEMENT_TYPES.ALL
        //     ? 'black'
        //     : engagementType === ENGAGEMENT_TYPES.ASSIGNED
        //     ? '#587dea'
        //     : '#aa9700',
      },
    ],
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 6,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 8,
            }}
          >
            {engagementType === ENGAGEMENT_TYPES.ALL ? (
              <Icon height={13} width={13} color="#337ab7" />
            ) : (
              <SubmissionTypeChip
                isVendor
                small
                submissionType={engagementType}
              />
            )}
            <Typography
              style={{ fontSize: 16, fontWeight: "600" }}
              align="left"
            >
              {title}
            </Typography>
            <Last14Chip />
          </div>
        </div>
        <div style={{ height: 6 }} />

        <Divider mt={1} mb={4} />
        <div style={{ height: 400 }}>
          <Bar data={data} options={options} />
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Top10Chart;

Top10Chart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  allSubmissions: PropTypes.array.isRequired,
  metric: PropTypes.string.isRequired,
};
