/* eslint-disbale */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data;
};

// UPDATED
const BASE_URL = '/dashboard/home/';
// const BASE_URL = '/dashboard/analytics/retail-reports/admin-home/';

const useHome = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useHome;
