/* eslint-disable */
import React from "react";
import { ThumbsDown, ThumbsUp } from "react-feather";

const ShowModuleFeedback = ({ feedback }) => {
  const { positive, negative } = feedback || {};

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: 8,
        }}
      >
        <ThumbsUp height={15} width={15} color="lightgray" />
        <span style={{ fontSize: 10, color: "darkgrey", fontWeight: "300" }}>
          {positive}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginLeft: 8,
        }}
      >
        <ThumbsDown height={15} width={15} color="lightgray" />
        <span style={{ fontSize: 10, color: "darkgrey", fontWeight: "300" }}>
          {negative}
        </span>
      </div>
    </div>
  );
};

export default ShowModuleFeedback;
