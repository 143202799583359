/* eslint-disable */
import React, { useState, useRef, useContext } from "react";
import styled from "styled-components/macro";
import { GlobalState } from "../../store/GlobalState";
import useCompanyDetails from "../../swr/hooks/Util/useCompanyDetails";
import {
  Button,
  Paper,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CompanySwitches from "./CompanySwitches";
import ActionButtonForAccount from "./ActionButtonForAccount";
import { Plus, Image, Edit, Repeat, MessageCircle } from "react-feather";
import EditCompanyLogo from "../Company/EditCompanyLogo";
import EditCompanyName from "../Company/EditCompanyName";
import EditCompanyDescription from "../Company/EditCompanyDescription";
import SelectCompanyModal from "./SelectCompanyModal";
import SnackbarWrapper from "../SnackbarWrapper";

const StyledButton = styled(Button)`
  height: 36px;
  width: 36px;
  min-height: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 18px;
  min-width: 36px;
  background-color: #eeeeee34;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;
  position: relative;

  svg {
    color: slategrey;
  }
  &:hover {
    background-color: #eeeeee;

    svg {
      color: black;
    }
  }
`;

const ActionButtonTransparent = ({
  handleClick,
  disabled = false,
  title = "title",
  Icon,
}) => {
  return (
    <>
      <Tooltip arrow placement="bottom" title={title}>
        <StyledButton onClick={handleClick} disabled={disabled}>
          <Icon height={14} width={14} />
        </StyledButton>
      </Tooltip>
    </>
  );
};

const CompanyContainer = styled.div`
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;

  p {
    margin-left: 6px;
    font-size: 12px;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const CompanyImage = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: white;
  cursor: pointer;
  transition: all 240ms ease-in;

  &:hover {
    border: 1px solid slategray;
    transform: scale(1.02);
  }
`;

const CompanyImgNoHover = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: white;
  cursor: pointer;
  transition: all 200ms ease-in;
`;

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 120px;
  min-width: 360px;
  max-width: 360px;
  text-align: left;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const AddCompanyImage = styled(Button)`
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  max-width: 40px;
  min-width: 40px;
  width: 40px;
  border-radius: 12px;
  border: 2px dashed #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border: 2px dashed ${(props) => props.theme.palette.info.light};
  }

  svg {
    height: 14px;
    width: 14px;
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const CurrentCompanyName = styled.p`
  @media (max-width: 600px) {
    display: none;
  }
`;

const CompanyDropdownV2 = () => {
  const { state, dispatch } = useContext(GlobalState);
  const { config } = state;
  const { isLoading, company, mutate, isError } = useCompanyDetails();
  const [editLogoModalOpen, setEditLogoModalOpen] = useState(false);
  const [editCompanyName, setEditCompanyName] = useState(false);
  const [switchCompanyOpen, setSwitchCompanyOpen] = useState(false);
  const [editCompanyDescription, setEditCompanyDescription] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const currentCompany = config.user.company;
  const { isRetail } = config;
  const isManager = config?.user?.job === "M";

  const canSwitchCompanies =
    config?.organizationAccess && config?.companies?.length > 1;

  const hasLogo = Boolean(currentCompany?.logo);

  if (isLoading) return null;

  if (!currentCompany || isError) return null;

  return (
    <>
      <CompanyContainer>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip
            arrow
            title={hasLogo ? "Company" : "Add Logo"}
            placement="bottom"
          >
            {hasLogo ? (
              <CompanyImage
                ref={ref}
                onClick={() => setOpen((p) => !p)}
                src={currentCompany.logo}
                alt="brand logo"
              />
            ) : (
              <AddCompanyImage
                onClick={() => setEditLogoModalOpen((p) => !p)}
                disabled={isManager}
              >
                <Plus />
              </AddCompanyImage>
            )}
          </Tooltip>
          <CurrentCompanyName>{currentCompany.name}</CurrentCompanyName>
        </div>
        {canSwitchCompanies && (
          <div style={{ position: "relative" }}>
            <ActionButtonTransparent
              Icon={Repeat}
              title="Switch Company"
              handleClick={() => setSwitchCompanyOpen((p) => !p)}
            />

            <div
              style={{
                position: "absolute",
                top: -3,
                right: -3,
                height: 14,
                width: 14,
                background: "#907ac63b",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{ fontSize: 8, fontWeight: "600", color: "#813eee" }}
              >
                {config?.companies?.length}
              </span>
            </div>
          </div>
        )}
      </CompanyContainer>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Wrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: -4,
            }}
          >
            <CompanyImgNoHover
              src={currentCompany.logo}
              alt="brand logo"
              style={{ marginRight: 6 }}
            />
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {currentCompany.name}
            </Typography>
          </div>
          <div style={{ height: 16 }} />
          <p style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}>
            COMPANY INFO
          </p>
          <div style={{ height: 4 }} />

          <FlexDiv>
            <p>Company Name:</p>
            <span>{company?.name}</span>
          </FlexDiv>
          <FlexDiv>
            <p>Logo Uploaded:</p>
            <span>Yes</span>
          </FlexDiv>
          <FlexDiv>
            <p>Company Industry:</p>
            <span>{company?.vertical?.name}</span>
          </FlexDiv>
          <FlexDiv>
            <p>Spiffy Version:</p>
            <span>{company?.spiffyPro ? "PRO" : "FREE"}</span>
          </FlexDiv>
          {!isRetail && (
            <>
              <div style={{ height: 16 }} />
              <p style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}>
                COMPANY DESCRIPTION
              </p>
              <div style={{ height: 4 }} />
              <p style={{ fontSize: 12, marginBottom: -2 }}>
                {company?.description || "--/--"}
              </p>
            </>
          )}
          <div style={{ height: 16 }} />
          {!isManager && (
            <>
              <CompanySwitches
                isRetail={isRetail}
                company={company}
                mutate={mutate}
              />
            </>
          )}
          {!isManager && (
            <>
              <div style={{ height: 16 }} />
              <p style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}>
                COMPANY ACTIONS
              </p>
              <div style={{ height: 8 }} />
              <ButtonContainer>
                <ActionButtonForAccount
                  Icon={Image}
                  title="edit company logo"
                  handleClick={() => setEditLogoModalOpen((p) => !p)}
                />
                <ActionButtonForAccount
                  Icon={Edit}
                  title="edit company name"
                  handleClick={() => setEditCompanyName((p) => !p)}
                />
                {!isRetail && company?.is_description_change_allowed && (
                  <ActionButtonForAccount
                    Icon={MessageCircle}
                    title="edit company description"
                    handleClick={() => setEditCompanyDescription((p) => !p)}
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </Wrapper>
      </Popover>
      <Modal
        open={editLogoModalOpen}
        onClose={() => setEditLogoModalOpen(false)}
        aria-labelledby="Change companys logo"
        aria-describedby="modal to change company logo"
      >
        <div>
          <EditCompanyLogo
            handleClose={() => setEditLogoModalOpen(false)}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      {!isRetail && (
        <Modal
          open={editCompanyDescription}
          onClose={() => setEditCompanyDescription(false)}
        >
          <div>
            <EditCompanyDescription
              mutate={mutate}
              handleClose={() => setEditCompanyDescription(false)}
              setSnackbarText={setSnackbarText}
            />
          </div>
        </Modal>
      )}
      <Modal
        open={editCompanyName}
        onClose={() => setEditCompanyName(false)}
        aria-labelledby="Change companys name"
        aria-describedby="modal to change company name"
      >
        <div>
          <EditCompanyName
            setSnackbarText={setSnackbarText}
            handleClose={() => setEditCompanyName(false)}
          />
        </div>
      </Modal>
      <Modal
        open={switchCompanyOpen}
        onClose={() => setSwitchCompanyOpen(false)}
        aria-labelledby="switch company"
        aria-describedby="modal to switch company"
      >
        <div>
          <SelectCompanyModal
            availableCompanies={config.companies}
            isManager={isManager}
            currentCompanyId={currentCompany.id}
            handleClose={() => setSwitchCompanyOpen(false)}
            dispatch={dispatch}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default CompanyDropdownV2;
