/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { QUESTION_TYPES } from '../../../../storage/constants';
import ACTIONS from './state/builderActions';
import { CheckCircle } from 'react-feather';

const SpaceFromControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-root {
    margin-right: 40px;
  }
`;

const BuilderRadio = ({ builderContext, isModuleLive, isModuleConvertingNextStateLive }) => {
  if (isModuleLive || isModuleConvertingNextStateLive) return null;
  const { state, dispatch } = builderContext;
  const { quizType } = state;

  const handleRadioChange = (_, value) => {
    dispatch({ type: ACTIONS.UPDATE_QUIZ_TYPE, payload: value });
  };

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          row
          value={quizType}
          onChange={handleRadioChange}
        >
          <SpaceFromControlLabel
            value={QUESTION_TYPES.TRUE_FALSE}
            control={<Radio />}
            label="True/False"
            disabled={isModuleLive && quizType !== QUESTION_TYPES.TRUE_FALSE}
          />
          <SpaceFromControlLabel
            value={QUESTION_TYPES.MULTIPLE_CHOICE}
            control={<Radio />}
            label="Multiple Choice"
            disabled={isModuleLive && quizType !== QUESTION_TYPES.MULTIPLE_CHOICE}
          />
          <SpaceFromControlLabel
            value={QUESTION_TYPES.SELECT_ALL}
            control={<Radio />}
            label="Select All"
            disabled={isModuleLive && quizType !== QUESTION_TYPES.SELECT_ALL}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default BuilderRadio;

BuilderRadio.propTypes = {
  builderContext: PropTypes.object.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
};
