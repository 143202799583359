/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Typography, TextField, Button } from "@material-ui/core";
import { Edit } from "react-feather";
import API from "../../../../../axios/instances/API";
import {
  InputAdornment,
  CircularProgress,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import { QUESTION_TYPES } from "../../../../../storage/constants";

const QuestionAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  background-color: ${(props) =>
    props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

const QHolder = styled.div`
  min-height: 360px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
`;

const haveSelectedAnswersChanged = (radioOptions, targetQuestion) => {
  const testArr = [];

  ["A", "B", "C", "D"].forEach((letter) => {
    if (radioOptions[letter] === true) {
      testArr.push(letter);
    }
  });

  return !(
    JSON.stringify(testArr) === JSON.stringify(targetQuestion?.correctAnswer)
  );
};

const SelectAll = ({
  targetQuestion,
  details,
  mutate,
  setSnackbarText,
  handleClose,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const { correctAnswer } = targetQuestion;

  const [question, setQuestion] = useState(targetQuestion?.question);
  const [options, setOptions] = useState(targetQuestion.options);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [radioOptions, setRadioOptions] = useState({
    A: correctAnswer.includes("A"),
    B: correctAnswer.includes("B"),
    C: correctAnswer.includes("C"),
    D: correctAnswer.includes("D"),
  });

  const changesMade =
    targetQuestion?.question !== question ||
    JSON.stringify(Object.values(options)) !==
      JSON.stringify(Object.values(targetQuestion?.options)) ||
    haveSelectedAnswersChanged(radioOptions, targetQuestion);

  const optionsWithText = Object.values(options).filter(
    (option) => option.length >= 1
  );

  const isDisabled =
    isSubmitting ||
    question?.length <= 2 ||
    !changesMade ||
    optionsWithText.length < 4;

  const isAnswerKeyDisabled = isModuleLive || isModuleConvertingNextStateLive;

  const handleOptionTextChange = (e) => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleCheckboxClick = (e, v) => {
    const { value } = e?.target;
    const newOptions = {
      ...radioOptions,
      [value]: v,
    };

    setRadioOptions(newOptions);
  };

  const handleSaveEdit = async () => {
    try {
      setIsSubmitting(true);

      const correctAnswers = [];
      const keys = Object.keys(radioOptions);
      keys.forEach((key) => {
        if (radioOptions[key] === true) {
          correctAnswers.push(key);
        }
      });

      const newQuestions = [...details?.questions?.questions];
      newQuestions.splice(targetQuestion?.order, 1, {
        id: targetQuestion?.id,
        title: "none",
        question,
        type: QUESTION_TYPES.SELECT_ALL,
        correctAnswer: correctAnswers,
        options,
      });

      const sendObj = {
        id: details?.id,
        pass_score: details?.passScore,
        questions: newQuestions,
      };

      await API.put("dashboard/quiz/questions/", sendObj);
      await mutate();
      setIsSubmitting(false);
      setSnackbarText("Question edited success");

      handleClose();
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to edit question error");
      handleClose();
    }
  };

  return (
    <>
      <QHolder>
        <div style={{ height: 6 }} />

        <Typography variant="subtitle2">Question</Typography>
        <div style={{ height: 12 }} />
        <TextField
          type="text"
          size="small"
          variant="outlined"
          multiline
          value={question}
          onChange={(e) => setQuestion(e.currentTarget.value)}
          fullWidth
        />
        <div style={{ height: 16 }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <Typography variant="subtitle2" style={{ alignSelf: "flex-start" }}>
            Options
          </Typography>
          {["A", "B", "C", "D"].map((letter) => (
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                type="text"
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={`Answer ${letter}`}
                name={letter.toLowerCase()}
                value={options[letter.toLowerCase()]}
                onChange={handleOptionTextChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QuestionAvatar
                        activecolor={radioOptions[letter] ? "Yes" : "normal"}
                      >
                        <span style={{ fontSize: 14 }}>{letter}</span>
                      </QuestionAvatar>
                    </InputAdornment>
                  ),
                }}
              />
              <Checkbox
                checked={radioOptions[letter]}
                onChange={handleCheckboxClick}
                value={letter}
                disabled={isAnswerKeyDisabled}
              />
            </div>
          ))}
        </div>
      </QHolder>
      <div style={{ height: 12 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",

          marginTop: "auto",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          onClick={handleSaveEdit}
        >
          {isSubmitting ? "Saving..." : "Save Edit"}
        </Button>
      </div>
    </>
  );
};

export default SelectAll;
