/* eslint-disable */
import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro';
import { IconButton, MenuItem, Tooltip, Menu } from '@material-ui/core';
import { GlobalState } from '../../../../store/GlobalState';
import { Map, Home, ChevronDown } from 'react-feather';


const FullMenuItem = styled(MenuItem)`
  width: 100%;
  display: flex; 
  align-items: center; 
  justify-content: center;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  width: 100%;
  padding: 12px;
  margin-top: -8px;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid black;
  margin-bottom: 12px;
`;

const IconContainer = styled.div`

  height: 42px;
  width: 42px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  


  background-color: #182b3a;
  background-image: linear-gradient(315deg, #182b3a 0%, #20a4f3 74%);


  svg {
    color: white;
    height: 18px;
    width: 18px;
  }
`

// should show company name, job & location if super

const HomeHeader = ({
  children,
  title,
}) => {

  const { state } = useContext(GlobalState);
  const { config } = state;
  const [anchorEl, setAnchorEl] = useState(null);

  const isSupervisor = config.job === 'M';

  if (isSupervisor) {
    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            flexShrink: 0,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
            <div style={{ paddingLeft: 8,  display: 'flex', flexDirection: "column", alignItems: 'flex-start', justifyContent: 'center'}}>
              <span style={{ fontSize: 24, fontWeight: '700', color: 'black', lineHeight: 1.2}}>
                Supervisor Dashboard
              </span>
              <span>
                {title}

              </span>
            </div>
          </div>
        </div>
        {children}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <div 
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}
        >
          <IconContainer>
            <Home />
          </IconContainer>
          
          <div style={{ paddingLeft: 8}}>
            <span style={{ fontSize: 24, fontWeight: '800', color: 'black', lineHeight: 1.1}}>
              {title}
            </span>
          </div>
        </div>
      </div>
      {children}
    </Wrapper>
  )
}

export default HomeHeader