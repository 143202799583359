/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { Bar, Line } from 'react-chartjs-2';
import { getCorrectLabels } from './utils';

const ChartWrapper = styled.div`
  width: 100%;
  height: 390px;
  position: relative;
`;

const ExtraToggleHolder = styled.div`
  position: absolute;
  top: -46px;
  right: 10px;
  min-height: 30px;
  width: 120px;
`;

const UNIQUE_CHART_OPTIONS = {
  CUMULATIVE: 'Cumulative',
  TIME_PERIOD: 'Time Period',
};

const UniqueUsersChart = ({ data, frequency }) => {
  const [selectedMetric, setSelectedMetric] = useState(UNIQUE_CHART_OPTIONS.CUMULATIVE);

  const getNewUsersTooltipText = dataIndex => {
    const target = data[dataIndex];
    const numNewUsers = target.unique_users_after - target.unique_users_before;

    return `New Users: ${numNewUsers}`;
  };

  const getReturningUsersTooltipText = dataIndex => {
    const target = data[dataIndex];
    const numReturningUsers =
      target.unique_users_per_period - (target.unique_users_after - target.unique_users_before);

    return `Returning Users: ${numReturningUsers}`;
  };

  const getTotalUsersTooltipText = dataIndex => {
    const target = data[dataIndex];
    const numReturningUsers =
      target.unique_users_per_period - (target.unique_users_after - target.unique_users_before);
    const numNewUsers = target.unique_users_after - target.unique_users_before;

    return `Total Users: ${numReturningUsers + numNewUsers}`;
  };

  const labels = getCorrectLabels(data, frequency);

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    offset: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => '',
          afterBody: ctx => getNewUsersTooltipText(ctx[0].dataIndex),
          beforeBody: ctx => getReturningUsersTooltipText(ctx[0].dataIndex),
          footer: ctx => getTotalUsersTooltipText(ctx[0].dataIndex),
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          precision: 0,
        },
      },
      x: {
        beginAtZero: true,
      },
    },
  };

  const stackKey = 'stack2';

  const chartDataBar = {
    labels,
    datasets: [
      {
        label: 'Returning users',
        backgroundColor: 'rgba(100, 181, 246, 0.4)',
        data: data.map(
          v => v.unique_users_per_period - (v.unique_users_after - v.unique_users_before)
        ),
        // data: data.map((v) => v.unique_users_before),
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 2,
        stack: stackKey,
        order: 2,
      },
      {
        label: 'New users',
        backgroundColor: '#c0dfd3',
        data: data.map(v => v.unique_users_after - v.unique_users_before),
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 2,
        stack: stackKey,
        order: 1,
      },
    ],
  };

  const optionsLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => '',
          title: context => {
            const numUsers = data[context[0].dataIndex]?.unique_users_after;
            return `Unique Users: ${numUsers}`;
          },
          afterTitle: context => {
            return getNewUsersTooltipText(context[0].dataIndex);
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const chartDataLine = canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 160);
    gradient.addColorStop(0, 'rgba(100, 181, 246, 0.6');
    gradient.addColorStop(1, 'rgba(100, 181, 246, 0.1');

    const datasets = [
      {
        data: data.map(v => v.unique_users_after),
        fill: {
          target: 'origin',
          above: gradient,
        },

        borderColor: 'slategrey',
        pointBorderColor: 'black',
        tension: 0.4,
        pointBackgroundColor: 'black',
      },
    ];

    return {
      labels,
      type: 'line',
      datasets,
    };
  };

  return (
    <ChartWrapper>
      {selectedMetric === UNIQUE_CHART_OPTIONS.TIME_PERIOD ? (
        <Bar
          options={optionsBar}
          data={chartDataBar}
        />
      ) : (
        <Line
          options={optionsLine}
          data={chartDataLine}
        />
      )}
      <ExtraToggleHolder>
        <FormControl
          size="small"
          fullWidth
        >
          <Select
            variant="outlined"
            value={selectedMetric}
            onChange={e => setSelectedMetric(e.target.value)}
            size="small"
          >
            <MenuItem value={UNIQUE_CHART_OPTIONS.TIME_PERIOD}>By Week/Month</MenuItem>
            <MenuItem value={UNIQUE_CHART_OPTIONS.CUMULATIVE}>Over Time</MenuItem>
          </Select>
        </FormControl>
      </ExtraToggleHolder>
    </ChartWrapper>
  );
};

export default UniqueUsersChart;

UniqueUsersChart.propTypes = {
  data: PropTypes.object.isRequired,
  frequency: PropTypes.string.isRequired,
};
