/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Modal, CircularProgress } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Autocomplete } from '@material-ui/lab';
import VendorEditTable from './VendorEditTable';
import { Typography, Button, TextField, DeleteTimes } from '../../../styles';
import API from '../../../../../axios/instances/API';

const BodyWrapper = styled(Paper)`
  width: 60%;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const VendorEdit = ({
  setSnackbarText,
  id,
  mutate,
  availableCompanies,
  availableRoles,
  audience,
  useEdit,
  setUseEdit,
  EDIT_STATES,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rolesComboValue, setRolesComboValue] = useState([]);
  const [rolesKey, setRolesKey] = useState(true);

  const handleCloseModal = () => setUseEdit(EDIT_STATES.NEITHER);

  const handleRolesSubmit = async () => {
    if (rolesComboValue.length === 0) return setSnackbarText('Must specify roles to add warning');
    setIsSubmitting(true);
    try {
      let newMapping;
      if (audience.mapping === null) {
        newMapping = {
          companies: [],
          roles: rolesComboValue.map(role => role.id),
        };
      } else {
        const oldCompanies = audience.mapping.companies.map(company => company.id);
        const oldRoles = audience.mapping.roles.map(role => role.id);
        newMapping = {
          companies: oldCompanies,
          roles: oldRoles.concat(rolesComboValue.map(role => role.id)),
        };
      }

      await API.put('dashboard/audiences/details/', {
        id,
        mapping: newMapping,
      });
      setIsSubmitting(false);
      mutate();
      setRolesKey(p => !p);
      setRolesComboValue([]);
      setUseEdit(EDIT_STATES.NEITHER);
      return setSnackbarText('Successfully updated your audience roles success');
    } catch (error) {
      setIsSubmitting(false);
      setRolesKey(p => !p);
      setRolesComboValue([]);
      return setSnackbarText('Failed to update your audience roles error');
    }
  };

  if (useEdit === EDIT_STATES.NEITHER) return null;

  if (useEdit === EDIT_STATES.COMPANIES) {
    return (
      <Modal
        open
        onClose={handleCloseModal}
        aria-labelledby="Add companies to audience"
        aria-describedby="Modal to add companies to custom audience"
      >
        <BodyWrapper>
          <VendorEditTable
            availableCompanies={availableCompanies}
            mutate={mutate}
            audience={audience}
            setSnackbarText={setSnackbarText}
            setIsSubmitting={setIsSubmitting}
            id={id}
            handleCloseModal={handleCloseModal}
          />
          <DeleteTimes onClick={handleCloseModal} />
        </BodyWrapper>
      </Modal>
    );
  }

  return (
    <Modal
      open
      onClose={handleCloseModal}
      aria-labelledby="Add role categories to custom audience"
      aria-describedby="Modal to add role categories to custom audience"
    >
      <BodyWrapper>
        <Typography
          variant="h5"
          mt={0}
        >
          Available Role Categories
        </Typography>
        <Typography
          variant="subtitle1"
          mb={10}
        >
          Select role categories and add to your audience
        </Typography>
        <Autocomplete
          key={rolesKey}
          multiple
          options={availableRoles}
          getOptionLabel={option => option.name}
          onChange={(_, newValue) => setRolesComboValue(newValue)}
          getOptionSelected={(option, value) => option.id === value.id}
          size="small"
          noOptionsText="No Role Categories Available"
          renderInput={params => (
            <TextField
              {...params}
              label="Add Role Category"
              variant="outlined"
              size="small"
            />
          )}
        />
        <Button
          variant="contained"
          color="secondary"
          mt={6}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          onClick={handleRolesSubmit}
        >
          Add To Audience
        </Button>
        <DeleteTimes onClick={() => setUseEdit(EDIT_STATES.NEITHER)} />
      </BodyWrapper>
    </Modal>
  );
};

export default VendorEdit;

VendorEdit.propTypes = {
  options: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  availableCompanies: PropTypes.array.isRequired,
  audience: PropTypes.object.isRequired,
  availableRoles: PropTypes.array.isRequired,
  mutate: PropTypes.func.isRequired,
  useEdit: PropTypes.string.isRequired,
  setUseEdit: PropTypes.func.isRequired,
  EDIT_STATES: PropTypes.object.isRequired,
};
