/* eslint-disable */
/* eslint-disable import/prefer-default-export */

export const getIncompleteErrMessage = (steps, isVendor = false) => {
  let missingSteps;
  if (!isVendor) {
    missingSteps = steps.filter(step => !step.complete && step.label !== 'Launch');
  } else {
    missingSteps = steps.filter(
      step => !step.complete && !['Launch', 'Audience'].includes(step.label)
    );
  }

  if (missingSteps.length === 1) {
    return `Looks like your module is incomplete. Make sure you have "${missingSteps[0].label}" filled out correctly.`;
  }

  if (missingSteps.length === 2) {
    return `Looks like your module is incomplete. Make sure "${missingSteps[0].label}" and "${missingSteps[1].label}" are filled out correctly.`;
  }

  return 'Looks like your module is incomplete, Make sure you have your basic information filled out, a content piece, an audience, and questions.';
};

export const getVendorAddToMarketplaceErrorMsg = error => {
  if (error?.reponse?.data?.error === 'incomplete_quiz') {
    return 'Your module is not complete. Make sure all required fields are filled out error';
  }

  if (error?.response?.data?.error === 'selected_marketplaces_are_not_available') {
    return 'The marketplace selected for this module are not available error';
  }
  return 'Failed To launch your module error';
};

export const getVendorSuggestErrorMsg = error => {
  if (error?.response?.data?.error === 'no_audience') {
    return 'Module must have an audience to be suggested error';
  }
  if (error?.response?.data?.error === 'already_suggested') {
    return 'This module has already been suggested to an audience error';
  }

  if (error?.reponse?.data?.error === 'incomplete_quiz') {
    return 'Your module is not complete. Make sure all required fields are filled out error';
  }

  return 'Error suggesting this module error';
};
