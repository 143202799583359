/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { lighten } from "polished";
import styled from "styled-components/macro";
import EditAndDelete from "../EditAndDelete";
import { Typography, Button } from "../../../styles";

const AnswerButtonFalse = styled(Button)`
  min-width: 70px;
  min-height: 30px;
  font-size: 14px;
  padding: 5px;
  background-color: ${(props) => props.theme.palette.info.light};

  &:hover {
    background-color: ${(props) => props.theme.palette.info.light};
  }

  &:disabled {
    background-color: ${(props) =>
      lighten(0.28, props.theme.palette.info.light)};
  }
`;

const AnswerButtonTrue = styled(Button)`
  min-width: 70px;
  min-height: 30px;
  font-size: 14px;
  padding: 5px;
  background-color: ${(props) => props.theme.palette.info.light};
  &:hover {
    background-color: ${(props) => props.theme.palette.info.light};
  }

  &:disabled {
    background-color: ${(props) =>
      lighten(0.28, props.theme.palette.info.light)};
  }
`;

const ButtonHolder = styled.div`
  height: 100%;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  // margin: auto;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 4px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Separate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 34px;
  width: 100%;
`;

const QuestionDetailsTF = ({
  question,
  handleDeleteQuestion,
  builderContext,
  isReview = false,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => (
  <Wrapper>
    <Typography
      style={{
        fontSize: 10,
        fontWeight: "500",
        color: "darkgrey",
        whiteSpace: "nowrap",
        alignSelf: "flex-start",
      }}
    >
      True / False
    </Typography>
    <div style={{ height: 8 }} />

    <ButtonHolder>
      <AnswerButtonTrue disabled={question.correctAnswer !== "True"}>
        True
      </AnswerButtonTrue>
      <AnswerButtonFalse disabled={question.correctAnswer === "True"}>
        False
      </AnswerButtonFalse>
    </ButtonHolder>
    <div style={{ height: 16 }} />

    {!isReview && (
      <Separate>
        <div
          style={{
            minWidth: 200,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: 11,
              color: "black",
              whiteSpace: "nowrap",
              fontWeight: "600",
            }}
          >
            Correct Answer: {"  "}
            {question.correctAnswer === "True" ? "True" : "False"}
          </span>
        </div>
        <EditAndDelete
          builderContext={builderContext}
          handleDeleteQuestion={handleDeleteQuestion}
          question={question}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        />
      </Separate>
    )}
  </Wrapper>
);

export default QuestionDetailsTF;

QuestionDetailsTF.propTypes = {
  question: PropTypes.object.isRequired,
  handleDeleteQuestion: PropTypes.func,
  builderContext: PropTypes.object,
  isReview: PropTypes.bool.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
};

QuestionDetailsTF.defaultProps = {
  handleDeleteQuestion: null,
  builderContext: null,
};
