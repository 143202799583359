import React from 'react';
import PropTypes from 'prop-types';
import { Plus } from 'react-feather';
import { Button } from '../../../styles';

const CustomSelectCompanyToolBar = ({
  displayData,
  selectedRows,
  handleCompaniesSubmit,
  setSelectedRows,
}) => {
  const handleSelectMultiple = (dData, sRows, setSRows) => {
    const indexes = Object.keys(sRows.lookup).map(str => str * 1);
    const filteredIds = dData
      .filter(option => indexes.includes(option.dataIndex))
      .map(options => options.data[0]);
    handleCompaniesSubmit(filteredIds);
    setSRows([]);
  };

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={() => handleSelectMultiple(displayData, selectedRows, setSelectedRows)}
      startIcon={<Plus />}
      mr={2}
    >
      {`Add Companies (${selectedRows.data.length})`}
    </Button>
  );
};

export default CustomSelectCompanyToolBar;

CustomSelectCompanyToolBar.propTypes = {
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  handleCompaniesSubmit: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
};
