/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Vimeo from "@vimeo/player";
import {
  CheckCircle,
  AlertCircle,
  XCircle,
  Activity,
  Trash,
} from "react-feather";
import styled from "styled-components/macro";
import { Tab, IconButton, Tooltip } from "@material-ui/core";
import PdfDocument from "./PdfDocument";
import TabPanel from "../../../../components/TabPanel";
import { Typography, Button } from "../../styles";
import { QuizTabAppBar, QuizTabs } from "../styles";

const SuccessUploadIcon = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
  font-size: 8px;
`;

const LoadingUploadIcon = styled(AlertCircle)`
  color: ${(props) => props.theme.palette.info.light};
`;

const TranscodingIcon = styled(Activity)`
  padding: 6px;
  border-radius: 8px;
  background: gainsboro;
  transform: translate(4px, 6px);
  margin-bottom: 12px;
  color: slategrey;

  svg {
  }
`;

const NoUploadIcon = styled(XCircle)`
  color: ${(props) => props.theme.palette.error.light};
`;

const Centered = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  min-height: 486px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #eeeeee44;
  border-radius: 4px;
  border: 1px solid #eeeeee;
`;

const TranscodingWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #eeeeee;
  border-radius: 4px;
`;

const MediaWrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;
  margin-top: 8px;
  border-radius: 4px;
  flex-grow: 1;
  padding: 5px;
  min-height: 320px;
`;

const Notice = styled(Button)`
  svg {
    height: 16px;
    width: 16px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RemoveBtn = styled(IconButton)`
  height: 28px;
  width: 28px;
  margin-top: -8px;
  svg {
    color: ${(props) => props.theme.palette.grey[400]};
    height: 14px;
    width: 14px;
  }
`;

const NoticeHolder = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const HighlightTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[500]};
`;

const NewTab = styled(Tab)`
  .MuiTab-wrapper {
    padding: 0;
    width: auto;
  }

  .MuiButtonBase-root {
    padding: 0;
  }
`;

const CurrentMedia = ({
  media,
  videoUploading,
  videoTranscoding,
  handleRemovePdf,
  handleRemoveVideo,
  inDevelopment,
  isModuleLive,
}) => {
  const videoRef = useRef(null);
  const [tabValue, setTabValue] = useState(0);
  const hasPdf = media.content !== null;

  const hasVideo = media.video !== null;

  useEffect(() => {
    // Video Player
    if (!media.video) return;
    if (!videoRef.current) return;
    if (videoTranscoding || videoUploading) return;
    const videoId = media.video.split("/")[2] * 1;
    const vimeoOptions = {
      url: `https://vimeo.com/${videoId}`,
      width: "320px",
      height: "auto",
    };

    // eslint-disable-next-line no-unused-vars
    const player = new Vimeo(videoRef.current, vimeoOptions);
    player.on("error", () => {});
  }, [media, tabValue, videoTranscoding]);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const hasNoContentUploaded = Boolean(!media?.video && !media?.content);

  if (hasNoContentUploaded) {
    return (
      <Centered>
        <Typography style={{ fontSize: 15, fontWeight: "600" }}>
          No Content.
        </Typography>
        <Typography style={{ color: "darkgrey", fontSize: 12 }}>
          Upload some content and it will show up here.
        </Typography>
      </Centered>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography style={{ fontSize: 14, fontWeight: "500" }}>
        {media.content && media.video
          ? "You currently have a Video and a PDF uploaded."
          : `You currently have a ${hasPdf ? "PDF" : "video"} uploaded.`}
      </Typography>
      {!(media.content && media.video) ? (
        <HighlightTypography style={{ fontSize: 12 }}>
          {`Feel free to move on. If you wish, you can add a ${
            hasPdf ? "video" : "pdf"
          } as well.`}
        </HighlightTypography>
      ) : (
        <div style={{ height: 0 }} />
      )}
      {/* Two pieces of content (video and pdf) */}
      {media.content && media.video && !videoUploading ? (
        <>
          <QuizTabAppBar position="static" elevation={1}>
            <QuizTabs
              value={tabValue}
              onChange={handleTabChange}
              scrollButtons="off"
              textColor="secondary"
            >
              <Tab label="PDF" />
              <NewTab label="Video" />
            </QuizTabs>
          </QuizTabAppBar>
          <TabPanel value={tabValue} index={1}>
            {videoTranscoding ? (
              <MediaWrapper>
                <TranscodingWrapper>
                  <TranscodingIcon />

                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "slategrey",
                    }}
                  >
                    Video Transcoding
                  </Typography>
                  <p style={{ fontSize: 12, color: "darkgrey" }}>
                    This should only take a few minutes
                  </p>
                </TranscodingWrapper>
              </MediaWrapper>
            ) : (
              <MediaWrapper>
                <>
                  <div ref={videoRef} id="video-player" />
                </>
              </MediaWrapper>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={0}>
            <MediaWrapper>
              <PdfDocument media={media} allowDownload />
            </MediaWrapper>
          </TabPanel>
        </>
      ) : media.content ? (
        <>
          <div style={{ height: 22 }} />

          <MediaWrapper>
            <PdfDocument media={media} allowDownload />
          </MediaWrapper>
        </>
      ) : (
        <>
          <div style={{ height: 22 }} />
          <MediaWrapper>
            {!videoTranscoding ? (
              <>
                <div ref={videoRef} id="video-player" />
              </>
            ) : (
              <TranscodingWrapper>
                <TranscodingIcon />
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    color: "slategrey",
                  }}
                >
                  Video Transcoding
                </Typography>
                <p style={{ fontSize: 12, color: "darkgrey" }}>
                  This should only take a few minutes
                </p>
              </TranscodingWrapper>
            )}
          </MediaWrapper>
        </>
      )}
      <NoticeHolder>
        <FlexDiv>
          <Notice
            endIcon={!media.content ? <NoUploadIcon /> : <SuccessUploadIcon />}
            component={Typography}
            disabled
          >
            PDF
          </Notice>
          {hasPdf &&
            !videoTranscoding &&
            !videoUploading &&
            (inDevelopment || (hasVideo && isModuleLive)) && (
              <Tooltip title="Remove Pdf">
                <RemoveBtn size="small" onClick={handleRemovePdf}>
                  <Trash />
                </RemoveBtn>
              </Tooltip>
            )}
        </FlexDiv>
        <FlexDiv>
          <Notice
            endIcon={
              videoUploading || videoTranscoding ? (
                <LoadingUploadIcon />
              ) : !media.video ? (
                <NoUploadIcon />
              ) : (
                <SuccessUploadIcon />
              )
            }
            component={Typography}
            disabled
          >
            Video
          </Notice>
          {hasVideo &&
            !videoUploading &&
            !videoTranscoding &&
            (inDevelopment || (hasPdf && isModuleLive)) && (
              <Tooltip title="Remove Video">
                <RemoveBtn size="small" onClick={handleRemoveVideo}>
                  <Trash />
                </RemoveBtn>
              </Tooltip>
            )}
        </FlexDiv>
      </NoticeHolder>
    </div>
  );
};

export default CurrentMedia;

CurrentMedia.propTypes = {
  media: PropTypes.object.isRequired,
  videoUploading: PropTypes.bool.isRequired,
  videoTranscoding: PropTypes.bool.isRequired,
  inDevelopment: PropTypes.bool.isRequired,
  handleRemoveVideo: PropTypes.func.isRequired,
  handleRemovePdf: PropTypes.func.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
};
