/* eslint-disable */
import PropTypes from "prop-types";
import React from "react";
import { UserPlus } from "react-feather";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../components/Table/TableTitle";
import VendorShowTeams from "../../../../components/VendorShowTeams";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import {
  CustomDatatableV2,
  SubmissionChipAssignedTooltip,
  SubmissionChipExtraCreditTooltip,
} from "../../styles";
import DateTooltip from "../../../../components/Table/DateTooltip";
import { useMediaQuery } from "@material-ui/core";

import Last14Chip from "../../../../components/Table/Last14Chip";

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
      display: true,
      customBodyRender: (value) => <DateTooltip date={value} />,
    },
  },
  {
    name: "quiz",
    label: "Module",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "teamMemberName",
    label: "User",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "company",
    label: "User Company",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => <VendorShowTeams teamsArray={value} />,
    },
  },
  {
    name: "subType",
    label: "Type",
    options: {
      filter: true,
      sort: true,
      hint: "Either assigned or follower submission type",
      customBodyRender: (value) => {
        if (value === ENGAGEMENT_TYPES.ASSIGNED) {
          return <SubmissionChipAssignedTooltip />;
        }
        if (value === ENGAGEMENT_TYPES.EXTRACREDIT) {
          return <SubmissionChipExtraCreditTooltip isVendor />;
        }

        return <span>{value}</span>;
      },
    },
  },
  {
    name: "score",
    label: "Score",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "passed",
    label: "Passed",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => <SubmissionResultIndicator value={value} />,
    },
  },
];

const SubmissionTable = ({ submissions, usersInfo, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const tableData = submissions.map((submission) => ({
    id: submission.id,
    date: new Date(submission.created).toISOString(),
    quiz: submission.quiz_name,
    teamMemberName: `${usersInfo[submission.user_id].first_name} ${
      usersInfo[submission.user_id].last_initial
    }`,
    // company: submission.active_team_members,
    company: usersInfo[submission.user_id].active_team_members,
    subType: submission.is_follower_submission
      ? ENGAGEMENT_TYPES.EXTRACREDIT
      : ENGAGEMENT_TYPES.ASSIGNED,
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));

  const options = makeDefaultTableOptions("submissions-last-14");
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 6,
            }}
          >
            <TableTitle
              Icon={UserPlus}
              title="Recent Engagement"
              submissionType={engagementType}
              isVendor
            />
            <Last14Chip />
            {/* <Last14Text>(All submissions, last 14 days)</Last14Text> */}
            {/* <SubmissionTypeChip
              small
              isVendor
              submissionType={engagementType}
            /> */}
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{ icons: CustomDataTableIcons }}
      />
    </>
  );
};

export default SubmissionTable;

SubmissionTable.propTypes = {
  submissions: PropTypes.array.isRequired,
};
