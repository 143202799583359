/* eslint-disable */
/* eslint-disable camelcase */

import React from 'react';
import { Alert } from '../../../styles';
import { LoadMoreWrapper, LoadingMore } from '../../../marketplace/marketplaceStyles';
import useCompanyReport from '../../../../../swr/hooks/VendorReports/useCompanyReport';
import CompaniesTable from './CompaniesTable';
import Loader from '../Loader';

const CompanyBreakdown = () => {
  const { data, isLoading, isError } = useCompanyReport();
  if (isLoading) {
    return <Loader />;
  }
  if (isError)
    return (
      <Alert
        severity="error"
        mt={2}
      >
        Unable to load company report, please refresh
      </Alert>
    );

  const {
    companies_breakdown: companies,
    users_with_team_members_in_multiple_companies: usersInMultiple,
  } = data;

  return (
    <>
      <CompaniesTable
        companies={companies}
        usersInMultiple={usersInMultiple}
      />
    </>
  );
};

export default CompanyBreakdown;
