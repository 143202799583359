/* eslint-disable */
import ACTIONS from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_CONFIG:
      return {
        ...state,
        refreshConfig: !state.refreshConfig,
        config: action.payload,
        initialLog: true,
        transcoding: action.payload.conversions,
      };
    case ACTIONS.LOGOUT:
      return { ...state, initialLog: false };
    case ACTIONS.REFRESH_CONFIG:
      return {
        ...state,
        refreshConfig: !state.refreshConfig,
        initialLog: false,
      };
    case ACTIONS.UPDATE_CONFIG:
      return {
        ...state,
        config: action.payload,
        transcoding: action?.payload?.conversions || [],
      };
    case ACTIONS.ADD_TRANSCODE:
      return {
        ...state,
        transcoding: [...state.transcoding, action.payload],
      };
    case ACTIONS.SET_TRANSCODE:
      return {
        ...state,
        transcoding: action.payload,
      };
    case ACTIONS.REMOVE_TRANSCODE:
      return {
        ...state,
        transcoding: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
