/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import styled, { keyframes } from "styled-components/macro";
import { BarChart2, PieChart, Percent, TrendingUp } from "react-feather";
import ExtraInfo from "../../../../components/ExtraInfo";
import { ENGAGEMENT_TYPES, DEFINITIONS } from "../../../../storage/constants";
import HighlightIncrease from "../reuseableComponents/HighlightIncrease";
import { addCommas } from "../../../../storage/helpers";
import useSingleLocationProgress from "../../../../swr/hooks/RetailReports/useSingleLocationProgress";
import ProgBarMini from "../retail/ProgBarMini";
import SubmissionTypeChip from "../retail/SubmissionTypeChip";

const Box = styled(Paper)`
  flex: 0.25;
  background-color: white;
  height: 100%;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 500;
    color: darkgrey;
  }
`;

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px) scale(.85);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
`;

const BoldText = styled.span`
  font-size: 40px;
  font-weight: 800;
  color: #337ab7;
  animation: 300ms ${fadeInRight} ease-out;
`;

const Container = styled.div`
  height: 180px;
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas: "box1 box2 box3 box4";

  @media (max-width: 800px) {
    height: 360px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "box1 box1 box2 box2"
      "box3 box3 box4 box4";
  }
`;

const InnerBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
  height: 100%;
`;

const calcPassRate = (subs, comps) => Math.floor((comps / subs) * 100);

const getOverallStatsbySubType = (data, submissionsType) => {
  try {
    let submissions = 0;
    let completions = 0;
    let passRate = 0;
    let subsIncrease = 0;
    let compsIncrease = 0;

    const hasRecentSubs = data.recent_submissions.length > 0;

    if (submissionsType === ENGAGEMENT_TYPES.ALL) {
      submissions = data.assigned_submissions + data.extra_credit_submissions;
      completions = data.assigned_completions + data.extra_credit_completions;
      passRate = calcPassRate(
        data.assigned_submissions + data.extra_credit_submissions,
        data.assigned_completions + data.extra_credit_completions
      );

      if (hasRecentSubs) {
        subsIncrease = data.recent_submissions.filter(
          (sub) => !sub.passed
        ).length;
        compsIncrease = data.recent_submissions.filter(
          (sub) => sub.passed
        ).length;
      }
    }

    if (submissionsType === ENGAGEMENT_TYPES.ASSIGNED) {
      submissions = data.assigned_submissions;
      completions = data.assigned_completions;
      passRate = calcPassRate(
        data.assigned_submissions,
        data.assigned_completions
      );

      if (hasRecentSubs) {
        subsIncrease = data.recent_submissions.filter(
          (sub) => !sub.passed && sub.assigned
        ).length;
        compsIncrease = data.recent_submissions.filter(
          (sub) => sub.passed && sub.assigned
        ).length;
      }
    }

    if (submissionsType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      submissions = data.extra_credit_submissions;
      completions = data.extra_credit_completions;
      passRate = calcPassRate(
        data.extra_credit_submissions,
        data.extra_credit_completions
      );

      if (hasRecentSubs) {
        subsIncrease = data.recent_submissions.filter(
          (sub) => !sub.passed && !sub.assigned
        ).length;
        compsIncrease = data.recent_submissions.filter(
          (sub) => sub.passed && !sub.assigned
        ).length;
      }
    }

    return {
      submissions,
      completions,
      passRate,
      subsIncrease,
      compsIncrease,
    };
  } catch (error) {
    throw Error("getOverallStatsbySubType failed");
  }
};

const getProgressValue = (progressData, isLoading, isError) => {
  try {
    if (isLoading || isError) return 0;

    const values = Object.values(progressData);
    const totalAssigned = values
      .map((v) => v.total)
      .reduce((prev, curr) => (prev += curr));
    const totalComplete = values
      .map((v) => v.complete)
      .reduce((prev, curr) => (prev += curr));

    const percentage =
      totalComplete === 0 || totalAssigned === 0
        ? 0
        : Math.ceil((totalComplete / totalAssigned) * 100);

    return percentage;
  } catch (error) {
    return 0;
  }
};

const InfoBlocks = ({ data, submissionsType }) => {
  const { submissions, completions, passRate, subsIncrease, compsIncrease } =
    getOverallStatsbySubType(data.submissions, submissionsType);

  const {
    data: progressData,
    isLoading,
    isError,
  } = useSingleLocationProgress(data?.id);

  const progress = getProgressValue(progressData, isLoading, isError);

  return (
    <Container>
      <Box style={{ gridArea: "box1" }}>
        <InnerBox>
          <BarChart2
            style={{
              height: 28,
              width: 28,
              color: "black",
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <p>Submissions All Time</p>
            {submissionsType === ENGAGEMENT_TYPES.ALL ? (
              <ExtraInfo text={DEFINITIONS.submissionsAllTime} />
            ) : (
              <>
                <div style={{ width: 4 }} />
                <SubmissionTypeChip small submissionType={submissionsType} />
              </>
            )}
          </div>
          <BoldText>{addCommas(submissions)}</BoldText>
        </InnerBox>
        {subsIncrease > 0 && (
          <HighlightIncrease subType={submissionsType}>
            <span>
              <strong style={{ fontWeight: "900", marginRight: 2 }}>
                + {addCommas(subsIncrease)}{" "}
              </strong>
              last 14 days
            </span>
          </HighlightIncrease>
        )}
      </Box>
      <Box style={{ gridArea: "box2" }}>
        <InnerBox>
          <PieChart
            style={{
              height: 28,
              width: 28,
              color: "black",
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <p>Completions All Time</p>
            {submissionsType === ENGAGEMENT_TYPES.ALL ? (
              <ExtraInfo text={DEFINITIONS.completionsAllTime} />
            ) : (
              <>
                <div style={{ width: 4 }} />
                <SubmissionTypeChip submissionType={submissionsType} small />
              </>
            )}
          </div>
          <BoldText>{addCommas(completions)}</BoldText>
        </InnerBox>
        {compsIncrease > 0 && (
          <HighlightIncrease subType={submissionsType}>
            <span>
              <strong style={{ fontWeight: "900", marginRight: 2 }}>
                + {addCommas(compsIncrease)}{" "}
              </strong>
              last 14 days
            </span>
          </HighlightIncrease>
        )}
      </Box>
      <Box style={{ gridArea: "box3" }}>
        <InnerBox>
          <Percent
            style={{
              height: 28,
              width: 28,
              color: "black",
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <p>Pass Rate All Time</p>
            <ExtraInfo text={DEFINITIONS.passRate} />
          </div>
          <BoldText>{isNaN(passRate) ? 0 : passRate}%</BoldText>
        </InnerBox>
      </Box>
      <Box style={{ gridArea: "box4" }}>
        <InnerBox>
          <TrendingUp
            style={{
              height: 28,
              width: 28,
              color: "black",
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <p>Progress</p>
            <ExtraInfo text="What percentage of currently assigned modules have been completed" />
          </div>
          <BoldText>{progress ? `${progress}%` : "0"}</BoldText>
          <ProgBarMini progress={progress} />
        </InnerBox>
      </Box>
    </Container>
  );
};

export default InfoBlocks;

InfoBlocks.propTypes = {
  data: PropTypes.object.isRequired,
};
