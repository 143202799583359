/* eslint-disable */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../components/PrivateHeader";
import EditStreamName from "./EditStreamName";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import useStreamDetails from "../../../../swr/hooks/Streams/useStreamDetails";
import AddQuizToStream from "./AddQuizToStream";
import RearrangeQuizzesList from "./RearrangeQuizzesList";
import { GlobalState } from "../../../../store/GlobalState";
import OnLoadError from "../../../../components/OnLoadError";

const StreamDetail = ({ match }) => {
  const { id } = match.params;
  const [snackbarText, setSnackbarText] = useState("");
  const { details, isLoading, mutate, isError } = useStreamDetails(id);
  const { state } = useContext(GlobalState);
  // eslint-disable-next-line no-unused-vars
  const { readOnly, isRetail } = state.config;

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Stream" secondary={true} />
        <LoaderWrapper text="Stream Details" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Stream Details" />
      <PrivateHeader header="Stream" secondary={details?.stream?.name} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AddQuizToStream
            details={details}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            readOnly={readOnly}
            isRetail={isRetail}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EditStreamName
            name={details.stream.name}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            id={id}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <RearrangeQuizzesList
        details={details}
        mutate={mutate}
        setSnackbarText={setSnackbarText}
        readOnly={readOnly}
        isRetail={isRetail}
      />
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default StreamDetail;

StreamDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
