/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components/macro';
import { Typography, Button } from '../../../styles';
import CustomSelectCompanyToolBar from './CustomSelectCompanyToolbar';
import API from '../../../../../axios/instances/API';

const SelectContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 220px;
`;

const AllowScroll = styled.div`
  height: 100%;
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
`;

const ScrollContainer = styled.div`
  max-height: 340px;
  border: 1px solid lightgrey;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 0px 10px 0px;
`;

const SelectDiv = styled.div`
  width: 100%;
  background-color: #eeeeee;
  padding: 2px 10px 2px 10px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListContainer = styled.div`
  flex: 0.5;
  margin-right: 40px;
  min-width: 180px;
`;

const FlexDiv = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const SubtitleSpan = styled.span`
  text-decoration: underline;
  font-weight: 600;
`;

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 4px;
`;

const TABLE_STATES = {
  ADD_COMPANIES: 'ADD_COMPANIES',
  SUGGEST_CONTENT: 'SUGGEST_CONTENT',
};

const VendorEditTable = ({
  availableCompanies,
  audience,
  setSnackbarText,
  setIsSubmitting,
  mutate,
  id,
  handleCloseModal,
}) => {
  const [tableState, setTableState] = useState(TABLE_STATES.ADD_COMPANIES);
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleCompaniesSubmit = async (addIds = []) => {
    try {
      let newMapping;
      if (audience.mapping === null || Object.keys(audience.mapping).length === 0) {
        newMapping = {
          companies: addIds,
          roles: [],
        };
      } else {
        const oldCompanies = audience.mapping.companies.map(company => company.id);
        const oldRoles = audience.mapping.roles.map(role => role.id);
        newMapping = {
          /// Gotta make sure you aren't getting the event as the addIds
          companies: Array.isArray(addIds) ? oldCompanies.concat(addIds) : oldCompanies,
          roles: oldRoles,
        };
      }

      const { data: newData } = await API.put('dashboard/audiences/details/', {
        id,
        mapping: newMapping,
      });

      if (newData?.currently_suggested_content?.length === 0) {
        setIsSubmitting(false);
        mutate();
        return setSnackbarText('Successfully updated companies success');
      }

      if (newData) {
        setData(newData);
        setTableState(TABLE_STATES.SUGGEST_CONTENT);
      }

      setIsSubmitting(false);
      mutate();
      return setSnackbarText('Successfully updated companies success');
    } catch (error) {
      setIsSubmitting(false);
      return setSnackbarText('Failed to update audience companies error');
    }
  };

  const handleSendSelectedContent = async () => {
    try {
      await API.post('dashboard/audiences/suggest-to-new-companies/', {
        audience_id: id,
        suggest_to: selected,
      });
      setSnackbarText(
        `Content sent to ${selected.length} ${
          selected.length > 1 ? 'companies' : 'company'
        } success`
      );
      handleCloseModal();
    } catch (error) {
      setSnackbarText('Failed to send content to companies error');
      handleCloseModal();
    }
  };

  const handleSendAllContent = async () => {
    const allCompanyIds = data.newly_added_companies.map(c => c.id);
    try {
      await API.post('dashboard/audiences/suggest-to-new-companies/', {
        audience_id: id,
        suggest_to: allCompanyIds,
      });
      setSnackbarText(
        `Content sent to ${allCompanyIds.length} ${
          allCompanyIds.length > 1 ? 'companies' : 'company'
        } success`
      );
      handleCloseModal();
    } catch (error) {
      setSnackbarText('Failed to send content to companies error');
      handleCloseModal();
    }
  };

  const options = {
    filterType: 'checkbox',
    selectableRows: 'multiple',
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    elevation: 1,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    responsive: 'simple',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomSelectCompanyToolBar
        displayData={displayData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleCompaniesSubmit={handleCompaniesSubmit}
      />
    ),
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Company Name',
    },
  ];

  const handleCheck = companyId => {
    if (selected.includes(companyId)) {
      return setSelected(p => p.filter(num => num !== companyId));
    }
    return setSelected([...selected, Number(companyId)]);
  };

  return (
    <AllowScroll>
      <Typography
        variant="h4"
        mt={2}
      >
        {tableState === TABLE_STATES.ADD_COMPANIES
          ? 'Add companies to audience'
          : 'Send content to new companies?'}
      </Typography>
      {tableState === TABLE_STATES.ADD_COMPANIES && (
        <>
          <Typography
            variant="subtitle1"
            mb={6}
          >
            These companies are available to add into your audience
          </Typography>
        </>
      )}
      {tableState === TABLE_STATES.ADD_COMPANIES ? (
        <MUIDataTable
          title=""
          data={availableCompanies}
          options={options}
          columns={columns}
        />
      ) : (
        <SelectContainer>
          <div>
            <Typography
              variant="subtitle1"
              mt={2}
            >
              This custom audience has the below content currently assigned to it
            </Typography>
            <FlexDiv>
              {data?.currently_suggested_content?.length > 0 && (
                <ListContainer>
                  <SubtitleSpan>
                    {data?.currently_suggested_content?.length} modules assigned
                  </SubtitleSpan>
                  <ul>
                    {data?.currently_suggested_content?.map(module => (
                      <li
                        key={module.id}
                        style={{ fontSize: 11 }}
                      >
                        {module.name}
                      </li>
                    ))}
                  </ul>
                </ListContainer>
              )}
            </FlexDiv>
          </div>
          <Typography
            variant="subtitle1"
            mt={4}
          >
            Select the companies you would like to receive this content
          </Typography>
          <ScrollContainer>
            {data.newly_added_companies.map(company => (
              <SelectDiv
                onClick={() => handleCheck(company.id)}
                key={company.id}
              >
                <span>{company.name}</span>
                <Checkbox
                  size="small"
                  checked={selected.includes(company.id)}
                />
              </SelectDiv>
            ))}
          </ScrollContainer>
          <ButtonHolder>
            <Button
              color="primary"
              style={{ marginRight: 'auto' }}
              onClick={handleCloseModal}
            >
              Dont Send
            </Button>
            <Button
              variant="outlined"
              color="primary"
              mr={2}
              onClick={handleSendAllContent}
            >
              Send to all
            </Button>
            <Button
              onClick={handleSendSelectedContent}
              variant="contained"
              color="primary"
            >
              Send to selected
            </Button>
          </ButtonHolder>
        </SelectContainer>
      )}
    </AllowScroll>
  );
};

export default VendorEditTable;

VendorEditTable.propTypes = {
  availableCompanies: PropTypes.array.isRequired,
  audience: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
