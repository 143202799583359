/* eslint-disable */
import React from "react";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";
import { Lock } from "react-feather";

const GreyOutDiv = styled.div`
  height: 20px;
  width: 100px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: lightgrey;
  }
`;

const UPGRADE_PRO_MSG = "Spiffy Pro Feature. Upgrade to access the data.";

const GreyOutLte = () => (
  <Tooltip title={UPGRADE_PRO_MSG}>
    <GreyOutDiv>
      <Lock style={{ height: 13, width: 13, color: "darkgrey" }} />
    </GreyOutDiv>
  </Tooltip>
);

export default GreyOutLte;
