/* eslint-disable */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Paper, CircularProgress, Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";
import { Typography, Divider } from "../pages/private/styles";
import useTeamMemberOutstanding from "../swr/hooks/Team/useTeamMemberOutstanding";
import { List } from "react-feather";
import { makeStyles } from "@material-ui/core";
import useQuizReview from "../swr/hooks/Library/useQuizReview";
import LibraryShowModule from "../pages/private/library/components/LibraryShowModule";
import { GlobalState } from "../store/GlobalState";
import { addDays } from "date-fns";

const Wrapper = styled(Paper)`
  padding: 20px 20px 20px 20px;
  border-radius: 6px;
  min-width: 280px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  margin-right: 4px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  min-height: 100px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid slategrey;
  min-height: 156px;
  height: 180px;

  span {
    color: black;
  }
`;

const Centered = styled.div`
  padding-top: 10px;
  display: flex;
  min-height: 20px;
  align-items: center;
  justify-content: flex-start;
`;

const OutstandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  height: auto;
  overflow-y: scroll;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    minWidth: 300,
    maxWidth: 400,
    minHeight: "none",
    maxHeight: "none",
    borderRadius: 8,
    padding: 0,
    // TODO -> used in many components
  },
}));

const Inner = ({ id }) => {
  const { details: data, isLoading } = useQuizReview(id);
  const { state } = useContext(GlobalState);
  const { config } = state;

  if (isLoading) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            minHeight: 140,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "darkgrey" }}>loading...</span>
        </div>
      </InnerContainer>
    );
  }
  return (
    <InnerContainer>
      <LibraryShowModule
        srcString={data.image}
        value={data.name}
        marketplaceParentInfo={
          Object.values(data.marketPlaceParent).length > 0
            ? { companyName: data.marketPlaceParent.company.name }
            : null
        }
        config={config}
      />
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Start date:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {addDays(new Date(data.startDate), 1).toDateString()}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          End date:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data.dueDate
            ? addDays(new Date(data.dueDate), 1).toDateString()
            : "---/---"}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Time to complete:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.estimatedTime} minutes
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Score to pass:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.passScore}/{data?.questions?.questions?.length}{" "}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Has Description:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data.description ? "Yes" : "No"}
        </span>
      </FlexDiv>

      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Has Disclaimer:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.disclaimer ? "Yes" : "No"}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Answer Key Enabled:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.displayAnswers ? "Yes" : "No"}
        </span>
      </FlexDiv>
    </InnerContainer>
  );
};

const OutstandingModules = ({ id, name }) => {
  const { isLoading, isError, data } = useTeamMemberOutstanding(id);

  const classes = useStyles();

  if (isLoading) {
    return (
      <Wrapper>
        <Centered>
          <CircularProgress size={30} />
        </Centered>
      </Wrapper>
    );
  }

  if (isError || !data) {
    return (
      <Wrapper>
        <Centered>
          <Typography variant="subtitle2">
            <em>Error Loading Outstanding Modules..</em>
          </Typography>
        </Centered>
      </Wrapper>
    );
  }

  const outstandingModules = data?.breakdown?.filter((quiz) => !quiz.completed);

  if (outstandingModules.length === 0) {
    return (
      <Wrapper>
        <Centered>
          <Typography variant="subtitle2">
            {name} has 0 outstanding modules
          </Typography>
        </Centered>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography mt={-1} mb={-1} style={{ fontSize: 15, fontWeight: "500" }}>
          Outstanding Modules
        </Typography>
      </div>
      <Divider mb={3} mt={3} />
      <OutstandingWrapper>
        {outstandingModules.map((quiz) => (
          <div
            key={quiz.id}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <span style={{ fontSize: 12, fontWeight: "400" }}>{quiz.name}</span>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={<Inner id={quiz.id} />}
              arrow
              placement="right"
            >
              <Container>
                <List height={12} width={12} color="darkgrey" />
              </Container>
            </Tooltip>
          </div>
        ))}
      </OutstandingWrapper>
    </Wrapper>
  );
};

export default OutstandingModules;

OutstandingModules.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
