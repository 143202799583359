/* eslint-disable */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  CustomDatatableV2,
  SubmissionChipExtraCreditTooltip,
  SubmissionChipAssignedTooltip,
} from "../../styles";
import {
  formatDateWithTime,
  makeDefaultTableOptions,
} from "../../../../storage/helpers";
import CustomHeadLabel from "./CustomHeadLabel";
import CustomToolBar from "./CustomToolbar";
import CustomFooter from "./CustomFooter";
import { ORDER_BY, ENGAGEMENT_TYPES } from "../../../../storage/constants";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";
import API from "../../../../axios/instances/API";
import VendorShowTeams from "../../../../components/VendorShowTeams";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../components/Table/TableTitle";
import { Download } from "react-feather";
const BASE_URL = "/dashboard/submissions/?";

const getTableData = (submissions, users) => {
  if (!submissions) return [];
  return submissions.map((submission) => {
    const submissionUser = users.find(
      (user) => user.user_id === submission.user_id
    );

    return {
      date: new Date(submission.created).toISOString(),
      module: submission.quiz_name,
      name: `${submission.first_name} ${submission.last_initial}`,
      state: submission.users_state ? submission.users_state : "Not Selected",
      submission_type: submission.is_follower_submission
        ? ENGAGEMENT_TYPES.EXTRACREDIT
        : ENGAGEMENT_TYPES.ASSIGNED,
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
      team: submissionUser.active_team_members,
    };
  });
};

const ServerSideSubmissionTable = ({
  engagementType = ENGAGEMENT_TYPES.ALL,
  users,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState(ORDER_BY.CREATED);
  const [orderDirection, setOrderDirection] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [queryData, setQueryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const buildSearchParamsAsString = (paginated = true) => {
    const obj = {
      pagination: paginated,
      page_size: pageSize,
      page: pageNumber,
      order_by: orderBy,
    };

    if (searchText.length > 2) {
      obj.search_text = searchText;
    }

    if (orderDirection) {
      obj.order_direction = orderDirection;
    }

    if (engagementType !== ENGAGEMENT_TYPES.ALL) {
      obj.submission_source =
        engagementType === ENGAGEMENT_TYPES.ASSIGNED ? "assigned" : "follower";
    }

    const searchParams = new URLSearchParams(obj);

    return searchParams.toString();
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const urlWithParams = `${BASE_URL}${buildSearchParamsAsString()}`;
      const { data } = await API.get(urlWithParams);
      setSubmissions(data.submissions);
      setQueryData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const retDataUnpaginated = async () => {
    try {
      const urlWithParams = `${BASE_URL}${buildSearchParamsAsString(false)}`;
      const { data } = await API.get(urlWithParams);
      const clean = getTableData(data.submissions, users);

      const cleanAndformattedTeams = clean.map((row) => {
        return {
          ...row,
          team: !row.team
            ? "No Team"
            : row.team.map((t) => `${t.company} | ${t.location}`).join(", "),
        };
      });

      return cleanAndformattedTeams;
    } catch (e) {
      return [];
    }
  };

  const tableData = useMemo(
    () => getTableData(submissions, users),
    [submissions, users]
  );

  useEffect(() => {
    getData();
  }, [
    orderBy,
    pageSize,
    pageNumber,
    orderDirection,
    searchText,
    engagementType,
  ]);

  if (!queryData) return null;

  const columns = [
    {
      name: "date",
      label: "Submission Date",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <span style={{ color: isLoading ? "#EEEEEE" : "black" }}>
            {formatDateWithTime(new Date(value))}
          </span>
        ),
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="Date"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.CREATED}
            isOrderedByColumn={orderBy === ORDER_BY.CREATED}
          />
        ),
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="User Name"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.FIRST_NAME}
            isOrderedByColumn={orderBy === ORDER_BY.FIRST_NAME}
          />
        ),
        customBodyRender: (val) => (
          <span style={{ color: isLoading ? "#EEEEEE" : "black" }}>{val}</span>
        ),
      },
    },
    {
      name: "state",
      label: "User State",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="User State"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.USERS_STATE}
            isOrderedByColumn={orderBy === ORDER_BY.USERS_STATE}
          />
        ),
        customBodyRender: (val) => (
          <span style={{ color: isLoading ? "#EEEEEE" : "black" }}>{val}</span>
        ),
      },
    },
    {
      name: "module",
      label: "Module Name",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="Module Name"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.MODULE_NAME}
            isOrderedByColumn={orderBy === ORDER_BY.MODULE_NAME}
          />
        ),
        customBodyRender: (val) => (
          <span style={{ color: isLoading ? "#EEEEEE" : "black" }}>{val}</span>
        ),
      },
    },
    {
      name: "team",
      label: "Users Team",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => <VendorShowTeams teamsArray={value} />,
      },
    },
    {
      name: "submission_type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          if (value === ENGAGEMENT_TYPES.ASSIGNED) {
            return <SubmissionChipAssignedTooltip />;
          }
          if (value === ENGAGEMENT_TYPES.EXTRACREDIT) {
            return <SubmissionChipExtraCreditTooltip isVendor />;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: () => (
          <CustomHeadLabel
            title="Score"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderKey={ORDER_BY.SCORE}
            isOrderedByColumn={orderBy === ORDER_BY.SCORE}
          />
        ),
      },
    },
    {
      name: "passed",
      label: " ",
      options: {
        display: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  const options = makeDefaultTableOptions("all-submissions");

  options.customFooter = () => (
    <CustomFooter
      isLoading={isLoading}
      queryData={queryData}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );

  options.customToolbar = () => (
    <CustomToolBar
      searchText={searchText}
      setSearchText={setSearchText}
      isLoading={isLoading}
      setPageNumber={setPageNumber}
      currentSubmissions={tableData}
      retDataUnpaginated={retDataUnpaginated}
    />
  );

  options.onSearchChange = (query) => {
    setSearchText(query);
  };

  options.rowsPerPage = pageSize;
  options.sort = false;
  options.search = false;
  options.print = false;
  options.download = false;
  options.textLabels = {
    body: {
      noMatch:
        "No users have engaged with your content! Create new modules and deploy it to the marketplace to engage new users!",
    },
  };

  return (
    <div style={{ width: "100%", marginTop: 16, minHeight: 200 }}>
      <CustomDatatableV2
        title={
          <div
            style={{
              height: 46,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TableTitle title="Submissions" Icon={Download} />
            <div style={{ width: 8 }} />
            <SubmissionTypeChip submissionType={engagementType} small />
            {searchText && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: 8,
                  marginTop: 4,
                }}
              >
                <span
                  style={{ fontSize: 10, color: "darkgrey", marginRight: 4 }}
                >
                  Including Keyword
                </span>
                <span style={{ fontSize: 10, fontWeight: 600, marginRight: 4 }}>
                  {searchText}
                </span>
                <span
                  style={{ fontSize: 10, color: "darkgrey", marginRight: 4 }}
                >
                  ({queryData.submissions_count} results)
                </span>
              </div>
            )}
          </div>
        }
        options={options}
        columns={columns}
        data={tableData}
      />
    </div>
  );
};

export default ServerSideSubmissionTable;

ServerSideSubmissionTable.propTypes = {
  engagementType: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
};
