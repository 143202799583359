/* eslint-disable */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import {
  DeleteButton,
  DeleteCircularProgress,
  Alert,
  Button,
  Typography,
} from "../styles";
import { Paper } from "@material-ui/core";
import API from "../../../axios/instances/API";
import { FEEDBACK_MESSAGES, JOB_LEVELS } from "../../../storage/constants";
import { GlobalState } from "../../../store/GlobalState";
import ACTIONS from "../../../store/actions";
import styled from "styled-components/macro";
import { getConfig } from "../../../storage/helpers";

const DeleteWrapperTwo = styled(Paper)`
  padding: 20px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 2px solid
    ${(props) => (props.modalMood === "positive" ? "mediumseagreen" : "tomato")};
  border-radius: 8px;
`;

const PendingModal = ({
  pendingData,
  setPendingData,
  mutate,
  setSnackbarText,
}) => {
  const { dispatch } = useContext(GlobalState);
  const [isdeleting, setIsDeleting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const handleDelete = async () => {
    setErrorAlert(false);
    setIsDeleting(true);
    try {
      await API.delete("dashboard/teams/pending/", {
        data: {
          id: pendingData.id,
        },
      });
      const config = await getConfig();
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      mutate();
      setIsDeleting(false);
      setSnackbarText(`Declined pending user ${pendingData.name} warning`);
      setPendingData(null);
    } catch (error) {
      setIsDeleting(false);
      setErrorAlert(true);
    }
  };

  const handleApprove = async () => {
    setErrorAlert(false);
    setIsDeleting(true);
    try {
      await API.put("dashboard/teams/pending/", {
        id: pendingData.id,
      });
      const config = await getConfig();
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      mutate();
      setIsDeleting(false);
      setSnackbarText(`Approved pending user ${pendingData.name} success`);
      setPendingData(null);
    } catch (error) {
      setIsDeleting(false);
      setErrorAlert(true);
    }
  };

  if (pendingData.action === JOB_LEVELS.APP_USER) {
    return (
      <DeleteWrapperTwo modalMood="positive">
        <Typography variant="h5" component="h5">
          Approve Team Member Request?
        </Typography>
        <Typography mb={3} mt={1} style={{ fontWeight: "400", fontSize: 13 }}>
          {pendingData.name}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          style={{ borderRadius: 6, backgroundColor: "mediumseagreen" }}
          onClick={handleApprove}
          startIcon={isdeleting && <DeleteCircularProgress size={20} />}
        >
          {" "}
          {isdeleting ? "Approving" : "Approve"}
        </Button>
        {errorAlert && (
          <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
            {FEEDBACK_MESSAGES.GENERIC_ERROR}
          </Alert>
        )}
      </DeleteWrapperTwo>
    );
  }

  return (
    <DeleteWrapperTwo modalMood="negative">
      <Typography variant="h5" component="h5">
        Decline Team Member Request?
      </Typography>
      <Typography mb={3} mt={1} style={{ fontWeight: "400", fontSize: 13 }}>
        {pendingData.name}
      </Typography>
      <DeleteButton
        fullWidth
        style={{ borderRadius: 6 }}
        onClick={handleDelete}
        startIcon={isdeleting && <DeleteCircularProgress size={20} />}
      >
        {" "}
        {isdeleting ? "Declining" : "Decline"}
      </DeleteButton>
      {errorAlert && (
        <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </DeleteWrapperTwo>
  );
};

export default PendingModal;

PendingModal.propTypes = {
  pendingData: PropTypes.object,
  setPendingData: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};

PendingModal.defaultProps = {
  pendingData: null,
};
