/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Paper, ClickAwayListener, CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Cast, Tag } from "react-feather";
import { Typography } from "../../pages/private/styles";
import useAudeince from "../../swr/hooks/Audience/useAudience";

const Wrapper = styled(Paper)`
  min-width: 180px;
  min-height: 130px;
  padding: 10px;
  border: 2px solid #eeeeee;
  text-align: left;
`;

const SubtitleSpan = styled(Typography)`
  display: block;
  font-size: 11px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

const ShowDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 240px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 6px 0px;
`;

const LinkSpan = styled(Link)`
  text-decoration: none;
  margin-top: 12px;
  display: block;

  &:active {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.dark};
  }

  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.dark};
  }

  &:hover {
    text-decoration: underline;
  }

  span {
    font-size: 10px;
    color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const CenterCircular = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagName = styled(Typography)`
  svg {
    height: 14px;
    width: 14px;
    margin-right: 3px;
    transform: translateY(2px);
  }

  font-weight: 600;
  font-size: 10px;
  color: #22597f;
`;

const ShowRoles = styled.div`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 10px;
    width: 10px;
    margin-right: 2px;
  }
`;

const ShowCompanies = styled.div`
  padding: 2px 0px;
  align-items: center;
  justify-content: center;
  max-height: 320px;
  overflow-y: scroll;

  svg {
    height: 10px;
    width: 10px;
    margin-right: 2px;
  }
`;

const Inner = ({ name, id, setOpen, shouldLinkToAudience = true }) => {
  const { isLoading, isError, data } = useAudeince(id);
  // This is the component that shows the vendor audience //
  if (isError) {
    return (
      <Wrapper>
        <span>Error loading Audience details</span>
      </Wrapper>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Wrapper>
        <TagName variant="subtitle2" style={{ color: "black" }}>
          <Cast height={12} width={12} />
          {name}
        </TagName>
        <SubtitleSpan mt={0}>
          Companies in Audience{" "}
          {isLoading ? "" : `(${data.audience.mapping.companies.length})`}
        </SubtitleSpan>
        {isLoading ? (
          <CenterCircular>
            <CircularProgress size={30} />
          </CenterCircular>
        ) : (
          <>
            <ShowCompanies>
              {data.audience.mapping.companies.map((company) => (
                <div key={company.id}>
                  {/* <Briefcase /> */}
                  <span style={{ fontSize: 12 }}>{company.name}</span>
                </div>
              ))}
            </ShowCompanies>
            <SubtitleSpan mt={4} mb={-1}>
              Role Categories ({data.audience.mapping.roles.length})
            </SubtitleSpan>
            <ShowDiv>
              {data.audience.mapping.roles.map((role) => (
                <ShowRoles key={role.id}>
                  <Tag />
                  <span>{role.name}</span>
                </ShowRoles>
              ))}
            </ShowDiv>
          </>
        )}
        {!isLoading && shouldLinkToAudience && (
          <LinkSpan to={`/audience/detail/${id}`}>
            <span>View Audience Details</span>
          </LinkSpan>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default Inner;

Inner.propTypes = {
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
