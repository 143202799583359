/* eslint-disable */
import { subDays, startOfDay } from 'date-fns';

const TODAY = new Date();

const TIMEPERIODS = {
  TODAY: 'Today',
  WEEK: 'Last Week',
  TWOWEEK: 'Last 14 Days',
};

const BREAKDOWNS = {
  USER: 'User',
  MODULE: 'Module',
  COMPANY: 'Company',
};

const SUBTYPES = {
  ALL: 'All',
  FOLLOWER: 'Follower',
  ASSIGNED: 'Assigned',
};

const getBreakdownName = (submission, breakdown, users) => {
  const selectedUser = users[submission.user_id];

  if (breakdown === BREAKDOWNS.USER)
    return users
      ? `${selectedUser.first_name} ${selectedUser.last_initial}`
      : `${submission.users_first_name} ${submission.users_last_name}`;
  if (breakdown === BREAKDOWNS.MODULE) return submission.quiz_name;
  if (breakdown === BREAKDOWNS.COMPANY) {
    if (!selectedUser.active_team_members) {
      return 'No Active Company';
    }
    return selectedUser.active_team_members[0].company;
  }
  return null;
};

const getSubsByType = (submissions, subType) => {
  if (subType === SUBTYPES.FOLLOWER) {
    return submissions.filter(sub => sub.is_follower_submission);
  }
  if (subType === SUBTYPES.ASSIGNED) {
    return submissions.filter(sub => !sub.is_follower_submission);
  }

  return submissions;
};

const getTimePeriod = timestring => {
  if (timestring === TIMEPERIODS.TODAY) return 1;
  if (timestring === TIMEPERIODS.WEEK) return 7;
  if (timestring === TIMEPERIODS.TWOWEEK) return 15;
  return 15;
};

// eslint-disable-next-line import/prefer-default-export
export const getSubBreakdown = (submissions, timePeriod, breakdown, subType, users) => {
  try {
    if (submissions.length === 0) return null;
    const numDays = getTimePeriod(timePeriod);
    const timeAgo = timePeriod === TIMEPERIODS.TODAY ? startOfDay(TODAY) : subDays(TODAY, numDays);
    const submissionsLastTimePeriod = submissions.filter(
      submission => new Date(submission.created) > timeAgo
    );

    const filteredSubmissions = getSubsByType(submissionsLastTimePeriod, subType);
    const answerObj = {};
    filteredSubmissions.forEach(submission => {
      const name = getBreakdownName(submission, breakdown, users);
      // eslint-disable-next-line no-prototype-builtins
      if (!answerObj.hasOwnProperty(name)) {
        if (submission.passed) {
          answerObj[name] = {
            subs: 1,
            pass: 1,
            name,
          };
        } else {
          answerObj[name] = {
            subs: 1,
            pass: 0,
            name,
          };
        }
      } else if (submission.passed) {
        answerObj[name].pass += 1;
        answerObj[name].subs += 1;
      } else {
        answerObj[name].subs += 1;
      }
    });
    return answerObj;
  } catch (error) {
    return {};
  }
};
