/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal } from '@material-ui/core';
import PrivateHeader from '../../../../../components/PrivateHeader';
import VendorCurrent from './VendorCurrent';
import VendorEdit from './VendorEdit';
import { Button } from '../../../styles';
import EditNameModal from '../EditNameModal';
import { Alert } from '@material-ui/lab';

export const EDIT_STATES = {
  ROLE_CATEGORIES: 'ROLE_CATEGORIES',
  COMPANIES: 'COMPANIES',
  NEITHER: 'NEITHER',
};

const VendorAudience = ({ data, setSnackbarText, id, mutate }) => {
  const { audience, options } = data;
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [useEdit, setUseEdit] = useState(EDIT_STATES.NEITHER);
  const [editNameOpen, setEditNameOpen] = useState(false);

  useEffect(() => {
    if (audience.mapping === null || Object.keys(audience.mapping).length === 0) {
      // If there is no mapping yet, set available to all
      setAvailableCompanies(options.companies);
      setAvailableRoles(options.roles);
      return;
    }
    // Set Companies to filter what is already in Audience
    const companiesInAudience = audience.mapping.companies.map(company => company.id);
    const available = options.companies.filter(
      company => !companiesInAudience.includes(company.id)
    );
    setAvailableCompanies(available);

    // Set Roles to filter what is already in Audience
    const rolesInAudience = audience.mapping.roles.map(role => role.id);
    const rolesAvailable = options.roles.filter(role => !rolesInAudience.includes(role.id));
    setAvailableRoles(rolesAvailable);
  }, [data]);

  return (
    <>
      <PrivateHeader
        header="Custom Audience"
        destination="/audience/"
        secondary={audience.name}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => setEditNameOpen(true)}
          disabled={audience?.mapping === null}
        >
          Edit Audience Name
        </Button>
      </PrivateHeader>
      <Alert
        severity="info"
        style={{ marginBottom: 12, marginTop: 24 }}
      >
        <p style={{ fontWeight: '600', fontSize: 14 }}>Custom Audience Notes</p>
        <ul>
          <li>There must be at least 1 role category selected</li>
          <li>There must be at least 1 company selected</li>
        </ul>
      </Alert>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <VendorCurrent
            audience={audience}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            setUseEdit={setUseEdit}
            EDIT_STATES={EDIT_STATES}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <VendorEdit
            options={options}
            availableCompanies={availableCompanies}
            availableRoles={availableRoles}
            audience={audience}
            setSnackbarText={setSnackbarText}
            id={id}
            mutate={mutate}
            useEdit={useEdit}
            setUseEdit={setUseEdit}
            EDIT_STATES={EDIT_STATES}
          />
        </Grid>
      </Grid>
      <Modal
        open={editNameOpen}
        onClose={() => setEditNameOpen(false)}
        aria-labelledby="Edit This Audience Name"
        aria-describedby="Modal To Edit Audience Name"
      >
        <div>
          <EditNameModal
            setSnackbarText={setSnackbarText}
            handleClose={() => setEditNameOpen(false)}
            audience={audience}
            mutate={mutate}
            isVendor
          />
        </div>
      </Modal>
    </>
  );
};

export default VendorAudience;

VendorAudience.propTypes = {
  data: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
};
