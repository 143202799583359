/* eslint-disable */

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../components/PrivateHeader";
import TeamMemberRoles from "./TeamMemberRoles";
import { GlobalState } from "../../../../store/GlobalState";
import OnLoadError from "../../../../components/OnLoadError";
import SubmissionTable from "./SubmissionTable";
import useSingleTeamMemberReport from "../../../../swr/hooks/RetailReports/useSingleTeamMemberReport";
import OutstandingModules from "../../../../components/OutstandingModules";
import { DescriptorText, OverSizedText } from "../../home/styles";
import {
  getTotals,
  getDaysSinceLastSubmission,
  getAverageScore,
} from "./utils";
import TimeStats from "./TimeStats";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { addCommas } from "../../../../storage/helpers";
import { PieChart, BarChart2, Percent, Award } from "react-feather";
import UserHero from "./UserHero";
import PerformaceStats from "./PerformaceStats";

import styled from "styled-components/macro";

const SOURCE_TYPES = {
  EXTRA_CREDIT: "extra_credit",
  ASSIGNED: "assigned",
};

const SvgImage = styled.img`
  height: 32px;
  width: 32px;
`;

const InfoWrapper = styled.div`
  display: grid;

  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 16px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

const InfoPaper = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  min-height: 150px;
  text-align: left;
  display: flex;
  border-radius 6px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: ${(props) => {
    if (props.subtype === ENGAGEMENT_TYPES.ASSIGNED) {
      return "0px 0px 2px 2px rgba(0, 0, 250, .4)";
    }
    if (props.subtype === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "0px 0px 1px 2px gold";
    }
  }};

  svg {
    height: 26; 
    width: 26;
    color: black;
  }
`;

const MaxWidthWrapper = styled.div`
  margin: auto;
  max-width: 1600px;
  background-color: transparent;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const InnerLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  flex-basis: 280px;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
  }
`;

const InnerRight = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: auto;
  overflow: hidden;
  gap: 16px;
`;

const getActiveSubmissions = (submissions, engagementType) => {
  if (!submissions || submissions.length === 0) return [];

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    return submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    return submissions.filter((sub) => sub.source === SOURCE_TYPES.ASSIGNED);
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    return submissions.filter(
      (sub) => sub.source === SOURCE_TYPES.EXTRA_CREDIT
    );
  }

  return [];
};

const TeamMemberDetail = ({ match }) => {
  const { id } = match.params;
  const { state } = useContext(GlobalState);
  const { readOnly } = state.config;
  const isManager = state.config.job === "M";

  const { isLoading, details, isError, mutate } = useSingleTeamMemberReport(id);
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return (
      <MaxWidthWrapper>
        <PrivateHeader header="Team Member" secondary={true} />
        <div
          style={{
            width: "100%",
            minHeight: 800,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoaderWrapper text="Team Member Details" />
        </div>
      </MaxWidthWrapper>
    );
  }

  if (isError) {
    return (
      <MaxWidthWrapper>
        <OnLoadError />
      </MaxWidthWrapper>
    );
  }

  const { submissionList: submissions } = details;
  const activeSubmissions = getActiveSubmissions(submissions, engagementType);

  const {
    totalUniqueModules,
    mostAttempts,
    passedFirstTry,
    firstTryPercentage,
  } = getTotals(activeSubmissions);

  const daysSinceLast =
    activeSubmissions.length > 0
      ? getDaysSinceLastSubmission(activeSubmissions)
      : 0;

  const fullName = `${details.user.firstName} ${details.user.lastName}`;

  const totalSubmission = activeSubmissions.length;

  const totalCompletions = activeSubmissions.reduce((total, acc) => {
    if (acc.passed) return (total += 1);
    return (total += 0);
  }, 0);

  const { averageScore, passRate } = getAverageScore(activeSubmissions);

  return (
    <MaxWidthWrapper>
      <PrivateHeader
        header="Team Member"
        secondary={isLoading ? null : fullName}
        small
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
      </PrivateHeader>
      <Container>
        <InnerLeft>
          <UserHero
            data={details}
            mutate={mutate}
            isManager={isManager}
            readOnly={readOnly}
          />
          {/* <TeamMemberActions /> */}
          <TeamMemberRoles
            id={id}
            readOnly={readOnly}
            isManager={isManager}
            details={details}
            mutate={mutate}
          />
          <OutstandingModules
            name={fullName}
            id={details.id}
            engagementType={engagementType}
          />
        </InnerLeft>
        <InnerRight>
          <InfoWrapper>
            <InfoPaper subtype={engagementType}>
              <div
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 8,
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 4,
                }}
              >
                <BarChart2 />
              </div>

              <DescriptorText>Submissions </DescriptorText>
              <OverSizedText>{addCommas(totalSubmission)}</OverSizedText>
            </InfoPaper>
            <InfoPaper subtype={engagementType}>
              <div
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 8,
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 4,
                }}
              >
                <PieChart />
              </div>

              <DescriptorText>Completions</DescriptorText>
              <OverSizedText>{addCommas(totalCompletions)}</OverSizedText>
            </InfoPaper>
            <InfoPaper subtype={engagementType}>
              <div
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 8,
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 4,
                }}
              >
                <Percent />
              </div>
              <DescriptorText>Pass Rate</DescriptorText>
              <OverSizedText>{passRate}%</OverSizedText>
            </InfoPaper>
            <InfoPaper
              subtype={engagementType}
              style={{
                background: "#eeeeee",
              }}
            >
              <div
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 8,
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 4,
                }}
              >
                <Award color="slategrey" height={22} width={22} />
              </div>
              <DescriptorText style={{ color: "slategray" }}>
                Spiffy Score
              </DescriptorText>
              <OverSizedText style={{ color: "black" }}>
                {addCommas(details?.spiffy_scores?.overall_score)}
              </OverSizedText>
            </InfoPaper>
          </InfoWrapper>

          <PerformaceStats
            teamMemberId={id}
            userId={details.user.id}
            name={fullName}
            firstTryPercentage={firstTryPercentage}
            passedFirstTry={passedFirstTry}
            totalUniqueModules={totalUniqueModules}
            totalCompletions={totalCompletions}
            totalSubmission={totalSubmission}
            averageScore={averageScore}
            engagementType={engagementType}
          />

          <TimeStats
            submissions={activeSubmissions}
            daysSinceLast={daysSinceLast}
            mostAttempts={mostAttempts}
            engagementType={engagementType}
          />

          <SubmissionTable
            submissions={activeSubmissions}
            engagementType={engagementType}
          />
        </InnerRight>
      </Container>
    </MaxWidthWrapper>
  );
};

export default TeamMemberDetail;

TeamMemberDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
