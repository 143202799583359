import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useQuizMedia = (id) => {
  const { data, error, mutate } = useSWR(`dashboard/quiz/media/?id=${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    media: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useQuizMedia;
