/* eslint-disable */
import React from "react";
import { Edit } from "react-feather";
import { StyledItem } from "../styles";
import { QUIZSTATES, USER_TYPES } from "../../../../../storage/constants";
import { useHistory } from "react-router-dom";

const EditModule = ({
  id,
  isRetailerModuleFromMarketplace,
  moduleState,
  isUserAManagerWhoCreatedModule,
  userType,
}) => {
  const history = useHistory();

  if (
    ![
      QUIZSTATES.IN_DEVELOPMENT,
      QUIZSTATES.PENDING,
      QUIZSTATES.CONVERSION,
    ].includes(moduleState)
  ) {
    return null;
  }

  if (isRetailerModuleFromMarketplace) return null;

  if (userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY) {
    if (!isUserAManagerWhoCreatedModule) return null;
  }

  return (
    <StyledItem
      onClick={() => {
        history.push(`/modules/quiz-builder/${id}`);
      }}
    >
      <Edit />
      <span>Edit Module</span>
    </StyledItem>
  );
};

export default EditModule;
