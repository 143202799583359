/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Modal } from "@material-ui/core";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../components/PrivateHeader";
import useAudience from "../../../../swr/hooks/Audience/useAudience";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import OnLoadError from "../../../../components/OnLoadError";
import VendorAudience from "./vendor";
import Retail from "./retail";

const AudienceDetail = ({ match }) => {
  const { id } = match.params;
  const [snackbarText, setSnackbarText] = useState("");
  const { data, isLoading, mutate, isError } = useAudience(id);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Audience Template" secondary={true} />
        <LoaderWrapper text="Audience Details" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  if (data.audience.mappingType === "vendor") {
    return (
      <>
        <Helmet title="Spiffy | Audience Template" />
        <VendorAudience
          data={data}
          setSnackbarText={setSnackbarText}
          id={id}
          mutate={mutate}
        />
        <SnackbarWrapper
          snackbarText={snackbarText}
          setSnackbarText={setSnackbarText}
        />
      </>
    );
  }

  const { audience } = data;

  return (
    <>
      <Helmet title="Spiffy | Audience Template" />
      <Retail audience={audience} mutate={mutate} />
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default AudienceDetail;

AudienceDetail.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
