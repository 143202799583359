/* eslint-disable */
import React, { useState } from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import styled from "styled-components/macro";
import {
  clearSWRKeys,
  hasLogoImage,
  getSrcCompanyPhoto,
} from "../../storage/helpers";

import SpiffyLogo from "../../assets/images/newspiffy.avif";
import CheckboxBrandBasic from "../CheckboxBrandBasic";
import API from "../../axios/instances/API";
import { useHistory } from "react-router-dom";
import ACTIONS from "../../store/actions";

const ModalWrapper = styled(Paper)`
  padding: 16px;
  width: 380px;
  text-align: left;
  min-height: 180px;
  max-width: 400px;
  max-height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 2px solid black;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 2px;
  padding: 2px;
  background: white;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
  background: ${(props) => (props.isselected === "Yes" ? "#eeeeee" : "white")};
  &:hover {
    background: ${(props) =>
      props.isselected === "Yes" ? "#eeeeee" : "#eeeeee66"};
  }
`;

const SelectCompanyModal = ({
  availableCompanies,
  isManager,
  currentCompanyId,
  handleClose,
  dispatch,
}) => {
  const [selected, setSelected] = useState(currentCompanyId || null);
  const history = useHistory();

  const handleCompanyChange = async (company) => {
    try {
      if (isManager) {
        await API.put("/dashboard/account/switch-managers-company/", {
          id: selected,
        });
      } else {
        await API.put("dashboard/account/company/", {
          id: selected,
        });
      }
      // Need to get the new organization details..
      // and get rid of all Cached SWR Data (new data for new company)
      // Doesn't actually log you out.. but gets the config info again
      clearSWRKeys();
      dispatch({ type: ACTIONS.REFRESH_CONFIG });
      handleClose();
      history.push("/home/");
    } catch (error) {
      // Need To Find Good Way to handle this error
    }
  };
  return (
    <ModalWrapper>
      <Typography variant="h4">Select Company</Typography>
      <Typography style={{ fontSize: 11, color: "darkgrey" }}>
        All companies in your organization are listed below
      </Typography>
      <div style={{ height: 12 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          overflowY: "scroll",
          gap: 4,
          paddingBottom: 8,
          maxHeigth: 400,
        }}
      >
        {availableCompanies.map((company) => (
          <Item
            onClick={() => setSelected(company.id)}
            key={company?.id}
            isselected={selected === company.id ? "Yes" : "No"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              gap: 4,
            }}
          >
            <img
              style={{
                height: 32,
                width: 32,
                borderRadius: 14,

                border: "1px solid lightgray",
                objectFit: "contain",
                backgroundColor: "white",
                marginRight: 4,
              }}
              src={
                hasLogoImage(company.logo)
                  ? getSrcCompanyPhoto(company.logo)
                  : SpiffyLogo
              }
            />
            <span style={{ fontSize: 12, fontWeight: "500" }}>
              {company?.name}
            </span>
            <div style={{ marginLeft: "auto" }}>
              <CheckboxBrandBasic
                checked={selected === company.id}
                handleChange={() => {}}
              />
            </div>
          </Item>
        ))}
      </div>
      <div style={{ height: 12 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ color: "white", marginLeft: "auto" }}
          onClick={handleCompanyChange}
        >
          Apply
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default SelectCompanyModal;
