/* eslint-disable */
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Modal } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import PrivateHeader from "../../../components/PrivateHeader";
import useAudienceList from "../../../swr/hooks/Audience/useAudienceList";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import { Alert } from "../styles";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import AudienceTable from "./AudienceTable";
import CreateAudience from "./CreateAudience";
import { GlobalState } from "../../../store/GlobalState";
import OnLoadError from "../../../components/OnLoadError";
import NoAccess from "../../../components/NoAccess";
import { Cast } from "react-feather";
import CreateButton from "../../../components/UI/CreateButton";

const Audience = () => {
  const { state } = useContext(GlobalState);
  const { readOnly, isRetail } = state.config;
  const { lte } = state.config.tier;
  const [addAudienceOpen, setAddAudienceOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const { audienceList, isLoading, mutate, isError } = useAudienceList();
  const hasMultipleVerticals = state?.config?.user?.company?.verticals > 1;

  if (isLoading) {
    return (
      <>
        <PrivateHeader
          header={isRetail ? "Audience Templates" : "Custom Audiences"}
          Icon={Cast}
        />
        <LoaderWrapper text="Audiences" />
      </>
    );
  }

  if (isError) {
    if (hasMultipleVerticals) {
      return (
        <>
          <PrivateHeader
            header={isRetail ? "Audience Templates" : "Custom Audiences"}
            Icon={Cast}
          />
          <div
            style={{
              height: 520,
              width: "100%",
              border: "1px solid lightgray",
              background: "#eeeeee",
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: 16, fontWeight: "600" }}>
              Work in progress
            </span>
            <span
              style={{
                color: "darkgrey",
                textAlign: "center",
                maxWidth: "80%",
              }}
            >
              Custom audiences not currently supported for vendors with multiple
              verticals
            </span>
          </div>
        </>
      );
    }

    return <OnLoadError />;
  }

  if (lte) {
    return <NoAccess />;
  }

  return (
    <>
      <Helmet title="Spiffy | Audiences" />
      <PrivateHeader
        header={isRetail ? "Audience Templates" : "Custom Audiences"}
        Icon={Cast}
      ></PrivateHeader>
      <Grid container spacing={6}>
        <Grid item>
          <CreateButton
            disabled={readOnly}
            handleClick={() => setAddAudienceOpen(true)}
            title={isRetail ? "Create Template" : "Create Audience"}
          />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle style={{ fontWeight: "600" }}>
              What are audience templates?
            </AlertTitle>
            {isRetail && (
              <>
                <p>
                  Most of the communication on our platform is sent to all team
                  members at all locations, or all team members with a certain
                  role, but sometimes you need to get more granular. That’s what
                  audience templates are for! After creating a custom audience
                  template, you can quickly apply it to modules you build in the
                  future to have content delivered to this specific group of
                  team members.
                </p>
                <div style={{ height: 8 }} />
                <ul>
                  <li>
                    {" "}
                    Audience templates are used to create pre-saved groups of
                    team members, so you quickly and easily communicate with
                    them when building modules in the future. For example, “all
                    Managers in the Northeast region.”
                  </li>
                  <li>
                    You can add and remove groups by role or location, or get
                    granular by ticking one box at a time For example, “Managers
                    only, only at the Spiffsville location.”
                  </li>
                </ul>
              </>
            )}
            {!isRetail && (
              <ul>
                <li>
                  Most content is deployed to the marketplace, which means it is
                  accessible to our broader userbase.
                </li>
                <li>
                  If you want to create a module for a specific company, or
                  selection of companies, you can use audeince templates to
                  target them.
                </li>
              </ul>
            )}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <AudienceTable
            isRetail={isRetail}
            audienceList={audienceList}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
            readOnly={readOnly}
          />
        </Grid>
        <Modal
          open={addAudienceOpen}
          onClose={() => setAddAudienceOpen(false)}
          aria-labelledby="Create New Audience"
          aria-describedby="Modal to Create New Audience"
        >
          <div>
            <CreateAudience
              setSnackbarText={setSnackbarText}
              mutate={mutate}
              isRetail={isRetail}
              setAddAudienceOpen={setAddAudienceOpen}
            />
          </div>
        </Modal>
      </Grid>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Audience;
