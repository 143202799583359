/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data;
};

const useKeyCompanyStats = () => {
  const { data, error, mutate } = useSWR(
    'dashboard/analytics/retail-reports/key-company-statistics/ ',
    fetcher
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useKeyCompanyStats;
