/* eslint-disable */
import React from 'react';
import FeedbackLiked from './FeedbackLiked';
import FeedbackDisliked from './FeedbackDisliked';

const FeedbackTotals = ({ numLiked = 0, numDisliked = 0 }) => {
  return (
    <div
      style={{
        height: 28,
        minWidth: 100,
        width: 'fit-content',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 4,
      }}
    >
      <FeedbackLiked isZero={numLiked === 0} />
      <span style={{ fontSize: 11, fontWeight: '600' }}>{numLiked} up</span>
      <div style={{ width: 2 }} />
      <FeedbackDisliked isZero={numDisliked === 0} />
      <span style={{ fontSize: 11, fontWeight: '600' }}>{numDisliked} down</span>
      <div style={{ width: 12 }} />
    </div>
  );
};

export default FeedbackTotals;
