/* eslint-disable no-useless-escape */
/* eslint-disable */
import { cache, mutate } from "swr";
import { differenceInDays, lightFormat, format } from "date-fns";
import {
  AUTH_KEY,
  REFRESH_KEY,
  IMAGE_URL,
  AMAZON_IMAGE_URL,
  USER_TYPES,
} from "./constants";
import CustomSearchBarForTables from "../components/Table/CustomSearchBarForTables";
import API from "../axios/instances/API";

export const getToken = () => localStorage.getItem(AUTH_KEY);
export const setToken = (token) => localStorage.setItem(AUTH_KEY, token);
export const getRefresh = () => localStorage.getItem(REFRESH_KEY);
export const setRefresh = (refreshtoken) =>
  localStorage.setItem(REFRESH_KEY, refreshtoken);

export const clearSWRKeys = () =>
  cache.keys().forEach((key) => cache.delete(key));

export const clearTokens = () => {
  localStorage.removeItem(AUTH_KEY);
  localStorage.removeItem(REFRESH_KEY);
};

export const makeDefaultTableOptions = (downloadName) => ({
  filterType: "textField",
  filter: false,
  viewColumns: false,
  elevation: 1,
  selectableRows: "none",
  rowsPerPage: 5,
  print: true,
  jumpToPage: true,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  responsive: "standard",
  downloadOptions: {
    filename: `${downloadName}.csv`,
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
  customSearchRender: (searchText, onSearch, onHide) => (
    <CustomSearchBarForTables
      searchText={searchText}
      onSearch={onSearch}
      onHide={onHide}
    />
  ),
});

export const addCommas = (num) => {
  if (!num || num === undefined) return 0;
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const fetcher = async () => {
  const { data } = await API.get("dashboard/quiz/");
  return data;
};

const teamFetcher = async () => {
  const { data } = await API.get("/dashboard/teams/");
  return data.team;
};

export const prefetchQuizzes = async () => {
  await mutate("dashboard/quiz/", fetcher);
};

export const prefetchTeamMembers = async () => {
  await mutate("/dashboard/teams/", teamFetcher);
};

export const getSrc = (src, override = false) => {
  if (src === "/media/spiffy-logo.png") return null;
  if (process.env.NODE_ENV === "development") {
    if (override) {
      return `${IMAGE_URL}${src}`;
    }
    return `${IMAGE_URL}/media/${src}`;
  }
  return src;
};

export const getSrcTwo = (src) => {
  if (src.startsWith("uploads")) {
    return `${AMAZON_IMAGE_URL}/media/${src}`;
  }

  return src;
};

export const fixIncomingDate = (date) => {
  if (!date) return null;
  return new Date(date.replace(/-/g, "/"));
};

export const limitChars = (text, isDescription = true) => {
  const maxLen = isDescription ? 120 : 52;
  if (text === null)
    return `No ${isDescription ? "Description" : "Title"} Provided`;
  if (text.length > maxLen) {
    const shortened = text.substring(0, maxLen);
    return shortened.trim().concat("...");
  }
  return text;
};

export const getDaysOpen = (start, end) => {
  if (!start) return 0;

  // if no end date, return difference in days from now and the start date
  if (!end) {
    return differenceInDays(new Date(), new Date(start));
  }
  // if there is an end date, but we haven't reached it yet, return # of days since start date
  if (new Date() < new Date(end)) {
    return differenceInDays(new Date(), new Date(start));
  }

  // If start + end + we have reached end, return length it was open
  if (start && end) {
    return differenceInDays(new Date(end), new Date(start));
  }

  return null;
};

export const hasLogoImage = (string) => {
  if (!string) return false;
  if (
    string === "https://spiffy-staging.s3.amazonaws.com/media/spiffy-logo.png"
  )
    return false;
  if (string === `${AMAZON_IMAGE_URL}/media/spiffy-logo.png`) return false;

  return true;
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const apiFormatDate = (date) => {
  if (!date) return null;

  return lightFormat(date, "y-MM-dd");
};

export const getSrcCompanyPhoto = (src) => `${AMAZON_IMAGE_URL}/media/${src}`;

export const formatDateWithTime = (date) => {
  if (!date) return null;
  return format(date, "MMM-dd-y h:mm aaaa");
};

export const get400ErrorMsg = (
  error,
  backup = "Oops, we ran into an error, please try again error"
) => {
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data.messages
  ) {
    return `${error.response.data.messages.join(", ")} error`;
  }
  return backup;
};

export const getConfig = async () => {
  try {
    const { data } = await API.get("dashboard/account/config/");
    return data;
  } catch (error) {
    throw new Error("Failed to load the config object");
  }
};

export const getInvitesErrorMsgServer = (error) => {
  /// InviteTeamMember-  InviteSupervisor - InviteAdmin - AcceptDarModal ///
  try {
    const errorLogs = error?.response?.data?.error;
    const errors = errorLogs?.context?.errors?.join("\n");
    const warnings = errorLogs?.context?.warnings?.join("\n");

    const serverMsg =
      errors && warnings ? `${errors} \n${warnings}` : errors || warnings;

    return {
      status: error?.response?.status,
      title: errorLogs.title,
      details: errorLogs.details,
      context: serverMsg,
    };
  } catch (err) {
    return {
      status: error?.response?.status,
      title: "Error",
      details: "Unknown error occured",
      context: null,
    };
  }
};

export const getUserType = (config) => {
  /// THIS TAKES CONFIG AND RETURNS A USER TYPE STRING -> use with ./constants -> USER_TYPES

  const { isRetail, user, readOnly } = config;

  if (readOnly) {
    return USER_TYPES.READ_ONLY;
  }

  if (user.job === "C" && isRetail) {
    return USER_TYPES.RETAIL_ADMIN;
  }

  if (user.job === "M" && user.can_deploy_vendor_content) {
    return USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY;
  }

  if (user.job === "M" && !user.can_deploy_vendor_content) {
    return USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY;
  }

  if (!isRetail) {
    return USER_TYPES.VENDOR;
  }

  return "ERROR";
};
