/* eslint-disable */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { mutate as swrMutate } from "swr";
import Vimeo from "vimeo";
import API from "../../../../axios/instances/API";
import { GlobalState } from "../../../../store/GlobalState";
import ACTIONS from "../../../../store/actions";
import useQuizMedia from "../../../../swr/hooks/Library/useQuizMedia";
import HalfPanel from "../UI/HalfPanel";
import RequiredChip from "../UI/RequiredChip";
import { LoaderCentered } from "../styles";
import ContentTypeRadio from "./ContentTypeRadio";
import CurrentMedia from "./CurrentMedia";
import DropzonePDF from "./DropzonePDF";
import DropzoneVideo from "./DropzoneVideo";
import Guidelines from "./Guidelines";

const Content = ({ id, setSnackbarText, details, isModuleLive }) => {
  const { mutate, media, isLoading } = useQuizMedia(id);
  const { state, dispatch } = useContext(GlobalState);
  const { transcoding } = state;
  const transcodingIds = transcoding.map((code) => code.quizID);
  const videoTranscoding =
    transcoding.length > 0 && transcodingIds.includes(Number(id));
  const [uploadOption, setUploadOption] = useState(
    videoTranscoding ? "V" : "P"
  );
  const [pdfAlert, setPdfAlert] = useState("");
  const [videoAlert, setVideoAlert] = useState("");
  const [uploadPDF, setUploadPDF] = useState([]);
  const [pdfUploading, setPdfUploading] = useState(false);
  const [uploadVideo, setUploadVideo] = useState([]);
  const [videoUploading, setVideoUploading] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [dropzoneKey, setDropZoneKey] = useState(false);

  const usersCompany = state?.config?.user?.company?.name || "";

  const inDevelopment = details.state === "In Development";

  const VimeoAPI = Vimeo.Vimeo;
  const Client = new VimeoAPI(
    process.env.REACT_APP_CLIENT_IDENTIFIER,
    process.env.REACT_APP_CLIENT_SECRETS,
    process.env.REACT_APP_VIMEO_ACCESS_TOKEN
  );

  if (isLoading) {
    return (
      <LoaderCentered>
        <span style={{ color: "darkgrey" }}>loading...</span>
      </LoaderCentered>
    );
  }

  const handleRadioChange = (_, value) => {
    setUploadOption(value);
  };

  const handlePdfUpload = async () => {
    setPdfUploading(true);
    const formData = new FormData();
    const [sendPdf] = uploadPDF;
    formData.append("content", sendPdf);
    formData.append("media_type", "P");
    formData.append("id", id);
    try {
      await API.put("dashboard/quiz/media/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      await mutate();
      setDropZoneKey((p) => !p);
      setUploadPDF([]);
      setPdfUploading(false);
      setSnackbarText(`Successfully uploaded Pdf "${sendPdf.name}" success`);
    } catch (error) {
      setPdfUploading(false);
      setSnackbarText("Failed to upload Pdf. Please try again. error");
    }
  };

  const handleVideoUpload = () => {
    const VIDEO_NAME = `${details.name}-${usersCompany}`;
    const VIDEO_NAME_NO_EMOJIS = VIDEO_NAME.replaceAll(/\p{Emoji}/gu, "-");
    setVideoUploading(true);
    const [file_name] = uploadVideo;
    setUploadVideo([]);
    Client.upload(
      file_name,
      {
        name: VIDEO_NAME_NO_EMOJIS,
        description: "",
      },
      async (uri) => {
        await completeUpload(uri);
        setPercentageUploaded(0);
        dispatch({
          type: ACTIONS.ADD_TRANSCODE,
          payload: {
            uri,
            VIDEO_NAME,
            name: details.name,
            quizID: Number(id),
            startTime: new Date(),
          },
        });

        await swrMutate(`/dashboard/quiz/review/?id=${id}`);
        setDropZoneKey((p) => !p);
      },
      (bytes_uploaded, bytes_total) => {
        const percentage = ((bytes_uploaded / bytes_total) * 100).toFixed(2);
        setPercentageUploaded(percentage * 1);
      },

      (_error) => {
        setVideoUploading(false);
        setSnackbarText("Error uploading your video. Please try again error");
      }
    );
  };

  const completeUpload = async (uri) => {
    try {
      await API.put("dashboard/quiz/media/", {
        id,
        media_type: "V",
        video: uri,
      });
      await mutate();
      setVideoUploading(false);
      setSnackbarText("Successfully uploaded your video success");
    } catch (error) {
      setSnackbarText("Failed to upload your video error");
    }
  };

  const handleRemovePdf = async () => {
    try {
      await API.delete("dashboard/quiz/media/", {
        data: {
          id,
          media_type: "P",
        },
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      return setSnackbarText("Removed pdf from module success");
    } catch (error) {
      return setSnackbarText("Failed to remove the pdf error");
    }
  };

  const handleRemoveVideo = async () => {
    try {
      await API.delete("dashboard/quiz/media/", {
        data: {
          id,
          media_type: "V",
        },
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      return setSnackbarText("Removed the video for this module success");
    } catch (error) {
      return setSnackbarText("Failed to remove your video error");
    }
  };

  return (
    <>
      <HalfPanel
        title="Upload Content"
        RightComponent={() => (
          <RequiredChip
            completed={Boolean(details?.media || details?.video)}
            title="Required"
            small
            requirementText="Every module must have at least 1 piece of content"
          />
        )}
      >
        <Box mb={4}>
          <ContentTypeRadio
            uploadOption={uploadOption}
            handleRadioChange={handleRadioChange}
          />
        </Box>
        {uploadOption === "P" && (
          <DropzonePDF
            setPdfAlert={setPdfAlert}
            setUploadPDF={setUploadPDF}
            pdfAlert={pdfAlert}
            handlePdfUpload={handlePdfUpload}
            uploadPDF={uploadPDF}
            dropzoneKey={dropzoneKey}
            pdfUploading={pdfUploading}
            setSnackbarText={setSnackbarText}
          />
        )}
        {uploadOption === "V" && (
          <DropzoneVideo
            setVideoAlert={setVideoAlert}
            setUploadVideo={setUploadVideo}
            videoAlert={videoAlert}
            uploadVideo={uploadVideo}
            videoUploading={videoUploading}
            handleVideoUpload={handleVideoUpload}
            percentageUploaded={percentageUploaded}
            videoTranscoding={videoTranscoding}
            dropzoneKey={dropzoneKey}
            setSnackbarText={setSnackbarText}
          />
        )}
        <div>
          <Guidelines uploadOption={uploadOption} />
        </div>
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />
      <HalfPanel title="Review Content">
        <CurrentMedia
          media={media}
          videoUploading={videoUploading}
          videoTranscoding={videoTranscoding}
          handleRemovePdf={handleRemovePdf}
          handleRemoveVideo={handleRemoveVideo}
          inDevelopment={inDevelopment}
          isModuleLive={isModuleLive}
        />
      </HalfPanel>
    </>
  );
};

export default Content;

Content.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
};
