/* eslint-disable */
import React from "react";
import { LinearProgress } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import { Button, Typography } from "../../styles";
import TranscodingWarning from "../UI/TranscodingWarning";
// 100 mb
const MAXFILESIZE = 524288000;

const DropzoneVideo = ({
  setVideoAlert,
  setUploadVideo,
  videoAlert,
  uploadVideo,
  videoUploading,
  handleVideoUpload,
  percentageUploaded,
  videoTranscoding,
  dropzoneKey,
  setSnackbarText,
}) => {
  const videoTooLarge = (file) => {
    const uploadSize = file[0].size;
    if (uploadSize > MAXFILESIZE) {
      return setSnackbarText(
        "Failed to receive video. Your video is over the max file size of 500mb error"
      );
    }
    return setSnackbarText("Failed to receive video. Please try again error");
  };

  return (
    <>
      <DropzoneArea
        acceptedFiles={["video/*"]}
        showFileNamesInPreview
        showFileNames
        getFileAddedMessage={(fileName) => setVideoAlert(fileName)}
        filesLimit={1}
        dropzoneProps={{ disabled: videoUploading || videoTranscoding }}
        maxFileSize={MAXFILESIZE}
        onDropRejected={(file) => videoTooLarge(file)}
        showAlerts={false}
        onChange={(file) => {
          if ((videoTranscoding || videoUploading) && file.length > 0) {
            return setSnackbarText(
              "Cannot add new video while one is trascoding/uploading warning"
            );
          }
          return setUploadVideo(file);
        }}
        dropzoneText={
          <div
            style={{
              height: 80,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 16 }}>Drop a video here</span>
            <span style={{ fontSize: 13, color: "darkgrey" }}>
              {videoTranscoding
                ? "Video transcoding"
                : videoUploading
                ? "Uploading"
                : "Accepts video files"}
            </span>
          </div>
        }
        key={dropzoneKey}
      />
      {videoAlert && uploadVideo.length === 1 && !videoUploading && (
        <>
          <Button
            mt={2}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              handleVideoUpload();
            }}
          >
            Upload
          </Button>
        </>
      )}
      {videoUploading && (
        <div style={{ width: "100%" }}>
          <Typography variant="subtitle2" mt={2} mb={1}>
            Your video is uploading...
          </Typography>
          <LinearProgress variant="determinate" value={percentageUploaded} />
          <Typography variant="subtitle2">{percentageUploaded} %</Typography>
        </div>
      )}
      {videoTranscoding && (
        <>
          {" "}
          <div style={{ height: 8 }} />
          <TranscodingWarning />{" "}
        </>
      )}
    </>
  );
};

export default DropzoneVideo;

DropzoneVideo.propTypes = {
  setVideoAlert: PropTypes.func.isRequired,
  setUploadVideo: PropTypes.func.isRequired,
  videoAlert: PropTypes.string.isRequired,
  uploadVideo: PropTypes.array.isRequired,
  videoUploading: PropTypes.bool.isRequired,
  handleVideoUpload: PropTypes.func.isRequired,
  percentageUploaded: PropTypes.number.isRequired,
  videoTranscoding: PropTypes.bool.isRequired,
  dropzoneKey: PropTypes.bool.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
