/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {
  LoadMoreWrapper,
  LoadingMore,
} from "../../../marketplace/marketplaceStyles";
import useQuizReportList from "../../../../../swr/hooks/RetailReports/useQuizReportList";
import ModulesTable from "./ModulesTable";
import Loader from "../../vendorReports/Loader";

const ModulesReport = ({ isLte }) => {
  const { quizzes, isLoading } = useQuizReportList();

  if (isLoading) return <Loader />;

  return <ModulesTable modules={quizzes.quizzes} isLte={isLte} />;
};

export default ModulesReport;

ModulesReport.propTypes = {
  isLte: PropTypes.bool.isRequired,
};
