/* eslint-disable */
import React from "react";
import {
  makeDefaultTableOptions,
  fixIncomingDate,
} from "../../../../../storage/helpers";
import { CustomDatatableV2 } from "../../../styles";
import { ThumbsDown, ThumbsUp, MessageSquare } from "react-feather";
import TableTitle from "../../../../../components/Table/TableTitle";
import styled from "styled-components/macro";

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex;
  gap: 2px;
`;

const options = makeDefaultTableOptions("feedback");

const columns = [
  {
    name: "id",
    label: " ",
    options: {
      filter: false,
      sort: false,
      display: false,
      print: false,
      download: false,
    },
  },
  {
    name: "name",
    label: "User Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => (
        <span>{fixIncomingDate(value).toDateString()}</span>
      ),
    },
  },
  {
    name: "choice",
    label: "Feedback Selected",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => (
        <div
          style={{
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 20,
              width: 20,
              padding: 3,
              borderRadius: 4,
              background: value ? "mediumseagreen" : "tomato",
            }}
          >
            {value ? (
              <ThumbsUp color="white" height={18} width={18} />
            ) : (
              <ThumbsDown color="white" height={18} width={18} />
            )}
          </div>
        </div>
      ),
    },
  },
  {
    name: "text",
    label: "Message",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value) => (
        <div style={{ maxWidth: 540 }}>
          {value ? (
            <span>{value}</span>
          ) : (
            <span style={{ fontSize: 12, color: "lightgray" }}></span>
          )}
        </div>
      ),
    },
  },
];

const FeedbackTableRetail = ({ feedback }) => {
  const numFeedback = feedback.length;
  const numLiked = feedback.filter((f) => f.liked).length;
  const numDisliked = numFeedback - numLiked;

  const tableData = feedback.map((info) => ({
    id: info.id,
    name: `${info.first_name} ${info.last_initial}`,
    date: new Date(info.created).toISOString().split("T")[0],
    choice: info.liked,
    text: info.text || "",
  }));

  return (
    <CustomDatatableV2
      options={options}
      data={tableData}
      columns={columns}
      components={{
        TableToolbar: () => (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 14px",
              height: 66,
              borderBottom: "2px solid lightgray",
            }}
          >
            <TableTitle Icon={MessageSquare} title="Feedback" />
            <div
              style={{
                // height: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",

                gap: 12,
                width: "fit-content",
              }}
            >
              <FlexDiv>
                <ThumbsUp
                  style={{
                    color: "white",
                    background: numLiked === 0 ? "lightgray" : "mediumseagreen",
                    height: 18,
                    width: 18,
                    borderRadius: 4,
                    padding: 3,
                  }}
                />
                <span style={{ fontWeight: "600", fontSize: 12 }}>
                  {numLiked} up
                </span>
              </FlexDiv>
              <FlexDiv>
                <ThumbsDown
                  style={{
                    color: "white",
                    background: numDisliked === 0 ? "lightgray" : "tomato",
                    height: 18,
                    width: 18,
                    borderRadius: 4,
                    padding: 3,
                  }}
                />
                <span style={{ fontWeight: "600", fontSize: 12 }}>
                  {numDisliked} down
                </span>
              </FlexDiv>
            </div>
          </div>
        ),
        // TableToolbarSelect: () => <span>tb select</span>,
      }}
    />
  );
};

export default FeedbackTableRetail;
