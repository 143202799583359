/* eslint-disable */

import React from 'react';
import { ThumbsDown } from 'react-feather';

const FeedbackDisliked = ({ isZero = false }) => (
  <ThumbsDown
    style={{
      color: 'white',
      background: isZero ? 'lightgray' : 'tomato',
      height: 18,
      width: 18,
      borderRadius: 4,
      padding: 3,
    }}
  />
);

export default FeedbackDisliked;
