/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { MenuItem, useMediaQuery } from "@material-ui/core";

import { makeDefaultTableOptions } from "../../../../storage/helpers";
import { CustomDatatableV2, TextField } from "../../styles";
import { getSubBreakdown } from "./utils";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../components/Table/TableTitle";
import { Database } from "react-feather";

const CustomTextField = styled(TextField)`
  width: 180px;
  min-width: 1 .MuiSelect-root {
    padding: 8px 32px 8px 8px;
  }

  @media (max-width: 600px) {
    width: 80px;
    align-self: flex-end;
  }
`;

const TIMEPERIODS = {
  TODAY: "Today",
  WEEK: "Last Week",
  TWOWEEK: "Last 14 Days",
};

const BREAKDOWNS = {
  USER: "User",
  MODULE: "Module",
  COMPANY: "Company",
};

const SUBTYPES = {
  ALL: "All",
  FOLLOWER: "Follower",
  ASSIGNED: "Assigned",
};

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "submissions",
    label: "Submissions",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "passed",
    label: "Completions",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "passRate",
    label: "Pass Rate",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => <span>{`${value}%`}</span>,
    },
  },
];

const BreakdownTable = ({ submissions, users, engagementType }) => {
  const [timePeriod, setTimePeriod] = useState(TIMEPERIODS.TWOWEEK);
  const [breakdown, setBreakdown] = useState(BREAKDOWNS.COMPANY);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const tableRenderData = getSubBreakdown(
    submissions,
    timePeriod,
    breakdown,
    SUBTYPES.ALL,
    users
  );
  const tableData = !tableRenderData
    ? []
    : Object.values(tableRenderData).map((entry) => ({
        name: entry.name,
        submissions: entry.subs,
        passed: entry.pass,
        passRate:
          entry.pass > 0 ? Math.floor((entry.pass / entry.subs) * 100) : 0,
      }));

  const options = makeDefaultTableOptions("breakdown-information");
  options.search = false;
  options.jumpToPage = false;
  options.rowsPerPage = 10;
  options.filterType = "multiselect";
  options.sortOrder = {
    name: "submissions",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  return (
    <CustomDatatableV2
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 6,
          }}
        >
          <TableTitle
            Icon={Database}
            title="Submissons Breakdown"
            isVendor
            submissionType={engagementType}
          />
          {/* <SubmissionTypeChip submissionType={engagementType} small isVendor /> */}
          <div style={{ marginLeft: "auto" }} />
          <div
            style={{
              display: "flex",
              // border: "1px solid red",
              width: 300,
              marginRight: 12,
            }}
          >
            <CustomTextField
              select
              size="small"
              variant="outlined"
              placeholder="Select Breakdown"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <MenuItem value={TIMEPERIODS.TODAY}>Today</MenuItem>
              <MenuItem value={TIMEPERIODS.WEEK}>Last 7 Days</MenuItem>
              <MenuItem value={TIMEPERIODS.TWOWEEK}>Last 14 Days</MenuItem>
            </CustomTextField>
            <CustomTextField
              select
              size="small"
              variant="outlined"
              placeholder="Select Breakdown"
              ml={2}
              value={breakdown}
              onChange={(e) => setBreakdown(e.target.value)}
            >
              <MenuItem value={BREAKDOWNS.USER}>By User</MenuItem>
              <MenuItem value={BREAKDOWNS.MODULE}>By Module</MenuItem>
              <MenuItem value={BREAKDOWNS.COMPANY}>By Company</MenuItem>
            </CustomTextField>
          </div>
        </div>
      }
      columns={columns}
      options={options}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );

  return (
    // <PaperWrapper>
    //   <RelativeItem>
    {
      /* <TextFieldContainer>
          <CustomTextField
            select
            size="small"
            variant="outlined"
            placeholder="Select Breakdown"
            ml={2}
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
          >
            <MenuItem value={TIMEPERIODS.TODAY}>Today</MenuItem>
            <MenuItem value={TIMEPERIODS.WEEK}>Last 7 Days</MenuItem>
            <MenuItem value={TIMEPERIODS.TWOWEEK}>Last 14 Days</MenuItem>
          </CustomTextField>
          <CustomTextField
            select
            size="small"
            variant="outlined"
            placeholder="Select Breakdown"
            ml={2}
            value={breakdown}
            onChange={(e) => setBreakdown(e.target.value)}
          >
            <MenuItem value={BREAKDOWNS.USER}>By User</MenuItem>
            <MenuItem value={BREAKDOWNS.MODULE}>By Module</MenuItem>
            <MenuItem value={BREAKDOWNS.COMPANY}>By Company</MenuItem>
          </CustomTextField>
          <CustomTextField
            select
            size="small"
            variant="outlined"
            placeholder="Submission Type"
            ml={2}
            value={subType}
            onChange={(e) => setSubType(e.target.value)}
          >
            <MenuItem value={SUBTYPES.ALL}>All</MenuItem>
            <MenuItem value={SUBTYPES.ASSIGNED}>Assigned</MenuItem>
            <MenuItem value={SUBTYPES.FOLLOWER}>Follower</MenuItem>
          </CustomTextField>
        </TextFieldContainer> */
    }

    //   </RelativeItem>
    // </PaperWrapper>
  );
};

export default BreakdownTable;

BreakdownTable.propTypes = {
  submissions: PropTypes.array.isRequired,
  users: PropTypes.object.isRequired,
};
