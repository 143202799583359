/* eslint-disable */
import { Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components/macro';
import { QUESTION_TYPES } from '../../../../../storage/constants';
import MultipleChoice from './MultipleChoice';
import SelectAll from './SelectAll';
import TrueFalse from './TrueFalse';
import ACTIONS from '../state/builderActions';

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: 500px;
  max-height: 90vh;
  min-height: 320px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
`;

const Components = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: MultipleChoice,
  [QUESTION_TYPES.SELECT_ALL]: SelectAll,
  [QUESTION_TYPES.TRUE_FALSE]: TrueFalse,
};

const EditQuestion = ({
  builderContext,
  questions,
  details,
  mutate,
  setSnackbarText,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const { state, dispatch } = builderContext;

  const targetQuestion = questions[state?.editInfo?.order];
  const { type } = targetQuestion;

  const handleClose = () => dispatch({ type: ACTIONS.CLEAR_EDIT });

  const Component = Components[type];

  return (
    <ModalWrapper>
      <div
        style={{
          display: 'flex',
          gap: 6,
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <span style={{ fontSize: 16, fontWeight: '600' }}>Edit question</span>
      </div>
      <div style={{ height: 8 }} />
      <Component
        targetQuestion={targetQuestion}
        details={details}
        mutate={mutate}
        setSnackbarText={setSnackbarText}
        handleClose={handleClose}
        isModuleLive={isModuleLive}
        isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
      />
    </ModalWrapper>
  );
};

export default EditQuestion;
