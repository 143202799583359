/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import PropTypes from "prop-types";
import React from "react";
import { AlertTriangle, Check, X } from "react-feather";
import styled from "styled-components/macro";
import useVendorDeploymentInfo from "../../../../../swr/hooks/useVendorDeploymentInfo";
import HalfPanel from "../../UI/HalfPanel";
import Deploy from "./Deploy";
import Suggest from "./Suggest";
import InfoTooltip from "../../UI/InfoTooltip";

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding: 4px;

  span {
    font-size: 12px;
    font-weight: 500;
    color: darkgrey;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: darkgrey;
  }
`;

const FinishedIcon = ({ isFinished }) => {
  if (isFinished) {
    return <Check height={13} width={13} color="mediumseagreen" />;
  }
  return <X height={13} width={13} color="firebrick" />;
};

const VendorLaunch = ({ id, details, setSnackbarText, steps }) => {
  const { data, isLoading, mutate, isError } = useVendorDeploymentInfo(id);

  if (isLoading) return null;
  if (isError) return <span>error</span>;

  const isReadyForDeploy =
    steps[0].complete && steps[1].complete && steps[2].complete;

  if (!isReadyForDeploy) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          border: "1px solid lightgray",
          flex: 1,
          minHeight: 480,
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
        }}
      >
        <AlertTriangle color="darkorange" height={28} width={28} />
        <div style={{ height: 2 }} />

        <span style={{ fontSize: 16, fontWeight: "500" }}>
          Module Not Ready To Deploy
        </span>
        <div style={{ height: 16 }} />
        <div
          style={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            border: "1px solid #eeeeee",
            padding: 8,
            borderRadius: 4,
          }}
        >
          <Item>
            <span>Name selected:</span>
            <FinishedIcon isFinished={Boolean(details?.name)} />
          </Item>
          <Item>
            <span>Description selected:</span>
            <FinishedIcon isFinished={Boolean(details?.description)} />
          </Item>
          <Item>
            <span>Start Date selected:</span>
            <FinishedIcon isFinished={Boolean(details?.startDate)} />
          </Item>
          <Item>
            <span>Content Uploaded:</span>
            <FinishedIcon
              isFinished={Boolean(details?.media || details.video)}
            />
          </Item>
          <Item>
            <span>Has at least 1 question:</span>
            <FinishedIcon
              isFinished={details.questions?.questions?.length > 0}
            />
          </Item>
        </div>
      </div>
    );
  }

  return (
    <>
      <HalfPanel
        title="Deploy to marketplace"
        RightComponent={() => (
          <InfoTooltip
            small={false}
            title=" Deploy to Industry Marketplace (available to all users)"
          />
        )}
      >
        <Deploy
          data={data}
          setSnackbarText={setSnackbarText}
          mutate={mutate}
          id={id}
          currentlyInMarketplace={details?.inMarketPlace}
        />
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />
      <HalfPanel
        title="Suggest Module"
        RightComponent={() => (
          <InfoTooltip
            small={false}
            title="Send to Custom Audience (specific locations)"
          />
        )}
      >
        <Suggest
          currentlySuggested={details?.isSuggested}
          id={id}
          setSnackbarText={setSnackbarText}
        />
      </HalfPanel>
    </>
  );
};

export default VendorLaunch;

VendorLaunch.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  hasVideoConverting: PropTypes.bool.isRequired,
  availableMarketplaces: PropTypes.array.isRequired,
};
