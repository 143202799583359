/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  SubmissionChipAssignedTooltip,
  SubmissionChipExtraCreditTooltip,
} from "../../styles";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import SeeSubmissionModal from "../../../../components/SeeSubmission/SeeSubmissionModal";
import { Download, Search } from "react-feather";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import ActionsButtonForTables from "../../../../components/Table/ActionButtonForTables";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import { CustomDatatableV2 } from "../../styles";
import TableTitle from "../../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../components/Table/DateTooltip";
import { useMediaQuery } from "@material-ui/core";

const SubmissionTable = ({ submissions, engagementType }) => {
  const [submissionInfo, setSubmissionInfo] = useState(null);
  const isUnder960Px = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const options = makeDefaultTableOptions("submissions");
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };

  options.rowsPerPage = 10;
  options.textLabels = {
    body: {
      noMatch: "This user has not submitted any modules yet!",
    },
  };

  if (isUnder960Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        customBodyRender: (v) => (
          <span
            style={{
              color: "black",
              fontSize: 11,
              fontWeight: "300",
              color: "lightgray",
            }}
          >
            # {v}
          </span>
        ),
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <DateTooltip date={value} />,
      },
    },
    {
      name: "quiz",
      label: "Module Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "source",
      label: "Submission Type",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (val) => {
          if (val === "assigned") {
            return <SubmissionChipAssignedTooltip />;
          }
          return <SubmissionChipExtraCreditTooltip />;
        },
      },
    },
    {
      name: "time",
      label: "Time To Complete",
      options: {
        filter: true,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (score) => <span>{score}</span>,
      },
    },
    {
      name: "passed",
      label: "Result",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: true,
        customBodyRender: (_, tableMeta) => (
          <ActionsButtonForTables
            handleClick={() => setSubmissionInfo({ id: tableMeta.rowData[0] })}
            title="view"
            Icon={Search}
          />
        ),
      },
    },
  ];

  const tableData = submissions.map((submission) => ({
    id: submission.id,
    date: new Date(submission.created).toISOString(),
    origin: submission.quiz.fromMarketplace ? "Marketplace" : "Custom",
    quiz: submission.quiz.name,
    time: submission.time,
    score: `${submission.score}/${submission.questions}`,
    source: submission.source,
    passed: submission.passed ? "Yes" : "No",
  }));

  return (
    <>
      <div
        style={{
          borderRadius: 8,
          boxShadow:
            engagementType === ENGAGEMENT_TYPES.ASSIGNED
              ? "0px 0px 2px 2px rgba(0, 0, 250, .4)"
              : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
              ? "0px 0px 1px 2px gold"
              : "",
        }}
      >
        <CustomDatatableV2
          title={<TableTitle title="All Submissions" Icon={Download} />}
          data={tableData}
          options={options}
          columns={columns}
          components={{
            icons: CustomDataTableIcons,
          }}
        />
      </div>
      <SeeSubmissionModal
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </>
  );
};

export default SubmissionTable;

SubmissionTable.propTypes = {
  submissions: PropTypes.array.isRequired,
};
