/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro'
import {
  Wrapper,
  TextLink,
} from '../auth/styles';
import { Link } from 'react-router-dom';
import { Typography } from '../../private/styles';
import DashboardSVG from '../../../assets/svg/Dashboard.svg'
import AppUserSVG from '../../../assets/svg/AppUser.svg'
import { ArrowLeft } from 'react-feather';

const SvgImage = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
`;

const PressableContainer = styled.div`
  width: 100%; 
  min-height: 300px;
  border-radius: 2px;
  flex: .5;
  border: 1px solid lightgray;
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  padding-top: 50px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border: 2px solid #337ab7;
  }

  &:visited {
    text-decoration: none;
  }

`

const StyledLink = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  // text-transform: uppercase;
  color: #22597f;
  
  &:hover {
    color: dodgerblue;
    margin-left: 1px;
  }
`

const SelectRegisterPath = ({
  dispatch, 
  ACTIONS,
}) => {
  return (
    <Wrapper>
      <Typography variant="h3" component="h3" align="center" color="textPrimary" mb={4} mt={-2}>
        Register As
      </Typography>
      <div style={{ flexGrow: 1, minHeight: 300, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8}}>
        <PressableContainer as="a" href="https://learn.withspiffy.com/register/" >
          <SvgImage src={AppUserSVG} />
          <Typography variant='h5' style={{ textDecoration: 'none', color: 'black'}}>App User</Typography>
          <Typography  variant="body1" mt={2} style={{ color: 'darkgrey', textAlign: 'center', padding: '0px 4px', lineHeight: 1.3 }}>
            I am an employee who wants to use the Spiffy App for education purposes.
          </Typography>
        </PressableContainer>
        <PressableContainer
          onClick={() => dispatch({ type: ACTIONS.RETURN_TO_BASICS_FORM })}
        >
          <SvgImage src={DashboardSVG} />
          <Typography variant='h5'>Dashboard User</Typography>
          <Typography variant="body1" mt={2} style={{ color: 'darkgrey', textAlign: 'center', padding: '0px 4px', lineHeight: 1.3 }}>
            I am an owner/supervisor who wants to manage my team members on Spiffy.
          </Typography>
        </PressableContainer>
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: -10}}
        
      >
        <ArrowLeft style={{ color: '#22597f', height: 15, width: 15, marginRight: 6 }} />  
        <StyledLink to="/">
          Log In
        </StyledLink>
      </div>
    </Wrapper>
  )
}

export default SelectRegisterPath;
