/* eslint-disable */
import React from "react";
import { Tooltip } from "@material-ui/core";
import { Calendar, Coffee, Layers } from "react-feather";
import { formatDateWithTime } from "../../storage/helpers";
import { format } from "date-fns";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  max-height: 22px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    // minWidth: 160,
    maxWidth: 200,
    background: "white",
    height: 24,
    maxHeight: "none",
    minHeight: "none",
    borderRadius: 4,
    padding: "0px 12px",
    border: "1px solid lightgray",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // TODO -> used in many components
  },
}));

const DateTooltip = ({ date }) => {
  const formattedLong = formatDateWithTime(new Date(date));
  const formattedShort = format(new Date(date), "MMM do");
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 2,
      }}
    >
      <Tooltip
        arrow
        placement="top"
        classes={{ tooltip: classes.tooltip }}
        title={
          <span
            style={{
              fontWeight: "400",
              color: "black",
              fontSize: 11,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            {formattedLong}
          </span>
        }
      >
        <Container>
          <Calendar height={12} width={12} color="darkgrey" />
        </Container>
      </Tooltip>
      <span style={{ whiteSpace: "nowrap" }}>{formattedShort}</span>
    </div>
  );
};

export default DateTooltip;
