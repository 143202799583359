/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import {
  Wrapper,
  BriefcaseBlue,
  FilePlusBlue,
} from '../auth/styles';
import {
  TextField,
  Typography,
  Button,
} from '../../private/styles';
import CreateCompanyForm from './CreateCompanyForm';
import { ChevronLeft, Search } from 'react-feather';
import SearchForCompanyForm from './SearchForCompanyForm';
import DashboardSignupHeader from './DashboardSignupHeader';

const SelectWorkplace = ({ dispatch, ACTIONS, signupState }) => {

  const { cantFindCompany } = signupState
  // eslint-disable-next-line consistent-return


  return (
    <Wrapper>
      <DashboardSignupHeader 
        handleBack={() => 
          cantFindCompany ? 
          dispatch({ type: ACTIONS.RETURN_TO_FIND_COMPANY })
          : dispatch({ type: ACTIONS.RETURN_TO_BASICS_FORM })
        }            
        title={cantFindCompany  ? "Create A Company" : "Where Do You Work"}
        subtitle={cantFindCompany ? "If you can't find your company, create one" : "Search for the company you work at"}
      />
      {cantFindCompany
        ? (        
          <CreateCompanyForm 
            dispatch={dispatch} 
            ACTIONS={ACTIONS}
            signupState={signupState}
          />
          ) : (
          <SearchForCompanyForm 
            dispatch={dispatch} 
            ACTIONS={ACTIONS}
            signupState={signupState}
          />          
        )}
    </Wrapper>
  );
};

export default SelectWorkplace;

SelectWorkplace.propTypes = {
  dispatch: PropTypes.func.isRequired, 
  ACTIONS: PropTypes.object.isRequired,
  signupState: PropTypes.object.isRequired,
};
