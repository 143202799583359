/* eslint-disable */
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { TextField, Typography, Button } from "../../private/styles";
import API from "../../../axios/instances/API";
import styled from "styled-components/macro";

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 15px;
    font-weight: 500;
  }
`;

const CustomChip = styled.div`
  padding: 0px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isRetail === "yes" ? "#659deb" : "#f4ca16"};

  p {
    color: white;
    font-size: 9px;
    font-weight: 500;
  }
`;

const CustomRenderOption = ({ option }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p style={{ color: "black", fontWeight: "400" }}>{option.name}</p>
      <CustomChip isRetail={option.is_retail ? "yes" : "no"}>
        <p style={{ fontSize: 12 }}>
          {!option.is_retail ? "Vendor" : "Retailer"}
        </p>
      </CustomChip>
    </div>
  );
};

const ACCESS_LEVELS = {
  SUPERVISOR: "Supervisor",
  ADMIN: "Admin",
};

const SearchForCompanyForm = ({ dispatch, ACTIONS, signupState }) => {
  const { companyInfo } = signupState;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [accessLevel, setAccessLevel] = useState(ACCESS_LEVELS.ADMIN);
  const [locations, setLocations] = useState("");
  const [locationComboValue, setLocationComboValue] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [search, setSearch] = useState("");

  const isLoading =
    open && options.length === 0 && search.length > 2 && requestLoading;
  const locationId = companyInfo?.id || null;

  useEffect(() => {
    if (search.length < 3) {
      return setOptions([]);
    }
    const searchCompany = async () => {
      try {
        setRequestLoading(true);
        const { data } = await API.get(
          `common/company/search/?search=${search}&include_vendors=1`
        );
        setOptions(data.companies);
        setRequestLoading(false);
      } catch (error) {
        setOptions([]);
      }
    };
    searchCompany();
  }, [search]);

  useEffect(() => {
    if (!locationId) return undefined;

    const getLocations = async () => {
      try {
        const { data } = await API.get(
          `dashboard/signup/company/locations/?company_id=${locationId}`
        );
        setLocations(data.locations);
      } catch (error) {
        // setErrorAlert(FEEDBACK_MESSAGES.GENERIC_ERROR);
      }
    };

    getLocations();
  }, [locationId]);

  const handleAddCompany = (_, selectedCompany) => {
    if (selectedCompany) {
      dispatch({ type: ACTIONS.UPDATE_WORKPLACE, payload: selectedCompany });
    } else {
      setAccessLevel(ACCESS_LEVELS.ADMIN);
      dispatch({ type: ACTIONS.REMOVE_WORKPLACE });
    }
  };

  const handleRadioChange = (e) => {
    setAccessLevel(e.target.value);
  };

  const companySelected = companyInfo && Object.keys(companyInfo).length > 0;

  const isContinueDisabled = () => {
    if (!companySelected) return true;

    if (!companyInfo.is_retail) return false;

    if (
      accessLevel === ACCESS_LEVELS.SUPERVISOR &&
      locationComboValue.length === 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setOptions([]);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        onInputChange={(e) =>
          e.target.innerText ? setSearch(e.target.innerText) : setSearch("")
        }
        onChange={handleAddCompany}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={isLoading}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => <CustomRenderOption option={option} />}
        noOptionsText="No Companies Found"
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label=""
            variant="outlined"
            style={{ fontSize: 14, fontWeight: "600" }}
            placeholder="Search for your company..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {companySelected && companyInfo.is_retail && (
        <div style={{ marginTop: 16 }}>
          <FormControl component="fieldset">
            <Typography variant="subtitle2" color="textSecondary">
              Are you an Admin or Supervisor at {companyInfo.name} ?
            </Typography>
            <RadioGroup row value={accessLevel} onChange={handleRadioChange}>
              <FormControlLabel
                value={ACCESS_LEVELS.ADMIN}
                control={<Radio />}
                label={ACCESS_LEVELS.ADMIN}
              />
              <FormControlLabel
                value={ACCESS_LEVELS.SUPERVISOR}
                control={<Radio />}
                label={ACCESS_LEVELS.SUPERVISOR}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      {accessLevel === ACCESS_LEVELS.SUPERVISOR &&
        companySelected &&
        companyInfo.is_retail && (
          <>
            <Typography mt={3} mb={4} variant="subtitle2" color="textSecondary">
              Please select the location(s) you supervise
            </Typography>
            <Autocomplete
              size="small"
              options={locations}
              multiple
              noOptionsText="No Locations Available"
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => setLocationComboValue(newValue || [])}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location(s) To Add"
                  variant="outlined"
                />
              )}
            />
          </>
        )}
      {companySelected && (
        <Button
          color="secondary"
          variant="contained"
          mt={10}
          fullWidth
          disabled={isContinueDisabled()}
          onClick={() =>
            dispatch({
              type: ACTIONS.SUBMIT_WORKPLACE,
              payload: {
                accessLevel,
                company: options[0],
                location:
                  accessLevel === ACCESS_LEVELS.ADMIN
                    ? null
                    : locationComboValue,
              },
            })
          }
        >
          Continue
        </Button>
      )}
      {!companySelected && (
        <Button
          fullWidth
          mt={3}
          mb={-2}
          onClick={() => dispatch({ type: ACTIONS.USER_CANT_FIND_COMPANY })}
          variant="text"
          color="secondary"
        >
          Can't Find Your Company? Create one here!
        </Button>
      )}
    </>
  );
};

export default SearchForCompanyForm;
