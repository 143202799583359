/* eslint-disable */
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { GlobalState } from '../../../../store/GlobalState';
import { Typography } from '../../styles';
import { User } from 'react-feather';

const IconContainer = styled.div`
  border-radius: 16px; 
  background-color: #337AB7;
  height: 44px; 
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const UserContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: center; 
  flex-grow: 1;
  overflow-x: hidden;
`;

const Hometitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: black;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: aliceblue;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: auto;
  border: 1px solid darkgrey;
  overflow-x: hidden;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: mediumseagreen;
`

const UserBox = () => {

  const { state } = useContext(GlobalState);
  const { config } = state;

  const { user, readOnly } = config;
  const { company } = user

  const usersJob = config?.job === 'M' ? 'Supervisor' : 'Admin'
  const hasWriteAccess = !config?.readOnly;
  const hasOrgAccess = config.organizationAccess;



  return (
    <Wrapper>
      <div style={{ flexBasis: 50 }}>
        <IconContainer>
          <User
            style={{ color: 'white' }}
          />
        </IconContainer>
      </div>
      <UserContainer>
        <Hometitle style={{ marginBottom: -3, marginTop: 2, lineHeight: 1.2 }}>
          {config.user.firstName} {config.user.lastName}
        </Hometitle>
        <p style={{ fontSize: 13, fontWeight: '600', color: '#337AB7', marginBottom: -2 }}>
          {config.user.email}
        </p>
        <span style={{ color: 'darkgrey', fontSize: 13, fontWeight: '500'}}>
          {usersJob}
        </span>
        {/* <div 
          style={{ width: '100%', display: 'flex', padding: 0, alignItems: 'center', justifyContent: 'flex-start'}}
        >
          <div 
            style={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
          >
            <Dot style={{ background: hasWriteAccess ? 'mediumseagreen' : 'tomato'}} />
            <span style={{ fontSize: 10,  color: 'darkgrey', marginRight: 2}}>
              Write Access
            </span>
          </div>
          <div style={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
            <Dot style={{  background: config.job === 'M' ? 'tomato' : 'mediumseagreen'}} />
            <span style={{ fontSize: 10, color: 'darkgrey', marginRight: 2}}>Admin Access</span>
          </div>
          <div style={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
            <Dot style={{ background: hasOrgAccess ? 'mediumseagreen' : 'tomato'}} />
            <span style={{ fontSize: 10, color: 'darkgrey', marginRight: 2}}>Org Access</span>
          </div>
        </div> */}
      </UserContainer>
    </Wrapper>
  )
}

export default UserBox;

