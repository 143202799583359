/* eslint-disable */
import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import ExtraInfo from "../../../../components/ExtraInfo";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";
import { Users } from "react-feather";

const Wrapper = styled(Paper)`
  flex: 0.33;
  flex-grow: 1;
  height: 60px;
  border-radius: 4px;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  position: relative;

  p {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: darkgrey;
  }
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const OverSizedText = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: black;
`;

const SmallBlockByEngagementType = ({
  title,
  definition,
  number,
  engagementType,
  Icon = null,
}) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <AlignCenter>
            {Icon && (
              <Icon
                height={22}
                width={22}
                style={{
                  padding: 4,
                  borderRadius: 3,
                  background: "#eeeeee",
                  color: "black",
                }}
              />
            )}

            <p style={{ whiteSpace: "nowrap" }}>{title}</p>
            {engagementType === "All" ? (
              <ExtraInfo text={definition} />
            ) : (
              <SubmissionTypeChip
                small
                submissionType={engagementType}
                isVendor
              />
            )}
          </AlignCenter>
        </div>
        <OverSizedText engagementType={engagementType}>{number}</OverSizedText>
      </div>
    </Wrapper>
  );
};

export default SmallBlockByEngagementType;
