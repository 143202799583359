/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ChevronLeft, PlusCircle } from "react-feather";
import { addDays, lightFormat } from "date-fns";
import styled from "styled-components";
import { Button } from "../../styles";

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer = styled.div`
  width: 4px;
  background-color: transparent;
`;

const formatDateAsString = (date) => lightFormat(date, "yyyy-MM-dd");

const DateRange = ({ setFilterDateString, setEngagementType }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const handleApplyFilter = () => {
    setEngagementType("All");
    const queryDateString = `&start=${formatDateAsString(
      startDate
    )}&end=${formatDateAsString(endDate)}`;
    const readDateString = `Showing stats from ${formatDateAsString(
      startDate
    )} to ${formatDateAsString(endDate)}`;
    setFilterDateString({
      queryString: queryDateString,
      readString: readDateString,
    });
    setStartDate(null);
    setEndDate(null);
    setShowFilter(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!showFilter && (
          <Button
            variant="text"
            mr={2}
            color="primary"
            startIcon={
              <PlusCircle style={{ height: 14, width: 14, color: "black" }} />
            }
            onClick={() => {
              setShowFilter(true);
            }}
          >
            {showFilter ? "" : "Date Range Filter"}
          </Button>
        )}
        {showFilter && (
          <DateWrapper>
            <DatePicker
              style={{ width: 200, backgroundColor: "white" }}
              size="small"
              disableToolbar
              maxDate={endDate || null}
              disableFuture
              autoOk
              label="Start Date"
              fullWidth
              inputVariant="outlined"
              variant="inline"
              format="yyyy-MM-dd"
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <Spacer />
            <DatePicker
              style={{ width: 200, backgroundColor: "white" }}
              size="small"
              disableToolbar
              autoOk
              label="End Date"
              fullWidth
              inputVariant="outlined"
              variant="inline"
              format="yyyy-MM-dd"
              // disableFuture
              maxDate={addDays(new Date(), 1)}
              value={endDate}
              onChange={(date) => {
                if (date < startDate) {
                  setStartDate(null);
                }
                setEndDate(date);
              }}
            />
            <Button
              variant="contained"
              ml={1}
              color="primary"
              disabled={!startDate || !endDate}
              onClick={handleApplyFilter}
            >
              Apply
            </Button>
            <Button
              variant="text"
              ml={1}
              color="primary"
              onClick={() => setShowFilter(false)}
            >
              <ChevronLeft style={{ color: "black" }} />
            </Button>
          </DateWrapper>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateRange;

DateRange.propTypes = {
  setFilterDateString: PropTypes.func.isRequired,
  setEngagementType: PropTypes.func.isRequired,
};
