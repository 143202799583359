/* eslint-disable */
import React, { useEffect, useState } from "react";
import API from "../../../../axios/instances/API";
import { TextField } from "@material-ui/core";
import styled from "styled-components/macro";
import { Award } from "react-feather";

const Wrapper = styled.div`
  height: 40px;
  width: 100%;
  background: #eeeeee22;
  border-radius: 4px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eeeeee;
  padding: 0px 12px;
`;

const ScoreToPass = ({ details, setSnackbarText, mutate }) => {
  const questions = details?.questions?.questions;
  const [scoreToPass, setScoreToPass] = useState(details?.passScore);

  const isModifyLive = details?.state === "Live";

  useEffect(() => {
    setScoreToPass(details?.passScore);
  }, [details?.passScore]);

  const handleUpdateScoreToPass = async () => {
    if (scoreToPass === details?.passScore) return null;
    try {
      const updateScoreToPass =
        scoreToPass > questions.length ? questions.length : scoreToPass * 1;

      const sendObj = {
        id: details.id,
        pass_score: updateScoreToPass,
        questions,
      };

      await API.put("dashboard/quiz/questions/", sendObj);
      await mutate();
      setSnackbarText(
        `Score to pass updated to (${updateScoreToPass}/${questions.length}) success`
      );
    } catch (error) {
      setSnackbarText("Error here error");
    }
  };

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 6,
        }}
      >
        <div
          style={{
            padding: 4,
            background: "white",
            border: "1px solid lightgray",
            height: 22,
            width: 22,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 4,
          }}
        >
          <Award height={13} width={13} color="slategrey" />
        </div>
        <span style={{ color: "black", fontWeight: "500" }}>
          Minimum Score To Pass Module:{" "}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 4,
        }}
      >
        <TextField
          style={{ marginLeft: 12, width: 30, fontSize: 16 }}
          type="number"
          value={scoreToPass}
          onChange={(e) => setScoreToPass(e.target.value)}
          color="secondary"
          disabled={isModifyLive}
          onBlur={() => handleUpdateScoreToPass()}
          max={details?.questions?.questions.length}
          InputProps={{
            inputProps: { min: 0, max: details?.questions?.questions.length },
          }}
        />
        <span>/</span>
        <span style={{ color: "black", fontWeight: "600" }}>
          {details?.questions?.questions?.length}
        </span>
      </div>
    </Wrapper>
  );
};

export default ScoreToPass;
