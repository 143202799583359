/* eslint-disable */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Download, Search } from "react-feather";
import SeeSubmissionModal from "../../../../components/SeeSubmission/SeeSubmissionModal";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import ActionButtonForTables from "../../../../components/Table/ActionButtonForTables";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../components/Table/DateTooltip";
import TableTitle from "../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import {
  CustomDatatableV2,
  SubmissionChipAssignedTooltip,
  SubmissionChipExtraCreditTooltip,
} from "../../styles";

const options = makeDefaultTableOptions("submissions-last-14");
options.sortOrder = {
  name: "date",
  direction: "desc",
};

const NewSubmissionsTable = ({ newSubmissions, submissionsType }) => {
  const [submissionInfo, setSubmissionInfo] = useState(null);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          // <span>{formatDateWithTime(new Date(value))}</span>
          <DateTooltip date={value} />
        ),
      },
    },
    {
      name: "name",
      label: "Member Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "quiz",
      label: "Module Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subType",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val === "assigned") {
            return <SubmissionChipAssignedTooltip />;
          }
          return <SubmissionChipExtraCreditTooltip />;
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "passed",
      label: "Passed",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="view"
            Icon={Search}
            onClick={() => setSubmissionInfo({ id: tableMeta.rowData[0] })}
          />
        ),
      },
    },
  ];

  const tableData = newSubmissions.map((submission) => ({
    id: submission.id,
    name: `${submission.first_name} ${submission.last_name}`,
    date: new Date(submission.created).toISOString(),
    quiz: submission.quiz_name,
    subType: submission.assigned ? "assigned" : "ec",
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            Icon={Download}
            title="Recent Submissions"
            submissionType={submissionsType}
          />
        }
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <SeeSubmissionModal
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </>
  );
};

export default NewSubmissionsTable;

NewSubmissionsTable.propTypes = {
  newSubmissions: PropTypes.array.isRequired,
};
