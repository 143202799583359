/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { CustomDatatableV2 } from "../../styles";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import TableTitle from "../../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import { UserPlus } from "react-feather";

const options = makeDefaultTableOptions("new-team-members");

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "firstName",
    label: "First Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "lastName",
    label: "Last Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const NewMembersTable = ({ newEmployees }) => {
  const tableData = newEmployees.map((employee) => ({
    firstName: employee.first_name,
    lastName: employee.last_name,
    phoneNumber: employee.phone_number === null ? "N/A" : employee.phone_number,
    email: employee.email === null ? "N/A" : employee.email,
  }));

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="New Team Members" Icon={UserPlus} />}
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default NewMembersTable;

NewMembersTable.propTypes = {
  newEmployees: PropTypes.array.isRequired,
};
