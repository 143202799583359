/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const BASE_URL =
  "/dashboard/analytics/retail-reports/single-user-progress/?team_member_id=";
// "/dashboard/analytics/report/?report=team_member_outstanding&team_member_id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useTeamMemberOutstanding = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher, {
    dedupingInterval: 1000, // Minute and a half should be fine //
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMemberOutstanding;
