/* eslint-disable */
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Grid, Modal } from "@material-ui/core";
import SupervisorLocationTable from "./SupervisorLocationTable";
import AddSupervisorLocation from "./AddSupervisorLocation";
import useManagerDetail from "../../../../swr/hooks/Managers/useManagerDetail";
import PrivateHeader from "../../../../components/PrivateHeader";
import About from "./About";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../../components/OnLoadError";
import PromoteModal from "./PromoteModal";
import { Button, PlusIcon } from "../../styles";
import { GlobalState } from "../../../../store/GlobalState";
import { PlusCircle } from "react-feather";

const ManagerDetail = ({ match }) => {
  const { id } = match.params;
  const { isLoading, details, mutate, isError } = useManagerDetail(id);
  const [promoteModalInfo, setPromoteModalInfo] = useState(false);
  const { state } = useContext(GlobalState);
  const { config } = state;
  const { readOnly, organizationAccess } = config;
  const companyHasOrganization = Boolean(config.organization);

  if (isError) {
    return <OnLoadError />;
  }

  if (isLoading) {
    return (
      <>
        <Helmet title="Spiffy | Supervisor Detail" />
        <PrivateHeader header="Supervisor" secondary={true} />
        <LoaderWrapper text="Supervisor Details" />
      </>
    );
  }

  const { locations, manager } = details;
  const managerName = `${manager.firstName} ${manager.lastName}`;
  const managerInMultipleCompanies =
    details.companies_where_user_is_manager > 1;

  const dontHaveOrgAccesBlock = companyHasOrganization && !organizationAccess;

  return (
    <>
      <Helmet title="Spiffy | Supervisor Detail" />
      <PrivateHeader header="Supervisor" secondary={managerName}>
        <Button
          disabled={readOnly || dontHaveOrgAccesBlock}
          color="secondary"
          variant="contained"
          startIcon={<PlusCircle height={14} width={14} />}
          onClick={() =>
            setPromoteModalInfo({
              name: managerName,
              id: manager.id,
              numCompanies: details.companies_where_user_is_manager,
            })
          }
        >
          Promote
        </Button>
      </PrivateHeader>
      <Grid container spacing={4} align="center">
        <Grid item md={6} sm={12} xs={12}>
          <AddSupervisorLocation
            managerId={id}
            mutate={mutate}
            currentlyManaging={locations}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <About manager={manager} locations={locations} mutate={mutate} />
        </Grid>
        <Grid item xs={12}>
          <SupervisorLocationTable
            locations={locations}
            managerId={id}
            managerName={managerName}
            mutate={mutate}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Modal
        open={Boolean(promoteModalInfo)}
        onClose={() => setPromoteModalInfo(false)}
        aria-labelledby="Promote Supervisor"
        aria-describedby="Give Supervisor Admin Privileges"
      >
        <div>
          <PromoteModal
            setPromoteModalInfo={setPromoteModalInfo}
            promoteModalInfo={promoteModalInfo}
            managerInMultipleCompanies={managerInMultipleCompanies}
          />
        </div>
      </Modal>
    </>
  );
};

export default ManagerDetail;

ManagerDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
