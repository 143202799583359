/* eslint-disable */
import React, { useEffect, useState } from "react";
import API from "../../../../axios/instances/API";
import LocationSelection from "./LocationSelection";
import { createWorkbook } from "./utils";

const ExcelTemplateCreator = () => {
  const [locations, setLocations] = useState(null);
  const [roles, setRoles] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data: locationData } = await API.get("/dashboard/locations/");
      const { data: roleData } = await API.get("/dashboard/roles/");
      setLocations(locationData.locations);
      setRoles(roleData.roles);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {error && !isLoading && (
        <div
          style={{
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#eeeeee40",
            borderRadius: 4,
            border: "1px solid slategrey",
          }}
        >
          <span style={{ color: "firebrick", fontSize: 11, fontWeight: "600" }}>
            Error getting company location/roles
          </span>
        </div>
      )}
      {!error && (
        <LocationSelection
          selectedLocation={selectedLocations}
          setSelectedLocation={setSelectedLocations}
          locations={locations || []}
          handleClick={() => createWorkbook(roles, selectedLocations)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default ExcelTemplateCreator;
