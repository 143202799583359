/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Checkbox } from "@material-ui/core";
import { Wrapper } from "../auth/styles";
import { Typography, Alert, Button } from "../../private/styles";
import DashboardSignupHeader from "./DashboardSignupHeader";
import API from "../../../axios/instances/API";
import { getRegisterInfo, getRegisterErrorMessage } from "./helpers";
import { setToken, setRefresh } from "../../../storage/helpers";
import { useHistory } from "react-router-dom";

const TOC_LINK = "https://www.withspiffy.com/terms-and-conditions/";

const StyledLink = styled.a`
  display: inline;
  color: #22597f;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 500;
`;

const REGISTER_TYPES = {
  ADMIN: "ADMIN_REGISTER",
  ADMIN_NEW_COMPANY: "ADMIN NEW COMPANY",
  MANAGER: "MANAGER",
};

const InfoContainer = styled.div`
  padding: 4px 8px;
  width: 100%;
  min-height: 60px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  flex-grow: 1;
  border: 1px solid #eeeeee;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin-bottom: 2px;
`;

const TitleText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: rgb(51, 122, 183);
  color: black;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfoText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: darkgrey;
`;

const ConfirmAndRegister = ({ signupState, dispatch, ACTIONS }) => {
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const { companyInfo, registerInfo, cantFindCompany, createCompanyInfo } =
    signupState;

  const handleRegister = async () => {
    // Three types of signup, manager, admin & admin creating new company
    setIsSubmitting(true);
    try {
      const { registerType, sendObj } = getRegisterInfo(signupState);
      await API.post("dashboard/signup/", sendObj);
      setIsSubmitting(false);

      // if created new company , you can sign in right away with res hopefully
      if (registerType === REGISTER_TYPES.ADMIN_NEW_COMPANY) {
        try {
          await handleLogUserIn(sendObj.email, sendObj.password);
          return undefined;
        } catch (error) {
          dispatch({ type: ACTIONS.FINISH_REGISTER, payload: registerType });
        }
      }
      dispatch({ type: ACTIONS.FINISH_REGISTER, payload: registerType });
    } catch (error) {
      setIsSubmitting(false);
      const errorMsg = getRegisterErrorMessage(error);
      setError(errorMsg);
    }
  };

  const handleLogUserIn = async (email, password) => {
    const { data } = await API.post("/dashboard/auth/", {
      username: email,
      password: password,
    });
    setToken(data.token);
    setRefresh(data.refresh);
    return history.push("/home/");
  };

  return (
    <Wrapper>
      <DashboardSignupHeader
        handleBack={() => dispatch({ type: ACTIONS.RETURN_FROM_CONFIRM })}
        title="Confirm & Register"
        subtitle="Double check information and finalize registration"
      />
      <div
        style={{
          gap: 8,
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <InfoContainer>
          <Heading>User</Heading>
          <TextContainer>
            <TitleText>{registerInfo.email}</TitleText>
            <InfoText>
              {registerInfo.firstName} {registerInfo.lastName}
            </InfoText>
          </TextContainer>
        </InfoContainer>
        {!cantFindCompany && (
          <InfoContainer>
            <Heading>Workplace</Heading>
            <TextContainer>
              <TitleText style={{ textTransform: "uppercase" }}>
                {companyInfo.name}
              </TitleText>
              {companyInfo.is_retail && (
                <InfoText>
                  {companyInfo.accessLevel}{" "}
                  {companyInfo.accessLevel === "Supervisor"
                    ? `| ${companyInfo.location.map((l) => l.name).join(" + ")}`
                    : ""}
                </InfoText>
              )}
            </TextContainer>
          </InfoContainer>
        )}
        {cantFindCompany && (
          <InfoContainer>
            <TextContainer>
              <Heading>New Workplace</Heading>
              <TitleText style={{ textTransform: "uppercase" }}>
                {createCompanyInfo.companyName}
              </TitleText>
              <InfoText>
                {createCompanyInfo.locationName} | {createCompanyInfo.city.name}{" "}
                | {createCompanyInfo.state.name} |{" "}
              </InfoText>
            </TextContainer>
          </InfoContainer>
        )}
      </div>
      <div
        style={{
          width: "100%",
          // height: 30,
          // border: '1px solid black',
          borderRadius: 4,
          padding: 2,
          background: "#EEEEEE44",
          marginTop: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Checkbox checked={checked} onClick={() => setChecked((p) => !p)} />
        <span style={{ fontSize: 12, color: "black" }}>
          I agree with the
          <StyledLink target="_blank" href={TOC_LINK}>
            terms and conditions
          </StyledLink>
          of Spiffy
        </span>
      </div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        mt={6}
        disabled={isSubmitting || !checked}
        onClick={handleRegister}
      >
        Confirm & Register
      </Button>
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          style={{ marginTop: 8 }}
        >
          {error}
        </Alert>
      )}
    </Wrapper>
  );
};

export default ConfirmAndRegister;

ConfirmAndRegister.propTypes = {
  signupState: PropTypes.object.isRequired,
};
