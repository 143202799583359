/* eslint-disable */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { LinearProgress, Paper } from '@material-ui/core';
import styled from 'styled-components/macro';
import ModuleStepper from './UI/ModuleStepper';
import Builder from './questions';
import Basics from './basics';
import Launch from './launch';
import Audience from './audience';
import Content from './content';
import PrivateHeader from '../../../components/PrivateHeader';
import OnLoadError from '../../../components/OnLoadError';
import SnackbarWrapper from '../../../components/SnackbarWrapper';
import useQuizReview from '../../../swr/hooks/Library/useQuizReview';
import AboutThisPage from '../../../components/AboutThisPage';
import { GlobalState } from '../../../store/GlobalState';
import BottomToolbar from './UI/BottomToolbar';
import { QUIZSTATES } from '../../../storage/constants';

const MoveUp = styled.div`
  margin-top: -10px;
  margin-bottom: -10px;
`;

const QuizBuilderWrapper = styled(Paper)`
  max-width: 100%;
  min-height: 540px;
  padding: 0px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.07);
`;

const ComponentWrapper = styled.div`
  min-height: 500px;
  display: flex;
  padding: 14px;
  flex: 1,
  flex-direction: row;
  gap: 12px;

  @media (max-width: 1040px) {
    flex-direction: column;
  }
`;

const QuizBuilder = ({ match }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const { state } = useContext(GlobalState);
  const { isRetail, job } = state?.config;
  const [snackbarText, setSnackbarText] = useState('');
  const { details, isLoading, isError, mutate, isValidating } = useQuizReview(match.params.id);

  // Check to see if our video is currently converting
  const convertingIds = state.transcoding.map(quiz => quiz?.quizID);

  const hasVideoConverting =
    convertingIds.includes(details?.id) && details.state === 'Content Conversion In Progress';

  useEffect(() => {
    mutate();
  }, [stepIndex]);

  if (isLoading) return null;

  if (isError) {
    return <OnLoadError />;
  }

  const isModifyLive = details.state === QUIZSTATES.LIVE;

  const isModuleLive = details?.state === QUIZSTATES.LIVE;
  const isModulePending = details?.state === QUIZSTATES.PENDING;
  const isModuleConvertingNextStateLive =
    details?.state === QUIZSTATES.CONVERSION && details?.next_state_str === QUIZSTATES.LIVE;
  const isModuleConvertingNextStateDev =
    details?.state === QUIZSTATES.CONVERSION &&
    details?.next_state_str === QUIZSTATES.IN_DEVELOPMENT;
  const isModuleConvertingNextStatePending =
    details.state === QUIZSTATES.CONVERSION && details?.next_state_str === QUIZSTATES.PENDING;

  const isManager = job === 'M';

  const retailModuleHasNoAudience =
    details.audience.type === 'O' && !details?.audience?.audience_exists;

  const retailSteps = [
    {
      label: 'Basics',
      complete: Boolean(details.description && details.startDate && details.image && details.name),
      requiredFields: 4,
      completedFields: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
      ].filter(bool => bool === true).length,
    },
    {
      label: 'Content',
      complete: Boolean(details.media || details.video),
      requiredFields: 1,
      completedFields: Boolean(details.media || details.video) ? 1 : 0,
    },
    {
      label: 'Questions',
      complete: details.questions.questions.length > 0,
      requiredFields: 1,
      completedFields: details.questions.questions.length > 0 ? 1 : 0,
    },
    {
      label: 'Audience',
      complete: !retailModuleHasNoAudience,
      requiredFields: 1,
      completedFields: !retailModuleHasNoAudience ? 1 : 0,
    },
    {
      label: 'Launch',
      complete: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
        !retailModuleHasNoAudience,
        Boolean(details.questions.questions.length > 0),
        Boolean(details.media || details.video),
      ].every(val => val === true),
      requiredFields: 7,
      completedFields: null,
    },
  ];

  const vendorSteps = [
    {
      label: 'Basics',
      complete: Boolean(details.description && details.startDate && details.image && details.name),
      requiredFields: 4,
      completedFields: [
        Boolean(details.description),
        Boolean(details.startDate),
        Boolean(details.image),
        Boolean(details.name),
      ].filter(bool => bool === true).length,
    },
    {
      label: 'Content',
      complete: Boolean(details.media || details.video),
      requiredFields: 1,
      completedFields: Boolean(details.media || details.video) ? 1 : 0,
    },
    {
      label: 'Questions',
      complete: details.questions.questions.length > 0,
      requiredFields: 1,
      completedFields: details.questions.questions.length > 0 ? 1 : 0,
    },
    {
      label: 'Deploy',
      complete: Boolean(details?.inMarketPlace || details?.isSuggested),
      requiredFields: null,
      completedFields: null,
    },
  ];

  const stepComponents = {
    0: Basics,
    1: Content,
    2: Builder,
    3: Audience,
    4: Launch,
  };

  const vendorStepComponents = {
    0: Basics,
    1: Content,
    2: Builder,
    3: Launch,
  };

  const StepComponent = isRetail ? stepComponents[stepIndex] : vendorStepComponents[stepIndex];
  const steps = isRetail ? retailSteps : vendorSteps;

  return (
    <>
      <Helmet title="Spiffy | Module Builder" />
      <MoveUp>
        <PrivateHeader
          header="Module Builder"
          secondary={details?.name}
          small
        />
      </MoveUp>
      {!isModifyLive && (
        <AboutThisPage
          pageTitle="Build A Module"
          tipText="Help with building a module"
        />
      )}
      <ModuleStepper
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        steps={steps}
      />
      <div style={{ height: 8 }} />
      <QuizBuilderWrapper>
        <ComponentWrapper>
          <StepComponent
            id={match.params.id}
            steps={steps}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            details={details}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isRetail={isRetail}
            isManager={isManager}
            //
            hasVideoConverting={hasVideoConverting}
            isModulePending={isModulePending}
            isModuleLive={isModuleLive}
            isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
            isModuleConvertingNextStatePending={isModuleConvertingNextStatePending}
          />
        </ComponentWrapper>
        <BottomToolbar
          title={steps[stepIndex]?.label}
          steps={steps}
          stepIndex={stepIndex}
          details={details}
          ///
          hasVideoConverting={hasVideoConverting}
          isModulePending={isModulePending}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isModuleConvertingNextStatePending={isModuleConvertingNextStatePending}
          isRetail={isRetail}
        />
        {isValidating && (
          <LinearProgress
            color="secondary"
            variant="indeterminate"
            style={{ height: 2, marginBottom: -1 }}
          />
        )}
      </QuizBuilderWrapper>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default QuizBuilder;

QuizBuilder.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
