/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components/macro';
import { MapPin } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';

const NoMaxWidthTooltip = styled(Tooltip)`
  root {
    max-width: none;
  }
  .tooltip {
    max-width: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 6px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: 'none',
    background: 'white',
    height: 24,
    maxHeight: 'none',
    minHeight: 'none',
    width: 'fit-content',
    borderRadius: 4,
    padding: '0px 12px',
    border: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // TODO -> used in many components
  },
}));

const LocationTooltip = ({ location }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<span style={{ color: 'black' }}>{location}</span>}
      arrow
      classes={{ tooltip: classes.tooltip }}
      placement="top"
    >
      <Container>
        <MapPin
          color="darkgrey"
          height={11}
          width={11}
        />
      </Container>
    </Tooltip>
  );
};

const VendorShowTeams = ({ teamsArray }) => {
  if (!teamsArray) {
    return <span style={{ color: 'firebrick', fontSize: 12 }}>No Active Team</span>;
  }

  if (teamsArray.length === 1) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: 'fit-content',
        }}
      >
        <span style={{ fontSize: 12 }}>{teamsArray[0].company}</span>
        <LocationTooltip location={teamsArray[0].location} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
      }}
    >
      <span style={{ fontSize: 12 }}>{teamsArray[0].company}</span>
      <LocationTooltip location={teamsArray[0].location} />

      <NoMaxWidthTooltip
        arrow
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              overflow: 'hidden',
            }}
          >
            {teamsArray.slice(1, teamsArray.length).map((v, i) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                key={i}
              >
                <span style={{ whiteSpace: 'nowrap' }}>{v.company}</span>
                <span style={{ whiteSpace: 'nowrap', marginLeft: 6 }}>({v.location})</span>
              </div>
            ))}
          </div>
        }
      >
        <span
          style={{
            fontSize: 10,
            color: 'black',
            textDecoration: 'underline',
            // marginLeft: 8,
            fontWeight: 600,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
          }}
        >
          +{teamsArray.length - 1} more
        </span>
      </NoMaxWidthTooltip>
    </div>
  );
};

export default VendorShowTeams;

VendorShowTeams.propTypes = {
  teamsArray: PropTypes.array.isRequired,
};
