import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data;
};

const useSingleTeamMemberReport = id => {
  const { data, error, mutate } = useSWR(`dashboard/analytics/report/?report=team_member_view&team_member_id=${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    details: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSingleTeamMemberReport;
