import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '../pages/private/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  background-color: rgba(10, 10, 10, .05);
  border: 1px solid #eeeeee;
  z-index: 0;
  box-shadow: 0px 2px 4px 4px rgba(1, 1, 1, .05);
`;

const Primary = styled(Typography)`
  color: ${props => props.theme.palette.primary.dark};
  font-style: italic;
  font-size: 28px;
  font-weight: 800;
`;

const Secondary = styled(Typography)`
  color: ${props => props.theme.palette.grey[600]};
  font-style: italic;
  font-size: 22px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.info.light};
  margin-left: 4px;

  &:hover{
    text-decoration: underline;
  }
`;

const NoAccess = () => (
  <Wrapper>
    <Primary>
      This Is A Premium Feature
    </Primary>
    <Secondary>
      To access this feature, please upgrade to
      <StyledLink href="https://meetings.hubspot.com/snoyer" target="_blank">
        Spiffy Pro
      </StyledLink>
    </Secondary>
  </Wrapper>
);

export default NoAccess;
