/* eslint-disable */
import React, { useState, useRef } from "react";
import { Button, MenuItem, Menu, Popover } from "@material-ui/core";
import styled from "styled-components/macro";
import { ChevronDown } from "react-feather";
import ACTIONS, { ORDER_OPTIONS } from "../state/actions";
import CompanyFilter from "./CompanyFilter";
import SearchFilter from "./SearchFilter";

const BREAKPOINT = 1060;

const Wrapper = styled.div`
  width: 100%;
  background: white;
  z-index: 1000;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 14px;
  position: sticky;
  top: -60px;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    top: -3000px;
    align-items: flex-start;
    gap: 6px;
    justify-content: flex-start;
    padding: 6px;

    Button {
      flex: 1;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
`;

const ResetButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;

  @media (max-width: ${BREAKPOINT}px) {
    margin-left: 0px;
    border: 1px solid lightgray;
  }
`;

const FakeItem = styled(Button)`
  height: 46px;
  width: fit-content;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid
    ${(props) => (props.isactive === "Y" ? "slategrey" : "#eeeeee")};
  padding: 6px;
  border-radius: 6px;
  background: white;
`;

const ItemLeft = styled.div`
  flex: 0.9;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: "2px 0px";

  span {
    color: darkgrey;
    font-size: 10px;
    line-height: 1.3;
  }

  p {
    font-weight: 600;
    font-size: 13px;
    line-height: 1.3;
  }
`;

const ItemRight = styled.div`
  flex-basis: 20px;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: slategrey;
    height: 16px;
    width: 16px;
  }
`;

const getIsCountryFilterApplied = (countryCodes, countryCodeString) => {
  if (countryCodes.length === 1) {
    return false;
  }

  if (countryCodes.length > 1) {
    const stringArr = countryCodeString.split(",");
    return stringArr.length !== countryCodes.length;
  }
};

const FilterToolbar = ({ state, dispatch }) => {
  const [sortOpen, setSortOpen] = useState(false);
  const sortAnchor = useRef(null);

  const [countriesOpen, setCountriesOpen] = useState(false);
  const countryAnchor = useRef(null);

  const [companyFilterOpen, setCompanyFilterOpen] = useState(false);
  const companyAnchor = useRef(null);

  const [searchOpen, setSearchOpen] = useState(false);
  const searchAnchor = useRef(null);

  const isCountryFilterApplied = getIsCountryFilterApplied(
    state?.countryCodes,
    state?.countryCodeString
  );

  const noFiltersApplied =
    !state.vendorSearchInfo &&
    !state?.searchName &&
    state?.orderBy !== "NEW" &&
    !isCountryFilterApplied;

  const hasMultipleMp = state?.countryCodes?.length > 1;

  const handleChangeOrder = (text) => {
    if (text === state.orderBy) return setSortOpen(false);

    dispatch({ type: ACTIONS.ORDER_BY, payload: text });
    setSortOpen(false);
  };

  const handleChangeCountryCode = (country) => {
    if (country === "ALL") {
      if (state.countryCodeString.includes(",")) return setCountriesOpen(false);

      const allCountryCodes = state.countryCodes.map((c) => c.code).join(",");

      dispatch({
        type: ACTIONS.UPDATE_TO_ALL_COUNTRIES,
        payload: allCountryCodes,
      });
      return setCountriesOpen(false);
    }

    if (country.code === state.countryCodeString)
      return setCountriesOpen(false);

    dispatch({ type: ACTIONS.UPDATE_TO_SINGLE_COUNTRY, payload: country });
    setCountriesOpen(false);
  };

  const getCountryCodeByName = (str) => {
    const filtered = state.countryCodes.filter((c) => c.code === str);
    return filtered[0]?.name;
  };

  return (
    <Wrapper>
      {hasMultipleMp && (
        <>
          <FakeItem
            ref={countryAnchor}
            onClick={() => setCountriesOpen(true)}
            isactive={!state.countryCodeString.includes(",") ? "Y" : "N"}
          >
            <ItemLeft>
              <span>Countries</span>
              <p>
                {state.countryCodeString.includes(",")
                  ? "All"
                  : getCountryCodeByName(state.countryCodeString)}
              </p>
            </ItemLeft>
            <ItemRight>
              <ChevronDown />
            </ItemRight>
          </FakeItem>
          <Menu
            open={countriesOpen}
            anchorEl={countryAnchor?.current}
            disabled={state.countryCodes.length < 2}
            onClose={() => setCountriesOpen(false)}
          >
            <MenuItem onClick={() => handleChangeCountryCode("ALL")}>
              All
            </MenuItem>
            {state.countryCodes.map((country) => (
              <MenuItem
                key={country.code}
                value={country.code}
                onClick={() => handleChangeCountryCode(country)}
              >
                {country.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <FakeItem
        ref={sortAnchor}
        onClick={() => setSortOpen((p) => !p)}
        isactive={state.orderBy === ORDER_OPTIONS.NEW ? "Y" : "N"}
      >
        <ItemLeft>
          <span>Sort By</span>
          <p>{state.orderBy === ORDER_OPTIONS.NEW ? "Newest" : "Popularity"}</p>
        </ItemLeft>
        <ItemRight>
          <ChevronDown />
        </ItemRight>
      </FakeItem>
      <Menu
        open={sortOpen}
        anchorEl={sortAnchor?.current}
        onClose={() => setSortOpen(false)}
      >
        <MenuItem
          value={ORDER_OPTIONS.POPULARITY}
          onClick={() => handleChangeOrder(ORDER_OPTIONS.POPULARITY)}
        >
          Popularity
        </MenuItem>
        <MenuItem
          value={ORDER_OPTIONS.NEW}
          onClick={() => handleChangeOrder(ORDER_OPTIONS.NEW)}
        >
          Newest
        </MenuItem>
      </Menu>
      <FakeItem
        style={{ minWidth: 250 }}
        onClick={() => setCompanyFilterOpen(true)}
        ref={companyAnchor}
        isactive={Boolean(state.vendorSearchInfo) ? "Y" : "N"}
      >
        <ItemLeft>
          <span>Filter modules by company</span>
          <p>
            {Boolean(state.vendorSearchInfo)
              ? state.vendorSearchInfo.name
              : "All Companies"}{" "}
          </p>
        </ItemLeft>
        <ItemRight>
          <ChevronDown />
        </ItemRight>
      </FakeItem>
      <Popover
        open={companyFilterOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={companyAnchor.current}
        onClose={() => setCompanyFilterOpen(false)}
      >
        <CompanyFilter
          state={state}
          dispatch={dispatch}
          handleClose={() => setCompanyFilterOpen(false)}
        />
      </Popover>
      <FakeItem
        style={{ minWidth: 250 }}
        ref={searchAnchor}
        onClick={() => setSearchOpen(true)}
        isactive={Boolean(state.searchName) ? "Y" : "N"}
      >
        <ItemLeft>
          <span>Search by module name</span>
          <p>{Boolean(state.searchName) ? state.searchName : "---/---"}</p>
        </ItemLeft>
        <ItemRight>
          <ChevronDown />
        </ItemRight>
      </FakeItem>
      <Popover
        open={searchOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={searchAnchor.current}
        onClose={() => setSearchOpen(false)}
      >
        <SearchFilter
          state={state}
          dispatch={dispatch}
          handleClose={() => setSearchOpen(false)}
        />
      </Popover>
      <ResetButton
        disabled={noFiltersApplied}
        onClick={() => dispatch({ type: ACTIONS.RESET_MARKETPLACE })}
      >
        Reset fiters
      </ResetButton>
    </Wrapper>
  );
};

export default FilterToolbar;
