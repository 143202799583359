/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useQuizReportList = () => {
  const { data, error, mutate } = useSWR(
    "dashboard/analytics/report/?report=quiz_list",
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    quizzes: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useQuizReportList;
