/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Paper } from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import * as Yup from "yup";
import { Formik } from "formik";
import { mutate } from "swr";
import useLocations from "../../../swr/hooks/Locations/useLocations";
import { TextField, Button, Typography } from "../styles";
import API from "../../../axios/instances/API";
import InviteDialog from "./InviteDialog";
import styled from "styled-components/macro";
import useRoles from "../../../swr/hooks/Roles/useRoles";
import { getInvitesErrorMsgServer } from "../../../storage/helpers";
import ErrorAlertInvite from "../../../components/UI/ErrorAlertInvite";

const ModalWrapper = styled(Paper)`
  padding: 30px;
  width: 540px;
  position: absolute;
  min-height: 340px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  border: 2px solid black;
`;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Field is Required"),
  lastName: Yup.string().required("Field is Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Field is Required"),
});

const InviteTeamMember = ({ setSnackbarText, setInviteMemberModal }) => {
  const [comboValue, setComboValue] = useState(null);
  const [comboValueRoles, setComboValueRoles] = useState([]);

  const [resetKey, setResetKey] = useState(false);
  const [resetKeyTwo, setResetKeyTwo] = useState(true);

  const [dialogInfo, setDialogInfo] = useState(null);
  const { locations, isLoading, isError } = useLocations();
  const { roles, rolesLoading } = useRoles();

  const [errorAlert, setErrorAlert] = useState(null);

  const autocompleteOptions = isLoading
    ? []
    : locations.locations.map((location) => ({
        id: location.id,
        name: `${location.name} (${location.city.name})`,
      }));

  const autoCompleteRolesOptions = rolesLoading
    ? []
    : roles.map((role) => ({
        id: role.id,
        name: role.name,
      }));

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);

    setErrorAlert(null);

    if (values.email.length < 1) {
      return setFormError("Email required for email invite");
    }
    setSubmitting(true);
    try {
      const sendObj = {
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        email: values.email.trim(),
        locations: comboValue ? [comboValue.id] : [],
        roles: comboValueRoles?.map((r) => r.id) || [],
      };
      const { data } = await API.post("dashboard/teams/invitations/", sendObj);
      setSubmitting(false);

      if (data.status === "action_required" || data.status === "failed") {
        return setDialogInfo(data);
      }

      setSnackbarText(`Invite sent to ${values.email} success`);

      resetForm();
      mutate("dashboard/teams/invitations/");
      setInviteMemberModal(false);
      resetAutocompletes();
    } catch (error) {
      const errorObj = getInvitesErrorMsgServer(error);
      setErrorAlert(errorObj);
      resetAutocompletes();
    }
  };

  const handleCloseDialog = (shouldCloseInvite = false) => {
    if (shouldCloseInvite) {
      setDialogInfo(null);
      mutate("/dashboard/teams/");
      return setInviteMemberModal(false);
    }
    return setDialogInfo(null);
  };

  const resetAutocompletes = () => {
    setComboValueRoles([]);
    setComboValue(null);
    setResetKey((p) => !p);
    setResetKeyTwo((p) => !p);
  };

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: -8,
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          align="left"
          color="textPrimary"
        >
          Invite Team Member
        </Typography>
      </div>
      <Typography variant="body1" align="left" color="textPrimary" mb={5}>
        Fill in the inputs below to send an invitation to join your team.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="text"
              name="email"
              label="Email"
              value={values.email}
              fullWidth
              error={Boolean(touched.email && errors.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.email && errors.email}
              // helperText="*Required"
              autoComplete="new-password"
              size="small"
              variant="outlined"
            />
            <div style={{ height: 12 }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 12,
              }}
            >
              <TextField
                type="text"
                name="firstName"
                label="First Name"
                value={values.firstName}
                fullWidth
                error={Boolean(touched.firstName && errors.firstName)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.firstName && errors.firstName}
                autoComplete="new-password"
                size="small"
                variant="outlined"
              />

              <TextField
                type="text"
                name="lastName"
                label="Last Name"
                value={values.lastName}
                fullWidth
                error={Boolean(touched.lastName && errors.lastName)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.lastName && errors.lastName}
                autoComplete="new-password"
                size="small"
                variant="outlined"
              />
            </div>
            <div style={{ height: 20 }} />

            <Autocomplete
              key={resetKey}
              size="small"
              options={autocompleteOptions}
              noOptionsText="No Locations Available"
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => setComboValue(newValue)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isLoading}
                  size="small"
                  variant="outlined"
                  label="Team Member Location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <div style={{ height: 12 }} />
            <Autocomplete
              key={resetKeyTwo}
              multiple
              size="small"
              options={autoCompleteRolesOptions}
              noOptionsText="No Roles Available"
              getOptionLabel={(option) => option.name}
              onChange={(_, newValue) => setComboValueRoles(newValue)}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={rolesLoading}
                  size="small"
                  variant="outlined"
                  label="Team Member Roles"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {rolesLoading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <Button
              mt={4}
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              disabled={
                isSubmitting ||
                !isValid ||
                !comboValue ||
                // comboValueRoles.length < 1 ||
                Object.entries(touched).length === 0
              }
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              {isSubmitting ? "Submitting" : "Invite Team Member"}
            </Button>

            {errorAlert && (
              <>
                <div style={{ height: 12 }} />
                <ErrorAlertInvite
                  errorMsg={errorAlert}
                  handleClose={() => setErrorAlert(null)}
                />
              </>
            )}
          </form>
        )}
      </Formik>
      <InviteDialog
        dialogInfo={dialogInfo}
        handleClose={handleCloseDialog}
        locationOptions={isLoading ? [] : locations.locations}
        setSnackbarText={setSnackbarText}
      />
    </ModalWrapper>
  );
};

export default InviteTeamMember;

InviteTeamMember.propTypes = {
  setInviteMemberModal: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
