/* eslint-disable */
import React, { useContext, useState, useRef } from "react";
import styled from "styled-components/macro";
import {
  Typography,
  Button,
  Paper,
  Popover,
  Tooltip,
  Modal,
} from "@material-ui/core";
import { GlobalState } from "../../store/GlobalState";
import { LogOut } from "react-feather";
import UserSwitches from "./UserSwitches";
import { Mail, Key, User } from "react-feather";
import ActionButtonForAccount from "./ActionButtonForAccount";
import ChangePassword from "./ChangePassword";
import Contact from "./Contact";
import ACTIONS from "../../store/actions";
import { clearTokens, clearSWRKeys } from "../../storage/helpers";
import { useHistory } from "react-router-dom";
const getInitials = (user) => `${user?.firstName[0]}${user?.lastName[0]}`;

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 220px;
  min-width: 360px;
  max-width: 440px;
  text-align: left;
`;

const AccountButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: slategrey;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &:hover {
    cursor: pointer;
    background-color: lightgray;

    span {
      color: black;
    }
  }
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0px;
  border-bottom: 1px solid #eeeeee68;

  p {
    font-size: 12px;
    color: black;
  }

  span {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const AccountDropdownV2 = () => {
  const { state, dispatch } = useContext(GlobalState);
  const { config } = state;
  const { user } = config;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const initials = getInitials(user);
  const [editPasswordOpen, setEditPasswordOpen] = useState(false);
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const history = useHistory();

  const isManager = user?.job === "M";

  const handleLogout = () => {
    clearTokens();
    clearSWRKeys();
    dispatch({ type: ACTIONS.LOGOUT });
    history.push("/");
  };

  return (
    <>
      <Tooltip title="Account" arrow placement="bottom">
        <AccountButton onClick={() => setOpen((p) => !p)} ref={ref}>
          <span>{initials}</span>
        </AccountButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Wrapper>
          <Typography
            style={{
              fontSize: 13,
              fontWeight: "600",
              textTransform: "uppercase",
              color: "black",
            }}
          >
            {user.firstName} {user.lastName}{" "}
          </Typography>
          <div style={{ height: 8 }} />
          <p style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}>
            USER INFO
          </p>
          <div style={{ height: 4 }} />
          <FlexDiv>
            <p>Name:</p>
            <span>
              {user.firstName} {user.lastName}{" "}
            </span>
          </FlexDiv>
          <FlexDiv>
            <p>Email:</p>
            <span>{user.email}</span>
          </FlexDiv>

          <FlexDiv>
            <p>User Type:</p>
            <span>{user.job === "C" ? "Admin" : "Supervisor"}</span>
          </FlexDiv>
          <FlexDiv>
            <p>Organization Access:</p>
            <span>{config.organizationAccess ? "Yes" : "No"}</span>
          </FlexDiv>
          {isManager && (
            <FlexDiv>
              <p>Deploy permissions:</p>
              <span>
                {config?.user?.can_deploy_vendor_content ? "Yes" : "No"}
              </span>
            </FlexDiv>
          )}
          <FlexDiv>
            <p>Read only:</p>
            <span>{config?.readOnly ? "Yes" : "No"}</span>
          </FlexDiv>
          <div style={{ height: 16 }} />
          <UserSwitches user={user} />
          <div style={{ height: 16 }} />
          <p style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}>
            USER ACTIONS
          </p>
          <div style={{ height: 8 }} />
          <ButtonContainer>
            <ActionButtonForAccount
              Icon={Key}
              title="change your password"
              handleClick={() => setEditPasswordOpen(true)}
            />
            <div style={{ width: 4 }} />
            <ActionButtonForAccount
              Icon={Mail}
              title="contact us"
              handleClick={() => setContactUsOpen(true)}
            />
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "firebrick",
                marginLeft: "auto",
                color: "white",
              }}
              onClick={handleLogout}
              startIcon={<LogOut height={14} width={14} color="white" />}
            >
              Log Out
            </Button>
          </ButtonContainer>
        </Wrapper>
        <Modal
          open={editPasswordOpen}
          onClose={() => setEditPasswordOpen(false)}
        >
          <div>
            <ChangePassword />
          </div>
        </Modal>
        <Modal open={contactUsOpen} onClose={() => setContactUsOpen(false)}>
          <div>
            <Contact user={user} />
          </div>
        </Modal>
      </Popover>
    </>
  );
};

export default AccountDropdownV2;
