/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ACTIONS from './state/builderActions';
import styled from 'styled-components/macro';
import { Tooltip, Button } from '@material-ui/core';
import { Trash2, Edit2 } from 'react-feather';

const StyledButton = styled(Button)`
  height: 30px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  min-width: 30px;
  max-width: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee44;
  border: 1px solid #eeeeee;

  &:hover {
    border: 1px solid lightgray;
  }

  &:disabled {
    svg {
      color: lightgray;
    }
  }

  svg {
    color: slategrey;
    height: 14px;
    width: 14px;
  }
`;

const EditAndDelete = ({ builderContext, handleDeleteQuestion, question }) => {
  const { state, dispatch } = builderContext;
  const { editInfo } = state;
  const { isEditing } = editInfo;

  return (
    <div
      style={{
        width: '100%',

        display: 'flex',
        gap: 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Tooltip
        arrow
        title="Edit Question"
      >
        <StyledButton
          onClick={() =>
            dispatch({
              type: ACTIONS.START_EDIT,
              payload: {
                id: question.id,
                quizType: question.type,
                order: question.order,
              },
            })
          }
        >
          <Edit2 />
        </StyledButton>
      </Tooltip>
      <Tooltip
        arrow
        title="Delete Question"
      >
        <StyledButton
          disabled={isEditing}
          onClick={() => handleDeleteQuestion(question.id)}
        >
          <Trash2 />
        </StyledButton>
      </Tooltip>
    </div>
  );
};

export default EditAndDelete;

EditAndDelete.propTypes = {
  builderContext: PropTypes.object.isRequired,
  handleDeleteQuestion: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
};
