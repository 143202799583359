/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import CustomRemoveCompanyToolBar from './CustomRemoveCompanyToolBar';
import API from '../../../../../axios/instances/API';

const CurrentCompaniesTable = ({ audience, setSnackbarText, mutate, hasCompanies }) => {
  const handleDelete = async removeIds => {
    const newMapping = {
      roles: audience.mapping.roles.map(role => role.id),
      companies: audience.mapping.companies
        .filter(company => !removeIds.includes(company.id))
        .map(company => company.id),
    };

    try {
      await API.put('dashboard/audiences/details/', {
        id: audience.id,
        mapping: newMapping,
      });
      mutate();
      setSnackbarText(
        `Successfully removed ${
          removeIds.length === 1 ? 'company' : `${removeIds.length} companies`
        } warning`
      );
    } catch (error) {
      setSnackbarText('Failed to remove companies error');
    }
  };

  const options = {
    filterType: 'checkbox',
    selectableRows: 'multiple',
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    elevation: 1,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    responsive: 'simple',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomRemoveCompanyToolBar
        displayData={displayData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleDelete={handleDelete}
      />
    ),
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Comany Name',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      {hasCompanies && (
        <MUIDataTable
          title=""
          options={options}
          columns={columns}
          data={audience.mapping.companies}
        />
      )}
    </>
  );
};

export default CurrentCompaniesTable;

CurrentCompaniesTable.propTypes = {
  audience: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  hasCompanies: PropTypes.bool.isRequired,
};
