/* eslint-disable */
import React from 'react';
import useVendorQuizAudience from '../../../../../swr/hooks/Audience/useVendorQuizAudience';
import NoAccess from '../../../../../components/NoAccess';
import VendorAudienceEdit from './VendorAudienceEdit';
import VendorAudienceCurrent from './VendorAudienceCurrent';
import { Grid, Divider } from '@material-ui/core';
import styled from 'styled-components/macro';
import LoaderWrapper from '../../../../../components/Loaders/LoaderWrapper';

const NoAccessWrapper = styled.div`
  width: 100%;
  height: 500px;
`;

const CenterLoader = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeightDivider = styled(Divider)`
  min-height: 500px;

  @media (max-width: 960px) {
    display: none;
  }
`;

const VendorModuleAudience = ({ id, details, setSnackbarText }) => {
  const { vendorAudience, isLoading, mutate, isError } = useVendorQuizAudience(id);

  if (isLoading) {
    return (
      <CenterLoader>
        <LoaderWrapper text="Audience" />
      </CenterLoader>
    );
  }

  if (vendorAudience?.msg) {
    return (
      <NoAccessWrapper>
        <NoAccess />
      </NoAccessWrapper>
    );
  }

  if (isError) {
    return <span>remove this page for vendors?</span>;
  }

  return (
    <>
      <Grid
        container
        alignItems="stretch"
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <VendorAudienceEdit
            vendorAudience={vendorAudience}
            mutate={mutate}
            details={details}
            setSnackbarText={setSnackbarText}
          />
        </Grid>
        <Grid
          item
          md={1}
          align="center"
        >
          <HeightDivider orientation="vertical" />
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <VendorAudienceCurrent vendorAudience={vendorAudience} />
        </Grid>
      </Grid>
    </>
  );
};

export default VendorModuleAudience;
