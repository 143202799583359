/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

import SpiffyLogoWhite from "../assets/images/newspiffy.avif";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  flex-direction: column;
  height: 90%;
  vertical-align: middle;
  margin: auto;
  width: 100%;
`;

const Background = styled.div`
  height: 48px;
  width: 110px;
  padding: 6px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.grey[600]};
`;

const ImageHolder = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ErrorLoading = styled.span`
  color: ${(props) => props.theme.palette.grey[600]};
  font-style: italic;
  font-size: 28px;
`;

const Refresh = styled.span`
  color: ${(props) => props.theme.palette.primary.dark};
  font-style: italic;
  font-size: 28px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OnLoadError = () => (
  <Root>
    <FlexDiv>
      <Background>
        <ImageHolder src={SpiffyLogoWhite} />
      </Background>
      <ErrorLoading>Error Loading This Page</ErrorLoading>
    </FlexDiv>
    <Refresh onClick={() => window.location.reload(false)}>
      Click To Refresh
    </Refresh>
  </Root>
);

export default OnLoadError;
