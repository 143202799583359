/* eslint-disable */
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { mutate } from "swr";
import API from "../../../../../axios/instances/API";
import QuizStreams from "../QuizStreams";

const ModalWrapper = styled(Paper)`
  padding: 16px;
  width: 380px;
  text-align: left;
  min-height: 180px;
  max-width: 400px;
  max-height: 600px;
  position: absolute;
  background: white;
  left: 50%;
  top: 50%;
  border: 2px solid black;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid darkgrey;
  margin-top: 12px;
  padding: 12px;
  border-radius: 4px;
  background: white;
`;

const LaunchModal = ({ data, setSnackbarText, handleClose }) => {
  const [isLaunching, setIsLaunching] = useState(false);
  const questions = data?.questions?.questions;
  const [minScore, setMinScore] = useState(data?.passScore || 0);
  const [comboValue, setComboValue] = useState([]);

  const handleLaunch = async () => {
    try {
      setIsLaunching(true);
      await API.put("dashboard/quiz/questions/", {
        id: data.id,
        pass_score: minScore,
        questions,
      });
      if (comboValue.length > 0) {
        await API.post("dashboard/quiz/add-to-streams/", {
          id: data.id,
          stream_ids: comboValue.map((stream) => stream.id),
        });
      }
      await API.post("dashboard/quiz/deployment/deploy-retail-quiz/", {
        id: data.id,
      });
      await mutate(`/dashboard/quiz/review/?id=${data?.id}`);

      setIsLaunching(false);
      setSnackbarText("Module launched 🚀 success");
      handleClose();
    } catch (error) {
      setSnackbarText("something went wrong error");
    }
  };

  const handleScoreChange = (e) => {
    const { value } = e.currentTarget;
    if (value > questions.length) {
      return;
    }
    setMinScore(e.currentTarget.value);
  };

  return (
    <ModalWrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textOverflow: "ellipsis",
          gap: 4,
        }}
      >
        <span style={{ fontSize: 14, fontWeight: "600", whiteSpace: "nowrap" }}>
          Launch Module
        </span>
        {/* <span style={{ fontSize: 14, fontWeight: '600', whiteSpace: 'nowrap' }}>/</span>
        <span
          style={{
            fontSize: 14,
            fontWeight: '600',
            whiteSpace: 'nowrap',
            color: 'slategray',
          }}
        >
          {data?.name}
        </span> */}
      </div>

      <InputWrapper>
        <Typography variant="subtitle2">Set mimimum score to pass</Typography>
        <div style={{ marginLeft: "auto" }} />
        <TextField
          style={{ marginLeft: 12, width: 26, fontSize: 16 }}
          type="number"
          color="secondary"
          value={minScore}
          onChange={handleScoreChange}
          InputProps={{ inputProps: { min: 0, max: questions.length } }}
        />
        <span style={{ marginLeft: 2 }}>/</span>
        <span style={{ marginLeft: 6 }}>{questions?.length}</span>
      </InputWrapper>
      <div style={{ height: 8 }} />
      <QuizStreams setComboValue={setComboValue} />
      <div style={{ height: 8 }} />
      <Button
        variant="contained"
        color="secondary"
        diabled={isLaunching}
        style={{ marginTop: "auto", width: 148, marginLeft: "auto" }}
        onClick={handleLaunch}
      >
        {isLaunching ? "Launching..." : "Launch Module 🚀"}
      </Button>
    </ModalWrapper>
  );
};

export default LaunchModal;
