/* eslint-disable */
import React, { useState } from 'react';
import useKeyCompanyStats from '../../../../../swr/hooks/RetailReports/useKeyCompanyStats';
import styled from 'styled-components/macro';
import { Paper, MenuItem } from '@material-ui/core';
import { Typography, TextField } from '../../../styles';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { borderColor, positions } from '@material-ui/system';
import { addWeeks } from 'date-fns';
import { Style } from '@material-ui/icons';

const PaperWrapper = styled(Paper)`
  width: 100%;
  padding: 10px;
  background: white;
  border-radius: 6px;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 260px;
  padding: 0px 10px;

  @media (max-width: 600px) {
    padding: 0px;
  }
`;
const ChartWrapper = styled.div`
  width: 100%;
  height: 400px;
  padding: 10px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const StyledTextfield = styled(TextField)`
  min-width: 140px;
  margin-right: 8px;
  margin-left: auto;
  background-color: transparent;
  border: none;

  @media (max-width: 600px) {
    display: none;
  }
`;

const getAverage = (totalCountArray, numWeeks = 12) => {
  const totalComps = totalCountArray.reduce((p, c) => (p += c));

  return (totalComps / numWeeks).toFixed(2);
};

const CHART_OPTIONS = {
  SUBMISSIONS: 'Submissions',
  COMPLETIONS: 'Completions',
};

const EngagementChart = () => {
  const { data, isLoading, isError } = useKeyCompanyStats();

  const [engagementType, setEngagementType] = useState(CHART_OPTIONS.COMPLETIONS);

  const [chartType, setChartType] = useState(CHART_OPTIONS.COMPLETIONS);

  if (isLoading || isError) return null;

  const { submissions_breakdown: breakdown } = data;

  const chartLabels = Object.values(breakdown).map(val => {
    return `${format(new Date(val.date), 'MMM do')}-${format(
      addWeeks(new Date(val.date), 1),
      'do'
    )}`;
  });

  const extraCreditCompsArray = Object.values(breakdown).map(val => val.extra_credit_completions);
  const extraCreditSubsArray = Object.values(breakdown).map(val => val.extra_credit_submissions);

  const assignedCompsArray = Object.values(breakdown).map(val => val.assigned_completions);
  const assignedSubsArray = Object.values(breakdown).map(val => val.assigned_submissions);

  const totalSubsArray = Object.values(breakdown).map(
    val => val.assigned_submissions + val.extra_credit_submissions
  );
  const totalCompsArray = Object.values(breakdown).map(
    val => val.assigned_completions + val.extra_credit_completions
  );

  const getNumExtraCredit = dataIndex => {
    if (chartType === CHART_OPTIONS.COMPLETIONS) {
      return `Extra Credit: ${extraCreditCompsArray[dataIndex]}`;
    }

    return `Extra Credit: ${extraCreditSubsArray[dataIndex]}`;
  };

  const getNumAssigned = dataIndex => {
    if (chartType === CHART_OPTIONS.COMPLETIONS) {
      return `Assigned: ${assignedCompsArray[dataIndex]}`;
    }
    return `Assigned: ${assignedSubsArray[dataIndex]}`;
  };

  const totalCountArray =
    chartType === CHART_OPTIONS.COMPLETIONS
      ? [...assignedCompsArray, ...extraCreditCompsArray]
      : [...assignedSubsArray, ...extraCreditSubsArray];

  const averageEngagement = getAverage(totalCountArray);

  const averageBarAnnotation = {
    type: 'line',
    borderColor: 'lightgray',
    borderDash: [6, 1],
    borderDashOffset: 0,
    z: 0,
    borderWidth: 2,
    label: {
      enabled: true,
      content: () => `Average: ${averageEngagement}`,
      backgroundColor: '#EEEEEE',
      fontSize: 11,
      color: 'slategrey',
      position: 'end',
      yAdjust: -22,
    },
    scaleID: 'y',
    value: averageEngagement,
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: () => '',
          beforeBody: ctx => getNumAssigned(ctx[0].dataIndex),
          afterBody: ctx => getNumExtraCredit(ctx[0].dataIndex),
          footer: ctx =>
            `Total: ${
              chartType === CHART_OPTIONS.SUBMISSIONS
                ? totalSubsArray[ctx[0].dataIndex]
                : totalCompsArray[ctx[0].dataIndex]
            }`,
        },
      },
      annotation: {
        annotations: {
          averageBarAnnotation,
        },
      },
    },
    responsive: true,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 5,
          display: true,
          color: 'darkgrey',
        },
        grid: {
          color: 'rgba(0,0,0,0.05)',
        },
      },
      x: {
        stacked: true,
        grid: {
          color: 'rgba(0,0,0,0.05)',
        },
        ticks: {
          color: 'darkgrey',
        },
      },
    },
  };

  const chartData = () => {
    return {
      labels: chartLabels,
      z: 1,
      datasets: [
        {
          label: 'Extra Credit',
          // backgroundColor: '#ffdd6799',
          backgroundColor: 'lightgray',
          order: 0,
          borderColor: 'black',
          borderRadius: 4,
          // borderWidth: 1,
          data:
            engagementType === CHART_OPTIONS.COMPLETIONS
              ? extraCreditCompsArray
              : extraCreditSubsArray,
        },
        {
          label: 'Assigned',
          backgroundColor: 'slategrey',
          // backgroundColor: '#296CAB80',
          borderColor: 'black',
          // borderWidth: 1,
          borderRadius: 4,
          order: 1,
          data: chartType === CHART_OPTIONS.COMPLETIONS ? assignedCompsArray : assignedSubsArray,
        },
      ],
    };
  };
  return (
    <PaperWrapper>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '10px',
            background: 'transparent',
          }}
        >
          <div
            style={{
              paddingLeft: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <span style={{ fontWeight: '600', fontSize: 16 }}>Engagement Last 12 Weeks</span>
          </div>
          <StyledTextfield
            select
            size="small"
            variant="outlined"
            style={{}}
            value={chartType}
            onChange={e => setChartType(e.target.value)}
          >
            <MenuItem value={CHART_OPTIONS.COMPLETIONS}>Completions</MenuItem>
            <MenuItem value={CHART_OPTIONS.SUBMISSIONS}>Submissions</MenuItem>
          </StyledTextfield>
        </div>
        <Wrapper>
          <ChartWrapper>
            <Bar
              data={chartData}
              options={options}
            />
          </ChartWrapper>
        </Wrapper>
      </div>
    </PaperWrapper>
  );
};

export default EngagementChart;
