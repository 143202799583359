/* eslint-disable */
import React from "react";
import { Search } from "react-feather";
import { StyledItem } from "../styles";
import {
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const PreviewModule = ({ id, moduleState, setModalState, handleClose }) => {
  if (![QUIZSTATES.CLOSED, QUIZSTATES.LIVE].includes(moduleState)) {
    return null;
  }

  const handleViewClosed = () => {
    setModalState({ id, key: LIBRARY_MODAL_KEYS.PREVIEW });
    handleClose();
  };

  return (
    <StyledItem onClick={handleViewClosed}>
      <Search />
      <span>Preview Module</span>
    </StyledItem>
  );
};

export default PreviewModule;
