import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useFollowers = () => {
  const { data, error, mutate } = useSWR('/dashboard/followers/', fetcher, {
    revalidateOnFocus: false,
  });

  return {
    followers: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useFollowers;
