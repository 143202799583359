import ACTIONS from "./builderActions";

const builderReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_QUIZ_TYPE:
      return { ...state, quizType: action.payload };
    case ACTIONS.START_EDIT:
      return {
        ...state,
        quizType: action.payload.quizType,
        editInfo: {
          isEditing: true,
          editId: action.payload.id,
          order: action.payload.order,
        },
      };
    case ACTIONS.CLEAR_EDIT:
      return {
        ...state,
        editInfo: { isEditing: false, editId: null, order: null },
      };
    default:
      return { ...state };
  }
};

export default builderReducer;
