/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Typography } from "../../styles";

const SpaceFromControlLabel = styled(FormControlLabel)`
  span {
    font-weight: 500;
  }
`;

const ContentTypeRadio = ({ uploadOption, handleRadioChange }) => (
  <FormControl component="fieldset">
    <Typography style={{ fontSize: 14, color: "black", fontWeight: 500 }}>
      Select the media type for upload
    </Typography>
    <RadioGroup row value={uploadOption} onChange={handleRadioChange}>
      <SpaceFromControlLabel value="P" control={<Radio />} label="PDF" />
      <SpaceFromControlLabel value="V" control={<Radio />} label="Video" />
    </RadioGroup>
  </FormControl>
);

export default ContentTypeRadio;

ContentTypeRadio.propTypes = {
  uploadOption: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
};
