/* eslint-disable */
import React from 'react';
import FunLoader from '../../../../components/Loaders/FunLoader';

const Loader = () => {
  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderRadius: 6,
        background: '#eeeeee22',
        gap: 12,
      }}
    >
      <FunLoader />
      <span style={{ color: 'darkgrey' }}>loading report...</span>
    </div>
  );
};

export default Loader;
