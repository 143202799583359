/* eslint-disable */
import { lightFormat } from "date-fns";

const ACCESS_LEVELS = {
  SUPERVISOR: "Supervisor",
  ADMIN: "Admin",
};

const REGISTER_TYPES = {
  ADMIN: "ADMIN_REGISTER",
  ADMIN_NEW_COMPANY: "ADMIN NEW COMPANY",
  MANAGER: "MANAGER",
};

const JOBS = {
  ADMIN: "C",
  MANAGER: "M",
};

export const getRegisterInfo = (signupState) => {
  try {
    const { companyInfo, registerInfo, cantFindCompany, createCompanyInfo } =
      signupState;

    let sendObj = {};
    let registerType;

    sendObj.email = registerInfo.email;
    sendObj.first_name = registerInfo.firstName;
    sendObj.last_name = registerInfo.lastName;
    (sendObj.password = registerInfo.password),
      (sendObj.birth_date = lightFormat(registerInfo.dob, "yyyy-MM-dd"));
    sendObj.country = registerInfo.country;
    sendObj.state = registerInfo.state;
    sendObj.referral_code = registerInfo.ref;
    sendObj.job = JOBS.ADMIN;

    // Manager Register
    const { accessLevel } = companyInfo;

    if (accessLevel === ACCESS_LEVELS.SUPERVISOR) {
      sendObj.company = companyInfo.id;
      sendObj.locations = companyInfo.location.map((l) => l.id);
      registerType = REGISTER_TYPES.MANAGER;
      sendObj.job = JOBS.MANAGER;
    } else {
      if (cantFindCompany) {
        // creating new company
        registerType = REGISTER_TYPES.ADMIN_NEW_COMPANY;

        sendObj.existing_company = false;
        sendObj.company = {
          name: createCompanyInfo.companyName,
          vertical: createCompanyInfo.companyVertical.id,
        };
        sendObj.location = {
          name: createCompanyInfo.locationName,
          line1: createCompanyInfo.locationLine1,
          line2: createCompanyInfo.locationLine2,
          country: createCompanyInfo.country.code,
          state: createCompanyInfo.state.id,
          city: createCompanyInfo.city.id,
          postcode: createCompanyInfo.postalCode,
        };
      } else {
        // joining existing company //
        registerType = REGISTER_TYPES.ADMIN;
        sendObj.existing_company = true;
        sendObj.company = companyInfo.id;
      }
    }
    return {
      registerType,
      sendObj,
    };
  } catch (error) {
    return null;
  }
};

export const getRegisterErrorMessage = (error) => {
  return "Error registering you, please try again";
};
