/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { QuestionAvatar, Separate, SpacingDiv } from "../../styles";
import { Typography } from "../../../styles";
import EditAndDelete from "../EditAndDelete";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const QuestionDetailsMC = ({
  question,
  handleDeleteQuestion,
  builderContext,
  isReview = false,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => (
  <Wrapper>
    <Typography
      style={{
        fontSize: 10,
        fontWeight: "500",
        color: "darkgrey",
        whiteSpace: "nowrap",
        alignSelf: "flex-start",
      }}
    >
      Multiple Choice
    </Typography>
    <div style={{ height: 8 }} />
    {["A", "B", "C", "D"].map((letter) => (
      <SpacingDiv key={letter}>
        <QuestionAvatar
          activecolor={
            question.correctAnswer.toUpperCase() === letter ? "Yes" : "normal"
          }
        >
          {letter}
        </QuestionAvatar>
        <Typography variant="body2">
          {question.options[letter.toLowerCase()]}
        </Typography>
      </SpacingDiv>
    ))}
    {!isReview && (
      <Separate>
        <div
          style={{
            minWidth: 200,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: 11,
              color: "black",
              whiteSpace: "nowrap",
              fontWeight: "600",
            }}
          >
            Correct Answer: {"  "}
            {question.correctAnswer.toUpperCase()}
          </span>
        </div>
        <EditAndDelete
          builderContext={builderContext}
          handleDeleteQuestion={handleDeleteQuestion}
          question={question}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        />
      </Separate>
    )}
  </Wrapper>
);

export default QuestionDetailsMC;

QuestionDetailsMC.propTypes = {
  question: PropTypes.object.isRequired,
  handleDeleteQuestion: PropTypes.func,
  builderContext: PropTypes.object,
  isReview: PropTypes.bool.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
};

QuestionDetailsMC.defaultProps = {
  handleDeleteQuestion: null,
  builderContext: null,
};
