/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Paper, Button } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { createImage } from "../../../../storage/helpers";
import { ChevronRight, ArrowLeft } from "react-feather";
import Cropper from "react-easy-crop";
import { Alert } from "@material-ui/lab";

const StyledDropzone = styled(DropzoneArea)`
  .MuiDropzoneArea-root {
    // min-height: 180px !important;
  }
`;

const ModalWrapper = styled(Paper)`
  padding: 20px;
  max-width: 520px;
  min-width: 500px;
  max-height: 800px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  border: 2px solid black;
`;

const BackButton = styled(Button)`
  heigth: 38px;
  width: 38px;
  max-width: 38px;
  max-height: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-content: space-between;
`;

const HoldCrop = styled.div`
  height: 260px;
  border-radius: 4px;
  width: 260px;
  background: white;
  border: 1px solid lightgray;
  position: relative;
`;

const CropWrapper = styled.div`
  padding: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StepItem = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${(props) => (props.active === "y" ? "slategrey" : "lightgray")};
    font-weight: 500;
  }
  svg {
    color: ${(props) => (props.active === "y" ? "slategrey" : "lightgray")};
    height: 12px;
    width: 12px;
  }
`;

const Stepper = styled.div`
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const MAX_IMAGE_SIZE = 3000000;
const UploadImageModal = ({
  setSnackbarText,
  handleImageSave,
  setUploadImageModalOpen,
}) => {
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [testFile, setTestFile] = useState("");
  const [complete, setComplete] = useState(null);
  const [backup, setBackup] = useState(null);
  const [afterCrop, setAfterCrop] = useState(null);

  const getCroppedImg = async () => {
    const image = await createImage(testFile);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 480;
    canvas.height = 480;

    ctx.drawImage(
      image,
      complete.x,
      complete.y,
      complete.width,
      complete.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    canvas.toBlob(
      (blob) => {
        const newUrl = URL.createObjectURL(blob, "");
        setAfterCrop(newUrl);
        setTestFile(blob);
      },
      "image/png",
      1
    );
    setStep(2);
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>
        Upload module image
      </span>

      <Stepper>
        <StepItem active={step === 0 ? "y" : "n"}>
          <span>Drop</span>
          <ChevronRight />
        </StepItem>
        <StepItem active={step === 1 ? "y" : "n"}>
          <span>Crop</span>
          <ChevronRight />
        </StepItem>
        <StepItem active={step === 2 ? "y" : "n"}>
          <span>Upload</span>
        </StepItem>
      </Stepper>
      <div style={{ height: 8 }} />
      <div style={{ width: 480 }}>
        {step === 0 && (
          <>
            <StyledDropzone
              acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
              showFileNamesInPreview
              showFileNames
              filesLimit={1}
              showAlerts={false}
              onChange={(file) => {
                if (!file) return;
                if (file.length === 0) return;
                const blobUrl = URL.createObjectURL(file[0]);
                setBackup(blobUrl);
                setTestFile(blobUrl);
                setStep(1);
              }}
              onDropRejected={(fileArr) => {
                const fileSize = fileArr[0].size;
                if (fileSize > MAX_IMAGE_SIZE) {
                  return setSnackbarText(
                    "This image is too large (max 3,000,000 bytes). Please compress your image and try again error"
                  );
                }
                return setSnackbarText(
                  "This image could not be uploaded. Please try a different image error"
                );
              }}
              dropzoneText={
                <div
                  style={{
                    height: 80,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontSize: 16 }}>Drop your image here</span>
                  <span style={{ fontSize: 13, color: "darkgrey" }}>
                    File types accepted: .png, .jpg, .jpeg
                  </span>
                </div>
              }
            />

            <div style={{ height: 12 }} />
            <Alert severity="info" mb={4}>
              <p style={{ fontWeight: "600" }}>
                General Guidlines for your image
              </p>
              <div style={{ height: 4 }} />
              <ul>
                <li>
                  Accepted File Types: <strong>.png .jpg .jpeg.</strong>
                </li>
                <li>Image dimensions should be at least 480 x 480 pixels.</li>
                <li>
                  If you do not add an image, your company image will be used.
                </li>
              </ul>
            </Alert>
          </>
        )}
        {step === 1 && (
          <>
            <CropWrapper>
              <HoldCrop>
                <Cropper
                  image={testFile}
                  crop={crop}
                  zoom={zoom}
                  minZoom={0.4}
                  aspect={1 / 1}
                  restrictPosition={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(_, b) => {
                    setComplete(b);
                  }}
                />
              </HoldCrop>
            </CropWrapper>
          </>
        )}
        {step === 2 && (
          <div
            style={{
              width: "100%",
              height: 220,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #eeeeee",
              background: "#eeeeee",
              borderRadius: 6,
            }}
          >
            <img
              src={afterCrop}
              style={{
                height: 200,
                width: 200,
                borderRadius: 6,
                background: "white",
              }}
            />
          </div>
        )}
      </div>
      <>
        {step === 1 && (
          <ButtonContainer>
            <BackButton
              onClick={() => {
                setTestFile("");
                setBackup(null);
                setStep(0);
              }}
            >
              <ArrowLeft />
            </BackButton>
            <Button
              onClick={getCroppedImg}
              variant="contained"
              color="secondary"
            >
              Use Image
            </Button>
          </ButtonContainer>
        )}
        {step === 2 && (
          <ButtonContainer>
            <BackButton
              onClick={() => {
                setTestFile(backup);
                setAfterCrop(null);
                setStep(1);
              }}
            >
              <ArrowLeft />
            </BackButton>
            <Button
              onClick={async () => {
                await handleImageSave(testFile);
                return setUploadImageModalOpen(false);
              }}
              variant="contained"
              color="secondary"
            >
              Upload Image
            </Button>
          </ButtonContainer>
        )}
      </>
    </ModalWrapper>
  );
};

export default UploadImageModal;
