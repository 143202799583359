/* eslint-disable */
import ACTIONS, { ORDER_OPTIONS } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_AVAIL_COUNTRIES:
      return {
        ...state,
        countryCodes: action.payload.codes,
        countryCodeString: action.payload.codesAsstring,
      };
    case ACTIONS.INITIAL_LOAD:
      return {
        ...state,
        quizzes: action.payload.quizzes,
        streams: action.payload.streams,
        initialLoading: true,
        hasNext: action.payload.hasNext,
        numPages: action.payload.numPages,
        vertical: action.payload.vertical,
        searchName: action.payload.urlParams.search || state.searchName,
        vendorSearchInfo: action.payload.urlParams.vendorSearchInfo || state.vendorSearchInfo,
        orderBy: action.payload.urlParams.orderBy ? ORDER_OPTIONS.NEW : ORDER_OPTIONS.POPULARITY,
      };
    case ACTIONS.UPDATE_URL:
      return {
        ...state,
        statefulUrl: action.payload,
        isLoading: true,
      };
    case ACTIONS.END_INTIAL_LOAD:
      return {
        ...state,
        initialLoading: false,
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        error: true,
        errorMsg: action.payload || 'Error loading marketplace, please refresh.',
        isLoading: false,
        initialLoading: false,
      };
    case ACTIONS.CLEAR_COMPANY_FILTER:
      return {
        ...state,
        vendorSearchInfo: null,
        page: 1,
        isLoading: true,
        quizzes: [],
      };

    case ACTIONS.CLEAR_SEARCH:
      return {
        ...state,
        searchName: '',
        page: 1,
        isLoading: true,
        quizzes: [],
      };
    case ACTIONS.GET_NEXT_PAGE:
      return {
        ...state,
        page: state.page + 1,
        isLoading: true,
      };

    case ACTIONS.HANDLE_NEXT_PAGE:
      return {
        ...state,
        quizzes: action.payload.quizzes,
        isLoading: false,
        hasNext: action.payload.hasNext,
        numPages: action.payload.numPages,
        resultCount: action.payload.resultCount,
      };
    case ACTIONS.ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
        page: 1,
        isLoading: true,
        quizzes: [],
      };
    case ACTIONS.SEARCH_BY_NAME:
      return {
        ...state,
        isLoading: true,
        page: 1,
        quizzes: [],

        searchName: action.payload,
      };
    case ACTIONS.SEARCH_BY_VENDOR:
      return {
        ...state,
        isLoading: true,
        page: 1,
        quizzes: [],
        vendorSearchInfo: action.payload,
      };
    case ACTIONS.RESET_MARKETPLACE:
      return {
        isLoading: true,
        orderBy: ORDER_OPTIONS.POPULARITY,
        initialLoading: false,
        error: false,
        quizzes: [],
        statefulUrl: state.statefulUrl,
        numPages: null,
        hasNext: false,
        page: 1,
        searchName: '',
        resultCount: null,
        vendorSearchInfo: null,
        vertical: state.vertical,
        countryCodes: state.countryCodes,
        countryCodeString: state.countryCodes.map(c => c.code).join(','),
      };

    /// New //

    case ACTIONS.UPDATE_TO_ALL_COUNTRIES:
      return {
        ...state,
        countryCodeString: action.payload,
        isLoading: true,
        page: 1,
        quizzes: [],
      };

    case ACTIONS.UPDATE_TO_SINGLE_COUNTRY:
      return {
        ...state,
        countryCodeString: action.payload.code,
        isLoading: true,
        page: 1,
        quizzes: [],
      };

    ////

    case ACTIONS.HANDLE_MODULE_EXISTS_CHANGE:
      const updateId = action.payload;

      const updated = state.quizzes.map(quiz => {
        if (quiz.id === updateId) {
          return {
            ...quiz,
            exists: true,
          };
        }
        return {
          ...quiz,
        };
      });

      return {
        ...state,
        quizzes: updated,
      };
    default:
      return { ...state };
  }
};

export default reducer;
