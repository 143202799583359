/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Tooltip } from "@material-ui/core";
import { Info } from "react-feather";

const Wrapper = styled.div`
  height: ${(props) => (props.issmall === "y" ? 16 : 20)}px;
  width: ${(props) => (props.issmall === "y" ? 16 : 20)}px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee80;
  padding: 2px;
  cursor: pointer;
  transition: background 200ms ease-in;

  svg {
    color: #337ab7;
  }

  &:hover {
    background: #eeeeee;

    svg {
      color: slategrey;
    }
  }
`;
const InfoTooltip = ({ title = "", small = true }) => {
  return (
    <Tooltip title={title} arrow>
      <Wrapper issmall={small ? "y" : "n"}>
        <Info height={small ? 14 : 18} width={small ? 14 : 18} />
      </Wrapper>
    </Tooltip>
  );
};

export default InfoTooltip;
