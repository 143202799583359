/* eslint-disable */
/* eslint-disable max-len */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ACTIONS, { ORDER_OPTIONS } from "./state/actions";
import API from "../../../axios/instances/API";

const addDashString = (str) => `/dashboard${str}`;

const getInitialUrl = (
  urlSearchParams,
  params,
  marketplaceType = "quizzes",
  codesAsString
) => {
  const numParams = Object.values(params).length;
  if (numParams === 0)
    return `/dashboard/marketplace/${marketplaceType}/?page=1&country_code=${codesAsString}`;
  if (numParams === 1 && urlSearchParams.has("page")) {
    return `/dashboard/marketplace/${marketplaceType}/?page=1&country_code=${codesAsString}`;
  }
  if (urlSearchParams.has("vendor_id"))
    return `/dashboard/marketplace/${marketplaceType}/?page=1&vendor_id=${params.vendor_id}&country_code=${codesAsString}`;
  if (urlSearchParams.has("search"))
    return `/dashboard/marketplace/${marketplaceType}/?page=1&search=${params.search}&country_code=${codesAsString}`;
  if (urlSearchParams.has("order_by")) {
    if (params.order_by === "new")
      return `/dashboard/marketplace/${marketplaceType}/?page=1&order_by=new&country_code=${codesAsString}`;
    return `/dashboard/marketplace/${marketplaceType}/?page=1&country_code=${codesAsString}`;
  }

  return `/dashboard/marketplace/${marketplaceType}/?page=1&country_code=${codesAsString}`;
};

const getVendorSearchInfo = (params, data) => {
  if (!Object.keys(params)?.includes("vendor_id")) {
    return null;
  }

  // if there is vendor search info, create the state object and add it
  return {
    id: params.vendor_id,
    name: data.quizzes.length > 0 ? data?.quizzes[0]?.company?.name : "",
  };
};

const useInfiniteMarketplace = (state, dispatch) => {
  const {
    orderBy,
    quizzes,
    page,
    vendorSearchInfo,
    searchName,
    statefulUrl,
    initialLoading,
    marketplaceType,
    countryCodeString,
  } = state;

  const history = useHistory();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await API.get(
          "/dashboard/marketplace/available_marketplaces/"
        );

        const { available_marketplaces } = data;

        const codes = available_marketplaces.map((avail) => ({
          code: avail.country_code,
          name: avail.country_name,
          active: true,
        }));
        const codesAsstring = codes.map((c) => c.code).join(",");
        dispatch({
          type: ACTIONS.ADD_AVAIL_COUNTRIES,
          payload: { codes, codesAsstring },
        });
        return codesAsstring;
      } catch (error) {
        const responseErrorMsg = error?.response?.data?.error;
        return dispatch({
          type: ACTIONS.ERROR,
          payload:
            responseErrorMsg?.details ||
            "Error loading the markplace because there are no available marketplaces. Please try again.",
        });
      }
    };

    const getInitalLoad = async () => {
      const codesAsString = await getCountries();
      const urlSearchParams = new URLSearchParams(history.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const useURL = getInitialUrl(
        urlSearchParams,
        params,
        marketplaceType,
        codesAsString
      );

      try {
        const { data } = await API.get(useURL);
        const initialParams = {
          vendorSearchInfo: getVendorSearchInfo(params, data),
          search: urlSearchParams.has("search") ? params.search : null,
          orderBy: urlSearchParams.has("order_by") ? params.order_by : null,
        };
        dispatch({
          type: ACTIONS.INITIAL_LOAD,
          payload: {
            quizzes: data.quizzes || [],
            streams: data.streams || [],
            hasNext: data.hasNext,
            numPages: data.pages,
            vertical: data.vertical.name,
            // Need to add these, because we will get our info from them
            urlParams: initialParams,
          },
        });
        dispatch({ type: ACTIONS.END_INTIAL_LOAD });
      } catch (err) {
        const responseErrorMsg = err?.response?.data?.error;
        dispatch({
          type: ACTIONS.ERROR,
          payload:
            responseErrorMsg?.details || "Error loading the marketplace.",
        });
      }
    };

    return getInitalLoad();
  }, []);

  useEffect(() => {
    if (initialLoading) return;
    const updateUrl = () => {
      let newUrl = `/marketplace/quizzes?page=${page}`;
      if (vendorSearchInfo) {
        newUrl = newUrl.concat(`&vendor_id=${vendorSearchInfo.id}`);
      }
      if (searchName.length > 0) {
        newUrl = newUrl.concat(`&search=${searchName}`);
      }
      if (orderBy === ORDER_OPTIONS.NEW) {
        newUrl = newUrl.concat("&order_by=new");
      }

      newUrl = newUrl.concat(`&country_code=${countryCodeString}`);

      dispatch({ type: ACTIONS.UPDATE_URL, payload: newUrl });
    };

    updateUrl();
  }, [page, searchName, orderBy, vendorSearchInfo, countryCodeString]);

  useEffect(() => {
    if (initialLoading) return;

    const getPage = async () => {
      try {
        window.history.replaceState(null, "", statefulUrl);
        const { data } = await API.get(addDashString(statefulUrl));

        return dispatch({
          type: ACTIONS.HANDLE_NEXT_PAGE,
          payload: {
            quizzes: [...quizzes, ...data.quizzes],
            hasNext: data.hasNext,
            numPages: data.pages,
            resultCount: data.count,
          },
        });
      } catch (err) {
        return dispatch({
          type: ACTIONS.ERROR,
          payload: "Error getting the next page",
        });
      }
    };

    getPage();
  }, [statefulUrl]);
};

export default useInfiniteMarketplace;
