/* eslint-disable */
import React, { useState } from "react";
import { MenuItem, Menu } from "@material-ui/core";
import { ChevronDown } from "react-feather";
import styled from "styled-components/macro";
import AttActionButton from "../../../../components/audience/AudienceTool/Buttons/AttActionButton";

const StyledMenuItem = styled(MenuItem)`
  font-weight: 500;
  font-size: 11px;
  border-radius: 2px;
  background-color: #eeeeee22;
  maxwidth: 100%;
  display: flex;
  padding: 4px 6px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #eeeeee88;
  }
`;

const SwitchLocationMenu = ({
  locationList,
  selectedLocationId,
  setSelectedLocationid,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // border: "1px solid #EEEEEE",
        // borderRadius: 16,
        marginLeft: 12,
      }}
    >
      <AttActionButton
        title="Switch Location"
        handleClick={(e) => setAnchorEl(e.currentTarget)}
        Icon={ChevronDown}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: 160,
            minWidth: 200,
          },
        }}
      >
        <div
          style={{
            padding: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <p
            style={{
              fontSize: 11,
              color: "black",
              fontWeight: "500",
              marginLeft: 4,
              marginTop: -2,
            }}
          >
            Locations You Manage
          </p>
        </div>
        {locationList.map((location) => (
          <StyledMenuItem
            key={location.id}
            value={location.id}
            onClick={(e) => {
              const { value } = e.currentTarget;
              if (value !== selectedLocationId) {
                setSelectedLocationid(value);
              }
              return setAnchorEl(null);
            }}
          >
            <span>{location.name}</span>
            <div
              style={{
                height: 9,
                width: 9,
                borderRadius: 3,
                backgroundColor:
                  selectedLocationId === location.id ? "#337AB7" : "lightgray",
                marginLeft: "auto",
              }}
            />
          </StyledMenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SwitchLocationMenu;
