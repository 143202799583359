/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const BASE_URL = '/dashboard/analytics/vendor-reports/feedback-list/';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data?.feedback;
};

const useVendorFeedback = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher);

  return {
    data,
    isError: error,
    mutate,
    isLoading: !error && !data,
  };
};

export default useVendorFeedback;
