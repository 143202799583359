/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Book, Users, BarChart, PieChart, Clock, Calendar, ChevronDown } from 'react-feather';
import { DEFINITIONS, ENGAGEMENT_TYPES } from '../../../../storage/constants';
import { InfoWrapper } from '../styles';
import BigBlockByEngagementType from '../../reports/reuseableComponents/BigBlockByEngagementType';
import styled from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';

const BigBlockClear = styled.div`
  padding: 0px;
  flex: 0.5;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-radius: 4px;
`;

const HalfBlocked = styled(Paper)`
  flex: 0.5;
  width: 100%;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    font-weight: 500;
    font-size: 13px;
    color: darkgrey;
  }

  p {
    font-weight: 800;
    color: black;
    font-size: 20px;
  }
`;

const getTotalsByEngagementType = (totals, engagementType) => {
  let uniqueUsers;
  let completions;
  let submissions;

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    uniqueUsers = totals.overall_unique_users;
    completions = totals.assigned_completions + totals.follower_completions;
    submissions = totals.assigned_submissions + totals.follower_submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    uniqueUsers = totals.assigned_unique_users;
    completions = totals.assigned_completions;
    submissions = totals.assigned_submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    uniqueUsers = totals.follower_unique_users;
    completions = totals.follower_completions;
    submissions = totals.follower_submissions;
  }

  return {
    uniqueUsers,
    completions,
    submissions,
  };
};

const formatLastSubmissionString = date => {
  if (!date) return 'No Recent Submissions';

  return formatDistanceToNow(new Date(date));
};

const VendorHomeHero = ({ data, engagementType, lastSubmission }) => {
  const { totals } = data;

  const lastSubText = formatLastSubmissionString(lastSubmission?.created);

  const { uniqueUsers, completions, submissions } = getTotalsByEngagementType(
    totals,
    engagementType
  );

  return (
    <>
      <InfoWrapper
        style={{
          // background: "#eeeeee",
          padding: 0,
          borderRadius: 6,
          gap: 10,
        }}
      >
        <BigBlockByEngagementType
          title="Unique Users Engaged"
          definition={DEFINITIONS.totalUniqueUsers}
          number={uniqueUsers}
          Icon={Users}
          engagementType={engagementType}
        />
        <BigBlockByEngagementType
          title="Completions (All-Time)"
          Icon={BarChart}
          number={completions}
          definition={DEFINITIONS.completionsAllTime}
          engagementType={engagementType}
        />
        <BigBlockByEngagementType
          title="Submissions (All-Time)"
          Icon={PieChart}
          definition={DEFINITIONS.submissionsAllTime}
          number={submissions}
          engagementType={engagementType}
        />
        <BigBlockClear>
          <HalfBlocked>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 8,
                width: '100%',
              }}
            >
              <span>Number of live modules</span>
              {/* <Book
              height={18}
              width={18}
              style={{
                padding: 3,
                borderRadius: 3,
                background: "#eeeeee",
                color: "slategrey",
              }}
            /> */}
            </div>
            <p>{data.live_quizzes}</p>
          </HalfBlocked>
          <HalfBlocked>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: 8,
              }}
            >
              <span>Time Since Last Submission</span>
              {/* <Calendar
              height={18}
              width={18}
              style={{ padding: 3, borderRadius: 3, background: "#eeeeee" }}
            /> */}
            </div>
            <p style={{ textTransform: 'capitalize' }}>{lastSubText}</p>
          </HalfBlocked>
        </BigBlockClear>
      </InfoWrapper>
    </>
  );
};

export default VendorHomeHero;

VendorHomeHero.propTypes = {
  data: PropTypes.object.isRequired,
};
