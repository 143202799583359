/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown } from 'react-feather';
import { Button } from '../../styles';
import { ORDER_BY, ORDER_DIRECTIONS } from '../../../../storage/constants';

const CustomHeadLabel = ({
  title,
  setOrderBy,
  orderKey,
  orderBy,
  isOrderedByColumn,
  setOrderDirection,
  orderDirection,
}) => {
  const handleOrderToggle = () => {
    if (orderBy !== orderKey) {
      setOrderBy(orderKey);
      return setOrderDirection(ORDER_DIRECTIONS.ASCENDING);
    }

    if (orderDirection === null) {
      setOrderBy(orderKey);
      return setOrderDirection(ORDER_DIRECTIONS.ASCENDING);
    }
    if (orderDirection === ORDER_DIRECTIONS.ASCENDING) {
      setOrderBy(orderKey);

      return setOrderDirection(ORDER_DIRECTIONS.DESCENDING);
    }
    if (orderDirection === ORDER_DIRECTIONS.DESCENDING) {
      setOrderBy(ORDER_BY.ID);
      return setOrderDirection(null);
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
      <Button
        onClick={handleOrderToggle}
        endIcon={
          !isOrderedByColumn
            ? null
            : orderDirection === ORDER_DIRECTIONS.ASCENDING
              ? <ChevronDown style={{ height: 16, width: 16, color: 'darkgrey' }} />
              : <ChevronUp style={{ height: 16, width: 16, color: 'lightgray' }} />
            }
      >
        {title}
      </Button>
    </div>
  );
};

export default CustomHeadLabel;

CustomHeadLabel.propTypes = {
  title: PropTypes.string.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  orderKey: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  isOrderedByColumn: PropTypes.bool.isRequired,
  setOrderDirection: PropTypes.func.isRequired,
  orderDirection: PropTypes.string.isRequired,
};
