/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  Paper,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Typography, Button } from "../../../styles";
import { REPORT_STATUS, CHART_TYPES, FREQUENCIES, TIME_PERIODS } from "./utils";
import { Database } from "react-feather";

const Wrapper = styled(Paper)`
  width: 100%;
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: -4px;
  gap: 8px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const getMenuItems = (localInterval) => {
  if (localInterval === FREQUENCIES.WEEKLY) {
    return [
      <MenuItem key={TIME_PERIODS.FOUR_WEEKS} value={TIME_PERIODS.FOUR_WEEKS}>
        4 Weeks
      </MenuItem>,
      <MenuItem key={TIME_PERIODS.EIGHT_WEEKS} value={TIME_PERIODS.EIGHT_WEEKS}>
        8 Weeks
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.TWELVE_WEEKS}
        value={TIME_PERIODS.TWELVE_WEEKS}
      >
        12 Weeks
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.SIXTEEN_WEEKS}
        value={TIME_PERIODS.SIXTEEN_WEEKS}
      >
        16 Weeks
      </MenuItem>,
    ];
  }
  if (localInterval === FREQUENCIES.MONTHLY) {
    return [
      <MenuItem
        key={TIME_PERIODS.THREE_MONTHS}
        value={TIME_PERIODS.THREE_MONTHS}
      >
        3 Months
      </MenuItem>,
      <MenuItem key={TIME_PERIODS.SIX_MONTHS} value={TIME_PERIODS.SIX_MONTHS}>
        6 Months
      </MenuItem>,
      <MenuItem key={TIME_PERIODS.NINE_MONTHS} value={TIME_PERIODS.NINE_MONTHS}>
        9 Months
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.TWELVE_MONTHS}
        value={TIME_PERIODS.TWELVE_MONTHS}
      >
        12 Months
      </MenuItem>,
    ];
  }

  if (localInterval === FREQUENCIES.DAILY) {
    return [
      <MenuItem key={TIME_PERIODS.SEVEN_DAYS} value={TIME_PERIODS.SEVEN_DAYS}>
        7 Days
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.FOURTEEN_DAYS}
        value={TIME_PERIODS.FOURTEEN_DAYS}
      >
        14 Days
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.TWENTY_ONE_DAYS}
        value={TIME_PERIODS.TWENTY_ONE_DAYS}
      >
        21 Days
      </MenuItem>,
      <MenuItem
        key={TIME_PERIODS.TWENTY_EIGHT_DAYS}
        value={TIME_PERIODS.TWENTY_EIGHT_DAYS}
      >
        28 Days
      </MenuItem>,
    ];
  }
  return [<MenuItem>Error</MenuItem>];
};

const GenerateReportFilters = ({
  handleGenerateReport,
  globalReportType,
  globalInterval,
  globalTimePeriod,
  reportStatus,
}) => {
  const [localReportType, setLocalReportType] = useState(
    CHART_TYPES.SUBMISSIONS
  );
  const [localInterval, setLocalInterval] = useState(FREQUENCIES.MONTHLY);
  const [localTimePeriod, setLocalTimePeriod] = useState(
    TIME_PERIODS.SIX_MONTHS
  );

  const isLoading = reportStatus === REPORT_STATUS.LOADING;

  const getIsDisabled = () => {
    if (localReportType !== globalReportType) {
      return false;
    }

    if (localInterval !== globalInterval) {
      return false;
    }

    if (globalTimePeriod !== localTimePeriod) {
      return false;
    }

    return true;
  };

  const isDisabled = getIsDisabled();

  const handleIntervalChange = (e) => {
    const newValue = e.target.value;

    if (newValue === FREQUENCIES.MONTHLY) {
      setLocalInterval(newValue);
      return setLocalTimePeriod(TIME_PERIODS.SIX_MONTHS);
    }

    if (newValue === FREQUENCIES.DAILY) {
      setLocalInterval(newValue);
      return setLocalTimePeriod(TIME_PERIODS.SEVEN_DAYS);
    }

    if (newValue === FREQUENCIES.WEEKLY) {
      setLocalInterval(newValue);
      return setLocalTimePeriod(TIME_PERIODS.EIGHT_WEEKS);
    }

    return setLocalInterval(newValue);
  };

  return (
    <Wrapper>
      <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
        Generate Custom Report
      </Typography>
      <FormWrapper>
        <FormControl fullWidth size="small">
          <FormLabel>Select Report Type</FormLabel>
          <Select
            style={{ marginTop: 6, background: "white" }}
            row
            fullWidth
            size="small"
            variant="outlined"
            value={localReportType}
            onChange={(e) => setLocalReportType(e.target.value)}
          >
            <MenuItem value={CHART_TYPES.SUBMISSIONS}>Engagement</MenuItem>
            <MenuItem value={CHART_TYPES.UNIQUE_USERS}>Unique Users</MenuItem>
            <MenuItem value={CHART_TYPES.FOLLOWERS}>Follower Growth</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <FormLabel>Interval</FormLabel>
          <Select
            style={{ marginTop: 6, background: "white" }}
            onChange={handleIntervalChange}
            value={localInterval}
            row
            fullWidth
            size="small"
            variant="outlined"
          >
            <MenuItem value={FREQUENCIES.DAILY}>Daily</MenuItem>
            <MenuItem value={FREQUENCIES.MONTHLY}>Monthly</MenuItem>
            <MenuItem value={FREQUENCIES.WEEKLY}> Weekly</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" style={{ marginTop: 10 }}>
          <FormLabel>Time Period</FormLabel>
          <Select
            style={{ marginTop: 6, background: "white" }}
            onChange={(e) => setLocalTimePeriod(e.target.value)}
            value={localTimePeriod}
            row
            fullWidth
            size="small"
            variant="outlined"
          >
            {getMenuItems(localInterval)}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          style={{ minWidth: 100, marginLeft: "auto", maxWidth: 200 }}
          startIcon={
            isLoading ? (
              <CircularProgress size={14} color="white" />
            ) : (
              <Database height={14} width={14} />
            )
          }
          disabled={isDisabled || isLoading}
          onClick={() =>
            handleGenerateReport(
              localReportType,
              localInterval,
              localTimePeriod
            )
          }
        >
          {isLoading ? "Generating.." : "Generate Report"}
        </Button>
      </FormWrapper>
    </Wrapper>
  );
};

export default GenerateReportFilters;

GenerateReportFilters.propTypes = {
  handleGenerateReport: PropTypes.func.isRequired,
  globalReportType: PropTypes.string.isRequired,
  globalInterval: PropTypes.string.isRequired,
  globalTimePeriod: PropTypes.string.isRequired,
  reportStatus: PropTypes.string.isRequired,
};
