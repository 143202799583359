/* eslint-disable */
import React, { useEffect, useReducer } from "react";
import AuthLayout from "../../../layouts/Auth";
import RegisterInfoForm from "./RegisterInfoForm";
import SelectWorkplace from "./SelectWorkplace";
import SignupStepper from "./Stepper";
import ConfirmAndRegister from "./ConfirmAndRegister";
import SelectRegisterPath from "./SelectRegisterPath";
import { Helmet } from "react-helmet-async";
import RegisterComplete from "./RegisterComplete";
import { useLocation } from "react-router-dom";

const ACTIONS = {
  ///
  HANDLE_REFERRAL: "HANDLE_REFERRAL",
  ///

  UPDATE_REGISTER: "UPDATE_REGISTER",
  CREATE_COMPANY: "CREATE_COMPANY",
  SIGNUP: "SIGNUP",
  RETURN_TO_BASICS_FORM: "RETURN_TO_BASICS_FORM",
  RETURN_TO_SELECT_PATH: "RETURN_TO_SELECT_PATH",
  RETURN_TO_FIND_COMPANY: "RETURN_TO_FIND_COMPANY",
  RETURN_FROM_CONFIRM: "RETURN_FROM_CONFIRM",
  USER_CANT_FIND_COMPANY: "USER_CANT_FIND_COMPANY",

  UPDATE_WORKPLACE: "UPDATE_WORKPLACE",
  REMOVE_WORKPLACE: "REMOVE_WORKPLACE",
  SUBMIT_WORKPLACE: "SUBMIT_WORKPLACE",

  FINISH_REGISTER: "FINISH_REGISTER",
};

const initialState = {
  index: 0,
  registerInfo: {},
  companyInfo: {},
  createCompanyInfo: {},
  cantFindCompany: false,
  registerType: "",
};

const signupReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.HANDLE_REFERRAL:
      return {
        ...initialState,
        index: 1,
      };

    // Navigation Actions
    case ACTIONS.RETURN_TO_SELECT_PATH:
      return {
        ...initialState,
      };
    case ACTIONS.RETURN_TO_BASICS_FORM:
      return {
        ...state,
        index: 1,
      };
    case ACTIONS.RETURN_TO_FIND_COMPANY:
      return {
        ...state,
        cantFindCompany: false,
      };
    case ACTIONS.RETURN_TO_FORM_ONE:
      return {
        ...state,
        index: 1,
        companyInfo: {},
      };
    case ACTIONS.RETURN_FROM_CONFIRM:
      return {
        ...state,
        index: 2,
        companyInfo: {},
        // If returning to create company form, keep create company state & fill form
        createCompanyInfo: state.cantFindCompany ? state.createCompanyInfo : {},
      };

    case ACTIONS.USER_CANT_FIND_COMPANY:
      return {
        ...state,
        index: 2,
        cantFindCompany: true,
      };

    //--------- Form Submit Actions-------- //
    // Basics Form //
    case ACTIONS.UPDATE_REGISTER:
      return {
        ...state,
        index: 2,
        registerInfo: action.payload,
      };

    // Workpace Form //
    case ACTIONS.UPDATE_WORKPLACE:
      return {
        ...state,
        companyInfo: action.payload,
        cantFindCompany: false,
      };
    case ACTIONS.REMOVE_WORKPLACE:
      return {
        ...state,
        companyInfo: {},
      };
    case ACTIONS.SUBMIT_WORKPLACE:
      return {
        ...state,
        index: 3,
        companyInfo: {
          ...state.companyInfo,
          accessLevel: action.payload.accessLevel,
          location: action.payload.location,
        },
      };
    case ACTIONS.CREATE_COMPANY:
      return {
        ...state,
        index: 3,
        createCompanyInfo: action.payload,
      };

    // Finish Action //
    case ACTIONS.FINISH_REGISTER:
      return {
        ...state,
        index: 4,
        registerType: action.payload,
      };
    default:
      return { ...state };
  }
};

const Register = () => {
  // eslint-disable-next-line consistent-return

  const [signupState, dispatch] = useReducer(signupReducer, initialState);
  const { index } = signupState;

  const location = useLocation();

  const { search } = location;

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    if (searchParams.has("referral")) {
      dispatch({ type: ACTIONS.HANDLE_REFERRAL });
    }
  }, [search]);

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Register" />
      <SignupStepper signupState={signupState} />
      {index === 0 && (
        <SelectRegisterPath dispatch={dispatch} ACTIONS={ACTIONS} />
      )}
      {index === 1 && (
        <RegisterInfoForm
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          signupState={signupState}
        />
      )}
      {index === 2 && (
        <SelectWorkplace
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          signupState={signupState}
        />
      )}
      {index === 3 && (
        <ConfirmAndRegister
          ACTIONS={ACTIONS}
          dispatch={dispatch}
          signupState={signupState}
        />
      )}
      {index === 4 && <RegisterComplete signupState={signupState} />}
    </AuthLayout>
  );
};

export default Register;
