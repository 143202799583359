/* eslint-disable */
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { addDays } from "date-fns";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Calendar, X } from "react-feather";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { TextField } from "../../styles";
import EditDescriptionModal from "../UI/EditDescriptionModal";
import EditNameModal from "../UI/EditNameModal";
import InfoTooltip from "../UI/InfoTooltip";
import RequiredChip from "../UI/RequiredChip";

const GreyCalendar = styled(Calendar)`
  margin-right: 4px;
  color: ${(props) => props.theme.palette.grey[500]};

  &:hover {
    cursor: pointer;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 320px;
  height: 100%;
  border-radius: 0px;
  padding: 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 320px;
  height: 100%;
  width: 100%;
`;

const SwitchContainer = styled.div`
  width: 50%;
  padding: 0px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0px;
  flex-direction: column;
`;

const SmallTextfield = styled(TextField)`
  width: 100%;
`;

const Label = styled.span`
  font-size: 12px;
  color: darkgrey;
  font-weight: 400;
  margin-left: 2px;
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  gap: 4px;
`;

const EditLiveButton = styled(Button)`
  padding: 2px;
  margin-left: auto;
  border-radius: 3px;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 18px;
  max-height: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #183667;
  // background: slategrey;

  &:hover {
    background: #eeeeee;
  }

  span {
    color: #337ab7;
    font-weight: 600;
    font-size: 10px;
  }
`;

const validationSchema = Yup.object({
  name: Yup.string("Enter Module Name"),
  description: Yup.string(),
  disclaimer: Yup.string()
    .min(2, "Disclaimer should be a minimum of 6 characters")
    .max(400, "Description can be at most 400 characters"),
  timeToComplete: Yup.number()
    .min(1, "Must be at least 1 minute to complete")
    .max(60, "Maximum of 60 minutes to complete")
    .required("Required"),
  includeDisclaimer: Yup.string(),
  showAnswers: Yup.string(),
  lang: Yup.string(),
});

const BasicsForm = ({
  handleFormSubmit,
  initialValues,
  startDate,
  setStartDate,
  dueDate,
  setDueDate,
  details,
  setSnackbarText,
  mutate,

  isModuleLive,
  isModulePending,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const minEndDate = addDays(startDate, 1);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormWrapper>
              <LabelWrapper>
                <Label>Module Name</Label>
                {(isModuleLive || isModulePending) && (
                  <EditLiveButton onClick={() => setNameModalOpen(true)}>
                    <span>Edit</span>
                  </EditLiveButton>
                )}
              </LabelWrapper>
              <TextField
                type="text"
                name="name"
                fullWidth
                color="secondary"
                disabled={
                  isModuleLive ||
                  isModulePending ||
                  isModuleConvertingNextStateLive ||
                  isModuleConvertingNextStatePending
                }
                value={values.name}
                onChange={handleChange}
                onBlur={async (e) => {
                  if (values.name !== details?.name) {
                    await handleSubmit(e);
                    setSnackbarText("Updated module name success");
                  }
                }}
                helperText={
                  values?.name?.length === 100 ? "**100 characters maximum" : ""
                }
                autoComplete="new-password"
                variant="outlined"
                size="small"
                mb={3}
                style={{ background: "white" }}
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <RequiredChip
                      completed={Boolean(details?.name)}
                      small
                      requirementText="Every module must have a name."
                    />
                  ),
                }}
              />
              <LabelWrapper>
                <Label>Module Description</Label>
                <InfoTooltip
                  title="Use this section to give some context about the module. This will help app users to understand what this module is about.
                      Ex. “This module covers everything you need to know about our store’s XYZ policy.”
                      OR
                      “With the Holiday Season approaching, learn about our newest product that’s hitting the shelves this week!”"
                />
                {(isModuleLive || isModulePending) && (
                  <EditLiveButton onClick={() => setDescriptionModalOpen(true)}>
                    <span>Edit</span>
                  </EditLiveButton>
                )}
              </LabelWrapper>
              <TextField
                multiline
                rows={4}
                disabled={
                  isModuleLive ||
                  isModulePending ||
                  isModuleConvertingNextStateLive ||
                  isModuleConvertingNextStatePending
                }
                type="text"
                color="secondary"
                name="description"
                value={values.description}
                style={{
                  background: "white",
                }}
                fullWidth
                // error={Boolean(touched.description && errors.description)}
                // error={values.description.length === 300}
                onChange={handleChange}
                onBlur={async (e) => {
                  if (values.description !== details?.description) {
                    await handleSubmit(e);
                    setSnackbarText("Updated module description success");
                  }
                }}
                helperText={
                  values?.description?.length === 300
                    ? "** 300 characters maximum"
                    : null
                }
                autoComplete="new-password"
                variant="outlined"
                size="small"
                mb={3}
                inputProps={{
                  maxLength: 300,
                }}
                InputProps={{
                  endAdornment: (
                    <RequiredChip
                      completed={Boolean(details.description)}
                      small
                      requirementText="Every module must have a description."
                    />
                  ),
                }}
              />
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <LabelWrapper>
                    <Label>Module Start Date</Label>
                    <InfoTooltip title="Select the date you want to deploy this module. You can select today’s date or a future date. If you select a future date, the module will deploy at 10:30 AM EST on the selected date." />
                  </LabelWrapper>
                  <DatePicker
                    size="small"
                    disableToolbar
                    autoOk
                    fullWidth
                    color="secondary"
                    minDateMessage={" "}
                    disablePast
                    disabled={
                      isModuleLive ||
                      isModulePending ||
                      isModuleConvertingNextStateLive ||
                      isModuleConvertingNextStatePending
                    }
                    inputVariant="outlined"
                    variant="inline"
                    format="yyyy-MM-dd"
                    value={startDate}
                    onBlur={handleBlur}
                    style={{ background: "white" }}
                    onChange={async (date) => {
                      setStartDate(date);
                      await handleSubmit();
                      setSnackbarText("Updated module start date success");
                    }}
                    InputProps={{
                      endAdornment: (
                        <RequiredChip
                          completed={Boolean(details?.startDate)}
                          small
                          requirementText="Every module must have a start date."
                        />
                      ),
                      startAdornment: <GreyCalendar />,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabelWrapper>
                    <Label>Module End Date</Label>
                    <InfoTooltip title="Including an end date for a module is optional, but it can be useful for automatically closing a module at a specific date. Once the end date has passed, users who haven't completed the module will no longer be able to access it." />
                  </LabelWrapper>

                  <DatePicker
                    disablePast
                    disableToolbar
                    color="secondary"
                    inputVariant="outlined"
                    size="small"
                    autoOk
                    style={{ background: "white" }}
                    fullWidth
                    onClose={async () => {
                      await handleSubmit();
                      setSnackbarText("Updated module end date success");
                    }}
                    minDate={minEndDate}
                    variant="inline"
                    format="yyyy-MM-dd"
                    value={dueDate}
                    onChange={async (date) => {
                      setDueDate(date);
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {dueDate ? (
                            <X
                              style={{
                                color: "darkgrey",
                                cursor: "pointer",
                              }}
                              onClick={async (e) => {
                                e.stopPropagation();
                                setDueDate(null);
                                await handleSubmit();
                                setSnackbarText(
                                  "Removed module end date warning"
                                );
                              }}
                            />
                          ) : null}
                        </>
                      ),
                      startAdornment: <GreyCalendar height={18} width={18} />,
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <LabelWrapper>
                        <Label>Language</Label>
                      </LabelWrapper>
                      <Select
                        name="lang"
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        onClose={async (e) => {
                          await handleSubmit();
                          setSnackbarText("Updated module language success");
                        }}
                        value={values.lang}
                        onChange={handleChange}
                        style={{ background: "white" }}
                        color="secondary"
                      >
                        <MenuItem value="en-us">English</MenuItem>
                        <MenuItem value="FR">French</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <LabelWrapper>
                      <Label>Minutes to complete</Label>
                    </LabelWrapper>

                    <TextField
                      name="timeToComplete"
                      type="number"
                      value={values.timeToComplete}
                      style={{ background: "white" }}
                      color="secondary"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                      error={Boolean(
                        touched.timeToComplete && errors.timeToComplete
                      )}
                      onChange={handleChange}
                      onBlur={async (e) => {
                        e.preventDefault();
                        await handleSubmit();
                        setSnackbarText(
                          `Updated minutes to complete to ${e.target.value} success`
                        );
                        handleBlur(e);
                      }}
                      helperText={
                        touched.timeToComplete && errors.timeToComplete
                      }
                      autoComplete="new-password"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <SwitchContainer>
                    <LabelWrapper>
                      <Label>Include a disclaimer?</Label>
                      <InfoTooltip title="Disclaimers are short notices presented to users, requiring acknowledgement through a tick box before attempting the module. They can be used to convey important information and ensure compliance." />
                    </LabelWrapper>
                    <SmallTextfield
                      size="small"
                      select
                      color="secondary"
                      onBlur={async (e) => {
                        if (values.includeDisclaimer === "No") {
                          await handleSubmit(e);
                          setSnackbarText("Updated disclaimer success");
                        }
                        return;
                      }}
                      variant="outlined"
                      fullWidth
                      style={{ background: "white" }}
                      value={values.includeDisclaimer}
                      name="includeDisclaimer"
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </SmallTextfield>
                  </SwitchContainer>
                  <SwitchContainer>
                    <LabelWrapper>
                      <Label>Show incorrect answers?</Label>
                      <InfoTooltip title="After submission, would you like to show team members which answers they got wrong?" />
                    </LabelWrapper>

                    <SmallTextfield
                      size="small"
                      select
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={values.showAnswers}
                      onBlur={handleBlur}
                      name="showAnswers"
                      onChange={async (e) => {
                        handleChange(e);
                        await handleSubmit();
                        setSnackbarText(
                          "Updated show incorrect answers success"
                        );
                      }}
                      saved={details.displayAnswers}
                      style={{ background: "white" }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </SmallTextfield>
                  </SwitchContainer>
                </Grid>
              </Box>
              {values.includeDisclaimer === "Yes" && (
                <Box mt={2}>
                  <TextField
                    mt={2}
                    multiline
                    rows={4}
                    type="text"
                    name="disclaimer"
                    label="Disclaimer"
                    value={values.disclaimer}
                    fullWidth
                    color="secondary"
                    error={Boolean(touched.disclaimer && errors.disclaimer)}
                    onChange={handleChange}
                    onBlur={async (e) => {
                      handleBlur(e);
                      await handleSubmit();
                      setSnackbarText("Disclaimer updated success");
                    }}
                    helperText={touched.disclaimer && errors.disclaimer}
                    autoComplete="new-password"
                    variant="outlined"
                    size="small"
                    mb={4}
                  />
                </Box>
              )}
            </FormWrapper>
          </form>
        )}
      </Formik>
      {(isModuleLive || isModulePending) && (
        <>
          <Modal open={nameModalOpen} onClose={() => setNameModalOpen(false)}>
            <div>
              <EditNameModal
                initialName={details?.name}
                details={details}
                setSnackbarText={setSnackbarText}
                handleClose={() => setNameModalOpen(false)}
                mutate={mutate}
              />
            </div>
          </Modal>
          <Modal
            open={descriptionModalOpen}
            onClose={() => setDescriptionModalOpen(false)}
          >
            <div>
              <EditDescriptionModal
                intialDescription={details?.description}
                details={details}
                setSnackbarText={setSnackbarText}
                handleClose={() => setDescriptionModalOpen(false)}
                mutate={mutate}
              />
            </div>
          </Modal>
        </>
      )}
    </Wrapper>
  );
};

export default BasicsForm;

BasicsForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  startDate: PropTypes.any,
  setStartDate: PropTypes.func.isRequired,
  dueDate: PropTypes.any,
  setDueDate: PropTypes.any.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  datesChanged: PropTypes.bool.isRequired,
};

BasicsForm.defaultProps = {
  startDate: null,
  dueDate: null,
};
