/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { mutate as swrMutate } from "swr";
import API from "../../../../../axios/instances/API";
import useAudience from "../../../../../swr/hooks/Audience/useAudience";
import useVendorQuizAudience from "../../../../../swr/hooks/Audience/useVendorQuizAudience";
import { getVendorSuggestErrorMsg } from "../utils";

const TabBar = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: fit-content;
`;

const Tab = styled.div`
  cursor: pointer;
  width: fit-content;
  border-radius: 3px;
  border: 1px solid
    ${(props) => (props.isselected === "Y" ? "slategrey" : "lightgray")};
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 90px;

  span {
    color: ${(props) => (props.isselected === "Y" ? "black" : "darkgrey")};
    font-weight: 500;
    font-size: 10px;
    padding: 2px;
  }

  p {
    color: ${(props) => (props.isselected === "Y" ? "white" : "slategrey")};
    font-size: 9px;
    font-weight: 700;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 12px;
  overflow-y: scroll;
  min-height: 400px;
`;

const NumberIndication = ({ num, isSelected }) => {
  return (
    <div
      style={{
        height: 12,
        minWidth: 12,
        padding: 2,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: isSelected ? "slategrey" : "lightgray",
      }}
    >
      <p style={{ fontSize: 8 }}>{num}</p>
    </div>
  );
};

const Suggest = ({ currentlySuggested = false, id, setSnackbarText }) => {
  const { vendorAudience, isLoading, isError, mutate } =
    useVendorQuizAudience(id);

  const audienceSaved = Boolean(vendorAudience?.savedID);
  const [selectedAudience, setSelectedAudience] = useState(null);

  const { data } = useAudience(selectedAudience);
  const [tab, setTab] = useState(0);
  const [isSuggesting, setIsSuggesting] = useState(false);

  useEffect(() => {
    if (isLoading) return null;

    if (audienceSaved) {
      setSelectedAudience(vendorAudience?.savedID);
    }
  }, [audienceSaved, isLoading]);

  if (isLoading)
    return (
      <Wrapper>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 400,
          }}
        >
          <span style={{ color: "darkgrey" }}>loading...</span>
        </div>
      </Wrapper>
    );

  if (isError) {
    return (
      <>
        <div
          style={{
            height: "100%",
            width: "100%",
            background: "#eeeeee",
            flex: 1,
            borderRadius: 4,
            marginTop: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ color: "firebrick", fontSize: 14, fontWeight: "600" }}>
            No Access
          </span>
          <span style={{ color: "slategrey" }}>
            {isError?.response?.data?.detail ===
            "You do not have permission to perform this action."
              ? "Multiple vertical vendors cannot suggest content"
              : "Modules cannot be suggested"}
          </span>
        </div>
      </>
    );
  }

  const { savedAudiences } = vendorAudience;

  const handleSuggest = async () => {
    setIsSuggesting(true);
    try {
      /// first.. we will have to save the audience
      await API.put("/dashboard/quiz/audience/vendor/", {
        id,
        audience_id: selectedAudience,
      });
      await API.post("dashboard/quiz/deployment/suggest-vendor-quiz/", { id });
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      await mutate();
      setIsSuggesting(false);
      return setSnackbarText("Module has been suggested to audience success");
    } catch (error) {
      setIsSuggesting(false);

      const msg = getVendorSuggestErrorMsg(error);
      setSnackbarText(msg);
    }
  };

  return (
    <>
      <Wrapper>
        <span style={{ color: "darkgrey" }}>Select Audience</span>
        <TextField
          select
          fullWidth
          variant="outlined"
          disabled={currentlySuggested}
          size="small"
          color="secondary"
          placeholder="Select a custom audience"
          value={selectedAudience}
          onChange={(e) => setSelectedAudience(e.target.value)}
        >
          <MenuItem value={null}>None</MenuItem>
          {savedAudiences.map((audience) => (
            <MenuItem value={audience.id} key={audience.id}>
              <span>{audience.name}</span>
            </MenuItem>
          ))}
        </TextField>
        <div style={{ height: 12 }} />
        <span style={{ color: "darkgrey" }}>Audience Info</span>
        <div
          style={{
            width: "100%",
            border: "1px solid #eeeeee",
            background: "#eeeeee60",
            flex: 1,
            borderRadius: 4,
            padding: 8,
            maxHeight: 260,
          }}
        >
          {selectedAudience ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{ fontSize: 13, fontWeight: "500", marginLeft: 2 }}
                >
                  {data?.audience?.name}
                </span>
                <TabBar>
                  <Tab
                    onClick={() => setTab(0)}
                    isselected={tab === 0 ? "Y" : "N"}
                  >
                    <span>Companies</span>
                    <NumberIndication
                      isSelected={tab === 0}
                      num={data?.audience?.mapping?.companies?.length || 0}
                    />
                  </Tab>
                  <Tab
                    onClick={() => setTab(1)}
                    isselected={tab === 1 ? "Y" : "N"}
                  >
                    <span>Roles</span>
                    <NumberIndication
                      num={data?.audience?.mapping?.roles?.length || 0}
                      isSelected={tab === 1}
                    />
                  </Tab>
                </TabBar>
              </div>
              <div style={{ height: 8 }} />
              <div
                style={{
                  flex: 1,
                  maxHeight: 200,

                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              >
                {tab === 0 && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {data?.audience?.mapping?.companies?.map((company, i) => (
                      <div
                        style={{
                          border: "1px solid #eeeeee",
                          width: "100%",
                          borderRadius: 4,
                          marginBottom: 2,
                          padding: "4px 8px",
                          background: "white",
                        }}
                        key={company.id}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            color: "black",
                            fontWeight: "400",
                          }}
                        >
                          {i + 1}) {company?.name}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {tab === 1 && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {data?.audience?.mapping?.roles?.map((role, i) => (
                      <div
                        style={{
                          border: "1px solid #eeeeee",
                          width: "100%",
                          borderRadius: 4,
                          marginBottom: 2,
                          padding: "4px 8px",
                          background: "white",
                        }}
                        key={role.id}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            color: "black",
                            fontWeight: "400",
                          }}
                        >
                          {i + 1}) {role?.name}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                minHeight: 244,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <span style={{ color: "lightgray", fontSize: 12 }}>
                -- No audience seleted --
              </span>
            </div>
          )}
        </div>
        {currentlySuggested && <div style={{ height: 12 }} />}
        <div
          style={{
            minHeight: 60,
            flexBasis: 60,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {currentlySuggested ? (
            <>
              <Alert severity="success" style={{ width: "100%" }}>
                <p style={{ fontSize: 13, fontWeight: "600" }}>
                  Module Suggested
                </p>
                <span style={{ fontSize: 12 }}>
                  *Selected audience cannot be changed once suggested
                </span>
              </Alert>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSuggest}
              disabled={!selectedAudience || isSuggesting}
            >
              Suggest
            </Button>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default Suggest;
