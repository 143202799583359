/* eslint-disable */
import React from "react";
import { AlertTriangle, CheckCircle } from "react-feather";
import styled from "styled-components/macro";
import { Tooltip } from "@material-ui/core";

const Container = styled.div`
  width: fit-content;
  min-height: 20px;
  min-width: 20px;
  padding: ${(props) => (props.small === "T" ? "1px" : "1px 5px")};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 4px;
  border: 1px solid lightgray;
  gap: 3px;
  ${(props) => (props.iscompleted === "T" ? "mediumseagreen" : "lightgray")};

  span {
    font-size: 10px;
    font-weight: 500;
    color: ${(props) => (props.iscompleted === "T" ? "seagreen" : "slategrey")};
  }
`;

const Title = ({ completed, requirementText }) => {
  if (completed) return <span>Requirement Complete</span>;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <span style={{ color: "#FBC988" }}>Required: </span>
      <span>{requirementText}</span>
    </div>
  );
};

const RequiredChip = ({
  completed = false,
  title = "Required",
  requirementText = "Action Required",
  small = false,
}) => {
  return (
    <Tooltip
      title={<Title completed={completed} requirementText={requirementText} />}
      arrow
    >
      <Container iscompleted={completed ? "T" : "F"} small={small ? "T" : "F"}>
        {!completed ? (
          <AlertTriangle height={11} width={11} color="darkorange" />
        ) : (
          <CheckCircle height={11} width={11} color="seagreen" />
        )}
        {!small && <span>{title}</span>}
      </Container>
    </Tooltip>
  );
};

export default RequiredChip;
