/* eslint-disable */
/* redeploy */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LoadMoreWrapper,
  LoadingMore,
} from "../../../marketplace/marketplaceStyles";
import useRetailReportsTeamMemberList from "../../../../../swr/hooks/RetailReports/useRetailReportsTeamMemberList";
import MemberTable from "./MemberTable";
import OnLoadError from "../../../../../components/OnLoadError";
import Loader from "../../vendorReports/Loader";

const TeamMembersReports = ({ isLte }) => {
  const [filterDateString, setFilterDateString] = useState(null);

  const { isLoading, teamMembers, isError } = useRetailReportsTeamMemberList(
    filterDateString?.queryString || null
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div style={{ height: 700, width: "100%" }}>
        <OnLoadError />
      </div>
    );
  }

  return (
    <MemberTable
      teamMembers={teamMembers}
      isLte={isLte}
      filterDateString={filterDateString}
      setFilterDateString={setFilterDateString}
      reportLoading={isLoading}
    />
  );
};

export default TeamMembersReports;

TeamMembersReports.propTypes = {
  isLte: PropTypes.bool.isRequired,
};
