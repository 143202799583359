/* eslint-disable */
import { Button, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Globe, Plus } from "react-feather";
import styled from "styled-components/macro";
import { mutate as swrMutate } from "swr";
import API from "../../../../../axios/instances/API";
import { getVendorAddToMarketplaceErrorMsg } from "../utils";

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 12px;
  overflow-y: scroll;
  max-height: 440px;
`;

const DeployButton = styled(Button)`
  height: 26px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #337ab7;

  span {
    color: white;
  }
`;

const RemoveButton = styled(Button)`
  height: 26px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeployAllButton = styled(Button)`
  height: 26px;
  width: 26px;
  max-width: 26px;
  min-height: 26px;
  min-width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background: #337ab7;

  &:hover {
    background: slategrey;
  }
  &:disabled {
    background: darkgrey;
  }
`;

const MarketplaceListItem = styled.div`
  width: 100%;
  padding: 4px;
  background: #eeeeee53;
  margin-bottom: 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #eeeeee;

  &:hover {
    background: #eeeeee;
  }
`;

const Deploy = ({
  id,
  data,
  mutate,
  setSnackbarText,
  currentlyInMarketplace,
}) => {
  const { available_for_deployment: available, deployed_to: deployed } = data;

  const deployedIds = deployed.map((mp) => mp.id);

  const allMarketplaces = [...available, ...deployed]
    .map((mp) => ({
      ...mp,
      deployed: deployedIds.includes(mp.id),
    }))
    .sort((a, b) => a.id - b.id);

  const getMpNameFromId = (id) => {
    const mp = allMarketplaces.find((mp) => mp.id === id);
    return `${mp?.country_name} - ${mp?.vertical_name}`;
  };

  const handleAddMp = async (mpId) => {
    const codes = [...deployed.map((mp) => mp.id), mpId];

    try {
      await API.post("/dashboard/quiz/deployment/marketplace/", {
        id,
        marketplace_ids: codes,
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);

      setSnackbarText(
        `Module added to marketplace (${getMpNameFromId(mpId)}) success`
      );
    } catch (error) {
      const msg = getVendorAddToMarketplaceErrorMsg(error);
      return setSnackbarText(msg);
    }
  };

  const handleRemoveMp = async (mpId) => {
    try {
      await API.delete("/dashboard/quiz/deployment/marketplace/", {
        data: {
          id,
          marketplace_ids: [mpId],
        },
      });
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      await mutate();

      setSnackbarText(
        `Module removed from marketplace (${getMpNameFromId(mpId)}) warning`
      );
    } catch (error) {
      const msg = getVendorAddToMarketplaceErrorMsg(error);
      return setSnackbarText(msg);
    }
  };

  const handleDeployToAll = async () => {
    const codes = allMarketplaces.map((mp) => mp.id);

    try {
      await API.post("/dashboard/quiz/deployment/marketplace/", {
        id,
        marketplace_ids: codes,
      });
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      await mutate();
      setSnackbarText(`Module deployed to all marketplaces success`);
    } catch (error) {
      const msg = getVendorAddToMarketplaceErrorMsg(error);
      return setSnackbarText(msg);
    }
  };

  return (
    <Wrapper>
      {allMarketplaces.length > 1 ? (
        <div
          style={{
            minHeight: 30,

            width: "100%",
            display: "flex",
            marginBottom: 6,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "darkgrey", fontSize: 15 }}>Marketplaces</span>
          <Tooltip title="Deploy to all marketplaces">
            <DeployAllButton
              onClick={handleDeployToAll}
              disabled={available.length === 0}
            >
              <Plus height={12} width={12} color="white" />
            </DeployAllButton>
          </Tooltip>
        </div>
      ) : (
        <>
          <span style={{ color: "darkgrey" }}>Marketplaces</span>
          <div style={{ height: 12 }} />
        </>
      )}
      {allMarketplaces.map((mp) => (
        <MarketplaceListItem key={mp.id}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Globe
              height={12}
              width={12}
              style={{
                color: mp.deployed ? "mediumseagreen" : "firebrick",
                marginRight: 4,
              }}
            />
            <span>{mp.country_name}</span>
            <span style={{ marginLeft: 4, marginRight: 4 }}>-</span>
            <span>{mp.vertical_name}</span>
          </div>
          {mp.deployed ? (
            <RemoveButton onClick={() => handleRemoveMp(mp.id)}>
              <span>Remove</span>
            </RemoveButton>
          ) : (
            <DeployButton onClick={() => handleAddMp(mp.id)}>
              <span>Deploy</span>
            </DeployButton>
          )}
        </MarketplaceListItem>
      ))}

      {currentlyInMarketplace && (
        <div style={{ marginTop: "auto", width: "100%" }}>
          <Alert severity="success" style={{ width: "100%" }}>
            <p style={{ fontSize: 13, fontWeight: "600" }}>
              Module deployed to ({deployedIds?.length}){" "}
              {deployedIds?.length > 1 ? "marketplaces" : "marketplace"}
            </p>
          </Alert>
        </div>
      )}
    </Wrapper>
  );
};

export default Deploy;
