/* eslint-disable */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Paper } from '@material-ui/core';
import styled from 'styled-components/macro';

import { Button, TextField, Typography } from '../../pages/private/styles';
import API from '../../axios/instances/API';
import ACTIONS from '../../store/actions';

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(5)}px;
  width: 500px;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const EditCompanyDescription = ({ handleClose, setSnackbarText, mutate }) => {
  const [description, setDescription] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const readOnly = false;

  const handleUpdate = async () => {
    setIsUpdating(true);
    try {
      await API.put('dashboard/company/details/', {
        description,
      });
      mutate();
      setSnackbarText('Company description updated success');
      setIsUpdating(false);
      handleClose();
    } catch (error) {
      setSnackbarText('There was an issue updating company description.. please try again. error');
      setIsUpdating(false);
    }
  };

  return (
    <Wrapper>
      <Typography
        variant="h4"
        mb={4}
      >
        Edit Company Description
      </Typography>
      <TextField
        value={description}
        onChange={e => setDescription(e.currentTarget.value)}
        size="small"
        fullWidth
        mb={2}
        mt={0}
        multiline
        rows={4}
        label="Enter new description..."
        variant="outlined"
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={description.length < 2 || readOnly || isUpdating}
        mb={2}
        fullWidth
        onClick={handleUpdate}
        startIcon={isUpdating ? <CircularProgress size={20} /> : null}
      >
        {isUpdating ? 'Saving' : 'Save'}
      </Button>
    </Wrapper>
  );
};

export default EditCompanyDescription;

EditCompanyDescription.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
