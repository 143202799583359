/* eslint-disable */
import React, { useContext } from "react";
import { ArrowLeft } from "react-feather";
import { Button } from "@material-ui/core";
import styled from "styled-components/macro";
import { GlobalState } from "../../../../store/GlobalState";

const BackButton = styled(Button)`
  height: 38px
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 10px;
  flex-shrink: 38px;
  flex-basis: 38px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

const MockHeader = ({ step, data, handleGoBack }) => {
  const { state } = useContext(GlobalState);
  const { config } = state;

  const isMarketplaceModule = Object.keys(data?.marketPlaceParent).length > 0;

  const companyName = isMarketplaceModule
    ? "Marketplace moddy"
    : config?.user?.company?.name;

  return (
    <div
      style={{
        width: "100%",
        background: "#eeeeee",
        height: 80,
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid lightgray",
      }}
    >
      <div style={{ height: 26 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: 42,
          padding: 6,
          marginTop: "auto",
          marginBottom: 8,
        }}
      >
        <BackButton onClick={handleGoBack} disabled={step === 0}>
          <ArrowLeft height={20} width={20} />
        </BackButton>
        <div style={{ marginLeft: "auto" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
            height: 40,
            paddingRight: 4,
            paddingLeft: 4,
            overflow: "hidden",
            maxWidth: 168,
          }}
        >
          <span
            style={{
              fontSize: 8,
              textTransform: "uppercase",
              fontWeight: "300",
              marginBottom: -3,
            }}
          >
            {companyName}
          </span>
          <span
            style={{
              fontWeight: "700",
              fontSize: 11,
              marginBottom: -2,
              textAlign: "right",
              lineHeight: 1.4,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {data.name}
          </span>
          <span style={{ fontSize: 10, color: "#337ab7", fontWeight: "600" }}>
            {step === 0 ? "Overview" : step === 1 ? "Content" : "Questions"}
          </span>
        </div>
        <img
          src={data?.image}
          style={{
            height: 38,
            width: 38,
            objectFit: "contain",
            minWidth: 38,
            flexBasis: 38,
            borderRadius: 10,
            border: "1px solid #eeeeee",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
          }}
        />
      </div>
    </div>
  );
};

export default MockHeader;
