/* eslint-disable */
import React, { useContext } from "react";
import InfoAlert from "./InfoAlert";
import { getUserType } from "../../../../../storage/helpers";
import { GlobalState } from "../../../../../store/GlobalState";
import PrivateHeader from "../../../../../components/PrivateHeader";
import AudienceTool from "../../../../../components/audience/AudienceTool";

const handleFormatAudience = (audience) => {
  if (audience?.mappingv2 === null) {
    return {
      audience: {},
      audience_modifiable_by_managers: false,
      audience_type: { raw: "O", formatted: "Other" },
    };
  }

  const newAudienceObj = {};

  audience.mapping[0]?.locations.forEach((l) => {
    newAudienceObj[l.id] = l.roles;
  });

  return {
    audience: newAudienceObj,
    audience_modifiable_by_managers: false,
    audience_type: { raw: "O", formatted: "Other" },
  };
};

const Retail = ({ audience }) => {
  const { state } = useContext(GlobalState);
  const { config } = state;
  const userType = getUserType(config);
  const formattedAudience = handleFormatAudience(audience);

  return (
    <>
      <PrivateHeader header="Audience Template" secondary={audience?.name} />
      <div style={{ minHeight: 240, backgroundColor: "transparent" }}>
        <div style={{ position: "relative", paddingBottom: 60 }}>
          <InfoAlert />
          <div style={{ height: 8 }} />
          <AudienceTool
            moduleId={null}
            userType={userType}
            isTemplate
            templateId={audience.id}
            templateAudience={formattedAudience}
          />
        </div>
      </div>
    </>
  );
};

export default Retail;
