/* eslint-disable */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { MenuItem, Paper } from "@material-ui/core";
import Overview from "./Overview";
import { TextField, Typography } from "../../styles";
import CompanyBreakdown from "./CompanyBreakdown";
import QuizBreakdown from "./QuizBreakdown";
import UsersBreakdown from "./UsersBreakdown";
import ContentRequests from "./ContentRequests";
import FeedbackReport from "./FeedbackReport";

const VENDOR_REPORT_KEY = "spiffyVRS";

const ReportBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const ReportWrapper = styled(Paper)`
  padding: 20px 20px 20px 20px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const VENDOR_REPORTS = {
  OVERVIEW: "OVERVIEW",
  COMPANY_BREAKDOWN: "COMPANY_BREAKDOWN",
  MODULE_BREAKDOWN: "MODULE_BREAKDOWN",
  USERS_BREAKDOWN: "USERS_BREAKDOWN",
  // CUSTOM_BREAKDOWN: "CUSTOM_BREAKDOWN",
  CONTENT_REQUESTS: "CONTENT_REQUESTS",
  FEEDBACK_REPORT: "FEEDBACK_REPORT",
};

const availableReports = [
  {
    name: "Overview",
    id: VENDOR_REPORTS.OVERVIEW,
  },
  {
    name: "Company Overview Report",
    id: VENDOR_REPORTS.COMPANY_BREAKDOWN,
  },
  {
    name: "Module Overview Report",
    id: VENDOR_REPORTS.MODULE_BREAKDOWN,
  },
  {
    name: "User Overview Report",
    id: VENDOR_REPORTS.USERS_BREAKDOWN,
  },
  {
    name: "Content Requests",
    id: VENDOR_REPORTS.CONTENT_REQUESTS,
  },
  {
    name: "Feedback Report",
    id: VENDOR_REPORTS.FEEDBACK_REPORT,
  },
];

const ALL_REPORTS = {
  OVERVIEW: Overview,
  COMPANY_BREAKDOWN: CompanyBreakdown,
  MODULE_BREAKDOWN: QuizBreakdown,
  USERS_BREAKDOWN: UsersBreakdown,
  CONTENT_REQUESTS: ContentRequests,
  FEEDBACK_REPORT: FeedbackReport,
};

const VendorReports = () => {
  const [report, setReport] = useState(null);

  useEffect(() => {
    if (!report) return null;

    return localStorage.setItem(VENDOR_REPORT_KEY, report);
  }, [report]);

  useEffect(() => {
    const item = localStorage.getItem(VENDOR_REPORT_KEY);
    const availReports = Object.keys(ALL_REPORTS);

    if (item && availReports.includes(item)) {
      return setReport(item);
    }
    setReport(availableReports[0].id);
  }, []);

  if (!report) return null;

  const SelectedReport = ALL_REPORTS[report];

  return (
    <>
      <ReportWrapper>
        <Typography variant="h5" mb={4}>
          Choose Report Type
        </Typography>
        <TextField
          select
          label="Available Reports"
          value={report}
          onChange={(e) => setReport(e.target.value)}
          fullWidth
          variant="outlined"
        >
          {availableReports.map((rep) => (
            <MenuItem key={rep.id} value={rep.id} style={{ height: 36 }}>
              {rep.name}
            </MenuItem>
          ))}
        </TextField>
      </ReportWrapper>
      <ReportBody>
        <SelectedReport />
      </ReportBody>
    </>
  );
};

export default VendorReports;
