/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import BuilderRadio from './BuilderRadio';
import QuestionTF from './Add/QuestionTF';
import QuestionSA from './Add/QuestionSA';
import QuestionMC from './Add/QuestionMC';
import { QUESTION_TYPES } from '../../../../storage/constants';
import EditQuestion from './Edit';
import { Modal } from '@material-ui/core';
import ACTIONS from './state/builderActions';
import { CheckCircle } from 'react-feather';
// import QuestionTabs from "./QuestionTabsExperimental";

const ComponentOptions = {
  [QUESTION_TYPES.TRUE_FALSE]: QuestionTF,
  [QUESTION_TYPES.MULTIPLE_CHOICE]: QuestionMC,
  [QUESTION_TYPES.SELECT_ALL]: QuestionSA,
};

const Quizbuilder = ({
  id,
  isModuleLive,
  details,
  builderContext,
  setSnackbarText,
  mutate,
  isModuleConvertingNextStateLive,
}) => {
  const { state, dispatch } = builderContext;
  const { editInfo, quizType } = state;
  const { isEditing } = editInfo;

  const QuestionComponent = ComponentOptions[quizType];

  return (
    <>
      <BuilderRadio
        builderContext={builderContext}
        isModuleLive={isModuleLive}
        isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
      />
      {/* <QuestionTabs /> */}
      {!isModuleLive && !isModuleConvertingNextStateLive ? (
        <QuestionComponent
          id={id}
          setSnackbarText={setSnackbarText}
          questions={details.questions.questions}
          builderContext={builderContext}
          details={details}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        />
      ) : (
        <div
          style={{
            display: 'flex',

            minHeight: 464,
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            flexDirection: 'column',
            flex: 1,
            borderRadius: 4,
            zIndex: 10,
            height: '100%',
            width: '100%',
            background: '#eeeeee',
          }}
        >
          <CheckCircle
            style={{ color: 'seagreen' }}
            height={20}
            width={20}
          />
          <div style={{ height: 4 }} />

          <span style={{ fontWeight: '600', color: 'black', fontSize: 14 }}>Module Is Live</span>
          <div style={{ height: 8 }} />
          <span style={{ color: 'darkgrey' }}>New questions cannot be added to live modules</span>
          <span style={{ color: 'darkgrey' }}>
            <b
              style={{
                color: 'darkgrey',
              }}
            >
              Questions can only be edited
            </b>
          </span>
        </div>
      )}
      <Modal
        open={isEditing}
        onClose={() => dispatch({ type: ACTIONS.CLEAR_EDIT })}
      >
        <div>
          <EditQuestion
            builderContext={builderContext}
            questions={details?.questions?.questions}
            details={details}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isModuleLive={isModuleLive}
            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          />
        </div>
      </Modal>
    </>
  );
};

export default Quizbuilder;

Quizbuilder.propTypes = {
  builderContext: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
