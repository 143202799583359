/* eslint-disable */
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Popover, IconButton, Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { ChevronDown, User } from "react-feather";
import { StyledLink } from "../styles";

const CustomIcon = styled(IconButton)`
  position: relative;
  background-color: ${(props) =>
    props.isactive === "Yes" ? props.theme.palette.grey[200] : "transparent"};

  svg {
    height: 16px;
    width: 16px;
    color: ${(props) =>
      props.isactive === "Yes"
        ? props.theme.palette.primary.light
        : props.theme.palette.grey[500]};
  }
`;

const InnerWrapper = styled(Paper)`
  min-height: 40px;
  min-width: 100px;
  padding: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
`;

const ShowUserLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: black;
  font-size: 12px;

  &:active {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.palette.info.light};
  }
`;

const MultipleLocationPopper = ({ teamMembers, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <Wrapper>
        <span style={{ marginRight: 2 }}>{name}</span>
        <CustomIcon
          onClick={(e) => {
            setAnchorEl(e.target);
          }}
          size="small"
          isactive={open ? "Yes" : "No"}
        >
          <ChevronDown />
        </CustomIcon>
      </Wrapper>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <InnerWrapper>
          {teamMembers.map((member) => (
            <StyledLink
              key={member.location_name}
              to={`/teammembers/detail/${member.team_member_id}`}
              CustomIcon={User}
              value={`${name} | ${member.location_name}`}
              small
            />
          ))}
        </InnerWrapper>
      </Popover>
    </>
  );
};

export default MultipleLocationPopper;

MultipleLocationPopper.propTypes = {
  data: PropTypes.object.isRequired,
  showLocations: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
