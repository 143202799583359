/* eslint-disable */
import React, { useState } from "react";
import { Typography, TextField, Button } from "../../styles";
import API from "../../../../axios/instances/API";
import { CircularProgress, Paper } from "@material-ui/core";
import styled from "styled-components/macro";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(600px, 100vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
  display: flex;
  flex-direction: column;
`;

const cleanMappingForApi = (mapping) => {
  const cleanLocations =
    !mapping || !mapping.locations
      ? []
      : mapping.locations.map((loc) => ({
          id: loc.id,
          roles: loc.roles.map((r) => r.id),
        }));

  return {
    select_all: false,
    role_override: [],
    locations: cleanLocations,
  };
};

const EditNameModal = ({
  handleClose,
  setSnackbarText,
  mutate,
  audience,
  isVendor = false,
}) => {
  const [name, setName] = useState(audience.name);
  const [updating, setUpdating] = useState(false);

  const handleUpdateRetail = async () => {
    const sendObj = {
      id: audience.id,
      name,
      mapping: cleanMappingForApi(audience.mapping[0]),
    };

    try {
      setUpdating(true);
      await API.put("dashboard/audiences/details/", sendObj);
      await mutate();
      setUpdating(false);
      setSnackbarText("Audience name updated success");
      handleClose();
    } catch (error) {
      setUpdating(false);
      setSnackbarText("Failed to update audience name error");
    }
  };

  const handleUpdateVendor = async () => {
    const sendObj = {
      id: audience.id,
      name,
      mapping: audience.rawMapping,
    };

    try {
      setUpdating(true);
      await API.put(`dashboard/audiences/details/?/id=${audience.id}`, sendObj);
      await mutate();
      setUpdating(false);
      setSnackbarText("Audience name updated success");
      handleClose();
    } catch (error) {
      setUpdating(false);
      setSnackbarText("Failed to update audience name error");
    }
  };

  return (
    <ModalWrapper>
      <Typography variant="h3">Edit Audience Name</Typography>
      <TextField
        fullWidth
        variant="outlined"
        mt={6}
        mb={4}
        size="small"
        label="New Audience Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button
        variant="contained"
        color="secondary"
        disabled={
          !name || name.length < 3 || name === audience.name || updating
        }
        startIcon={updating ? <CircularProgress size={30} /> : null}
        onClick={isVendor ? handleUpdateVendor : handleUpdateRetail}
        style={{ marginLeft: "auto" }}
      >
        Save
      </Button>
    </ModalWrapper>
  );
};

export default EditNameModal;
