import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { IconButton, Tooltip, Drawer } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { Typography, Divider } from '../../pages/private/styles';
import Admin from './Admin';
import Quizzes from './Quizzes';
import QuizBuilder from './QuizBuilder';
import { GlobalState } from '../../store/GlobalState';
import Followers from './Followers';

const DrawerContentWrapper = styled.div`
  min-width: 400px;
  width: 46vw;
  max-width: 720px;
  padding: 40px;
  text-align: left;
  padding-top: 80px;
`;

const SplitHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${props => props.theme.palette.info.light};
    transform: translateY(-1px);
  }
`;

const CustomButton = styled(IconButton)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: rgba(1, 1, 1, 0.04);
  border: 1px solid #eeeeee;
  z-index: 10;

  svg {
    height: 24px;
    width: 24px;
    color: ${props => props.theme.palette.grey[400]};
    transition: all 200ms ease-in;
  }

  &:hover {
    background-color: #eeeeee;
    svg {
      color: ${props => props.theme.palette.info.light};
      height: 25px;
      width: 25px;
    }
  }
`;

const AboutThisPage = ({ pageTitle, tipText }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const location = useLocation();
  const { state } = useContext(GlobalState);
  const { config } = state;
  const { isRetail } = config;

  const getElement = pathname => {
    if (pathname === '/admin/') return Admin;
    if (pathname === '/modules/') return Quizzes;
    if (pathname.includes('/quiz-builder')) return QuizBuilder;
    if (pathname === '/followers/') return Followers;
    return null;
  };

  const Element = getElement(location.pathname);

  return (
    <>
      <Tooltip
        title={tipText}
        placement="left"
      >
        <CustomButton onClick={() => setIsInfoOpen(p => !p)}>
          <HelpOutline />
        </CustomButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
      >
        <DrawerContentWrapper>
          <SplitHeader>
            <Typography
              variant="h3"
              mb={2}
            >
              {pageTitle}
            </Typography>
            <HelpOutline />
          </SplitHeader>
          <Divider mb={6} />
          <Element isRetail={isRetail} />
        </DrawerContentWrapper>
      </Drawer>
    </>
  );
};

export default AboutThisPage;

AboutThisPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  tipText: PropTypes.string.isRequired,
};
