/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import API from "../../../axios/instances/API";
import { Wrapper } from "../auth/styles";
import { TextField, Button, Typography, Alert } from "../../private/styles";
import DashboardSignupHeader from "./DashboardSignupHeader";
import FirstNameField from "../Inputs/FirstNameField";
import LastNameField from "../Inputs/LastNameField";
import EmailField from "../Inputs/EmailField";
import PasswordField from "../Inputs/PasswordField";
import ConfirmPasswordField from "../Inputs/ConfirmPasswordField";
import CountryAndStateFields from "../Inputs/CountryAndStateFields";
import DobField from "../Inputs/DobField";
import ReferralCodeField from "../Inputs/ReferralCodeField";
import ExplanationTooltip from "./ExplanationTooltip";

const Spacer = styled.div`
  height: 12px;
`;

const Subtitle = styled(Typography)`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: center;
`;
const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const validationSchema = Yup.object({
  firstName: Yup.string("Enter your First Name").required(
    "First name is required"
  ),
  lastName: Yup.string("Enter your Last Name").required(
    "Last name is required"
  ),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  ref: Yup.string(),
  password: Yup.string("Enter your password")
    .min(8, "New Password must be 8 characters long")
    .required("Password is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.number().required("State/Province is required"),
  dob: Yup.date().required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Confirm password is required")
    .min(8, "Password should be at least 8 characters"),
});

const STATUS_OPTIONS = {
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED",
  UNKNOWN: "UNKNOWN",
};

const RegisterInfoForm = ({ dispatch, ACTIONS, signupState }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const ref = params.has("ref") ? params.get("ref") : "";
  const dbReferral = params.has("referral") ? params.get("referral") : "";

  const { registerInfo } = signupState;

  const [errorAlert, setErrorAlert] = useState("");

  const [emailStatus, setEmailStatus] = useState(STATUS_OPTIONS.UNKNOWN);
  const [referralStatus, setReferralStatus] = useState(STATUS_OPTIONS.UNKNOWN);

  const initialValues = {
    firstName: registerInfo.firstName || "",
    lastName: registerInfo.lastName || "",
    email: registerInfo.email || "",
    password: registerInfo.password || "",
    confirmPassword: registerInfo.confirmPassword || "",
    ref: registerInfo.ref || dbReferral || ref || "",
    country: registerInfo.country || "",
    state: registerInfo.state || "",
    dob: registerInfo.dob || null,
  };

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    dispatch({
      type: ACTIONS.UPDATE_REGISTER,
      payload: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        password: values.password.trim(),
        confirmPassword: values.confirmPassword.trim(),
        email: values.email.trim(),
        ref: values.ref.trim(),
        dob: values.dob,
        country: values.country,
        state: values.state,
      },
    });
  };

  const isContinueButtonDisabled = (errors, values, isSubmitting) => {
    if (isSubmitting) return true;

    const hasErrors = Object.values(errors).length > 0;

    if (hasErrors) return true;

    if (emailStatus !== STATUS_OPTIONS.CONFIRMED) return true;

    const allValuesPresent = Boolean(
      values.firstName &&
        values.lastName &&
        values.email &&
        values.password &&
        values.confirmPassword &&
        values.country &&
        values.dob &&
        values.state
    );

    if (!allValuesPresent) return true;

    return false;
  };

  return (
    <>
      <Wrapper>
        <DashboardSignupHeader
          handleBack={() => dispatch({ type: ACTIONS.RETURN_TO_SELECT_PATH })}
          title="Dashboard User"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Subtitle>--- Account Details ---</Subtitle>
              <EmailField
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                emailStatus={emailStatus}
                setEmailStatus={setEmailStatus}
                verifyEmail
              />
              <Spacer />
              <FlexDiv>
                <PasswordField
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                />

                <ConfirmPasswordField
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                />
              </FlexDiv>
              <Spacer />
              <Spacer />
              <Subtitle>--- User Details ---</Subtitle>
              {/* <Spacer /> */}
              <Spacer />
              <FlexDiv>
                <FirstNameField
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                />
                <LastNameField
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                />
              </FlexDiv>
              <Spacer />
              <CountryAndStateFields
                handleChange={handleChange}
                values={values}
                handleBlur={handleBlur}
                columns
                setFieldValue={setFieldValue}
              />
              <Spacer />

              <DobField values={values} setFieldValue={setFieldValue} />
              <ExplanationTooltip
                title="Why do we need this?"
                text="Some of our modules are age-restricted, and your date of birth helps us ensure compliance with legal restrictions. Your data is kept confidential."
              />
              <Spacer />
              <ReferralCodeField
                touched={touched}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                verifyCode
                referralStatus={referralStatus}
                setReferralStatus={setReferralStatus}
              />

              <Button
                mt={6}
                mb={2}
                p={2}
                variant="contained"
                color="secondary"
                fullWidth
                type="submit"
                disabled={isContinueButtonDisabled(
                  errors,
                  values,
                  isSubmitting
                )}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? "Submitting" : "Continue"}
              </Button>
            </form>
          )}
        </Formik>
        {errorAlert.length > 0 && (
          <Alert severity="error" onClose={() => setErrorAlert("")}>
            {errorAlert}
          </Alert>
        )}
      </Wrapper>
    </>
  );
};

export default RegisterInfoForm;

RegisterInfoForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  signupState: PropTypes.object.isRequired,
};
