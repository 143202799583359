/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  Avatar,
  Button,
} from "@material-ui/core";
import { QUESTION_TYPES } from "../../../../../storage/constants";
import API from "../../../../../axios/instances/API";

const QHolder = styled.div`
  min-height: 320px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
`;

const QuestionAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  background-color: ${(props) =>
    props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

const MultipleChoice = ({
  targetQuestion,
  details,
  mutate,
  setSnackbarText,
  handleClose,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const [question, setQuestion] = useState(targetQuestion?.question);
  const [correctAnswer, setCorrectAnswer] = useState(
    targetQuestion?.correctAnswer
  );
  const [options, setOptions] = useState(targetQuestion.options);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changesMade =
    correctAnswer !== targetQuestion?.correctAnswer ||
    question !== targetQuestion?.question ||
    JSON.stringify(Object.values(targetQuestion?.options)) !==
      JSON.stringify(Object.values(options));

  const optionsWithText = Object.values(options).filter(
    (option) => option.length >= 1
  );

  const isDisabled =
    isSubmitting ||
    question?.length <= 2 ||
    !changesMade ||
    optionsWithText.length < 4;

  const isAnswerKeyDisabled = isModuleLive || isModuleConvertingNextStateLive;

  const handleRadioChange = (e) => {
    setCorrectAnswer(e.target.value);
  };

  const handleOptions = (e) => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleSaveEdit = async () => {
    try {
      setIsSubmitting(true);
      const newQuestions = [...details?.questions?.questions];
      newQuestions.splice(targetQuestion?.order, 1, {
        id: targetQuestion?.id,
        title: "none",
        question,
        correctAnswer,
        type: QUESTION_TYPES.MULTIPLE_CHOICE,
        options,
      });

      const sendObj = {
        id: details?.id,
        pass_score: details?.passScore,
        questions: newQuestions,
      };
      await API.put("dashboard/quiz/questions/", sendObj);
      await mutate();

      setIsSubmitting(false);
      setSnackbarText("Question edited success");

      handleClose();
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to edit question error");
      handleClose();
    }
  };

  return (
    <>
      <QHolder>
        <div style={{ height: 6 }} />

        <Typography variant="subtitle2">Question</Typography>
        <div style={{ height: 12 }} />

        <TextField
          type="text"
          size="small"
          variant="outlined"
          multiline
          value={question}
          onChange={(e) => setQuestion(e.currentTarget.value)}
          fullWidth
        />
        <div style={{ height: 16 }} />

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <Typography variant="subtitle2" style={{ alignSelf: "flex-start" }}>
            Options
          </Typography>
          {["A", "B", "C", "D"].map((letter) => (
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                type="text"
                size="small"
                variant="outlined"
                color="secondary"
                placeholder={`Answer ${letter}`}
                name={letter.toLowerCase()}
                value={options[letter.toLowerCase()]}
                onChange={handleOptions}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QuestionAvatar
                        activecolor={
                          correctAnswer === letter ? "Yes" : "normal"
                        }
                      >
                        <span style={{ fontSize: 14 }}>{letter}</span>
                      </QuestionAvatar>
                    </InputAdornment>
                  ),
                }}
              />
              <Checkbox
                checked={correctAnswer === letter}
                onChange={handleRadioChange}
                value={letter}
                disabled={isAnswerKeyDisabled}
              />
            </div>
          ))}
        </div>
      </QHolder>
      <div style={{ height: 12 }} />
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          marginTop: "auto",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          onClick={handleSaveEdit}
        >
          {isSubmitting ? "Saving..." : "Save Edit"}
        </Button>
      </div>
    </>
  );
};

export default MultipleChoice;
