/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url, locationId) => {
  const { data } = await API.get( locationId ? `${url}?location_id=${locationId}` : url);
  return data;
};

const useSupervisorHome = (locationId = null) => {
  const { data, error, mutate } = useSWR(['/dashboard/home/', locationId], fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSupervisorHome;