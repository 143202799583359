/* eslint-disable */

import React from "react";
import { CustomDatatableV2, StyledLink } from "../../../styles";
import TableTitle from "../../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { Briefcase } from "react-feather";
import styled from "styled-components/macro";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const Wrapper = styled.div`
  flex: 0.5;
`;

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "company",
    label: "Company",
    options: {
      filter: false,
      sort: false,
      display: true,
      download: true,
      customBodyRender: (value, tableMeta) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 6,
          }}
        >
          <img
            src={tableMeta.rowData[3]}
            style={{
              border: "1px solid #eeeeee",
              height: 30,
              width: 30,
              borderRadius: 4,
              objectFit: "contain",
            }}
          />
          <StyledLink
            value={value}
            to={`/vendor-reports/company/${tableMeta.rowData[0]}`}
          />
        </div>
      ),
    },
  },

  {
    name: "count",
    label: "Count",
    options: {
      filter: false,
      sort: true,
      display: true,
      download: true,
    },
  },
  {
    name: "companyImage",
    label: "",
    options: {
      filter: false,
      sort: true,
      display: false,
      download: true,
    },
  },
];

const CompanyTable = ({ data }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("content-requests-by-company");

  options.sortOrder = {
    name: "count",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = Object.values(data).map((company) => ({
    id: company.id,
    company: company.name,
    count: company.count,
    companyImage: company.logo,
  }));

  return (
    <Wrapper>
      <CustomDatatableV2
        options={options}
        data={tableData}
        columns={columns}
        title={
          <TableTitle title="Content Requests By Company" Icon={Briefcase} />
        }
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </Wrapper>
  );
};
export default CompanyTable;
