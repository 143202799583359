/* eslint-disable */
import React from "react";
import { Alert } from "../../../../private/styles";
import { AlertTitle } from "@material-ui/lab";

const InfoAlert = () => {
  return (
    <Alert severity="info" mb={4}>
      <AlertTitle style={{ fontWeight: "600" }}>
        How can i use audience templates?
      </AlertTitle>

      {/* <div style={{ height: 12 }} /> */}
      <ul style={{ marginTop: 2 }}>
        <li style={{ fontSize: 12 }}>
          {" "}
          Audience templates are used to create{" "}
          <b>unique combinations of locations and roles</b>.
        </li>
        <li style={{ fontSize: 12 }}>
          {" "}
          Once a template is saved, it can be imported in the audience sections
          of the module builder.
        </li>

        <li style={{ fontSize: 12 }}>
          When a template is assigned to an audience, all team members who are
          included in the assigned location/role combo will receive the module.
        </li>
        {/* <li>
          Audience templates are used to create pre-saved groups of team
          members, so you quickly and easily communicate with them when building
          modules in the future. For example, “all Managers in the Northeast
          region.”
        </li>
        <li>
          You can add and remove groups by role or location, or get granular by
          ticking one box at a time For example, “Managers only, only at the
          Spiffsville location.”
        </li> */}
      </ul>
    </Alert>
  );
};

export default InfoAlert;
