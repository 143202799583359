/* eslint-disable */
import React, { useState } from 'react';
import { Button, Typography, TextField, CircularProgress } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Save } from 'react-feather';
import { lighten } from 'polished';
import API from '../../../../../axios/instances/API';
import { QUESTION_TYPES } from '../../../../../storage/constants';

const AnswerButton = styled(Button)`
  min-width: 100px;
  background-color: ${props =>
    props.isactive === 'Y'
      ? props.theme.palette.info.light
      : lighten(0.28, props.theme.palette.info.light)};
  &:hover {
    background-color: ${props => props.theme.palette.info.light};
  }
`;

const QHolder = styled.div`
  min-height: 100px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
`;
const TrueFalse = ({
  targetQuestion,
  details,
  mutate,
  setSnackbarText,
  handleClose,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const [question, setQuestion] = useState(targetQuestion?.question);
  const [answer, setAnswer] = useState(targetQuestion.correctAnswer === 'True' ? 'T' : 'F');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSaveEdit = async () => {
    try {
      setIsSubmitting(true);
      const newQuestions = [...details?.questions?.questions];

      newQuestions.splice(targetQuestion?.order, 1, {
        id: targetQuestion?.id,
        title: 'none',
        question,
        correctAnswer: answer === 'T',
        type: QUESTION_TYPES.TRUE_FALSE,
        options: null,
      });

      const sendObj = {
        id: details?.id,
        pass_score: details?.passScore,
        questions: newQuestions,
      };
      await API.put('dashboard/quiz/questions/', sendObj);
      await mutate();
      setIsSubmitting(false);
      setSnackbarText('Question edited success');
      handleClose();
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText('Failed to edit question error');
      handleClose();
    }
  };

  const changesMade =
    question !== targetQuestion?.question ||
    (answer === 'T' && targetQuestion?.correctAnswer !== 'True') ||
    (answer === 'F' && targetQuestion?.correctAnswer !== 'False');

  const isDisabled = question.length <= 2 || !changesMade || isSubmitting;

  const isAnswerKeyDisabled = isModuleLive || isModuleConvertingNextStateLive;

  return (
    <>
      <QHolder>
        <div style={{ height: 6 }} />
        <Typography variant="subtitle2">Question</Typography>
        <div style={{ height: 12 }} />

        <TextField
          type="text"
          size="small"
          variant="outlined"
          color="secondary"
          multiline
          value={question}
          onChange={e => setQuestion(e.currentTarget.value)}
          fullWidth
        />
        <div style={{ height: 16 }} />

        <Typography
          variant="subtitle2"
          mt={10}
        >
          Answer <small style={{ color: 'darkgrey' }}>(select one)</small>
        </Typography>
        <div style={{ height: 12 }} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 12,
          }}
        >
          <AnswerButton
            isactive={answer === 'T' ? 'Y' : 'N'}
            variant={answer === 'T' ? 'contained' : 'outlined'}
            onClick={() => setAnswer('T')}
            mt={2}
            color="inherit"
            disabled={isAnswerKeyDisabled}
          >
            True
          </AnswerButton>
          <AnswerButton
            isactive={answer === 'F' ? 'Y' : 'N'}
            variant={answer === 'F' ? 'contained' : 'outlined'}
            onClick={() => setAnswer('F')}
            mt={2}
            color="inherit"
            disabled={isAnswerKeyDisabled}
          >
            False
          </AnswerButton>
        </div>
        <div style={{ height: 8 }} />
      </QHolder>
      <div style={{ height: 12 }} />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          gap: 12,
          marginTop: 'auto',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          onClick={handleSaveEdit}
        >
          {isSubmitting ? 'Saving...' : 'Save Edit'}
        </Button>
      </div>
    </>
  );
};

export default TrueFalse;
