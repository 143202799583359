/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ChevronDown, Info } from "react-feather";
import styled from "styled-components/macro";
import { Typography } from "../../styles";

const SpecHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WideList = styled.ul`
  width: 100%;
`;

const Question = styled(Typography)`
  font-weight: 500;
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  padding-left: 22px;
  border-radius: 4px;
  border: none;
  // background: #eeeeee88;
  // border: 1px solid #eeeeee;
`;

const VideoSpecs = () => (
  <>
    <li>
      <SpecHolder>
        <Question variant="subtitle2">File Types</Question>
        <Typography variant="body2">
          .avi, .flv, .mov, .mp4(preferred)
        </Typography>
      </SpecHolder>
    </li>
    <li>
      <SpecHolder>
        <Question>File Size</Question>
        <Typography variant="body2">
          Less than 500MB preferred. No more than 800MB.
        </Typography>
      </SpecHolder>
    </li>
  </>
);

const PdfSpecs = () => (
  <>
    <li>
      <SpecHolder>
        <Question>File Types</Question>
        <Typography variant="body2">.pdf only</Typography>
      </SpecHolder>
    </li>
    <li>
      <SpecHolder>
        <Question>Size</Question>
        <Typography variant="body2">Less than 100MB</Typography>
      </SpecHolder>
    </li>
    <li>
      <SpecHolder>
        <Question>Ideal Width/Pixels for Mobile</Question>
        <Typography variant="body2">18cm/1440px</Typography>
      </SpecHolder>
    </li>
    <li>
      <SpecHolder>
        <Question>Ideal Height/Pixels for Mobile</Question>
        <Typography variant="body2">34.7cm/2960px</Typography>
      </SpecHolder>
    </li>
  </>
);

const VideoNotes = () => (
  <>
    <ul>
      <li>
        <Typography style={{ fontSize: 12 }}>
          Videos with 4:5 or 9:16 aspect ratios look best on Spiffy App.
          However, you can upload standard 16:9 landscape videos as well.
        </Typography>
      </li>
      <li>
        <Typography style={{ fontSize: 12 }}>
          Although there is no time limit on the duration of videos, we
          recommend that videos be around 1-2 minutes long. Videos should not be
          longer than 5 minutes.
        </Typography>
      </li>
    </ul>
  </>
);

const PdfNotes = () => (
  <>
    <ul>
      <li>
        <Typography variant="body2">
          PDF files <u>must not</u> contain animations to display properly
          across the application
        </Typography>
      </li>
      <li>
        <Typography variant="body2">
          PDF may fail to upload if the file is not localized. Do not upload
          directly from Microsoft One Drive or Share Drive.
        </Typography>
      </li>
      <li>
        <Typography variant="body2">
          There is no cap on the number of pages in your PDF, but we recommend
          keeping the PDFs on the shorter side to facilitate microlearning.
          Ideally, your PDF is 1-3 pages long.
        </Typography>
      </li>
    </ul>
  </>
);

const Guidelines = ({ uploadOption }) => (
  <>
    <Typography style={{ fontSize: 15, fontWeight: "500" }} mt={4} mb={2}>
      {uploadOption === "P" ? "PDF Guidelines" : "Video Guidelines"}
    </Typography>
    <Accordion>
      <AccordionSummary expandIcon={<ChevronDown height={16} width={16} />}>
        <Typography
          style={{
            fontSize: 13,
            fontWeight: "500",
          }}
        >
          Specifications
        </Typography>
      </AccordionSummary>
      <CustomAccordionDetails>
        <WideList>
          {uploadOption === "P" ? <PdfSpecs /> : <VideoSpecs />}
        </WideList>
      </CustomAccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ChevronDown height={16} width={16} />}>
        <Typography style={{ fontSize: 13, fontWeight: "500" }}>
          Notes
        </Typography>
      </AccordionSummary>
      <CustomAccordionDetails>
        {uploadOption === "P" ? <PdfNotes /> : <VideoNotes />}
      </CustomAccordionDetails>
    </Accordion>
  </>
);

export default Guidelines;

Guidelines.propTypes = {
  uploadOption: PropTypes.string.isRequired,
};
