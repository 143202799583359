/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { mutate as swrMutate } from "swr";
import {
  Grid,
  Radio,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { QUESTION_TYPES } from "../../../../../storage/constants";
import ACTIONS from "../state/builderActions";
import API from "../../../../../axios/instances/API";
import { TextField, Typography, Button } from "../../../styles";
import { Plus } from "react-feather";

const QHolder = styled.div`
  min-height: 420px;
  margin-top: 4px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  background: white;
`;

const SaveButton = styled(Button)`
  margin-top: auto;
`;

const QuestionAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  background-color: ${(props) =>
    props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

const YesRadio = styled(Radio)`
  &.Mui-checked {
    color: #337ab7;
  }
`;

const MGrid = styled(Grid)`
  margin: -8px;
  margin-bottom: 20px;
`;

const initalOptions = {
  a: "",
  b: "",
  c: "",
  d: "",
};

const QuestionMC = ({
  id,
  details,
  setSnackbarText,
  questions,
  builderContext,
  isModuleLive,
  isModuleConvertingNextStateLive,
}) => {
  const { state, dispatch } = builderContext;
  const { editInfo } = state;
  const { isEditing } = editInfo;
  const [question, setQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [options, setOptions] = useState(initalOptions);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetOptions = () => {
    setQuestion("");
    setCorrectAnswer("");
    setOptions(initalOptions);
  };

  const handleSaveQuestion = async () => {
    setIsSubmitting(true);

    const sendObj = {
      id,
      pass_score: details.passScore,
      questions: [
        ...questions,
        {
          id: null,
          title: "none",
          question,
          type: QUESTION_TYPES.MULTIPLE_CHOICE,
          correctAnswer,
          options,
        },
      ],
    };

    try {
      await API.put("dashboard/quiz/questions/", sendObj);
      resetOptions();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      setIsSubmitting(false);
      if (isEditing) {
        dispatch({ type: ACTIONS.CLEAR_EDIT });
        return setSnackbarText("Question successfully edited success");
      }
      return setSnackbarText("Question successfully added success");
    } catch (error) {
      setIsSubmitting(false);
      return setSnackbarText("Failed to add question. Please try again error");
    }
  };

  const handleRadioChange = (e) => {
    setCorrectAnswer(e.target.value);
  };

  const handleOptions = (e) => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleIsDisabled = () => {
    const vals = Object.values(options);
    const optionsFilled = vals.every((val) => String(val).length > 0);
    return (
      !(optionsFilled && correctAnswer.length > 0 && question.length > 2) ||
      isSubmitting ||
      isModuleConvertingNextStateLive
    );
  };

  return (
    <QHolder>
      <Typography variant="h4" mt={4} mb={4}>
        Multiple Choice{" "}
      </Typography>
      <Typography variant="subtitle2" mb={1} mt={2}>
        Your Question
      </Typography>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            type="text"
            size="small"
            variant="outlined"
            multiline
            value={question}
            onChange={(e) => setQuestion(e.currentTarget.value)}
            fullWidth
            mb={2}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="subtitle2" mb={1} mt={2}>
            Your Answers
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" mb={1} mt={2} align="center">
            Answer Key
          </Typography>
        </Grid>
      </Grid>
      <MGrid container spacing={4} alignItems="center">
        {["A", "B", "C", "D"].map((letter) => (
          <Grid item xs={12} key={letter}>
            <Grid container>
              <Grid item xs={10}>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  placeholder={`Answer ${letter}`}
                  name={letter.toLowerCase()}
                  value={options[letter.toLowerCase()]}
                  onChange={handleOptions}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QuestionAvatar
                          activecolor={
                            correctAnswer === letter ? "Yes" : "normal"
                          }
                        >
                          <span style={{ fontSize: 14 }}>{letter}</span>
                        </QuestionAvatar>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} align="center">
                <YesRadio
                  value={letter}
                  onClick={handleRadioChange}
                  checked={correctAnswer === letter}
                  disabled={isModuleLive}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </MGrid>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",

          justifyContent: " flex-start",
          gap: 12,
        }}
      >
        <SaveButton
          // fullWidth
          variant="contained"
          style={{ minWidth: 140 }}
          color="secondary"
          disabled={handleIsDisabled()}
          onClick={handleSaveQuestion}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <Plus height={14} width={14} color="white" />
            )
          }
        >
          {isSubmitting ? "Saving Question" : "Add Question"}
        </SaveButton>
      </div>
    </QHolder>
  );
};

export default QuestionMC;

QuestionMC.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  builderContext: PropTypes.object.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
};
