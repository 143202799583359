/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LoadMoreWrapper,
  LoadingMore,
} from "../../../marketplace/marketplaceStyles";
import useRetailReportsLocationList from "../../../../../swr/hooks/RetailReports/useRetailReportsLocationList";
import LocationsTable from "./LocationsTable";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import Loader from "../../vendorReports/Loader";
import DateRange from "../../filter/DateRange";

const LocationsReport = ({ isLte }) => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const [filterDateString, setFilterDateString] = useState(null);

  const { locations, isLoading, isError } = useRetailReportsLocationList(
    filterDateString?.queryString || null
  );
  if (isError) return null;

  if (isLoading) return <Loader />;

  return (
    <>
      <div style={{ height: 12 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
        <DateRange
          setEngagementType={setEngagementType}
          setFilterDateString={setFilterDateString}
        />
      </div>
      <div style={{ height: 12 }} />
      <LocationsTable
        filterDateString={filterDateString}
        locations={locations}
        isLte={isLte}
        engagementType={engagementType}
        setFilterDateString={setFilterDateString}
      />
    </>
  );
};

export default LocationsReport;

LocationsReport.propTypes = {
  isLte: PropTypes.bool.isRequired,
};
