/* eslint-disable */
import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { Phone, Mail, User, Trash } from "react-feather";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  Button,
  Typography,
  Question,
  Divider,
  Answer,
  Wrapper,
} from "../../styles";
import ConfirmDeleteManager from "./ConfirmDeleteManager";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";

const IconHolder = styled.div`
  margin-right: 8px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const BlueMail = styled(Mail)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const BlueUser = styled(User)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const BluePhone = styled(Phone)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const About = ({ manager, mutate }) => {
  const [snackbarText, setSnackbarText] = useState("");
  const [deleteInfo, setDeleteInfo] = useState(null);

  return (
    <>
      <Wrapper>
        <Typography mb={1} variant="h3">
          About
        </Typography>
        <Divider mb={4} />
        <InfoContainer>
          <IconHolder>
            <BlueUser />
          </IconHolder>
          <Question>Name:</Question>
          <Answer>{`${manager.firstName} ${manager.lastName}`}</Answer>
        </InfoContainer>
        <InfoContainer>
          <IconHolder>
            <BlueMail />
          </IconHolder>
          <Question>Email:</Question>
          <Answer>{manager.email}</Answer>
        </InfoContainer>
        <InfoContainer>
          <IconHolder>
            <BluePhone />
          </IconHolder>
          <Question>Phone Number:</Question>
          <Answer>{manager.phoneNumber}</Answer>
        </InfoContainer>
        <Button
          startIcon={<Trash />}
          mt={7}
          fullWidth
          variant="contained"
          color="inherit"
          onClick={() =>
            setDeleteInfo({
              id: manager.id,
              name: `${manager.firstName} ${manager.lastName}`,
            })
          }
        >
          Delete Supervisor
        </Button>
      </Wrapper>
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a Supervisor"
        aria-describedby="Delete a Supervisor"
      >
        <div>
          <ConfirmDeleteManager
            deleteInfo={deleteInfo}
            setConfirmModalOpen={() => setDeleteInfo(null)}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            setDeleteInfo={setDeleteInfo}
            routeBacktoSuperviorPage
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default About;

About.propTypes = {
  manager: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
};
