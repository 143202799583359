/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  // border: 1px solid lightgray;
  height: inherit;
  min-height: 480px;
  min-width: 380px;

  width: 100%;
  flex: 0.5;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  flex-direction: column;
  position: relative;
`;

const HalfPanel = ({ title = 'forgot title', children, RightComponent = null }) => {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 4,
          paddingBottom: 6,
          width: '100%',
        }}
      >
        <span style={{ fontSize: 16, fontWeight: '600', color: 'black' }}>{title}</span>
        {RightComponent ? <RightComponent /> : <div />}
      </div>
      <div style={{ height: 8 }} />
      {children}
    </Wrapper>
  );
};

export default HalfPanel;
