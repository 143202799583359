/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Paper, CircularProgress } from "@material-ui/core";
import { Typography, Divider, CustomLinearProgressMain } from "../../styles";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import useTeamMemberOutstanding from "../../../../swr/hooks/Team/useTeamMemberOutstanding";
// import RadarChart from './RadarChart';
/// ## TODO -> CONSIDER PUTTING RADAR CHART BACK IN ##

const Wrapper = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  border-radius: 6px;
`;

const AttemptsSpan = styled.span`
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  display: block;
`;

const Centered = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0px 8px;
  min-height: 120px;
  flex: 0.5;
  height: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  span {
    color: black;
    font-weight: 800;
    font-size: 18px;
  }
`;

const DISPLAY_TYPES = {
  RADAR: "RADAR",
  BAR: "BAR",
};

const MemberProgress = ({
  userId,
  name,
  firstTryPercentage,
  totalUniqueModules,
  passedFirstTry,
  totalCompletions,
  totalSubmission,
  averageScore,
  engagementType,
  teamMemberId,
}) => {
  // const [displayType, setDisplayType] = useState(DISPLAY_TYPES.BAR);

  const { data, isLoading, isError } = useTeamMemberOutstanding(teamMemberId);

  if (isLoading) {
    return (
      <Centered>
        <CircularProgress size={30} />
      </Centered>
    );
  }

  if (totalSubmission < 10) {
    return (
      <Wrapper>
        <Typography variant="subtitle2">
          Performance stats will show up when employee has 10 submissions.
        </Typography>
      </Wrapper>
    );
  }

  if (isError) {
    return (
      <Centered>
        <AttemptsSpan>Error Loading Team Member Progress</AttemptsSpan>
      </Centered>
    );
  }

  const { summary } = data || {};

  if (!summary) {
    return (
      <Centered>
        <AttemptsSpan>No Team Member Progress Available</AttemptsSpan>
      </Centered>
    );
  }

  const value =
    summary.completed_out_of_assigned === 0
      ? 0
      : Math.floor(
          (summary.completed_out_of_assigned / summary.assigned) * 100
        );

  const passRate =
    totalSubmission > 0
      ? Math.floor((totalCompletions / totalSubmission) * 100)
      : 0;

  // const overallVal = ((value * 2) + passRate + firstTryPercentage + averageScore.averageScore) / 5;

  return (
    <Wrapper
      style={{
        boxShadow:
          engagementType === ENGAGEMENT_TYPES.ASSIGNED
            ? "0px 0px 2px 2px rgba(0, 0, 250, .4)"
            : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
            ? "0px 0px 1px 2px gold"
            : "",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" mb={2} mt={1}>
          Performance Stats
        </Typography>
        <div />
        {/* <TextField
          select
          size="small"
          variant="outlined"
          // style={{ width: 140, height: 32 }}
          value={displayType}
          onChange={(e) => setDisplayType(e.target.value)}
        >
          <MenuItem style={{ height: 30 }} value={DISPLAY_TYPES.BAR}>
            Bar
          </MenuItem>
          <MenuItem style={{ height: 30 }} value={DISPLAY_TYPES.RADAR}>
            Radar
          </MenuItem>
        </TextField> */}
      </div>
      <Divider mb={3} mt={2} />

      <>
        <TopWrapper>
          <Typography variant="subtitle2" mb={1}>
            {`Progress % for ${name}`}
          </Typography>
          <span>{value}%</span>
        </TopWrapper>
        <CustomLinearProgressMain variant="determinate" value={value} />
        <AttemptsSpan>
          {`${summary.completed_out_of_assigned}/${summary.assigned} modules completed`}
        </AttemptsSpan>
        <TopWrapper>
          <Typography variant="subtitle2" mb={1}>
            First Attempt Success %
          </Typography>
          <span>{firstTryPercentage}%</span>
        </TopWrapper>
        <CustomLinearProgressMain
          variant="determinate"
          value={firstTryPercentage}
        />
        <AttemptsSpan>
          {`${passedFirstTry}/${totalUniqueModules} modules passed on first attempt`}
        </AttemptsSpan>
        <TopWrapper>
          <Typography variant="subtitle2" mb={1}>
            Pass Rate
          </Typography>
          <span>{passRate}%</span>
        </TopWrapper>
        <CustomLinearProgressMain variant="determinate" value={passRate} />
        <AttemptsSpan>
          {`${totalCompletions} modules passed, ${totalSubmission} attempts`}
        </AttemptsSpan>
        <TopWrapper>
          <Typography variant="subtitle2" mb={1}>
            Average Score All Time
          </Typography>
          <span>{averageScore.averageScore}%</span>
        </TopWrapper>
        <CustomLinearProgressMain
          variant="determinate"
          value={averageScore.averageScore}
        />
        <AttemptsSpan>
          {`${averageScore.correct} of ${averageScore.questions} questions answered correctly`}
        </AttemptsSpan>
      </>
    </Wrapper>
  );
};

export default MemberProgress;

MemberProgress.propTypes = {
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  firstTryPercentage: PropTypes.number.isRequired,
  totalUniqueModules: PropTypes.number.isRequired,
  passedFirstTry: PropTypes.number.isRequired,
  totalCompletions: PropTypes.number.isRequired,
  totalSubmission: PropTypes.number.isRequired,
  averageScore: PropTypes.object.isRequired,
};

{
  /* // ) : ( */
}
//   <>
//     <RadarChart
//       averageScore={averageScore.averageScore}
//       passRate={passRate}
//       firstTryPercentage={firstTryPercentage}
//       progress={value}
//     />
//   </>
// )}
