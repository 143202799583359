/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Grid } from "@material-ui/core";
import { Tag, Plus } from "react-feather";
import { Typography, Wrapper, Divider, Button } from "../../../styles";
import { RoleChipVendor } from "../../styles";
import CurrentCompaniesTable from "./CurrentCompaniesTable";
import API from "../../../../../axios/instances/API";

const BodyWrapper = styled.div`
  width: 100%;
  min-height: 120px;
  padding: 20px;
`;

const LocationsContainer = styled.div`
  min-width: 400px;
  width: 100%;
  border: 1px solid #eeeeee;
  padding: 20px;
  border-radius: 8px;
`;

const RolesContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #eeeeee;
  padding: 20px;
  border-radius: 8px;
  flex-grow: 1;
`;

const VendorCurrent = ({
  audience,
  setSnackbarText,
  mutate,
  setUseEdit,
  EDIT_STATES,
}) => {
  // eslint-disable-next-line consistent-return
  const handleRemoveRole = async (id, name) => {
    if (audience.mapping.roles.length === 1) {
      return setSnackbarText("You must have at least one role category error");
    }
    const newMapping = {
      companies: audience.mapping.companies.map((company) => company.id),
      roles: audience.mapping.roles
        .filter((role) => role.id !== id)
        .map((role) => role.id),
    };

    try {
      await API.put("dashboard/audiences/details/", {
        id: audience.id,
        mapping: newMapping,
      });
      mutate();
      setSnackbarText(`Successfully removed role "${name}" warning`);
    } catch (error) {
      setSnackbarText(`Failed to remove role "${name}" error`);
    }
  };

  const hasCompanies =
    audience.mapping !== null && audience.mapping.companies.length > 0;
  const hasRoles =
    audience.mapping !== null && audience.mapping.roles.length > 0;

  return (
    <Wrapper>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8}>
          <Typography variant="h3">Custom Audience</Typography>
          <Typography variant="subtitle1">
            Who is included in <em>"{audience.name}"</em>
          </Typography>
        </Grid>
      </Grid>
      <Divider mb={2} />
      <BodyWrapper>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <RolesContainer>
              <Typography variant="h5">Suggested Role Categories</Typography>
              <Typography variant="subtitle1" mb={4}>
                {hasRoles
                  ? "These are your suggested role categories for this audience"
                  : "Please add your suggested role categories for this audience"}
              </Typography>
              <div>
                {hasRoles &&
                  audience.mapping.roles.map((role) => (
                    <RoleChipVendor
                      key={role.id}
                      icon={<Tag />}
                      label={role.name}
                      onDelete={() => handleRemoveRole(role.id, role.name)}
                    />
                  ))}
              </div>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Plus />}
                mt={4}
                onClick={() => setUseEdit(EDIT_STATES.ROLE_CATEGORIES)}
              >
                Add
              </Button>
            </RolesContainer>
          </Grid>
          <Grid item xs={12}>
            <LocationsContainer>
              <Typography variant="h5">Selected Companies</Typography>
              <Typography variant="subtitle1" mb={4}>
                {hasCompanies
                  ? "Below are the companies currently included in the audience"
                  : "Add companies into your audience"}
              </Typography>
              <CurrentCompaniesTable
                hasCompanies={hasCompanies}
                audience={audience}
                setSnackbarText={setSnackbarText}
                mutate={mutate}
              />
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Plus />}
                mt={2}
                disabled={!hasRoles}
                onClick={() => setUseEdit(EDIT_STATES.COMPANIES)}
              >
                Add
              </Button>
            </LocationsContainer>
          </Grid>
        </Grid>
      </BodyWrapper>
    </Wrapper>
  );
};

export default VendorCurrent;

VendorCurrent.propTypes = {
  audience: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  setUseEdit: PropTypes.func.isRequired,
  EDIT_STATES: PropTypes.object.isRequired,
};
