/* eslint-disable */
import { LinearProgress } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import React from "react";
import { CSVLink } from "react-csv";
import { MapPin } from "react-feather";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  min-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CustomLinearProgress = styled(LinearProgress)`
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 8px;
  min-height: 10px;
  width: 158px;
  margin-right: 8px;
  border: 1px solid #eeeeee;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => {
      if (props.value > 80) return props.theme.palette.success.main;
      if (props.value > 60) return props.theme.palette.success.light;
      if (props.value > 40) return props.theme.palette.warning.light;
      if (props.value > 20) return props.theme.palette.warning.main;
      if (props.value > 0) return props.theme.palette.error.main;
      return props.theme.palette.primary.main;
    }};
  }
`;

const CustomCsvLink = styled(CSVLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 28px;
  border: 1px solid #eeeeee;
  width: fit-content;
  padding: 1px 4px;
  border-radius: 4px;

  &:hover {
    background: #eeeeee55;
  }
`;

const Item = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  padding: 4px;
  border-bottom: 1px solid #eeeeee55;
`;

const ProgressByLocation = ({ locationProgress, moduleName }) => {
  const downloadData = locationProgress.map((location) => ({
    "Location Name": location.name,
    "# Of Users Completed Module": location.completed_out_of_assigned,
    "# Of Users Assigned Module": location.assigned,
    "Percentage Of Users Completed Module":
      location.assigned === 0
        ? 0
        : `${Math.floor(
            (location.completed_out_of_assigned / location.assigned) * 100
          )}%`,
  }));

  return (
    <>
      <div style={{ height: 10 }} />

      <Wrapper>
        {locationProgress.map((location) => (
          <Item key={location.id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
              }}
            >
              <MapPin height={11} width={11} color="darkgrey" />
              <span>{location.name}</span>
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 4,
                  // background: "#eeeeee",
                  padding: "0px 6px",
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 16,
                }}
              >
                <span
                  style={{
                    color: "darkgrey",
                    fontSize: 11,
                    fontWeight: "600",
                  }}
                >
                  ({location.completed_out_of_assigned}
                </span>
                <span style={{ color: "darkgrey" }}>/</span>
                <span
                  style={{
                    color: "darkgrey",
                    fontSize: 11,
                    fontWeight: "600",
                  }}
                >
                  {location.assigned}){" "}
                </span>
                <div style={{ width: 4 }} />
                <span
                  style={{
                    color: "black",
                    fontSize: 11,
                    fontWeight: "600",
                    marginLeft: 5,
                  }}
                >
                  {" "}
                  {location.completed_out_of_assigned === 0
                    ? 0
                    : Math.floor(
                        (location.completed_out_of_assigned /
                          location.assigned) *
                          100
                      )}
                  %
                </span>
                <div style={{ width: 4 }} />

                <div style={{ width: 160 }}>
                  <CustomLinearProgress
                    variant="determinate"
                    value={
                      location.completed_out_of_assigned === 0
                        ? 0
                        : Math.floor(
                            (location.completed_out_of_assigned /
                              location.assigned) *
                              100
                          )
                    }
                  />
                </div>
              </div>
              <div style={{ width: 16 }} />
            </div>
          </Item>
        ))}
      </Wrapper>
      <div style={{ height: 6 }} />

      {/* <Button
          style={{ marginLeft: "auto" }}
          size="small"
          variant="outlined"
          // color="secondary"
          startIcon={<CloudDownload CustomCsvLink/>}
          onClick={handleDownload}
        >
          <span style={{ fontSize: 11, fontWeight: "600" }}>Download</span>
        </Button> */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <CustomCsvLink
          data={downloadData}
          filename={`Module - ${moduleName} - Progress By Location`}
          style={{ textDecoration: "none", fontSize: 12, color: "black" }}
        >
          <CloudDownload
            height={4}
            width={4}
            style={{ height: 14, width: 14, color: "slategrey" }}
          />
          <div style={{ width: 6 }} />
          <span style={{ fontWeight: "500", fontSize: 10 }}>Download CSV</span>
        </CustomCsvLink>
      </div>
    </>
  );
};

export default ProgressByLocation;
