/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import VendorModuleAudience from './vendor';
import RetailModuleAudience from './retail';
import { QUIZSTATES } from '../../../../storage/constants';

const Audience = ({ id, details, isRetail, setSnackbarText, steps }) => {
  if (!isRetail) {
    return (
      <VendorModuleAudience
        details={details}
        id={id}
        setSnackbarText={setSnackbarText}
      />
    );
  }

  return (
    <RetailModuleAudience
      id={id}
      details={details}
      setSnackbarText={setSnackbarText}
      isLiveModule={details.state === QUIZSTATES.LIVE}
      steps={steps}
    />
  );
};

export default Audience;

Audience.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
  isRetail: PropTypes.bool.isRequired,
};
