import React from 'react';
import { Trash } from 'react-feather';
import PropTypes from 'prop-types';
import { Button } from '../../../styles';

const CustomRemoveCompanyToolBar = ({
  displayData,
  selectedRows,
  setSelectedRows,
  handleDelete,
}) => {
  const handleDeleteMultiple = () => {
    const indexes = Object.keys(selectedRows.lookup).map(str => str * 1);
    const filteredIds = displayData
      .filter(option => indexes.includes(option.dataIndex))
      .map(options => options.data[0]);
    handleDelete(filteredIds);
    setSelectedRows([]);
  };

  return (
    <Button
      color="secondary"
      variant="outlined"
      startIcon={<Trash />}
      mr={2}
      onClick={() => handleDeleteMultiple()}
    >
      {`Remove Companies (${selectedRows.data.length})`}
    </Button>
  );
};

export default CustomRemoveCompanyToolBar;

CustomRemoveCompanyToolBar.propTypes = {
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
};
