/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import AudienceTool from '../../../../../components/audience/AudienceTool';
import RequiredChip from '../../UI/RequiredChip';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  max-width: 100%;
`;

const MatchWrapper = styled.div`
  min-height: 480px;
  width: 100%;
  flex: 1;
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;

const RetailModuleAudience = ({ id, steps }) => {
  return (
    <Wrapper>
      <MatchWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 6,

            width: '100%',
            maxWidth: '100%',
          }}
        >
          <span style={{ fontSize: 16, fontWeight: '600', color: 'black' }}>Select Audience</span>
          <RequiredChip
            completed={steps[3]?.complete}
            small
            requirementText="Every module must have an audience selected"
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            maxWidth: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              flexShrink: 1,
            }}
          />
          <div
            style={{
              flex: 1,
              paddingTop: 12,
              maxWidth: '100%',
            }}
          >
            <AudienceTool moduleId={id} />
          </div>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 1,
            }}
          />
        </div>
      </MatchWrapper>
    </Wrapper>
  );
};

export default RetailModuleAudience;
