/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";
import useStreams from "../../../../swr/hooks/Streams/useStreams";
import { TextField, Typography } from "../../styles";

const ChooseStreamsWrapper = styled.div``;

const QuizStreams = ({ setComboValue }) => {
  const { streams, isLoading, isError } = useStreams();

  if (isError) return null;

  if (isLoading) {
    return <div style={{ height: 60, width: "100%" }} />;
  }

  const autocompleteOptions = streams
    .filter((s) => !s.marketplace_parent)
    .map((stream) => ({
      id: stream.id,
      name: stream.name,
    }));

  return (
    <ChooseStreamsWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 4,
        }}
      >
        <Typography variant="subtitle2">
          Add module to selected streams
        </Typography>
        <span style={{ color: "darkgrey" }}>(optional)</span>
      </div>
      <div style={{ height: 4 }} />
      <Autocomplete
        multiple
        size="small"
        options={autocompleteOptions}
        noOptionsText="No Streams Available"
        getOptionLabel={(option) => option.name}
        onChange={(_, newValue) => setComboValue(newValue)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ background: "white" }}
            variant="outlined"
            size="small"
          />
        )}
      />
      <div style={{ height: 8 }} />
    </ChooseStreamsWrapper>
  );
};

export default QuizStreams;

QuizStreams.propTypes = {
  setComboValue: PropTypes.func.isRequired,
};
