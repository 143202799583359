/* eslint-disable */

import React from 'react';
import { CustomDatatableV2, StyledLink } from '../../../styles';
import TableTitle from '../../../../../components/Table/TableTitle';
import { Users } from 'react-feather';
import { makeDefaultTableOptions } from '../../../../../storage/helpers';
import styled from 'styled-components/macro';
import CustomDataTableIcons from '../../../../../components/Table/CustomDataTableIcons';
import { useMediaQuery } from '@material-ui/core';
import DateTooltip from '../../../../../components/Table/DateTooltip';

const Wrapper = styled.div`
  flex: 0.5;
`;

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      sort: false,
      filter: false,
      display: false,
      download: false,
    },
  },
  {
    name: 'user',
    label: 'User',
    options: {
      filter: false,
      sort: false,
      display: true,
      download: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
          />
        );
      },
    },
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      filter: false,
      sort: false,
      display: true,
      download: true,
      customBodyRender: value => {
        if (!value) return <span style={{ color: 'darkgrey' }}>No active company</span>;
        return <span>{value}</span>;
      },
    },
  },
  {
    name: 'created',
    label: 'Created',
    options: {
      filter: false,
      sort: true,
      display: true,
      download: true,
      customBodyRender: value => <DateTooltip date={value} />,
    },
  },
];

const UserTable = ({ data }) => {
  const options = makeDefaultTableOptions('content-requests-by-user');
  const isUnder600Px = useMediaQuery(theme => theme.breakpoints.down('xs'));

  options.sortOrder = {
    name: 'created',
    direction: 'desc',
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = data.map(request => ({
    id: request?.user?.id,
    user: `${request.user?.first_name} ${request?.user.last_name}`,
    created: new Date(request.created).toDateString(),
    company: request?.user?.companies.length === 0 ? null : request?.user?.companies?.[0].name,
  }));

  return (
    <>
      {/* <img
        src={ASSIGNED}
        style={{ height: 38, width: 38 }}
      />
      <img
        src={EC}
        style={{ height: 38, width: 38 }}
      /> */}
      {/* <ASSIGNED

      />
      <EC
      /> */}
      <Wrapper>
        <CustomDatatableV2
          options={options}
          data={tableData}
          columns={columns}
          title={
            <TableTitle
              title="Content Requests By User"
              Icon={Users}
            />
          }
          components={{
            icons: CustomDataTableIcons,
          }}
        />
      </Wrapper>
    </>
  );
};

export default UserTable;
