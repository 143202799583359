/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Button } from '../../styles';
import { CheckCircle } from 'react-feather';

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ModifyLive = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <CenterContainer>
        <CheckCircle
          height={26}
          width={26}
          color="mediumseagreen"
        />
        <span style={{ fontSize: 16, fontWeight: '600' }}>Module Is Live</span>

        <div style={{ height: 12 }} />
        <Button
          mt={2}
          variant="outlined"
          color="inherit"
          style={{ background: 'white' }}
          onClick={() => history.push('/modules/')}
        >
          Return To All Modules
        </Button>
      </CenterContainer>
    </Wrapper>
  );
};

export default ModifyLive;
