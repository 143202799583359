import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useVendorQuizAudience = (id) => {
  const { data, error, mutate } = useSWR(`dashboard/quiz/audience/vendor/?id=${id}`, fetcher);

  return {
    vendorAudience: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useVendorQuizAudience;
