/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";
import { Button } from "../../styles";
import API from "../../../../axios/instances/API";

const CloseModsButton = styled(Button)`
  background-color: tomato;
  color: white;

  &:hover {
    background-color: rgba(250, 0, 0, 0.6);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-right: 12px;
  min-height: 36px;
`;

const ClosingSpan = styled.span`
  font-style: italic;
  font-size: 16;
  margin-left: 4;
`;

const CircularProgWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomCloseModuleToolbar = ({
  displayData,
  selectedRows,
  setSelectedRows,
  setSnackbarText,
  mutate,
}) => {
  const [isClosing, setIsClosing] = useState(null);

  const handleBulkClose = async () => {
    setIsClosing("Closing modules...");
    const dataIndexes = selectedRows.data.map((row) => row.dataIndex);
    const modulesToDelete = displayData.filter((data) =>
      dataIndexes.includes(data.dataIndex)
    );
    const idsArray = modulesToDelete.map((mod) => mod.data[0]);
    try {
      await API.post("dashboard/quiz/bulk_close/", {
        quiz_ids: idsArray,
      });
      setIsClosing("Refetching all modules...");
      await mutate();
      setIsClosing(null);
      setSnackbarText(
        `Successfully closed ${idsArray.length} ${
          idsArray.length > 1 ? "modules" : "module"
        } success`
      );
    } catch (error) {
      setIsClosing(null);
      setSnackbarText("Failed to close modules error");
    }
  };

  return (
    <Wrapper>
      {isClosing ? (
        <>
          <CircularProgWrapper>
            <CircularProgress size={20} color="primary" />
          </CircularProgWrapper>
          <ClosingSpan>{isClosing}</ClosingSpan>
        </>
      ) : (
        <>
          <span style={{ fontSize: 13, color: "darkgrey" }}>
            {selectedRows.data.length}{" "}
            {selectedRows.data.length > 1 ? "modules" : "module"} selected
          </span>
          <CloseModsButton mr={2} ml={4} onClick={() => handleBulkClose()}>
            Close modules
          </CloseModsButton>
          <Button
            color="inherit"
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={() => setSelectedRows([])}
          >
            Clear
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default CustomCloseModuleToolbar;

CustomCloseModuleToolbar.propTypes = {
  displayData: PropTypes.array.isRequired,
  selectedRows: PropTypes.object.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};
