/* eslint-disable */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import {
  MpPointerContainer,
  CmPointerContainer,
  CustomDatatableV2,
} from "../../styles";
import {
  makeDefaultTableOptions,
  formatDateWithTime,
} from "../../../../storage/helpers";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import SeeSubmissionModal from "../../../../components/SeeSubmission/SeeSubmissionModal";
import ActionButtonForTables from "../../../../components/Table/ActionButtonForTables";
import { Download, Search } from "react-feather";
import TableTitle from "../../../../components/Table/TableTitle";
import SubmissionTypeChip from "../../home/retail/SubmissionTypeChip";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";
import DateTooltip from "../../../../components/Table/DateTooltip";

const SubmissionTable = ({ location, submissions, engagementType }) => {
  const [submissionInfo, setSubmissionInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions(`${location}-submissions`);
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const filteredSubmissions =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => sub.assigned)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? submissions.filter((sub) => !sub.assigned)
      : submissions;

  const columns = [
    {
      name: "submissionId",
      label: "submission id",
      options: {
        download: false,
        print: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <DateTooltip date={value} />,
      },
    },
    {
      name: "teamMemberName",
      label: "Team Member",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "moduleName",
      label: "Module",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => (
          <SubmissionTypeChip small submissionType={value} />
        ),
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "passed",
      label: "Passed",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="view"
            Icon={Search}
            handleClick={() => setSubmissionInfo({ id: tableMeta.rowData[0] })}
          />
        ),
      },
    },
  ];

  const tableData = filteredSubmissions.map((submission) => ({
    submissionId: submission.id,
    date: new Date(submission.created).toISOString(),
    moduleName: submission.quiz_name,
    teamMemberName: `${submission.first_name} ${submission.last_name}`,
    type: submission?.assigned
      ? ENGAGEMENT_TYPES.ASSIGNED
      : ENGAGEMENT_TYPES.EXTRACREDIT,
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
            }}
          >
            <TableTitle
              title="Submissions at this location"
              Icon={Download}
              submissionType={engagementType}
            />
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <SeeSubmissionModal
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </>
  );
};

export default SubmissionTable;

SubmissionTable.propTypes = {
  location: PropTypes.string.isRequired,
  submissions: PropTypes.array.isRequired,
};
