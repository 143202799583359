/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import { LoadMoreWrapper, LoadingMore } from '../../../marketplace/marketplaceStyles';
import { Typography, Alert, SubtitleSmall, StyledLink } from '../../../styles';
import { addCommas } from '../../../../../storage/helpers';
import { returnDefinitionByEngagementType } from '../../../../../storage/constants';
import useOverviewReport from '../../../../../swr/hooks/VendorReports/useOverviewReport';
import { InfoWrapper, InfoPaper, DescriptorText, OverSizedText } from '../../../home/styles';
import { AMAZON_IMAGE_URL, DEFINITIONS, ENGAGEMENT_TYPES } from '../../../../../storage/constants';
import ExtraInfo from '../../../../../components/ExtraInfo';
import EngagementTypeSelect from '../../reuseableComponents/EngagementTypeSelect';
import { CenterWithColor } from '../../../styles';
import SubmissionTypeChip from '../../../home/retail/SubmissionTypeChip';
import BigBlockByEngagementType from '../../reuseableComponents/BigBlockByEngagementType';
import SmallBlockByEngagementType from '../../reuseableComponents/SmallBlockByEngagementType';
import { Briefcase, Book, Users, PieChart, BarChart2, UserPlus, Percent } from 'react-feather';
import CustomReport from '../CustomReport';
import FunLoader from '../../../../../components/Loaders/FunLoader';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const PaperStyleOverRide = styled(InfoPaper)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;
`;

const Second = styled(Paper)`
  flex: 0.245;
  height: 60px;
  border-radius: 4px;
  padding: 0px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;

  p {
    font-size: 11px;
    font-weight: 400;
    text-align: center;
  }

  span {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }
`;

const HoldSecond = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  gap: 8px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const HoldThird = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  border-radius: 6px;
  min-height: 330px;
  gap: 16px;
`;

const ThirdInner = styled(Paper)`
  flex-grow: 1;
  min-height: 330px;
  min-width: 300px;
  padding: 10px;
  border: 1px solid lightgrey;
  position: relative;
  overflow-y: hidden;
`;

const ShowEngagementDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2px 0px;
  border-bottom: 1px solid #eeeeee87;
  height: 40px;
`;

const SmallCompanyImg = styled.img`
  height: 34px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 34px;
  aspect-ratio: 1/1;
  object-fit: contain;
  margin-right: 5px;
`;

const SmallModuleImg = styled.img`
  height: 34px;
  width: 34px;
  border: 1px solid #eeeeee;
  aspect-ratio: 1/1;
  object-fit: contain;
  margin-right: 5px;
  border-radius: 4px;
`;

const Column1 = styled.div`
  flex: 0.6;
  display: flex;
`;

const Column2 = styled.div`
  flex: 0.2;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    // color: black;
    font-weight: 600;
  }
`;

const Column3 = styled.div`
  flex: 0.2;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-weight: 700;
  }
`;

const getTopTens = (companies, modules, engagementType) => {
  let companyArr = [];
  let moduleArr = [];

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    const companyTotal = companies
      .map(company => {
        return {
          ...company,
          comps: company.assigned_completions + company.follower_completions,
          subs: company.assigned_submissions + company.follower_submissions,
        };
      })
      .sort((a, b) => b.subs - a.subs)
      .slice(0, 10);

    const moduleTotal = modules
      .map(mod => {
        return {
          ...mod,
          comps: mod.assigned_completions + mod.follower_completions,
          subs: mod.assigned_submissions + mod.follower_submissions,
        };
      })
      .sort((a, b) => b.subs - a.subs)
      .slice(0, 10);

    companyArr = companyTotal;
    moduleArr = moduleTotal;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    const companyTotal = companies
      .sort((a, b) => b.assigned_submissions - a.assigned_submissions)
      .slice(0, 10)
      .map(c => {
        return {
          ...c,
          comps: c.assigned_completions,
          subs: c.assigned_submissions,
        };
      });

    const moduleTotal = modules
      .sort((a, b) => b.assigned_submissions - a.assigned_submissions)
      .slice(0, 10)
      .map(c => {
        return {
          ...c,
          comps: c.assigned_completions,
          subs: c.assigned_submissions,
        };
      });

    companyArr = companyTotal;
    moduleArr = moduleTotal;
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    const companyTotal = companies
      .sort((a, b) => b.follower_submissions - a.follower_submissions)
      .slice(0, 10)
      .map(c => {
        return {
          ...c,
          comps: c.follower_completions,
          subs: c.follower_submissions,
        };
      });

    const moduleTotal = modules
      .sort((a, b) => b.follower_submissions - a.follower_submissions)
      .slice(0, 10)
      .map(c => {
        return {
          ...c,
          comps: c.follower_completions,
          subs: c.follower_submissions,
        };
      });

    companyArr = companyTotal;
    moduleArr = moduleTotal;
  }

  return {
    companyArr,
    moduleArr,
  };
};

const getStats = (totals, engagementType) => {
  let passRate = 0;
  let avgSubsPerUnique = 0;
  let avgCompsPerUnique = 0;
  let uniqueUsers = 0;
  let comps = 0;
  let subs = 0;

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    passRate = Math.floor(
      ((totals.assigned_completions + totals.follower_completions) /
        (totals.assigned_submissions + totals.follower_submissions)) *
        100
    );
    avgSubsPerUnique = (
      (totals.assigned_submissions + totals.follower_submissions) /
      totals.overall_unique_users
    ).toFixed(2);
    avgCompsPerUnique = (
      (totals.assigned_completions + totals.follower_completions) /
      totals.overall_unique_users
    ).toFixed(2);
    comps = totals.assigned_completions + totals.follower_completions;
    subs = totals.assigned_submissions + totals.follower_submissions;
    uniqueUsers = totals.overall_unique_users;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    passRate = Math.floor((totals.assigned_completions / totals.assigned_submissions) * 100);
    avgSubsPerUnique = (totals.assigned_submissions / totals.assigned_unique_users).toFixed(2);
    avgCompsPerUnique = (totals.assigned_completions / totals.assigned_unique_users).toFixed(2);
    comps = totals.assigned_completions;
    subs = totals.assigned_submissions;
    uniqueUsers = totals.assigned_unique_users;
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    passRate = Math.floor((totals.follower_completions / totals.follower_submissions) * 100);
    avgSubsPerUnique = (totals.follower_submissions / totals.follower_unique_users).toFixed(2);
    avgCompsPerUnique = (totals.follower_completions / totals.follower_unique_users).toFixed(2);
    comps = totals.follower_completions;
    subs = totals.follower_submissions;
    uniqueUsers = totals.follower_unique_users;
  }

  return {
    passRate,
    avgSubsPerUnique,
    avgCompsPerUnique,
    uniqueUsers,
    comps,
    subs,
  };
};

const Overview = () => {
  const { data, isLoading, isError } = useOverviewReport();
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return <FunLoader />;
  }

  if (isError)
    return (
      <Alert
        severity="error"
        mt={2}
      >
        Error loading report, please refresh
      </Alert>
    );

  const { companies_breakdown, company_info, quizzes_breakdown, totals } = data;

  const { passRate, avgCompsPerUnique, avgSubsPerUnique, uniqueUsers, comps, subs } = getStats(
    totals,
    engagementType
  );

  const { moduleArr, companyArr } = getTopTens(
    companies_breakdown,
    quizzes_breakdown,
    engagementType
  );

  return (
    <Wrapper>
      <EngagementTypeSelect
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        isVendor
      />
      <div style={{ height: 12 }} />
      <InfoWrapper>
        <BigBlockByEngagementType
          title="Unique Users Engaged"
          definition={DEFINITIONS.totalUniqueUsers}
          number={uniqueUsers}
          engagementType={engagementType}
          Icon={Users}
        />
        <BigBlockByEngagementType
          title="Total Completions"
          definition={returnDefinitionByEngagementType('completions', engagementType)}
          number={comps}
          Icon={PieChart}
          engagementType={engagementType}
        />
        <BigBlockByEngagementType
          title="Total Submissions"
          definition={returnDefinitionByEngagementType('submissions', engagementType)}
          Icon={BarChart2}
          number={subs}
          engagementType={engagementType}
        />
        <PaperStyleOverRide>
          <UserPlus
            height={24}
            width={24}
            style={{ marginLeft: 2 }}
          />
          <div style={{ height: 10 }} />

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 4,
            }}
          >
            <DescriptorText>Number of Followers</DescriptorText>
            <ExtraInfo text={DEFINITIONS.numberOfFollowers} />
          </div>
          <div style={{ height: 12 }} />
          <OverSizedText style={{ color: 'black' }}>
            {addCommas(company_info.followers)}
          </OverSizedText>
        </PaperStyleOverRide>
      </InfoWrapper>
      <HoldSecond>
        <SmallBlockByEngagementType
          title="Average # Subs Per Unique User"
          definition={DEFINITIONS.averageSubsPerUniqueUser}
          engagementType={engagementType}
          number={avgSubsPerUnique}
          Icon={BarChart2}
        />
        <SmallBlockByEngagementType
          title="Average # Comps Per Unique User"
          definition={DEFINITIONS.averageCompsPerUniqueUser}
          engagementType={engagementType}
          number={avgCompsPerUnique}
          Icon={PieChart}
        />
        <SmallBlockByEngagementType
          title="All Time Pass Rate"
          definition={DEFINITIONS.passRate}
          engagementType={engagementType}
          number={`${passRate}%`}
          Icon={Percent}
        />
        {/* <Second>
          <AlignCenter>
            <ExtraInfo text={DEFINITIONS.liveModules} />
            <p>Live Modules</p>
          </AlignCenter>
          <span style={{ color: "black" }}>{company_info.live_quizzes}</span>
        </Second> */}
      </HoldSecond>
      {companyArr.length > 0 && (
        <HoldThird>
          <ThirdInner>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="subtitle1"
                // mb={2}
                style={{ fontWeight: '600' }}
              >
                Top 10 Engaged Companies
              </Typography>
              <SubmissionTypeChip
                submissionType={engagementType}
                isVendor
                small
              />
            </div>
            <ShowEngagementDiv style={{ marginBottom: 0 }}>
              <Column1>
                <SubtitleSmall>Name</SubtitleSmall>
              </Column1>
              <Column2>
                <SubtitleSmall>Submissions</SubtitleSmall>
              </Column2>
              <Column3>
                <SubtitleSmall>Completions</SubtitleSmall>
              </Column3>
            </ShowEngagementDiv>
            {companyArr
              ? companyArr.map(company => (
                  <ShowEngagementDiv key={company.company_id}>
                    <Column1>
                      <SmallCompanyImg src={`${AMAZON_IMAGE_URL}/media/${company.logo}`} />
                      <StyledLink
                        to={`/vendor-reports/company/${company.id}`}
                        value={company.name}
                        CustomIcon={Briefcase}
                      />
                    </Column1>
                    <Column2>
                      <CenterWithColor
                        engagementType={engagementType}
                        small="yes"
                      >
                        <span>{company.subs}</span>
                      </CenterWithColor>
                    </Column2>
                    <Column3>
                      <CenterWithColor
                        engagementType={engagementType}
                        small="yes"
                      >
                        <span>{company.comps}</span>
                      </CenterWithColor>
                    </Column3>
                  </ShowEngagementDiv>
                ))
              : null}
          </ThirdInner>
          <ThirdInner>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="subtitle1"
                // mb={2}
                style={{ fontWeight: '600' }}
              >
                Top 10 Engaged Modules
              </Typography>
              <SubmissionTypeChip
                submissionType={engagementType}
                isVendor
                small
              />
            </div>
            <ShowEngagementDiv style={{ marginBottom: 6 }}>
              <Column1>
                <SubtitleSmall>Name</SubtitleSmall>
              </Column1>
              <Column2>
                <SubtitleSmall>Submissions</SubtitleSmall>
              </Column2>
              <Column3>
                <SubtitleSmall>Completions</SubtitleSmall>
              </Column3>
            </ShowEngagementDiv>
            {moduleArr &&
              moduleArr.map(quiz => (
                <ShowEngagementDiv key={quiz.id}>
                  <Column1>
                    <SmallModuleImg src={`${AMAZON_IMAGE_URL}/media/${quiz.image}`} />
                    <StyledLink
                      to={`/modules/progress/${quiz.id}`}
                      value={quiz.name}
                      CustomIcon={Book}
                    />
                  </Column1>
                  <Column2>
                    <CenterWithColor
                      engagementType={engagementType}
                      small="yes"
                    >
                      <span>{quiz.subs}</span>
                    </CenterWithColor>
                  </Column2>
                  <Column3>
                    <CenterWithColor
                      engagementType={engagementType}
                      small="yes"
                    >
                      <span>{quiz.comps}</span>
                    </CenterWithColor>
                  </Column3>
                </ShowEngagementDiv>
              ))}
          </ThirdInner>
        </HoldThird>
      )}
      <CustomReport />
    </Wrapper>
  );
};

export default Overview;
