/* eslint-disable */
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Modal, Button } from "@material-ui/core";
import { MapPin, Plus } from "react-feather";
import PrivateHeader from "../../../components/PrivateHeader";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import CreateNewLocation from "./CreateNewLocation";
import LocationsDataTable from "./LocationsDatatable";
import useLocations from "../../../swr/hooks/Locations/useLocations";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import { GlobalState } from "../../../store/GlobalState";
import OnLoadError from "../../../components/OnLoadError";
import DeleteLocationModal from "./DeleteLocationModal";
import CreateButton from "../../../components/UI/CreateButton";
import CreateLocationsViaCsv from "./CreateLocationsViaCsv";

const Locations = () => {
  const { isLoading, mutate, isError } = useLocations();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const { state } = useContext(GlobalState);
  const { config } = state;
  const { readOnly } = config;
  const isManager = config.job === "M";

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Locations" Icon={MapPin} />
        <LoaderWrapper text="Locations" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Locations" />
      <PrivateHeader header="Locations" Icon={MapPin} />
      <div
        style={{
          width: "fit-content",
          display: "flex",

          gap: 6,
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <CreateButton
          title="Create Location"
          handleClick={() => setCreateModalOpen(true)}
          disabled={readOnly || isManager}
        />
        <Button
          variant="outlined"
          color="inherit"
          disabled={readOnly}
          style={{ height: 38, borderRadius: 4 }}
          startIcon={<Plus height={14} width={14} />}
          onClick={() => setCsvModalOpen(true)}
        >
          Create via CSV
        </Button>
      </div>
      <div style={{ height: 18 }} />
      <Grid container justifyContent="center" spacing={8}>
        <Grid item xs={12}>
          <LocationsDataTable
            setDeleteInfo={setDeleteInfo}
            readOnly={readOnly}
            isManager={isManager}
            setSnackbarText={setSnackbarText}
          />
        </Grid>
      </Grid>
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        aria-labelledby="Create new location"
        aria-describedby="modal to create new location"
      >
        <div>
          <CreateNewLocation
            setCreateModalOpen={setCreateModalOpen}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a location"
        aria-describedby="modal to delete a location"
      >
        <div>
          <DeleteLocationModal
            deleteInfo={deleteInfo}
            setDeleteInfo={setDeleteInfo}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            args={{
              id: deleteInfo ? deleteInfo.id : null,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={csvModalOpen}
        onClose={() => {
          mutate();
          setCsvModalOpen(false);
        }}
        aria-labelledby="Create locations via Csv"
        aria-describedby="Modal to create locations via Csv"
      >
        <div>
          <CreateLocationsViaCsv
            setCsvModalOpen={setCsvModalOpen}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Locations;
