/* eslint-disable */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Cast } from "react-feather";
import styled from "styled-components/macro";
import { Chip, Popper } from "@material-ui/core";
import Inner from "./Inner";

const StyledChip = styled(Chip)`
  background-color: ${(props) => props.theme.palette.warning.light};
  padding: 0px;
  font-weight: 400;
  font-size: 12px;
  color: black;
  margin: 0px 2px;

  svg {
    color: black;
  }
`;

const ShowAudienceChip = ({ name, id, shouldLinkToAudience = true }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <StyledChip
        ref={anchorRef}
        isactive={open ? "Yes" : "No"}
        icon={<Cast />}
        label={name}
        clickable
        size="small"
        onClick={() => setOpen((p) => !p)}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        style={{ zIndex: "10000000001" }}
      >
        <Inner
          id={id}
          name={name}
          setOpen={setOpen}
          shouldLinkToAudience={shouldLinkToAudience}
        />
      </Popper>
    </>
  );
};

export default ShowAudienceChip;

ShowAudienceChip.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
