/* eslint-disable */
import useSWR from 'swr';
import API from '../../axios/instances/API';

const BASE_URL = '/dashboard/quiz/deployment/marketplace/?id=';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data;
};

const useVendorDeploymentInfo = id => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useVendorDeploymentInfo;
