/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { X } from "react-feather";
import { Button } from "@material-ui/core";
import { addDays } from "date-fns";

const DateRangeWrapper = styled.div`
  padding: 12px;
  border-radius: 4px;
  background: #eeeeee;
  border: 1px solid
    ${(props) => (props.filterapplied === "y" ? "black" : "lightgray")};
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const DateRangeSelector = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setFilterActive,
  filterActive,
}) => {
  return (
    <DateRangeWrapper filterapplied={filterActive ? "y" : "n"}>
      <span style={{ fontSize: 14, fontWeight: "500", marginBottom: 12 }}>
        Date Range Filter
      </span>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 6,
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            size="small"
            disableToolbar
            autoOk
            fullWidth
            color="secondary"
            minDateMessage={" "}
            inputVariant="outlined"
            variant="inline"
            format="yyyy-MM-dd"
            value={startDate}
            disableFuture
            maxDate={endDate || null}
            label="Start"
            disabled={filterActive}
            style={{ background: "white", maxWidth: 230, borderRadius: 4 }}
            onChange={(date) => setStartDate(date)}
            InputProps={{
              endAdornment: startDate ? (
                <X
                  height={12}
                  width={12}
                  style={{
                    color: "darkgrey",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setStartDate(null);
                    setFilterActive(false);
                  }}
                />
              ) : (
                <></>
              ),
            }}
          />
          <span>-</span>
          <DatePicker
            size="small"
            disableToolbar
            autoOk
            fullWidth
            color="secondary"
            minDateMessage={" "}
            inputVariant="outlined"
            variant="inline"
            disabled={filterActive}
            format="yyyy-MM-dd"
            value={endDate}
            minDate={startDate || null}
            label="End"
            style={{ background: "white", maxWidth: 230, borderRadius: 4 }}
            onChange={(date) => setEndDate(date)}
            maxDate={addDays(new Date(), 1)}
            InputProps={{
              endAdornment: endDate ? (
                <X
                  height={12}
                  width={12}
                  style={{
                    color: "darkgrey",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEndDate(null);
                    setFilterActive(false);
                  }}
                />
              ) : (
                <></>
              ),
            }}
          />
        </MuiPickersUtilsProvider>

        <div style={{ width: 20 }} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            variant={filterActive ? "contained" : "text"}
            style={{ boxShadow: "none" }}
            onClick={() => {
              setFilterActive(false);
              setStartDate(null);
              setEndDate(null);
            }}
            disabled={!filterActive}
          >
            Clear
          </Button>
          <Button
            disabled={!startDate || !endDate}
            variant="outlined"
            style={{ marginLeft: 12, background: "white" }}
            onClick={() => setFilterActive(true)}
          >
            Filter
          </Button>
        </div>
      </div>
    </DateRangeWrapper>
  );
};

export default DateRangeSelector;
