/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, Paper } from '@material-ui/core';
import { addDays } from 'date-fns';
import { PlusCircle, X } from 'react-feather';
import styled from 'styled-components/macro';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import { AUDIENCETYPES } from '../../../storage/constants';
import useAudienceOptions from '../../../swr/hooks/Audience/useAudienceOptions';
import useCompanyDetails from '../../../swr/hooks/Util/useCompanyDetails';
import API from '../../../axios/instances/API';
import { Typography, Button, Alert } from '../../../pages/private/styles';
import { apiFormatDate, getSrcTwo } from '../../../storage/helpers';

import RoleAndLocationSelectionModals from '../roleAndLocationSelectionModals';
import CurrentAudience from '../currentAudience';
import AudienceButtons from './AudienceButtons';
import ACTIONS from '../../../pages/private/marketplace/state/actions';

const ERROR_STAGES = {
  ADDED: 'ADDED',
  STREAM: 'STREAM',
  AUDIENCE: 'ERROR_AUDIENCE',
  DETAILS: 'ERROR_DETAILS',
  DEPLOY: 'ERROR_DEPLOY',
};

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(560px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const DateHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 20px;
`;

const Spacer = styled.div`
  width: 16px;
`;

const Subtitle = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
`;

const SelectedIndicator = styled.div`
  height: 13px;
  width: 13px;
  min-width: 13px;
  min-height: 13px;
  background: ${props => (props.isselected === 'Y' ? '#337AB7' : 'lightgray')};
  margin-right: 8px;
  border-radius: 4px;
`;

const formatSelectedAudeinceForApi = (selectedAudience, comboValue, options, id) => {
  const locationIds = options.locations.map(l => l.id);
  const roleIds = options.roles.map(r => r.id);
  const retObj = {};

  if (selectedAudience === AUDIENCETYPES.SELECT_ALL) {
    locationIds.forEach(id => (retObj[id] = roleIds));

    return {
      quiz_id: id,
      audience: retObj,
    };
  }

  if (selectedAudience === AUDIENCETYPES.ROLES) {
    const selectedRoleIds = comboValue;

    locationIds.forEach(id => (retObj[id] = selectedRoleIds));

    return {
      quiz_id: id,
      audience: retObj,
    };
  }

  if (selectedAudience === AUDIENCETYPES.LOCATIONS) {
    const selectedLocationIds = comboValue;
    const roleIds = options.roles.map(r => r.id);

    locationIds.forEach(id => (retObj[id] = selectedLocationIds.includes(id) ? roleIds : []));

    return {
      quiz_id: id,
      audience: retObj,
    };
  }
};

const AddAudienceAndDatesToLaunchFromMarketplace = ({
  moduleId = null,
  setSnackbarText,
  setAdded,
  setLaunched,
  setAddModalInfo,
  dispatch,
}) => {
  const { options, isLoading: optionsLoading, isError } = useAudienceOptions();
  const [moduleInfo, setModuleInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [addingToLibrary, setAddingToLibrary] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);
  const { company } = useCompanyDetails();
  const [selectedAudience, setSelectedAudience] = useState(AUDIENCETYPES.NONE);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [errorAlert, setErrorAlert] = useState('');
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [comboValue, setComboValue] = useState([]);

  useEffect(() => {
    const getModuleInfo = async () => {
      try {
        const { data } = await API.get(`/dashboard/marketplace/quizzes/quiz/?id=${moduleId}`);
        setModuleInfo(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getModuleInfo();
  }, []);

  useEffect(() => {
    if (endDate && startDate > endDate) {
      const newEndDate = addDays(startDate, 1);
      return setEndDate(newEndDate);
    }
    return null;
  }, [startDate, endDate]);

  if (isLoading || optionsLoading) {
    return (
      <ModalWrapper style={{ minHeight: 400 }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: 370,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: 12, color: 'lightgray' }}>loading...</span>
        </div>
      </ModalWrapper>
    );
  }

  if (isError) {
    return <Alert severity="error">Error Loading Options, please try again.</Alert>;
  }

  const handleErrorStage = errorStage => {
    if (errorStage === ERROR_STAGES.ADDED) {
      return setErrorAlert(
        'Error adding this modules to your library. There might be an issue with the module itself.'
      );
    }
    if (errorStage === ERROR_STAGES.AUDIENCE) {
      setAdded(true);

      return setErrorAlert(
        'Module has been added to your library, but failed to add an audience, and as a result could not be launched. Please edit this module from your library to launch'
      );
    }
    if (errorStage === ERROR_STAGES.DETAILS) {
      setAdded(true);
      return setErrorAlert(
        'Module has been added to your library, but failed to add start and end dates, and as a result could not be launched. Please edit this module from your library to launch'
      );
    }

    if (errorStage === ERROR_STAGES.DEPLOY) {
      setAdded(true);
      return setErrorAlert(
        'Module has been added to your library, but has failed to deploy. Please try to deploy this module from your library'
      );
    }
    return null;
  };

  const { locations, roles } = options;
  const noLocations = locations.length === 0;
  const minEndDate = addDays(startDate, 1);

  const handleLaunchFromMarketplace = async () => {
    setIsLaunching(true);
    let errorStage = ERROR_STAGES.ADDED;

    try {
      const { data } = await API.post('/dashboard/marketplace/quizzes/add/', {
        id: moduleInfo.id,
      });

      // attach audience //
      errorStage = ERROR_STAGES.AUDIENCE;
      const newModuleId = data.id;
      const audienceBody = formatSelectedAudeinceForApi(
        selectedAudience,
        comboValue,
        options,
        newModuleId
      );

      await API.put('/dashboard/quiz/audience/', audienceBody);

      errorStage = ERROR_STAGES.DETAILS;

      await API.put('/dashboard/quiz/details/', {
        id: newModuleId,
        name: moduleInfo.name,
        description: moduleInfo.description,
        estimated_time: moduleInfo?.estimatedTime || 3,
        language: moduleInfo?.language,
        start_date: apiFormatDate(startDate),
        due_date: endDate ? apiFormatDate(endDate) : null,
        disclaimer: moduleInfo.disclaimer,
        disclaimer_text: moduleInfo.disclaimerText,
        display_answers: company && company.displayAnswers ? company.displayAnswers : false,
        // We will just set this to the company level default, if it cannot load, we will just make it false
      });

      errorStage = ERROR_STAGES.DEPLOY;
      await API.post('/dashboard/quiz/deployment/deploy-retail-quiz/', {
        id: newModuleId,
      });
      setLaunched(true);
      setSnackbarText('Module launched success');
      dispatch({
        type: ACTIONS.HANDLE_MODULE_EXISTS_CHANGE,
        payload: moduleId,
      });
      setAddModalInfo(null);
    } catch (error) {
      handleErrorStage(errorStage);
      setIsLaunching(false);
    }
  };

  const handleAddToLibrary = async () => {
    try {
      setAddingToLibrary(true);
      await API.post('/dashboard/marketplace/quizzes/add/', {
        id: moduleInfo.id,
      });
      setAdded(true);
      setSnackbarText('Module added to library success');
      dispatch({
        type: ACTIONS.HANDLE_MODULE_EXISTS_CHANGE,
        payload: moduleId,
      });
      setAddModalInfo(null);
      setAddingToLibrary(false);
    } catch (error) {
      setAddingToLibrary(false);
      setErrorAlert(
        'Unable to add this module to your team library. There might be an issue with this module.'
      );
    }
  };

  const handleSetLocationsFromModal = selectedLocations => {
    setComboValue(selectedLocations);
    return setSelectedAudience(AUDIENCETYPES.LOCATIONS);
  };

  const handleSetRolesFromModal = selectedRoles => {
    setComboValue(selectedRoles);
    return setSelectedAudience(AUDIENCETYPES.ROLES);
  };

  const launchDisabled = !(
    Boolean(startDate) &&
    selectedAudience !== AUDIENCETYPES.NONE &&
    !isLaunching
  );

  return (
    <ModalWrapper>
      <div
        style={{
          height: 48,
          width: '100%',
          marginBottom: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderRadius: 2,
        }}
      >
        <img
          style={{
            height: 46,
            width: 46,
            background: 'white',
            borderRadius: 8,
            objectFit: 'contain',
            border: '1px solid #eeeeee',
          }}
          src={getSrcTwo(moduleInfo.image)}
        />
        <div style={{ marginLeft: 6 }}>
          <Typography style={{ fontSize: 16, fontWeight: '600', marginBottom: -2 }}>
            {moduleInfo?.name}
          </Typography>

          <Typography variant="body1">By: {moduleInfo.company}</Typography>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Subtitle>Select Start and End Date</Subtitle>
      </div>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateHolder>
          <DatePicker
            disableToolbar
            disablePast
            label="Start Date"
            emptyLabel="Start Date"
            fullWidth
            autoOk
            // helperText="*required"
            inputVariant="outlined"
            size="small"
            variant="inline"
            format="yyyy-MM-dd"
            value={startDate}
            onChange={date => setStartDate(date)}
            InputProps={{
              startAdornment: <SelectedIndicator isselected={Boolean(startDate) ? 'Y' : 'N'} />,
            }}
          />
          <Spacer />
          <DatePicker
            disablePast
            disableToolbar
            // helperText="*optional"
            label="End Date"
            emptyLabel="End Date (optional)"
            autoOk
            minDate={minEndDate}
            input
            inputVariant="outlined"
            size="small"
            fullWidth
            variant="inline"
            format="yyyy-MM-dd "
            value={endDate}
            onChange={date => setEndDate(date)}
            InputProps={{
              startAdornment: <SelectedIndicator isselected={Boolean(endDate) ? 'Y' : 'N'} />,
              endAdornment: endDate ? (
                <X
                  style={{
                    color: 'darkgrey',
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    setEndDate(null);
                  }}
                />
              ) : (
                <></>
              ),
            }}
          />
        </DateHolder>
      </MuiPickersUtilsProvider>
      <Subtitle style={{ marginBottom: 8 }}>Select Audience</Subtitle>
      <AudienceButtons
        setSelectedAudience={setSelectedAudience}
        setComboValue={setComboValue}
        setLocationModalOpen={setLocationModalOpen}
        setRoleModalOpen={setRoleModalOpen}
        selectedAudience={selectedAudience}
      />
      <div style={{ height: 12 }} />
      <CurrentAudience
        selectedAudience={selectedAudience}
        comboValue={comboValue}
        options={options}
        moduleId={moduleId}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginTop: 16,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Button
          variant="text"
          startIcon={
            addingToLibrary ? (
              <CircularProgress
                size={10}
                color="black"
              />
            ) : (
              <PlusCircle
                height={14}
                width={14}
              />
            )
          }
          color="inherit"
          disabled={noLocations}
          onClick={handleAddToLibrary}
          // disabled={addingToLibrary}
        >
          Add to library
        </Button>

        <Button
          disabled={launchDisabled}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: launchDisabled ? 'darkgrey' : '#337AB7',
            color: '#eeeeee',
            marginLeft: 'auto',
          }}
          onClick={handleLaunchFromMarketplace}
          startIcon={
            isLaunching ? (
              <CircularProgress
                size={18}
                color="white"
              />
            ) : null
          }
        >
          {isLaunching ? 'Launching Module' : 'Launch Module 🚀'}
        </Button>
      </div>

      {errorAlert && (
        <Alert
          severity="error"
          mt={3}
          onClose={() => setErrorAlert('')}
        >
          <span style={{ fontSize: 12 }}>{errorAlert}</span>
        </Alert>
      )}
      <RoleAndLocationSelectionModals
        locations={locations}
        roles={roles}
        handleApplyRoles={handleSetRolesFromModal}
        handleApplyLocations={handleSetLocationsFromModal}
        handleRolesClose={() => setRoleModalOpen(false)}
        handleLocationsClose={() => setLocationModalOpen(false)}
        locationModalOpen={locationModalOpen}
        roleModalOpen={roleModalOpen}
        setRoleModalOpen={setRoleModalOpen}
        setLocationModalOpen={setLocationModalOpen}
      />
    </ModalWrapper>
  );
};

export default AddAudienceAndDatesToLaunchFromMarketplace;

AddAudienceAndDatesToLaunchFromMarketplace.propTypes = {
  setAddModalInfo: PropTypes.func.isRequired,
  setAdded: PropTypes.func.isRequired,
};
