/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  Popover as MuiPopover,
  Paper,
  Avatar,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components/macro";
import { Activity, CheckCircle, Video } from "react-feather";
import { Typography, Divider } from "../pages/private/styles";
import API from "../axios/instances/API";
import ACTIONS from "../store/actions";
import { GlobalState } from "../store/GlobalState";
import useInterval from "../customHooks/useInterval";
import { mutate as swrMutate } from "swr";
import useLibrary from "../swr/hooks/Library/useLibrary";

const GreenCheck = styled(CheckCircle)`
  height: 16px;
  width: 16px;
  color: seagreen;
`;

const PopupSuccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  flex-direction: column;
  text-align: center;
  padding: 6px;
`;

const lookActive = keyframes`
  0% {
    background-color: #eeeeee;
  }
  50% {
    background-color: white;
  }
  100%{
    background-color: #eeeeee;
  }
`;

const Seperate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    min-height: 80px;
    min-width: 300px;
    // ${(props) => props.theme.shadows[1]};
    // border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ActivitySmall = styled(Activity)`
  height: 13px;
  width: 13px;
  color: ${(props) => props.theme.palette.info.light};
`;

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 100%;
`;

const CustomIconButton = styled(IconButton)`
  background: rgba(170, 210, 249, 0.41);
  // margin-right: 4px;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  animation: 3s ${lookActive} ease-out infinite;
  svg {
    color: slategrey;
  }

  &:hover {
    animation: none;
    background-color: #eeeeee;
  }
`;

const HighlightName = styled.span`
  font-size: 12px;
  font-weight: 600;

  margin: 0px 3px;
`;

const TransCodeItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 6px;
  gap: 6px;
`;

const DELAYTIME = 10000;

const ShowTranscode = ({ transcoding }) => {
  const { dispatch } = useContext(GlobalState);

  const [popupAlert, setPopupAlert] = useState(null);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const { mutate } = useLibrary();

  useInterval(async () => {
    const { data } = await API.get("/dashboard/quiz/vimeo/status/");
    // If none have finished..continue interval
    if (data.length > 0) {
      const allIds = data.map((val) => Number(val.id));
      const modulesFinished = transcoding.filter((mod) =>
        allIds.includes(Number(mod.quizID))
      );
      const filteredTranscoding = transcoding.filter(
        (mod) => !allIds.includes(Number(mod.quizID))
      );

      // If the transcoding array is longer then the filtered one.. we need to update it
      if (transcoding.length !== filteredTranscoding.length) {
        setOpen(true);
        setPopupAlert(modulesFinished);
        await swrMutate(
          `/dashboard/quiz/review/?id=${modulesFinished[0].quizID}`
        );
        await mutate();

        setTimeout(() => {
          setPopupAlert(null);
          setOpen(false);
          dispatch({
            type: ACTIONS.REMOVE_TRANSCODE,
            payload: filteredTranscoding,
          });
        }, 5000);
      }
    }
  }, DELAYTIME);

  return (
    <>
      <Tooltip title="Video Currently Transcoding">
        <CustomIconButton
          aria-haspopup="true"
          onClick={() => setOpen((p) => !p)}
          colior="inherit"
          ref={ref}
        >
          <Activity />
        </CustomIconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Wrapper>
          <Seperate>
            <Typography variant="subtitle2" style={{ color: "slategrey" }}>
              Videos Transcoding
            </Typography>
            <ActivitySmall color="slategrey" />
          </Seperate>
          <Divider />
          {popupAlert && popupAlert.length > 0 ? (
            popupAlert.length === 1 ? (
              <PopupSuccess>
                <Typography variant="body1" align="center">
                  Video for Module
                </Typography>
                <HighlightName>{popupAlert[0].name}</HighlightName>
                <Typography variant="body1" align="center">
                  transcoded successfully
                </Typography>
                <GreenCheck />
              </PopupSuccess>
            ) : (
              <>
                <PopupSuccess>
                  <Typography variant="body1">
                    Modules
                    <HighlightName>
                      {popupAlert.map((mod) => mod.name).join(",")}
                    </HighlightName>
                    transcoded successfully
                  </Typography>
                  <GreenCheck />
                </PopupSuccess>
              </>
            )
          ) : (
            <>
              {transcoding.map((mod, index) => (
                <TransCodeItem key={mod.quizID}>
                  <Video height={12} width={12} color="slategrey" />

                  <span
                    style={{ color: "black", fontWeight: "500", fontSize: 12 }}
                  >
                    {mod.name}
                  </span>
                </TransCodeItem>
              ))}
            </>
          )}
        </Wrapper>
      </Popover>
    </>
  );
};

export default ShowTranscode;

ShowTranscode.propTypes = {
  transcoding: PropTypes.array.isRequired,
};
