/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Paper } from '@material-ui/core';
import { Button, DeleteButton, Typography, DeleteCircularProgress } from '../../styles';
import styled from 'styled-components/macro';
import { Trash, User } from 'react-feather';
import API from '../../../../axios/instances/API';

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(460px, 90vw);
  max-width: 600px;
  border: 2px solid tomato;
  border-radius: 8px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const CloseModsButton = styled(Button)`
  background-color: tomato;
  color: white;

  height: 36px;
  width: 40px;
  min-width: 0px;
  &:hover {
    background-color: rgba(250, 0, 0, 0.6);
  }
`;

const getTeamMeberNames = (selectedRows, displayData) => {
  try {
    const dataIndexes = selectedRows.data.map(row => row.dataIndex);
    const validRows = displayData.filter(data => dataIndexes.includes(data.dataIndex));
    const names = validRows.map(row => row.data[1].props.value);

    return names;
  } catch (error) {
    return null;
  }
};

const BulkDeleteToolbar = ({
  selectedRows,
  displayData,
  setSelectedRows,
  setSnackbarText,
  mutate,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const teamMemberNames = getTeamMeberNames(selectedRows, displayData);

  const handleDeleteBulk = async () => {
    try {
      setDeleting(true);
      const dataIndexes = selectedRows.data.map(row => row.dataIndex);
      const modulesToDelete = displayData.filter(data => dataIndexes.includes(data.dataIndex));
      const idsArray = modulesToDelete.map(mod => mod.data[0]);
      await API.delete('/dashboard/teams/bulk-fire/', {
        data: {
          team_member_ids: idsArray,
        },
      });
      await mutate();
      setDeleting(false);
      setConfirmModalOpen(false);
      setSnackbarText(`Successfully removed ${idsArray.length} team members success`);
      setSelectedRows([]);
    } catch (error) {
      setSnackbarText('Failed to remove team members, please try again error');
      setDeleting(false);
    }
  };

  return (
    <div>
      <span style={{ marginRight: 8, fontSize: 13, color: 'darkgrey' }}>
        {selectedRows.data.length} team {selectedRows.data.length > 1 ? 'members' : 'member'}{' '}
        selected
      </span>
      <CloseModsButton
        ml={4}
        mr={2}
        onClick={() => setConfirmModalOpen(true)}
      >
        <Trash style={{ height: 18, width: 18 }} />
      </CloseModsButton>
      <Button
        variant="outlined"
        color="inherit"
        mr={2}
        style={{ backgroundColor: 'white' }}
        onClick={() => setSelectedRows([])}
      >
        Clear
      </Button>
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        aria-labelledby="Delete team members"
        aria-describedby="Modal to delete team members"
      >
        <ModalWrapper>
          <Typography
            style={{ fontWeight: '600', fontSize: 16 }}
            align="center"
            mb={2}
          >
            Confirm removal of team members
          </Typography>

          {teamMemberNames && (
            <div
              style={{
                maxHeight: 400,
                marginBottom: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                overflowY: 'scroll',
                padding: 6,
                gap: 2,
              }}
            >
              {teamMemberNames.map((n, i) => (
                <div
                  style={{
                    padding: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    paddingLeft: 8,
                    marginBottom: 2,
                    // border: "1px solid red",
                    borderBottom: '1px solid #eeeeee88',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      height: 14,
                      width: 14,
                      padding: 1,
                      borderRadius: 4,
                      background: '#EEEEEE',
                      marginRight: 8,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <User
                      height={10}
                      width={10}
                      color="slategrey"
                    />{' '}
                  </div>
                  <p
                    style={{ fontSize: 12 }}
                    key={n}
                  >
                    {n}
                  </p>
                </div>
              ))}
            </div>
          )}
          <DeleteButton
            onClick={handleDeleteBulk}
            startIcon={deleting && <DeleteCircularProgress size={20} />}
            fullWidth
            disabled={deleting}
          >
            Delete
          </DeleteButton>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default BulkDeleteToolbar;
