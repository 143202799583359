/* eslint-disable */
import { Tooltip } from '@material-ui/core';
import React from 'react';

const ExplanationTooltip = ({
  title, 
  text,
}) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
      <Tooltip
        arrow
        transparent
        style={{ backgroundColor: 'white'}}
        title={(
          <div style={{ minWidth: 160, minHeight: 20, padding: 6, borderRadius: 4 }}>
            <p style={{ fontSize: 12, fontWeight: '600', textAlign: 'left', color: 'white', cursor: 'pointer', marginBottom: 2 }}>{title}</p>
            <p style={{ fontSize: 10, color: 'white', fontWeight: '300', lineHeight: 1.2}}>{text}</p>
          </div>
        )}
      >
        <span style={{ fontSize: 10, fontWeight: '500', textAlign: 'left', color: 'rgb(51, 122, 183)', cursor: 'pointer' }}>
          {title}
        </span>
      </Tooltip>
    </div>
  )
}

export default ExplanationTooltip;