/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Button } from '../../styles';
import { AlertTriangle, CheckCircle } from 'react-feather';

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ModifyPending = ({ details }) => {
  const history = useHistory();
  return (
    <Wrapper>
      <CenterContainer>
        <AlertTriangle
          height={26}
          width={26}
          color="darkorange"
        />
        <div style={{ height: 4 }} />
        <span style={{ fontSize: 16, fontWeight: '600' }}>Module Is Pending Release</span>

        <div style={{ height: 16 }} />
        <span style={{ fontSize: 12, fontWeight: '500' }}>
          This module will be launched on{' '}
          <b style={{ textDecoration: 'underline' }}>
            {new Date(details?.startDate).toDateString()}
          </b>
        </span>
        <div style={{ height: 6 }} />
        <Button
          mt={2}
          variant="outlined"
          color="inherit"
          style={{ background: 'white' }}
          onClick={() => history.push('/modules/')}
        >
          Return To All Modules
        </Button>
      </CenterContainer>
    </Wrapper>
  );
};

export default ModifyPending;
