/* eslint-disable */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { GlobalState } from "../../../../store/GlobalState";
import VendorLaunch from "./vendor";
import RetailLaunch from "./retail";
import ModifyLive from "./ModifyLive";
import ModifyPending from "./ModifyPending";

const Wrapper = styled.div`
  min-height: 480px;
  max-height: 480px
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Launch = ({
  id,
  details,
  steps,
  isRetail,
  isManager,
  isModuleLive,
  setSnackbarText,
  hasVideoConverting,
  isModulePending,
  isModuleConvertingNextStateDev,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  const { state } = useContext(GlobalState);
  const LaunchComponent = isRetail ? RetailLaunch : VendorLaunch;

  if (isRetail && (isModuleLive || isModuleConvertingNextStateLive)) {
    return (
      <Wrapper>
        <ModifyLive details={details} />
      </Wrapper>
    );
  }

  if ((isRetail && isModulePending) || isModuleConvertingNextStatePending) {
    return (
      <Wrapper>
        <ModifyPending details={details} />
      </Wrapper>
    );
  }

  return (
    <>
      <LaunchComponent
        setSnackbarText={setSnackbarText}
        id={id}
        details={details}
        steps={steps}
        hasVideoConverting={hasVideoConverting}
        availableMarketplaces={
          state.config.user.company.available_marketplaces || null
        }
        isManager={isManager}
        isModuleLive={isModuleLive}
        isModulePending={isModulePending}
        isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
        isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        isModuleConvertingNextStatePending={isModuleConvertingNextStatePending}
      />
    </>
  );
};

export default Launch;

Launch.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  isRetail: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
