/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import { Activity } from 'react-feather';
import { LinearProgress } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  max-width: 640px;
  background: #eeeeee;
  border-radius: 4px;
  padding: 6px;
`;

const CustomLinearProgress = styled(LinearProgress)`
  .MuiLinearProgress-barColorPrimary {
    background-color: slategrey;
    border-radius: 4px;
  }
`;

const TranscodingWarning = () => {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 8,
        }}
      >
        <div
          style={{
            background: 'gainsboro',
            padding: 4,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 20,
            height: 20,
          }}
        >
          <Activity
            height={16}
            width={16}
            color="slategrey"
          />
        </div>
        <span style={{ color: 'slategrey', fontWeight: '500' }}>Video Transcoding</span>
      </div>
      <div style={{ height: 8 }} />
      <CustomLinearProgress
        variant="query"
        style={{ width: '100%' }}
      />
    </Wrapper>
  );
};

export default TranscodingWarning;
