/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import InfoBlocks from "./InfoBlocks";
import NewMembersTable from "./NewMembersTable";
import NewSubmissionsTable from "./NewSubmissionsTable";
import HomeEngagementChart from "../reuseableComponents/HomeEngagementChart";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../../components/OnLoadError";
import RightConsoleLayout from "../layouts/RightConsoleLayout";
import HeroLayout from "../layouts/HeroLayout";
import RightSide from "./RightSide";
import useSupervisorHome from "../../../../swr/hooks/Home/useSupervisorHome";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import SubmissionBreakdownTable from "../retail/SubmissionBreakdownTable";
import HomeHeader from "../reuseableComponents/HomeHeader";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import SwitchLocationMenu from "./SwitchLocationMenu";

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const PaddingWrap = styled.div`
  padding: 16px;
`;

const getSubsByType = (submissions, submissionsType) => {
  try {
    if (submissionsType === ENGAGEMENT_TYPES.ASSIGNED) {
      return submissions.filter((sub) => sub.assigned);
    }
    if (submissionsType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return submissions.filter((sub) => !sub.assigned);
    }

    return submissions;
  } catch (error) {
    return [];
  }
};

const ManagerHome = () => {
  const [selectedLocationId, setSelectedLocationid] = useState(null);
  const [submissionsType, setSubmissionType] = useState(ENGAGEMENT_TYPES.ALL);

  const { data, isLoading, isError } = useSupervisorHome(selectedLocationId);

  useEffect(() => {
    if (isLoading) return undefined;
    if (isError) return undefined;

    setSelectedLocationid(currentLocation.id);
  }, [isLoading]);

  if (isLoading) {
    return (
      <>
        <LoaderWrapper text="Dashboard" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  const { current_location: currentLocation } = data;
  const locationList = [currentLocation, ...data.other_managed_locations];

  const recentSubmissionsByType = getSubsByType(
    currentLocation.submissions.recent_submissions,
    submissionsType
  );

  return (
    <Wrapper>
      <HeroLayout>
        <HomeHeader title={currentLocation.name}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {locationList.length > 1 ? (
              <SwitchLocationMenu
                selectedLocationId={selectedLocationId}
                locationList={locationList}
                setSelectedLocationid={setSelectedLocationid}
              />
            ) : (
              <div />
            )}
            <EngagementTypeSelect
              engagementType={submissionsType}
              setEngagementType={setSubmissionType}
            />
          </div>
        </HomeHeader>

        <PaddingWrap>
          <InfoBlocks
            data={currentLocation}
            submissionsType={submissionsType}
          />
        </PaddingWrap>
        <PaddingWrap>
          <HomeEngagementChart
            submissions={recentSubmissionsByType}
            isVendor={false}
            engagementType={submissionsType}
          />
        </PaddingWrap>
        <PaddingWrap>
          <SubmissionBreakdownTable
            submissions={recentSubmissionsByType}
            submissionsType={submissionsType}
            isManager
          />
        </PaddingWrap>
        <PaddingWrap>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 6,
            }}
          ></div>

          <NewSubmissionsTable
            newSubmissions={recentSubmissionsByType}
            submissionsType={submissionsType}
          />
        </PaddingWrap>
        <PaddingWrap>
          <NewMembersTable newEmployees={currentLocation.team_members.recent} />
        </PaddingWrap>
      </HeroLayout>
      <RightConsoleLayout>
        <RightSide />
      </RightConsoleLayout>
    </Wrapper>
  );
};

export default ManagerHome;
