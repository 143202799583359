/* eslint-disable */
/* eslint-disable no-useless-escape */
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { addDays, isSameDay } from "date-fns";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import API from "../../../../axios/instances/API";
import { apiFormatDate } from "../../../../storage/helpers";
import HalfPanel from "../UI/HalfPanel";
import RequiredChip from "../UI/RequiredChip";
import BasicsForm from "./BasicsForm";
import AddModuleImage from "./AddModuleImage";

const checkDatesChanged = (
  startDate,
  initialStartDate,
  dueDate,
  initialDueDate
) => {
  if (
    initialStartDate === null &&
    startDate === null &&
    initialDueDate === null &&
    dueDate === null
  )
    return false;

  if (initialStartDate === null && startDate !== null) return true;
  if (initialDueDate === null && dueDate !== null) return true;

  const startDateChanged = !isSameDay(startDate, initialStartDate);
  const dueDateChanged = !isSameDay(dueDate, initialDueDate);
  return startDateChanged || dueDateChanged;
};

const Basics = ({
  id,
  details,
  setSnackbarText,
  mutate,
  isModulePending,
  isModuleLive,
  isModuleConvertingNextStateDev,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  const initialStartDate = details.startDate
    ? new Date(details.startDate.replace(/-/g, "/"))
    : null;

  const initialDueDate =
    details && details.dueDate
      ? new Date(details.dueDate.replace(/-/g, "/"))
      : null;

  const [startDate, setStartDate] = useState(initialStartDate);
  const [dueDate, setDueDate] = useState(initialDueDate);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [afterCrop, setAfterCrop] = useState(null);
  const [datesChanged, setDatesChanged] = useState(false);

  useEffect(() => {
    if (startDate >= dueDate && dueDate) {
      const newEndDate = addDays(startDate, 1);
      setDueDate(newEndDate);
    }

    const isChanged = checkDatesChanged(
      startDate,
      initialStartDate,
      dueDate,
      initialDueDate
    );

    if (isChanged) {
      return setDatesChanged(true);
    }
    return setDatesChanged(false);
  }, [startDate, dueDate]);

  const initialValues = {
    name: details.name,
    description: details.description ? details.description : "",
    disclaimer: details.disclaimer ? details.disclaimerText : "",
    timeToComplete: details.estimatedTime ? details.estimatedTime : 5,
    lang: details.language ? details.language : "en-us",
    includeDisclaimer:
      (details.disclaimer && details.disclaimerText?.length) > 1 ? "Yes" : "No",
    showAnswers: details.displayAnswers ? "Yes" : "No",
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setFormSubmitting(true);

    try {
      const sendObj = {
        id,
        name: values.name.trim(),
        description: values.description.trim(),
        estimated_time: values.timeToComplete,
        language: values.lang,
        due_date: dueDate ? apiFormatDate(dueDate) : null,
        start_date: startDate ? apiFormatDate(startDate) : null,
        display_answers: values.showAnswers === "Yes",
        /// need to figuire out these two
        disclaimer: values.includeDisclaimer === "Yes",
        disclaimer_text:
          values.includeDisclaimer === "Yes" ? values.disclaimer.trim() : null,
        ///
      };

      await API.put("dashboard/quiz/details/", sendObj);
      await mutate();
      resetForm({
        values: {
          name: sendObj.name,
          description: sendObj.description,
          estimated_time: sendObj.estimated_time,
          language: sendObj.language,
          display_answers: sendObj.display_answers,
          disclaimer: sendObj.disclaimer,
          disclaimer_text: sendObj.disclaimer_text,
        },
      });
      setDatesChanged(false);
      setFormSubmitting(false);
    } catch (error) {
      setFormSubmitting(false);
      setSnackbarText("Failed to update field");
    }
  };

  const handleImageSave = async (blob) => {
    setFormSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("image", blob);
      formData.append("id", id);
      await API.put("dashboard/quiz/image/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setAfterCrop(null);
      setSnackbarText("Updated module image success");
      setFormSubmitting(false);
      mutate();
    } catch (error) {
      setFormSubmitting(false);
      setSnackbarText("Failed to update module image error");
    }
  };

  return (
    <>
      <HalfPanel title="Edit Basic Information">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BasicsForm
            handleFormSubmit={handleFormSubmit}
            initialValues={initialValues}
            startDate={startDate}
            setStartDate={setStartDate}
            dueDate={dueDate}
            setDueDate={setDueDate}
            formSubmitting={formSubmitting}
            details={details}
            datesChanged={datesChanged}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isModulePending={isModulePending}
            isModuleLive={isModuleLive}
            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
            isModuleConvertingNextStatePending={
              isModuleConvertingNextStatePending
            }
          />
        </MuiPickersUtilsProvider>
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />

      <HalfPanel
        title="Edit Module Image"
        RightComponent={() => (
          <RequiredChip
            completed={Boolean(details?.image)}
            title="Required"
            small
            requirementText="Every module must have an image attached"
          />
        )}
      >
        <AddModuleImage
          details={details}
          handleImageSave={handleImageSave}
          formSubmitting={formSubmitting}
          afterCrop={afterCrop}
          setAfterCrop={setAfterCrop}
          setSnackbarText={setSnackbarText}
        />
      </HalfPanel>
    </>
  );
};

export default Basics;

Basics.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
};
