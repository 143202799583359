/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { Typography, SubtitleSmall } from "../../../styles";
import FollowersChart from "./FollowersChart";
import { CHART_TYPES, REPORT_STATUS } from "./utils";
import EngagementChart from "./EngagementChart";
import UniqueUsersChart from "./UniqueUsersChart";
import { AlertTriangle } from "react-feather";
import FunLoader from "../../../../../components/Loaders/FunLoader";

const Wrapper = styled(Paper)`
  padding: 10px;
  flex-grow: 1;
  height: 480px;
  position: relative;
  min-width: 400px;
`;

const getTitles = (chartType) => {
  if (chartType === CHART_TYPES.FOLLOWERS) {
    return {
      title: "New Followers Added",
      subtitle: "Number of new followers added during selected time period",
    };
  }

  if (chartType === CHART_TYPES.UNIQUE_USERS) {
    return {
      title: "Unique Users Breakdown",
      subtitle: "# of unique users by selected time period",
    };
  }

  if (chartType === CHART_TYPES.SUBMISSIONS) {
    return {
      title: "Engagement Breakdown",
      subtitle: "Engagment over selected time period",
    };
  }

  return null;
};

const ShowReport = ({ chartType, chartData, reportStatus, frequency }) => {
  const { title, subtitle } = getTitles(chartType);

  if (reportStatus === REPORT_STATUS.LOADING) {
    return (
      <Wrapper>
        <Typography
          variant="subtitle1"
          mb={-1}
          ml={2}
          style={{ fontWeight: "600" }}
        >
          {title}
        </Typography>
        <SubtitleSmall ml={2}>{subtitle}</SubtitleSmall>
        <div style={{ height: 6 }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: 400,
            borderRadius: 4,
            gap: 8,
            background: "#eeeeee90",
          }}
        >
          <FunLoader />
          <span style={{ color: "lightgray" }}>loading chart ...</span>
        </div>
      </Wrapper>
    );
  }

  if (reportStatus === REPORT_STATUS.ERROR) {
    return (
      <Wrapper>
        <Typography
          variant="subtitle1"
          mb={1}
          ml={2}
          style={{ fontWeight: "600" }}
        >
          {title}
        </Typography>
        <SubtitleSmall ml={2}>{subtitle}</SubtitleSmall>
        <div style={{ height: 6 }} />
        <div
          style={{
            width: "100%",
            minHeight: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: 4,
            background: "#eeeeee90",
          }}
        >
          <AlertTriangle style={{ color: "tomato" }} />
          <span style={{ fontWeight: "600" }}>Error Building Chart</span>
          <span style={{ color: "darkgray", fontSize: 11 }}>
            Try generating a new one
          </span>
        </div>
      </Wrapper>
    );
  }

  const ChartComponents = {
    [CHART_TYPES.FOLLOWERS]: FollowersChart,
    [CHART_TYPES.SUBMISSIONS]: EngagementChart,
    [CHART_TYPES.UNIQUE_USERS]: UniqueUsersChart,
  };

  const Chart = ChartComponents[chartType];

  return (
    <Wrapper>
      <Typography
        variant="subtitle1"
        mb={-1}
        ml={2}
        style={{ fontWeight: "600" }}
      >
        {title}
      </Typography>
      <SubtitleSmall ml={2}>{subtitle}</SubtitleSmall>
      <div style={{ height: 12 }} />
      <Chart data={chartData} frequency={frequency} />
    </Wrapper>
  );
};

export default ShowReport;

ShowReport.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartData: PropTypes.object.isRequired,
  reportStatus: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
};
