import useSWR from 'swr';
import API from '../../../axios/instances/API';

const BASE_URL = 'dashboard/quiz/?origins=0,1&states=0,1,2,3,4';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useLibrary = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
  });

  return (
    {
      data,
      isLoading: !error && !data,
      isError: error,
      mutate,
    }
  );
};

export default useLibrary;
