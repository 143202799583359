/* eslint-disable */
import React, { useState, useContext } from "react";
import { Grid, Modal } from "@material-ui/core";
import { AlertTitle } from "@material-ui/lab";
import { Helmet } from "react-helmet-async";
import PrivateHeader from "../../../components/PrivateHeader";
import { Alert } from "../styles";
import StreamsTable from "./StreamsTable";
import useStreams from "../../../swr/hooks/Streams/useStreams";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../components/OnLoadError";
import CreateStream from "./CreateStream";
import OrderStream from "./OrderStream";
import { GlobalState } from "../../../store/GlobalState";
import NoAccess from "../../../components/NoAccess";
import CreateButton from "../../../components/UI/CreateButton";
import { TrendingUp } from "react-feather";

const Streams = () => {
  const [snackbarText, setSnackbarText] = useState("");
  const [addStreamOpen, setAddStreamOpen] = useState(false);
  const [orderStreamOpen, setOrderStreamOpen] = useState(false);
  const { state } = useContext(GlobalState);
  const { readOnly, isRetail } = state.config;

  const { streams, isLoading, mutate, isError } = useStreams();

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Streams" Icon={TrendingUp} />
        <LoaderWrapper text="Streams" />;
      </>
    );
  }

  if (isError) {
    if (isError.response && isError.response.status === 403) {
      return <NoAccess />;
    }
    return <OnLoadError />;
  }

  return (
    <>
      <Helmet title="Spiffy | Streams" />
      <PrivateHeader header="Streams" Icon={TrendingUp} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div style={{ width: "fit-content" }}>
            <CreateButton
              title="Create Stream"
              handleClick={() => setAddStreamOpen(true)}
              disabled={readOnly}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            <AlertTitle style={{ fontWeight: "600" }}>
              What are streams?
            </AlertTitle>
            {isRetail ? (
              <ul>
                <li>
                  Streams help you to organize modules deployed to your team
                  members.
                </li>
                <li>
                  Streams will be placed vertically in the app feed depending on
                  selected feed position.
                </li>
                <li>
                  You can use the feed position action button to organize the
                  priority level of each stream.
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  Streams help you organize modules deployed to all users. You
                  can create custom streams to categorize similar modules
                  together. For example, you can categorize all limited-time
                  products in one stream and all brand-based modules in another.
                </li>
                <li>Streams are placed vertically in the app feed.</li>
                <li>
                  You can use the Feed Position action button to organize the
                  priority level of each stream.
                </li>
              </ul>
            )}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <StreamsTable
            isRetail={isRetail}
            streams={streams}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Modal
        open={addStreamOpen}
        onClose={() => setAddStreamOpen(false)}
        aria-labelledby="Create New Stream"
        aria-describedby="Modal to Create a New Stream"
      >
        <div>
          <CreateStream
            setSnackbarText={setSnackbarText}
            setAddStreamOpen={setAddStreamOpen}
            mutate={mutate}
          />
        </div>
      </Modal>
      <Modal
        open={orderStreamOpen}
        onClose={() => setOrderStreamOpen(false)}
        aria-labelledby="Order Streams"
        aria-describedby="Modal to Order Your Streams"
      >
        <div>
          <OrderStream
            setSnackbarText={setSnackbarText}
            setOrderStreamOpen={setOrderStreamOpen}
            mutate={mutate}
            streams={streams}
          />
        </div>
      </Modal>

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Streams;
