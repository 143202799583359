/* eslint-disable */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Grid,
} from '../../private/styles';
import useAllVerticals from '../../../swr/hooks/Register/useAllVerticals';
import useAllCountries from '../../../swr/hooks/Register/useAllCountries';
import useStatesByCountry from '../../../swr/hooks/Register/useStatesByCountry';
import useCitiesByState from '../../../swr/hooks/Register/useCitiesByState';
import ExplanationTooltip from './ExplanationTooltip';

const validationSchema = Yup.object({
  companyName: Yup
    .string('Enter your Company Name')
    .required('Company Name is Required'),
  companyVertical: Yup
    .object()
    .required('Industry is required'),
  locationName: Yup
    .string('Enter Location Name')
    .required('Location Name is Required'),
  locationLine1: Yup
    .string('Enter Address Line One')
    .required('Address line 1 is Required'),
  locationLine2: Yup
    .string(),
  country: Yup
    .object()
    .required(),
  state: Yup
    .object()
    .required(),
  city: Yup
    .object()
    .required(),
  postalCode: Yup
    .string('Enter Postal Code')
    .required('Postal Code is Required')
    .min(5, 'Min 5 Characters')
    .max(7, 'Max 7 Characters'),
});

const CreateCompanyForm = ({ 
  signupState,
  dispatch, 
  ACTIONS, 
}) => {

  const { createCompanyInfo } = signupState;
  const [featuredCountryCode, setFeaturedCountryCode] = useState(createCompanyInfo?.country?.code || '');
  const [featuredStateId, setFeaturedStateId] = useState(createCompanyInfo?.state?.id || '')


  const { data: verticals, isLoading: verticalsLoading } = useAllVerticals();
  const { data: countries, isLoading: countriesLoading} = useAllCountries();
  const { data: states, isLoading: statesLoading } = useStatesByCountry(featuredCountryCode);
  const { data: cities, isLoading: citiesLoading } = useCitiesByState(featuredStateId);

  // eslint-disable-next-line consistent-return
  const handleTheSubmit = async (values, { setSubmitting }) => {
    const sendObj = {
      companyName: values.companyName.trim(),
      companyVertical: values.companyVertical,
      locationName: values.locationName.trim(),
      locationLine1: values.locationLine1.trim(),
      locationLine2: values.locationLine2.trim(),
      country: values.country,
      state: values.state,
      city: values.city,
      postalCode: values.postalCode.trim(),
    };

    try {
      dispatch({ type: ACTIONS.CREATE_COMPANY, payload: sendObj });

    } catch (error) {
      setSubmitting(false);
    }
  };

  const shouldFormBeDisabled = (errors, values) => {

    if (Object.values(errors).length > 0) return true;

    const allRequiredFieldsHaveValues = Boolean(
      values.companyName 
      && values.companyVertical 
      && values.locationName 
      && values.locationLine1 
      && values.postalCode
      && values.country
      && values.state
      && values.city
    );

    if (!allRequiredFieldsHaveValues) return true;

    return false;
  }

  const initialValues = {
    companyName: createCompanyInfo.companyName || '',
    companyVertical: createCompanyInfo.companyVertical || '',
    locationName: createCompanyInfo.locationName || '',
    locationLine1: createCompanyInfo.locationLine1 || '',
    locationLine2: createCompanyInfo.locationLine2 || '',
    country: createCompanyInfo.country || '',
    state: createCompanyInfo.state || '',
    city: createCompanyInfo.city || '',
    postalCode: createCompanyInfo.postalCode || '',
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleTheSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>            
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="companyName"
                  label="Company Name"
                  value={values.companyName}
                  fullWidth
                  error={Boolean(touched.companyName && errors.companyName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.companyName && errors.companyName}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  type="text"
                  name="companyVertical"
                  label="Industry"
                  value={values.companyVertical}
                  fullWidth
                  error={Boolean(touched.companyVertical && errors.companyVertical)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.companyVertical && errors.companyVertical}
                  autoComplete="new-password"
                  variant="outlined"
                  disabled={verticalsLoading}
                  size="small"
                >
                  {verticalsLoading ? (
                    <MenuItem disabled>
                      none
                    </MenuItem>
                  ) : (
                    verticals?.map(vertical => (
                     <MenuItem value={vertical} key={vertical.id}>
                       {vertical.name}
                     </MenuItem>
                    ))
                  )
                 }
                </TextField>
                <ExplanationTooltip
                  title="What is this?"
                  text="Industry field is used to categorize the nature of your business; this field helps customize your dashboard and the marketplace to ensure relevant content is available for your team. Ex. If you’re a Cannabis store, you should select ‘Cannabis’ as your industry, or if you’re a Cafè, you should select ‘Quick Service Restaurant’ as your Industry."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="locationName"
                  label="Location Name"
                  value={values.locationName}
                  fullWidth
                  error={Boolean(touched.locationName && errors.locationName)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.locationName && errors.locationName}
                  autoComplete="new-password"
                  variant="outlined"
                  mt={-3}
                  size="small"
                />
                <ExplanationTooltip
                  title="What is this?"
                  text="Your team members use Location Names to identify the location they work at when they sign up. 
                  For Ex. “Ancaster” or “Flagship Store“"
                />
              </Grid>
              <Grid item xs={6} mt={-2}>
                <TextField
                  type="text"
                  name="locationLine1"
                  label="Location Line One"
                  value={values.locationLine1}
                  fullWidth
                  error={Boolean(touched.locationLine1 && errors.locationLine1)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.locationLine1 && errors.locationLine1}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6} mt={-2}>
                <TextField
                  type="text"
                  name="locationLine2"
                  label="Location Line Two"
                  value={values.locationLine2}
                  fullWidth
                  error={Boolean(touched.locationLine2 && errors.locationLine2)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.locationLine2 && errors.locationLine2}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="country"
                  select
                  label="Country"
                  onChange={e => {
                    const { value } = e.target
                    setFeaturedCountryCode(value?.code)
                    setFieldValue('state', '')
                    setFieldValue('city', '')
                    handleChange(e)
                  }}
                  disabled={countriesLoading}
                  value={values.country}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  {
                    countriesLoading && (
                      <MenuItem value={null} disabled>
                        loading...
                      </MenuItem>
                    )
                  }
                  {
                    countries?.map(country => (
                      <MenuItem value={country} key={country.code}>
                        {country.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="state"
                  select
                  label="State"
                  disabled={statesLoading || !values.country}
                  onChange={e => {
                    const { value } = e.target;
                    setFeaturedStateId(value?.id)

                    setFieldValue('city', '')
                    handleChange(e);
                  }}
                  value={values.state}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  {
                    statesLoading && (
                      <MenuItem value={null} disabled>
                        loading...
                      </MenuItem>
                    )
                  }
                  {states?.map(state => (
                    <MenuItem value={state} key={state.code}>
                      {state.name}
                    </MenuItem>))
                  }
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  name="city"
                  label="City"               
                  onChange={handleChange}
                  value={values.city}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={citiesLoading || !values.state} 
                >
                  {
                    citiesLoading && (
                      <MenuItem value={null} disabled>
                        loading...
                      </MenuItem>
                    )
                  }
                  {cities?.map(city => (
                    <MenuItem value={city} key={city.id}>
                      {city.name}
                    </MenuItem>))
                  }
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="postalCode"
                  label="Postal Code"
                  value={values.postalCode}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.postalCode && errors.postalCode}
                  autoComplete="new-password"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
            <Button
              mt={6}
              mb={2}
              p={2}
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              disabled={shouldFormBeDisabled(errors, values)}
              startIcon={isSubmitting && <CircularProgress size={20} />}
            >
              {isSubmitting ? 'Submitting' : 'Create Company' }
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateCompanyForm;

CreateCompanyForm.propTypes = {
  dispatch: PropTypes.func.isRequired, 
  ACTIONS: PropTypes.object.isRequired, 
  signupState: PropTypes.object.isRequired,
};
