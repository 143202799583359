/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components/macro';
import { mutate as swrMutate } from 'swr';
import API from '../../../../axios/instances/API';
import { Typography } from '../../styles';
import QuestionDetailsMC from './Display/QuestionDetailsMC';
import QuestionDetailsSA from './Display/QuestionDetailsSA';
import QuestionDetailsTF from './Display/QuestionDetailsTF';
import { QUESTION_TYPES } from '../../../../storage/constants';

const CustomSummary = styled(AccordionSummary)`
  border-radius: 4px;
  background-color: #eeeeee66;
  min-height: 60px;

  .MuiExpanded {
    min-height: 10px;
    background-color: white;
  }

  .MuiAccordionSummary-content {
    display: flex;
  }
`;

const CustomDetail = styled(AccordionDetails)`
  padding: 8px 8px 0px 8px;
  margin-top: -4px;

  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const ShowQuizWrapper = styled.div`
  height: inherit;
  min-height: 380px;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 20px;
`;

const StyledAccordion = styled(Accordion)`
  border: 1px solid lightgray;
  box-shadow: none;
  margin-bottom: 4px;
  border-radius: 4px;

  &:before {
    display: none;
  }
`;

const Centered = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  min-height: 464px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #eeeeee44;
  border-radius: 4px;
  border: 1px solid #eeeeee;
`;

const ShowQuiz = ({
  id,
  details,
  builderContext,
  questions,
  setSnackbarText,
  isModuleLive,
  isModuleConvertingNextStateLive,
  isModulePending,
  isModuleConvertingNextStatePending,
}) => {
  const [listQuestions, setListQuestions] = useState([]);

  // Keeps local question state aligned with api question state
  useEffect(() => {
    setListQuestions(questions);
  }, [questions]);

  const numQuestions = questions.length;

  if (numQuestions < 1) {
    return (
      <Centered>
        <Typography style={{ fontSize: 15, fontWeight: '600' }}>
          No questions added to this module.
        </Typography>
        <Typography style={{ color: 'darkgrey', fontSize: 12 }}>
          Create some questions and they will show up here.
        </Typography>
      </Centered>
    );
  }

  const handleDeleteQuestion = async deleteQuestionId => {
    if (isModuleLive || isModuleConvertingNextStateLive) {
      return setSnackbarText(
        'Cannot delete questions from live modules. Only editing permitted. error'
      );
    }

    const filteredQuestions = questions.filter(question => question.id !== deleteQuestionId);

    if (
      filteredQuestions?.length === 0 &&
      (isModulePending || isModuleConvertingNextStatePending)
    ) {
      return setSnackbarText('Pending modules must have at least 1 question error');
    }

    const sendObj = {
      id,
      pass_score:
        details.passScore > filteredQuestions.length ? filteredQuestions.length : details.passScore,
      questions: filteredQuestions,
    };

    try {
      await API.put('dashboard/quiz/questions/', sendObj);
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);

      setSnackbarText('Question deleted warning');
    } catch (error) {
      setSnackbarText('Failed to delete question. Please try again. error');
    }
  };

  const handleReorder = async items => {
    const sendObj = {
      id,
      pass_score: details.passScore,
      questions: items,
    };

    try {
      await API.put('dashboard/quiz/questions/', sendObj);
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      setSnackbarText('Questions reordered success');
    } catch (error) {
      setSnackbarText('Failed to reorder question. Please try again. error');
    }
  };

  const handleDragEnd = result => {
    if (!result.destination) return;
    const items = Array.from(listQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setListQuestions(items);
    handleReorder(items);
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: 420,
        marginBottom: 4,
      }}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="testDroppable">
          {provided => (
            <ShowQuizWrapper
              className="testDroppable"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listQuestions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={String(question.id)}
                  index={index}
                >
                  {provided => (
                    <StyledAccordion
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CustomSummary
                        expandIcon={
                          <ChevronDown
                            height={18}
                            width={18}
                            color="black"
                          />
                        }
                      >
                        <div
                          style={{
                            flex: 1,

                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: ' flex-start',
                            flexDirection: 'column',

                            gap: 2,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              marginBottom: 0,
                              gap: 4,
                              width: '100%',
                            }}
                          >
                            <span
                              style={{
                                fontSize: 12,

                                fontWeight: '500',
                                color: '#707A82',
                              }}
                            >
                              Question # {index + 1}
                            </span>
                          </div>
                          <div>
                            <Typography
                              style={{
                                fontWeight: '500',
                                fontSize: 13,
                              }}
                            >
                              {question.question}
                            </Typography>
                          </div>
                        </div>
                      </CustomSummary>
                      <CustomDetail>
                        {question.type === QUESTION_TYPES.TRUE_FALSE && (
                          <QuestionDetailsTF
                            question={question}
                            handleDeleteQuestion={handleDeleteQuestion}
                            builderContext={builderContext}
                            isReview={false}
                            isModuleLive={isModuleLive}
                            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
                          />
                        )}
                        {question.type === QUESTION_TYPES.MULTIPLE_CHOICE && (
                          <QuestionDetailsMC
                            question={question}
                            handleDeleteQuestion={handleDeleteQuestion}
                            builderContext={builderContext}
                            isReview={false}
                            isModuleLive={isModuleLive}
                            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
                          />
                        )}
                        {question.type === QUESTION_TYPES.SELECT_ALL && (
                          <QuestionDetailsSA
                            question={question}
                            handleDeleteQuestion={handleDeleteQuestion}
                            builderContext={builderContext}
                            isReview={false}
                            isModuleLive={isModuleLive}
                            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
                          />
                        )}
                      </CustomDetail>
                    </StyledAccordion>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ShowQuizWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ShowQuiz;

ShowQuiz.propTypes = {
  builderContext: PropTypes.object.isRequired,
  questions: PropTypes.array,
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
};

ShowQuiz.defaultProps = {
  questions: null,
};
