/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useState } from "react";
import {
  Avatar,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Plus } from "react-feather";
import styled from "styled-components";
import { mutate as swrMutate } from "swr";
import API from "../../../../../axios/instances/API";
import { QUESTION_TYPES } from "../../../../../storage/constants";
import { Button, TextField, Typography } from "../../../styles";

const QHolder = styled.div`
  min-height: 420px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const SaveButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  min-width: 110px;
`;

const MGrid = styled(Grid)`
  margin: -8px;
  margin-bottom: 20px;
`;

const QuestionAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  background-color: ${(props) =>
    props.activecolor === "Yes" ? "#337ab7" : props.theme.palette.grey[400]};
`;

const initalOptions = {
  a: "",
  b: "",
  c: "",
  d: "",
};

const initialRadioOptions = {
  A: false,
  B: false,
  C: false,
  D: false,
};

const QuestionSA = ({
  id,
  setSnackbarText,
  questions,
  isModuleLive,
  details,
  isModuleConvertingNextStateLive,
}) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(initalOptions);
  const [radioOptions, setRadioOptions] = useState(initialRadioOptions);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOptions = (e) => {
    const { name, value } = e.target;
    setOptions({ ...options, [name]: value });
  };

  const handleCheckboxClick = (e) => {
    const tar = e.target.value;
    const newBool = !radioOptions[tar];
    setRadioOptions({ ...radioOptions, [tar]: newBool });
  };

  const handleDisabled = () => {
    const vals = Object.values(options);
    const optionsFilled = vals.every((val) => String(val).length > 0);
    const radioVals = Object.values(radioOptions);
    const answerChosen = radioVals.some((val) => val === true);

    return (
      !(question.length > 2 && optionsFilled && answerChosen) ||
      isSubmitting ||
      isModuleConvertingNextStateLive
    );
  };

  const resetSA = () => {
    setQuestion("");
    setOptions(initalOptions);
    setRadioOptions(initialRadioOptions);
  };

  const handleSaveQuestion = async () => {
    setIsSubmitting(true);
    const correctAnswers = [];
    const keys = Object.keys(radioOptions);
    keys.forEach((key) => {
      if (radioOptions[key] === true) {
        correctAnswers.push(key);
      }
    });

    const sendObj = {
      id,
      pass_score: details.passScore,
      questions: [
        ...questions,
        {
          title: "none",
          question,
          type: QUESTION_TYPES.SELECT_ALL,
          correctAnswer: correctAnswers,
          options,
        },
      ],
    };

    try {
      await API.put("dashboard/quiz/questions/", sendObj);
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      setIsSubmitting(false);
      resetSA();
      return setSnackbarText("Question successfully added success");
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to add auestion. Please try again. error");
    }
  };

  return (
    <QHolder>
      <Typography variant="h4" mt={4} mb={4}>
        Select All
      </Typography>
      <Typography variant="subtitle2" mb={1} mt={2}>
        Your Question
      </Typography>

      <Grid container>
        <Grid item xs={10}>
          <TextField
            type="text"
            size="small"
            variant="outlined"
            style={{ background: "white" }}
            multiline
            value={question}
            onChange={(e) => setQuestion(e.currentTarget.value)}
            fullWidth
            mb={2}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="subtitle2" mb={1} mt={2}>
            Your Answers
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2" mb={1} mt={2} align="center">
            Answer Key
          </Typography>
        </Grid>
      </Grid>
      <MGrid container spacing={4} alignItems="center">
        {["A", "B", "C", "D"].map((letter) => (
          <Grid item xs={12} key={letter}>
            <Grid container>
              <Grid item xs={10}>
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  placeholder={`Answer ${letter}`}
                  name={letter.toLowerCase()}
                  value={options[letter.toLowerCase()]}
                  onChange={handleOptions}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QuestionAvatar
                          activecolor={radioOptions[letter] ? "Yes" : "normal"}
                        >
                          <span style={{ fontSize: 14 }}>{letter}</span>
                        </QuestionAvatar>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} align="center">
                <Checkbox
                  checked={radioOptions[letter]}
                  disabled={isModuleLive}
                  onChange={handleCheckboxClick}
                  value={letter}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </MGrid>

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",

          justifyContent: " flex-start",
          gap: 12,
        }}
      >
        <SaveButton
          variant="contained"
          color="secondary"
          disabled={handleDisabled()}
          onClick={handleSaveQuestion}
          startIcon={
            isSubmitting ? (
              <CircularProgress size={12} color="white" />
            ) : (
              <Plus height={14} width={14} color="white" />
            )
          }
        >
          {isSubmitting ? "Saving Question" : "Add Question"}
        </SaveButton>
      </div>
    </QHolder>
  );
};

export default QuestionSA;

QuestionSA.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  builderContext: PropTypes.object.isRequired,
  isModuleLive: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
};
