/* eslint-disable */
import { format, addDays } from "date-fns";

export const BASE_ENDPOINT =
  "/dashboard/analytics/vendor-reports/interval-data";

export const REPORT_STATUS = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  VIEW: "VIEW",
};

export const CHART_TYPES = {
  FOLLOWERS: "followers",
  UNIQUE_USERS: "unique_users",
  SUBMISSIONS: "submissions",
};

export const TIME_PERIODS = {
  // Daily
  SEVEN_DAYS: "SEVEN_DAYS",
  FOURTEEN_DAYS: "FOURTEEN_DAYS",
  TWENTY_ONE_DAYS: "TWENTY_ONE_DAYS",
  TWENTY_EIGHT_DAYS: "TWENTY_EIGHT_DAYS",

  // WEEKLY
  FOUR_WEEKS: "FOUR_WEEKS",
  EIGHT_WEEKS: "EIGHT_WEEKS",
  TWELVE_WEEKS: "TWELVE_WEEKS",
  SIXTEEN_WEEKS: "SIXTEEN_WEEKS",

  // MONTHLY

  THREE_MONTHS: "THREE_MONTHS",
  SIX_MONTHS: "SIX_MONTHS",
  NINE_MONTHS: "NINE_MONTHS",
  TWELVE_MONTHS: "TWELVE_MONTHS",
};

export const FREQUENCIES = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  DAILY: "daily",
};

const WEEKLY_LABEL_FORMAT = "MMM do";
const MONTHY_LABEL_FORMAT = "MMM yyyy";
const DAILY_LABEL_FORMAT = "MMM do";

export const getCorrectLabels = (data, frequency) => {
  if (frequency === FREQUENCIES.MONTHLY) {
    return data.map((v) => format(new Date(v.end_date), MONTHY_LABEL_FORMAT));
  }
  if (frequency === FREQUENCIES.DAILY) {
    return data.map((v) => format(new Date(v.end_date), DAILY_LABEL_FORMAT));
  }

  if (frequency === FREQUENCIES.WEEKLY) {
    return data.map((v) => {
      const firstDayOfWeek = format(
        addDays(new Date(v.start_date), 1),
        WEEKLY_LABEL_FORMAT
      );
      const lastDayOfWeek = format(addDays(new Date(v.end_date), 1), "do");

      return `${firstDayOfWeek} - ${lastDayOfWeek}`;
    });
  }
};
