/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { mutate as swrMutate } from "swr";
import styled from "styled-components/macro";
import { Typography, Button, TextField, Alert } from "../../../styles";
import API from "../../../../../axios/instances/API";

const MarginButton = styled(Button)`
  margin-top: auto;
`;

const Wrapper = styled.div`
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;

const VendorAudienceEdit = ({
  vendorAudience,
  mutate,
  details,
  setSnackbarText,
}) => {
  const [comboValue, setComboValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetKey, setResetKey] = useState("");

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await API.put("/dashboard/quiz/audience/vendor/", {
        id: vendorAudience.id,
        audience_id: comboValue.id,
      });
      setSnackbarText("Successfully updated your audience success");
      setIsSubmitting(false);
      mutate();
      setResetKey((p) => !p);
      swrMutate(`/dashboard/quiz/review/?id=${vendorAudience.id}`);
      setComboValue("");
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to update your audience error");
      setComboValue("");
      setResetKey((p) => !p);
    }
  };

  return (
    <Wrapper>
      <Typography variant="h3">Select Your Audience</Typography>
      <Typography variant="subtitle1">
        Choose from One of Your Saved Audiences
      </Typography>
      {!details.isSuggested ? (
        <Alert severity="info" mt={4}>
          Most content is deployed to the marketplace, which means it is
          accessible to our broader user base. If you want to create a module
          for a specific company or select companies, you can use Custom
          Audiences to target them.
        </Alert>
      ) : (
        <Alert severity="warning" mt={4}>
          This module has been suggested to an audience. <br />
          You cannot change the audience after the module has been suggested.
        </Alert>
      )}

      <Typography variant="subtitle2" mt={18} mb={2}>
        Your Saved Audiences
      </Typography>
      <Autocomplete
        key={resetKey}
        size="small"
        disabled={details.isSuggested}
        options={vendorAudience.savedAudiences}
        noOptionsText="No Saved Audiences"
        getOptionLabel={(option) => option.name}
        onChange={(_, newValue) => setComboValue(newValue)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Audience"
            variant="outlined"
            size="small"
            mt={2}
          />
        )}
      />
      <MarginButton
        variant="contained"
        fullWidth
        color="secondary"
        disabled={!comboValue || isSubmitting}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        onClick={handleSubmit}
      >
        {isSubmitting ? "Saving Audience" : "Save Audience"}
      </MarginButton>
    </Wrapper>
  );
};

export default VendorAudienceEdit;

VendorAudienceEdit.propTypes = {
  vendorAudience: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
